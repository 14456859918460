import * as cmsSEO from '../actions/cms.seo-management.actions';
import * as fromApp from 'abs@core/store/reducers';

export interface State {
  supportedLanguages: any;
  pageData: any;
}

export const initialState: State = {
  supportedLanguages: [],
  pageData: null,
};

export function CmsSEOReducer(state = initialState, action: cmsSEO.Actions): State {
  switch (action.type) {
    case cmsSEO.SET_SUPPORTED_LANGUAGES: {
      return {
        ...state,
        supportedLanguages: action.payload,
      };
    }
    // case cmsSEO.SET_SEO_PAGES_URLS: {
    //   return {
    //     ...state,
    //     pageUrls: action.payload,
    //   };
    // }
    case cmsSEO.RESET_SEO_PAGE_DROPDOWNS: {
      return {
        ...state,
        supportedLanguages: [],
        pageData: null,
      };
    }
    case cmsSEO.SET_SEO_PAGE_DATA: {
      return {
        ...state,
        pageData: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const getSEOSupportedLanguages = (state: fromApp.AppState) => state.cmsSEOReducer.supportedLanguages;
export const getSEOPageData = (state: fromApp.AppState) => state.cmsSEOReducer.pageData;
