import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-comment-form',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.css'],
})
export class CommentFormComponent implements OnInit {
  data: any;
  form: FormGroup;
  submitted: boolean;
  showExternalId: boolean;
  constructor(private formBuilder: FormBuilder, public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.data = this.config.data;
    this.form = this.formBuilder.group({
      comments: new FormControl(null),
    });
    if (this.data.extra && this.data.extra.requireExternalId) {
      this.form.addControl(this.data.extra.controlName, new FormControl(null, [Validators.required]));
      this.showExternalId = true;
    }
  }
  close() {
    this.ref.close();
  }
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.ref.close(this.form.value);
  }
  get f() {
    return this.form.controls;
  }
}
