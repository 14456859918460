import {
  ActiveFilter,
  AutoCompleteInput,
  BOFilter,
  DateInput,
  DropDownInput,
  MultiSelectInput,
} from '../../models/models';
import {
  CLEAR_ONE_FILTER,
  DESTROY_ALL_FILTERS,
  RESET_ALL_FILTERS,
  SET_BO_FILTER_TIME_ZONE,
  SET_BO_FILTERS,
  UPDATE_BO_FILTERS_CONFIG,
} from '../actions/bo-filter.actions';
import * as fromApp from '../../../../../core/store/reducers';
import { createSelector } from '@ngrx/store';
import { ePrimeNGInputType } from '../../models/enums';
import copy from 'fast-copy';
import { TimeZoneModel } from '../../../../../core/models/timeZone.model';
import { GeneralConstants } from '../../../../../core/app.config';
import * as moment from 'moment-timezone';
import { AppSettings } from 'src/app/core/app.config';
import { isNullOrUndefined } from '../../../../shared-functions/functions';
import { forEach } from "lodash";

export interface State {
  boFilter: Map<string, BOFilter>;
  BOFilterTimeZone: TimeZoneModel;
}

export const initialState: State = {
  boFilter: null,
  BOFilterTimeZone: null,
};

// NOTE: NEED TO USE copy BECAUSE OF IMMUTABILITY
export function BOFilterReducer(state = initialState, action: any): State {
  switch (action.type) {
    case SET_BO_FILTERS: {
      return {
        ...state,
        boFilter: setFilters(action.payload.boFilters, action.payload.firstTime),
      };
    }
    case UPDATE_BO_FILTERS_CONFIG: {
      return {
        ...state,
        boFilter: updateFilter(state.boFilter, action.payload),
      };
    }
    case CLEAR_ONE_FILTER: {
      const newBoFilters = new Map(state.boFilter);
      if (newBoFilters.get(action.payload)) {
        newBoFilters.get(action.payload).models.forEach((model) => {
          model.componentConfig.selectedValue = null;
        });

        // if filter owns childConnectionKey, we need to clear the childFilter recursively from their options
        let isRequest = !!newBoFilters.get(action.payload).onChange?.request;
        let nextChildFilter = newBoFilters.get(newBoFilters.get(action.payload).childConnectionKey);
        while (nextChildFilter) {
          clearChildFilter(nextChildFilter, isRequest);
          isRequest = !!nextChildFilter.onChange?.request;
          nextChildFilter = newBoFilters.get(nextChildFilter.childConnectionKey);
        }
      } else {
        console.error('Cannot get ', action.payload);
      }

      return {
        ...state,
        boFilter: newBoFilters,
      };
    }
    case RESET_ALL_FILTERS: {
      return {
        ...state,
        boFilter: resetFilters(state.boFilter),
      };
    }
    case DESTROY_ALL_FILTERS: {
      return {
        ...state,
        boFilter: null,
      };
    }
    case SET_BO_FILTER_TIME_ZONE: {
      return {
        ...state,
        BOFilterTimeZone: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const getSelectedFilters = (state: fromApp.AppState) => new Map(state.boFilterReducer.boFilter);
export const getBOFilterTimeZone = (state: fromApp.AppState) => state.boFilterReducer.BOFilterTimeZone;

export const getActiveFilters = createSelector(
  getSelectedFilters,
  getBOFilterTimeZone,
  (filters: Map<string, BOFilter>, timeZone: TimeZoneModel) => {
    // IN THE ACTIVE FILTERS COMPONENT WE GET THE DATA FROM THE SELECTOR, PLACE THE LABEL FIRST
    // THEN WE SHOULD CONVERT DROPDOWNS AND MULTISELECTS TO STRINGS AND USE THE SEPARATOR BETWEEN THEM
    // IF MORE THAN 2, OTHERWISE 'Label: Value'
    if (filters) {
      const activeFilters: ActiveFilter[] = [];

      for (const [, value] of filters) {
        // WE NEED TO CHECK IF AT LEAST ONE OF THE MODELS OF BO-FILTER HAS A VALUE, OR MORE
        const siblingValues = [];
        for (let i = 0; i < value.models.length; i++) {
          const model = value.models[i];

          if (!isNullOrUndefined(model.componentConfig.selectedValue)) {
            switch (model.componentType) {
              case ePrimeNGInputType.multiselect:
                {
                  const values = [];
                  // MAKE SURE ITS AN ARRAY, OPTHERWISE IT WILL THROW AN ERROR
                  if (Array.isArray(model.componentConfig.selectedValue)) {
                    if ((model.componentConfig as MultiSelectInput).optionsMapForActiveFilter) {
                      for (const val of model.componentConfig.selectedValue) {
                        values.push((model.componentConfig as MultiSelectInput).optionsMapForActiveFilter.get(val));
                      }
                    }
                    let shortValue = values.join();
                    if (shortValue.length > AppSettings.ACTIVE_FILTER_TEXT_MAX_LENGTH) {
                      shortValue = shortValue.substring(0, AppSettings.ACTIVE_FILTER_TEXT_MAX_LENGTH) + '...';
                    }

                    if (values.length > 0) siblingValues.push(shortValue);
                  }
                }
                break;
              case ePrimeNGInputType.dropdown:
                siblingValues.push(
                  (model.componentConfig as DropDownInput).optionsMapForActiveFilter.get(
                    model.componentConfig.selectedValue
                  )
                );
                break;
              case ePrimeNGInputType.autocomplete:
                {
                  const fieldName = (model.componentConfig as AutoCompleteInput).field;
                  if (model.componentConfig.selectedValue[fieldName]) {
                    siblingValues.push(model.componentConfig.selectedValue[fieldName]);
                  }
                }
                break;
              case ePrimeNGInputType.date: {
                const dateValue = (model.componentConfig as DateInput).selectedValue as Date;
                const date =
                  moment(dateValue, GeneralConstants.PIPE_DATE_TIME_FORMAT_MOMENT_USE).isValid() === false
                    ? moment(dateValue)
                    : moment(dateValue, GeneralConstants.PIPE_DATE_TIME_FORMAT_MOMENT_USE);

                if (model.componentConfig['activeFilterFormat']) {
                  const formattedDate = date.tz(timeZone.tzCode).format(model.componentConfig['activeFilterFormat']);
                  siblingValues.push(formattedDate); // custom FORMAT
                } else {
                  const formattedDate = date
                    .tz(timeZone.tzCode)
                    .format(GeneralConstants.PIPE_DATE_TIME_FORMAT_MOMENT_USE);
                  siblingValues.push(formattedDate);
                }
              }
                break;
              default:
                siblingValues.push(model.componentConfig.selectedValue);
                break;
            }
          }
        }
        if (siblingValues.length > 0) {
          activeFilters.push({
            key: value.label,
            value: siblingValues.join(value.activeFilterConfig?.valuesConnector || ' - '),
            disabled: value.models[0].componentConfig.disabled,
          });
        }
      }

      activeFilters.sort((a, b) => {
        if (a.disabled > b.disabled) return -1;
        if (a.disabled < b.disabled) return 1;
        return 0;
      });

      return activeFilters;
    }
    return null;
  }
);

function setFilters(boFilters: Map<string, BOFilter>, firstTime: boolean): Map<string, BOFilter> {
  const filters = boFilters;
  for (const [, value] of filters) {
    for (let i = 0; i < value.models.length; i++) {
      value.models[i].componentConfig.selectedValue =
        isNullOrUndefined(value.models[i].componentConfig.selectedValue) && firstTime
          ? copy(value.models[i].componentConfig.defaultVal)
          : value.models[i].componentConfig.selectedValue;
    }
  }
  return filters;
}

function updateFilter(boFilters: Map<string, BOFilter>, updateOptions: any) {
  const filters = boFilters;
  const options = updateOptions;
  options.keys.forEach((key) => {
    filters.get(key)?.models.forEach((model) => {
      for (const [optionKey, optionValue] of Object.entries(options.result)) {
        model.componentConfig[optionKey] = optionValue;
      }
    });
  });
  return filters;
}

function resetFilters(boFilters: Map<string, BOFilter>): Map<string, BOFilter> {
  const filters = boFilters;
  for (const [key, value] of filters) {
    for (let i = 0; i < value.models.length; i++) {
      const defaultVal = value.models[i].componentConfig.defaultVal;
      value.models[i].componentConfig.selectedValue = isNullOrUndefined(defaultVal)
        ? null
        : copy(value.models[i].componentConfig.defaultVal);
    }
    if (value.onChange?.action?.mapResult) {
      forEach(value.onChange.action.keys, (key) => {
        const boFilterToConfigure = filters.get(key);
        for (let i = 0; i < boFilterToConfigure.models.length; i++) {
          boFilterToConfigure.models[i].componentConfig = Object.assign(
            boFilterToConfigure.models[i].componentConfig,
            value.onChange.action.mapResult[0]
          );
        }
      });
    }
    if (filters.get(key).childConnectionKey) {
      clearChildFilter(filters.get(filters.get(key).childConnectionKey), !!value.onChange?.request);
    }
  }
  return filters;
}

function clearChildFilter(childFilter: BOFilter, isRequest: boolean) {
  // Check if childFilter is defined and has models
  if (!childFilter) {
    return;
  }

  if (!childFilter.models) {
    return;
  }

  if (childFilter.models.length === 0) {
    return;
  }

  if (isRequest) {
    const componentConfig = childFilter.models[0].componentConfig as MultiSelectInput;

    if (componentConfig) {
      componentConfig.options = null;
      componentConfig.staticOptions = null;
      componentConfig.optionsMapForActiveFilter = null;
    } else {
      console.error('componentConfig is undefined');
    }
  }

  if (childFilter.models[0].componentConfig) {
    childFilter.models[0].componentConfig.selectedValue = null;
  } else {
    return;
  }

  childFilter.hidden = true;
}
