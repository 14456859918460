import { createAction, props } from '@ngrx/store';
import { Filtering } from 'src/app/sport-betting/common/models';
import { FailAction } from 'src/app/sport-betting/common/models/fail-action.model';
import { SportTeam } from '../../models/entities';
import { UpdateSportTeam } from '../../models/update-entities';

const SB_GET_TEAMS = '[SB] Get teams';
const SB_SET_TEAMS = '[SB] Set teams';
const SB_DELETE_TEAM = '[SB] Delete team';
const SB_UPDATE_TEAM = '[SB CRUD] Update team';
const SB_UPDATE_TEAM_SUCCESS = '[SB] Update team SUCCESS';
const SB_TEAM_FAILURE = '[SB CRUD] League failure';

export const SbGetTeams = createAction(SB_GET_TEAMS, props<{
  payload: Filtering
}>());
export const SbSetTeams = createAction(SB_SET_TEAMS, props<{
  payload: {
    leagueId: number,
    data: Array<SportTeam>,
    totalCount: number
  }
}>());
export const SbDeleteTeam = createAction(SB_DELETE_TEAM, props<{
  payload: {
    leagueId: number,
  }
}>());
export const SbUpdateTeam = createAction(SB_UPDATE_TEAM, props<{
  payload: {
    entity: UpdateSportTeam
    leagueId: number
  }
}>());
export const SbUpdateTeamSuccess = createAction(SB_UPDATE_TEAM_SUCCESS, props<{
  payload: {
    entity: UpdateSportTeam
    leagueId: number
  }
}>());
export const TeamsFailure = createAction(SB_TEAM_FAILURE, props<FailAction>());