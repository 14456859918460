import { Component, Input, OnInit } from '@angular/core';
import { pTableLink } from '../../../models/p-table';
import { AuthorizationService } from '../../../authorization/authorization.service';
import { ePTableColumnType } from '../../../eNums';

@Component({
  selector: 'app-navigation-cell',
  templateUrl: './navigation-cell.component.html',
  styleUrls: ['./navigation-cell.component.scss'],
})
export class NavigationCellComponent implements OnInit {
  @Input() rowData: any;
  @Input() linkData: pTableLink;
  @Input() noDataTemplate?: boolean;
  public mainDisplayValue: number;
  public bracketDisplayValue: number;
  public tableColumnTypeEnum = ePTableColumnType;

  constructor(private authorizationService: AuthorizationService) {}

  ngOnInit(): void {
    const parentField = this.linkData?.parentField;
    const mainField = this.linkData?.mainValue?.field;
    const bracketField = this.linkData?.bracketValue?.field;

    if (parentField) {
      this.mainDisplayValue = this.rowData?.[parentField]?.[mainField];
      this.bracketDisplayValue = this.rowData?.[parentField]?.[bracketField];
    } else {
      this.mainDisplayValue = this.rowData?.[mainField];
      this.bracketDisplayValue = this.rowData?.[bracketField];
    }
  }

  public hasPermission(): boolean {
    return this.authorizationService.hasPermission(
      this.linkData?.permissionsScreensCodes as string,
      this.linkData?.permissionLevel
    );
  }
}
