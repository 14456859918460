import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { getSelectedTimeZone } from '../../core/store/reducers/metadata.reducer';
import { GeneralConstants } from '../../core/app.config';
import * as moment from 'moment-timezone';
import { TimeZoneModel } from '../../core/models/timeZone.model';

@Pipe({
  name: 'dateTimeTimezoneStatic',
})
export class DateTimeTimezoneStaticPipe implements PipeTransform {
  selectedTimeZone: TimeZoneModel;

  constructor(private store: Store<any>) {
    this.store.select(getSelectedTimeZone).subscribe((selectedTimeZone) => (this.selectedTimeZone = selectedTimeZone));
  }

  transform(value: any, args?: any): string {
    const date =
      moment(value, GeneralConstants.PIPE_DATE_TIME_FORMAT_MOMENT_USE).isValid() === false
        ? moment(value)
        : moment(value, GeneralConstants.PIPE_DATE_TIME_FORMAT_MOMENT_USE);

    return date
      .tz(this.selectedTimeZone.tzCode)
      .format(args == null ? GeneralConstants.PIPE_DATE_TIME_FORMAT_MOMENT_USE : args);
  }
}
