<div *ngIf="linkData else noData">
  <ng-template *ngIf="linkData?.redirect && hasPermission(); then linkTemplate; else noLinkTemplate"></ng-template>
  <ng-template #linkTemplate>

    <!-- mainDisplayValue && bracketDisplayValue -->
    <ng-container *ngIf="mainDisplayValue && bracketDisplayValue">
      <ng-template *ngIf="true then mainDisplay"></ng-template>
      (
        <a (click)="linkData?.navigate(this.rowData)">
          <ng-template *ngIf="true then bracketDisplay"></ng-template>
        </a>
      )
    </ng-container>
    <!-- mainDisplayValue && !bracketDisplayValue -->
    <ng-container *ngIf="mainDisplayValue && !bracketDisplayValue">
      <a (click)="linkData?.navigate(this.rowData)">
        <ng-template *ngIf="true then mainDisplay"></ng-template>
      </a>
    </ng-container>
    <!-- !mainDisplayValue && bracketDisplayValue -->
    <ng-container *ngIf="!mainDisplayValue && bracketDisplayValue">
      (
        <a (click)="linkData?.navigate(this.rowData)">
          <ng-template *ngIf="true then bracketDisplay"></ng-template>
        </a>
      )
    </ng-container>
  </ng-template>

  <ng-template #noLinkTemplate>
    <!-- mainDisplayValue && bracketDisplayValue -->
    <ng-container *ngIf="mainDisplayValue && bracketDisplayValue">
      <ng-template *ngIf="true then mainDisplay"></ng-template>
      (
        <ng-template *ngIf="true then bracketDisplay"></ng-template>
      )
    </ng-container>
    <!-- mainDisplayValue && !bracketDisplayValue -->
    <ng-container *ngIf="mainDisplayValue && !bracketDisplayValue">
      <ng-template *ngIf="true then mainDisplay"></ng-template>
    </ng-container>
    <!-- !mainDisplayValue && bracketDisplayValue -->
    <ng-container *ngIf="!mainDisplayValue && bracketDisplayValue">
      (
        <ng-template *ngIf="true then bracketDisplay"></ng-template>
      )
    </ng-container>
  </ng-template>
  <ng-template *ngIf="!mainDisplayValue && !bracketDisplayValue then noData"></ng-template>
</div>
<!-- mainDisplay template -->
<ng-template #mainDisplay>
    <app-string-cell
      *ngIf="linkData?.mainValue?.type === tableColumnTypeEnum.String"
      [val]="mainDisplayValue" [noDataTemplate]="true">
    </app-string-cell>
    <app-decimal-cell
      *ngIf="linkData?.mainValue?.type === tableColumnTypeEnum.Number"
      [value]="mainDisplayValue" [format]="linkData?.mainValue?.numberFormat" [noDataTemplate]="noDataTemplate">
    </app-decimal-cell>
</ng-template>
<!-- bracketDisplay template -->
<ng-template #bracketDisplay>
  <app-string-cell
    *ngIf="linkData?.bracketValue?.type === tableColumnTypeEnum.String"
    [val]="bracketDisplayValue" [noDataTemplate]="true">
  </app-string-cell>
  <app-decimal-cell
    *ngIf="linkData?.bracketValue?.type === tableColumnTypeEnum.Number"
    [value]="bracketDisplayValue" [format]="linkData?.bracketValue?.numberFormat" [noDataTemplate]="noDataTemplate">
  </app-decimal-cell>
</ng-template>
<!-- noData template -->
<ng-template #noData>
  <span *ngIf="noDataTemplate">
    <i class="fas fa-minus noData"></i>
  </span>
</ng-template>
