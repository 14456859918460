import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'secondsToDateTimePipe',
})
export class SecondsToDateTimePipe extends DatePipe implements PipeTransform {
  transform(value: null | undefined): null;
  transform(value: number): string | null {
    // const minutes: number = Math.floor(value / 60);
    // return minutes + ':' + (value - minutes * 60);
    if (value) {
      const minutes: number = Math.floor(value / 60);
      return ('00' + minutes).slice(-2) + ':' + ('00' + Math.floor(value - minutes * 60)).slice(-2);
    }
    return null;
  }
}
