import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateChild, CanLoad, Route, Router, RouterStateSnapshot } from '@angular/router';
import * as fromApp from '../../core/store/reducers/index';
import * as AuthActions from '../store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, take } from 'rxjs/operators';
import { ePermissionsLevel, RouteConstants } from '../../core/app.config';
import { AuthorizationService } from '../../shared/authorization/authorization.service';
import { GrowlMessage, SeverityCode } from '../../core/models/Growl-Messages';
import * as fromLayout from '../../core/store/actions/layout.actions';
import { getAuthStatus } from '../store/reducers/auth';
import { User } from '../models/user';
import { CachingService } from '../../core/services/caching.service';

@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild, CanLoad {
  constructor(
    private store: Store<fromApp.AppState>,
    private router: Router,
    private cachingService: CachingService,
    private authorizationService: AuthorizationService
  ) {
  }

  canLoad(route: Route): Observable<boolean> | Promise<boolean> | boolean {
    const authorized = this.isAuthorized(route);
    return authorized;
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canProceedToRoute(route, state).pipe(
      switchMap((res) => {
        if (res) {
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.canProceedToRoute(route, state).pipe(
      switchMap((res) => {
        if (res) {
          return of(true);
        } else {
          return of(false);
        }
      }),
      catchError(() => {
        return of(false);
      })
    );
  }

  private canProceedToRoute(route, state) {
    return this.store.select(getAuthStatus).pipe(
      map((loggedState) => {
        const redirectUrl = {
          url: state.url.split('?')[0],
          queryParams: JSON.stringify(route.queryParams),
        };
        const token = this.cachingService.getData('*token');
        const user: User = { token: token };
        if (!loggedState.loggedIn) {
          if (token != null) {
            if (!loggedState.loadingStatus) {
              this.authorizationService.redirectUrl = redirectUrl;
              this.store.dispatch(new AuthActions.GetUserByToken(user, redirectUrl));
            }
          } else {
            return this.backToLogin(redirectUrl);
          }
        }

        if (token == null) {
          return this.backToLogin(redirectUrl);
        }
        if (!this.isAuthorized(route)) {
          const noAuthorizationRedirect = route.data['noAuthorizationRedirect'] as string;
          if (!noAuthorizationRedirect) this.router.navigate([RouteConstants.ACCESS]);
          else this.router.navigate([noAuthorizationRedirect]);
        }
        return loggedState.loggedIn;
      }),
      take(1)
    );
  }

  backToLogin(redirectUrl): boolean {
    this.authorizationService.redirectUrl = redirectUrl;
    this.router.navigate([RouteConstants.LOGIN_PAGE]);
    return false;
  }

  isAuthorized(route: any) {
    let authorized = true;
    if (route.data) {
      const authorizationList = route.data['authorization'] as Array<string>;
      const authorizationLevel = route.data['level'] as ePermissionsLevel;
      if (authorizationList) {
        authorized = this.authorizationService.hasPermission(authorizationList, authorizationLevel);
      }
    }
    if (!authorized) {
      const message = new GrowlMessage(SeverityCode.ERROR, 'No Permission', '', 5000);
      this.store.dispatch(new fromLayout.ErrorMsg(message));
    }
    return authorized;
  }
}
