import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OperationResult } from 'src/app/sport-betting/common/models';
import { Currency } from '../../models';

export interface CurrenciesState extends EntityState<Currency> {
  totalCount: number;
  operationResult?: OperationResult;
}
export const currencyAdapter: EntityAdapter<Currency> = createEntityAdapter<Currency>({
  selectId: (item) => item.id,
});
export const currenciesInitialState: CurrenciesState = currencyAdapter.getInitialState({
  totalCount: 0,
  entities: null,
});