import { ChangeDetectionStrategy, Component, Input, Output, EventEmitter, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-string-cell',
  templateUrl: './string-cell.component.html',
  styleUrls: ['./string-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StringCellComponent implements OnDestroy {
  @Input() val: any;
  @Input() inEditState: boolean;
  @Input() noDataTemplate: boolean;
  @Input() className: string;
  @Input() authClassName: string;
  @Input() number = false;
  @Output() finishedEditEvent = new EventEmitter<any>();
  typingTimer: any;
  doneTypingInterval = 1000;

  typing() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.finishedEditEvent.emit(this.val);
    }, this.doneTypingInterval);
  }
  ngOnDestroy() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }
}
