<span *ngIf="user">
  <ng-container [ngSwitch]="templateToShow">
    <ng-container *ngSwitchCase="template.usrNameAndId">
        <span class="name" *ngIf="user.userName">{{user.userName}}</span>&nbsp;
        <ng-container *ngTemplateOutlet="user.id !== null ? userId : null; context: {data: user.id}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="template.usrNameOnly">
      <span class="name" *ngIf="user.userName">{{user.userName}}</span>
    </ng-container>
    <ng-container *ngSwitchCase="template.NameAndId">
        <span class="name" *ngIf="user.name">{{user.name}}</span>&nbsp;
        <ng-container *ngTemplateOutlet="user.id !== null ? userId : null; context: {data: user.id}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="template.NameOnly">
      <span class="name" *ngIf="user.name">{{user.name}}</span>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <span class="name" *ngIf="user.name">{{user.name}}</span>&nbsp;
        <ng-container *ngTemplateOutlet="user.id !== null ? userId : null; context: {data: user.id}"></ng-container>
    </ng-container>
  </ng-container>
</span>

<ng-template #userId let-data="data">
  
  <span class="userId" *ngIf="!isRoleCSR()">(<a (click)="goToUser()">{{data}}</a>)</span>
  <span class="userId" *ngIf="isRoleCSR()">({{data}})</span>
</ng-template>
