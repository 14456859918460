<div class="login-body">
  <div class="login-image"><img alt="atlantis" src="assets/layout/images/pages/login-onlight.png"></div>
  <div class="login-panel p-fluid">

    <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
      <div class="flex flex-column">
        <div class="flex align-items-center mb-6 logo-container">
          <img class="login-logo" src="assets/layout/images/login/icon_{{ logo }}.png"  onerror="this.src='assets/layout/images/login/icon_login_default.png';"/>
          <span class="login-appname text-left">
          <div>{{ loginMessage }}</div>
          <div>Welcome, please use the form to sign-in</div>
        </span>
          <!--        <img class="login-appname" src="assets/layout/images/appname-dark.png">-->
        </div>

        <div class="form-container">
        <span class="p-input-icon-left"><i class="pi pi-user"></i>
          <input pInputText
                 id="userName"
                 formControlName="username"
                 type="text"
                 readonly
                 onfocus="this.removeAttribute('readonly')"
                 placeholder="Username"
                 autocomplete="username"
                 class="p-inputtext p-component p-element">
          <!--          <input type="text" autocomplete="off" pinputtext="" placeholder="Email" class="p-inputtext p-component p-element">-->
        </span>
          <span class="p-input-icon-left"><i class="pi pi-key"></i>
         <input pInputText
                type="password"
                id="password"
                formControlName="password"
                autocomplete="current-password"
                placeholder="Password"
                class="p-inputtext p-component p-element">
            <!--         <input type="password" autocomplete="off" pinputtext="" placeholder="Password" class="p-inputtext p-component p-element">-->
        </span>
        </div>
      </div>

      <div class="button-container">
        <!--      <button type="button" pbutton="" label="Login" class="p-element p-button p-component" tabindex="0">-->
        <button *ngIf="!loading"
                label="{{ 'Login' | translate }}"
                pButton
                type="submit"
                icon="pi p-icon-input"
                iconPos="right"
                class="p-element p-button p-component" tabindex="0">
          <span class="p-button-label"></span>
        </button>
        <button *ngIf="loading" pButton disabled class="p-element p-component p-button-secondary">
          <span class="p-button-label">Signing you in....</span>
        </button>
      </div>
    </form>

    <div class="login-footer flex align-items-center">
      <div class="flex align-items-center login-footer-logo-container">
        <img src="assets/layout/images/logo-gray.png" class="login-footer-logo">
        <span class="login-footer-appname">B2tech</span>
      </div>
      <span>Copyright 2022</span></div>

  </div>
</div>
