import { Directive, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Input } from '@angular/core';
import { AuthorizationService } from './authorization.service';
import { ePermissionsLevel, eRolesLevel } from '../../core/app.config';

@Directive({
  selector: '[appCanAccessDirective]',
})
export class CanAccessStracturalDirective implements OnInit {
  @Input() appCanAccess?: string | string[];
  @Input() CanAccessRole?: eRolesLevel | eRolesLevel[];
  @Input() permissionLevel?: ePermissionsLevel;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authorizationServiceService: AuthorizationService
  ) { }

  ngOnInit(): void {
    this.applyPermission();
  }

  private applyPermission(): void {
    if (this.CanAccessRole) {
      if (this.authorizationServiceService.hasRole(this.CanAccessRole)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    } else if (this.appCanAccess) {
      if (
        this.authorizationServiceService.hasPermission(this.appCanAccess, this.permissionLevel) ||
        this.appCanAccess === 'show'
      ) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    } else {
      this.viewContainer.clear();
    }
  }
}
