import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-sport-name-logo-cell',
  templateUrl: './sport-name-logo-cell.component.html',
  styleUrls: ['./sport-name-logo-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SportNameLogoCellComponent {
  @Input() value: string;
}
