import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppMainComponent } from './app.main.component';
import { AppComponent } from '../app.component';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthorizationService } from '../shared/authorization/authorization.service';
import { AppSettings, PermissionsScreensCodes } from '../core/app.config';
import { Actions, ofType } from '@ngrx/effects';
import { REFRESH_USER_SETTINGS_SUCCESS } from '../auth/store/actions/auth.actions';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  @Input() public reset: boolean;
  public model: any[];

  public logo = AppSettings.BRAND_DOMAIN;
  private destroyed$ = new Subject<boolean>();

  constructor(
    public appMain: AppMainComponent,
    public app: AppComponent,
    private refreshUserSettingsSuccess$: Actions,
    public router: Router,
    private authorizationService: AuthorizationService
  ) {
    this.refreshUserSettingsSuccess$
      .pipe(
        ofType(REFRESH_USER_SETTINGS_SUCCESS),
        takeUntil(this.destroyed$),
        tap(() => {
          this.initMenu();
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.initMenu();
  }

  public checkIfToShowMenuRecursive(menu: any): boolean {
    if (menu.hasOwnProperty('items') && menu.items.length > 0) {
      for (const child of menu.items) {
        const answer = this.checkIfToShowMenuRecursive(child);
        if (menu.hasOwnProperty('visible') && !menu.visible) {
          if (answer) {
            menu.visible = true;
          } else {
            menu.visible = false;
          }
        } else {
          if (answer) {
            menu.visible = true;
          }
        }
      }
    }
    if (!menu.hasOwnProperty('visible')) {
      menu.visible = false;
      return false;
    }
    if (menu.hasOwnProperty('visible') && menu.visible === true) {
      return true;
    }
    return false;
  }

  private initMenu(): void {
    this.model = [
      this.initCrmMenu(),
      this.initPromotionsMenu(),
      this.initCmsMenu(),
      this.initProductOfferingMenu(),
      this.initFinancialsMenu(),
      this.initNotificationsMenu(),
      this.initAdministrationMenu(),
      this.initRiskMenu()
    ];
  }

  private initCrmMenu(): any {
    const crmMenu = {
      label: 'CRM',
      icon: 'fas fa-users',
      mega: true,
      items: [
        {
          label: 'Search Players',
          icon: 'fas fa-list-alt',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CUSTOMER_LIST_SCREEN
          ),
          routerLink: ['/crm/search-players'],
        },
        {
          label: 'Customer Care',
          icon: 'fas fa-headset',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CUSTOMER_CARE_SCREEN
          ),
          routerLink: ['/crm/customer-care'],
        },
        {
          label: 'Voucher Menu',
          icon: 'fas fa-solid fa-bars',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.VOUCHER_MENU_ITEM
          ),
          //routerLink: [''],
          items: [
            {
              label: 'Voucher Batch',
              icon: 'fas fa-money-check',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.VOUCHER_BATCH_SUB_ITEM
              ),
              routerLink: ['/crm/voucher-batch'],
            },
            {
              label: 'Voucher Report',
              icon: 'fas fad fa-receipt',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.VOUCHER_REPORT_SUB_ITEM
              ) && this.authorizationService.hasPermission(
                PermissionsScreensCodes.VOUCHER_MENU_ITEM
              ),
              routerLink: ['/crm/voucher-report'],
            },
          ],
        },
        {
          label: 'Bulk Credit',
          icon: 'fas fa-user-plus',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.BONUS_CONNECT_BONUS_TO_USER_SCREEN
          ),
          routerLink: ['/crm/bulk-credit'],
        },
      ]
    };
    this.checkIfToShowMenuRecursive(crmMenu);
    return crmMenu;
  }

  private initCmsMenu(): any {
    const cmsMenu = {
      label: 'CMS',
      icon: 'fas fa-file-code',
      items: [
        {
          label: 'Languages Management',
          icon: 'fas fa-language',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CMS_LANGUAGE_MANAGEMENT_SCREEN
          ),
          routerLink: ['/cms/Languages-Management'],
        },
        {
          label: 'Banner Management',
          icon: 'far fa-images',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CMS_BANNER_MANAGEMENT_SCREEN
          ),
          routerLink: ['/cms/Banner-Management'],
        },
        {
          label: 'Label Management',
          icon: 'fa-solid fa-tag',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CMS_LABEL_MANAGEMENT_SCREEN
          ),
          routerLink: ['/cms/Label-Management'],
        },
        {
          label: 'SEO Management',
          icon: 'fab fa-searchengin',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CMS_SEO_MANAGEMENT_SCREEN
          ),
          routerLink: ['/cms/Seo-Management'],
        },
        {
          label: 'Pages Management',
          icon: 'far fa-file',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CMS_SEO_MANAGEMENT_SCREEN
          ),
          routerLink: ['/cms/Pages-Management'],
        },
        {
          label: 'Menu Management',
          icon: 'fas fa-list-alt',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CMS_MENU_MANAGEMENT_SCREEN
          ),
          routerLink: ['/cms/Menu-Management'],
        },
        // {
        //   label: 'HTMLS Management',
        //   icon: 'fas fa-code',
        //   visible: !environment.production && this.authorizationService.hasPermission(PermissionsScreensCodes.CMS_HTML_MANAGEMENT_SCREEN),
        //   routerLink: ['/cms/Htmls-Management'],
        // },
      ],
    };
    this.checkIfToShowMenuRecursive(cmsMenu);
    return cmsMenu;
  }

  private initFinancialsMenu(): any {
    const financialsMenu = {
      label: 'Reports',
      icon: 'fas fa-wallet',
      mega: true,
      items: [
        {
          label: 'Betslips Report',
          icon: 'fas fa-receipt',
          visible: this.authorizationService.hasPermission(
            [PermissionsScreensCodes.BETSLIPS_LIST_SCREEN]
          ),
          routerLink: ['/reports/betslips'],
        },
        {
          label: 'Deposit/Withdraw',
          icon: 'fas fa-hand-holding-usd',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CUSTOMERS_DEPOSIT_WITHDRAW_SCREEN
          ),
          routerLink: ['/reports/deposit-withdraw'],
        },
        {
          label: 'Branch Summary',
          icon: 'fas fa-file-invoice',
          routerLink: ['/reports/branch-summary'],
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.REPORTS_BRANCH_SUMMARY_REPORT_SCREEN
          ),
        },
        {
          label: 'Operational Reports',
          icon: 'fas fa-handshake',
          items: [
            {
              label: 'Order per Minute',
              icon: 'fas fa-chart-line',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_ORDER_PER_MINUTE_SCREEN
              ),
              routerLink: ['/setting/template/6-32'],
            },
            {
              label: 'Gap per Seconds',
              icon: 'fas fa-user-tag',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_GAP_PER_SECONDS_SCREEN
              ),
              routerLink: ['/setting/template/6-33'],
            },
            {
              label: 'Pending Settle Orders',
              icon: 'fas fa-table',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_PENDING_SETTLE_ORDERS_SCREEN
              ),
              routerLink: ['/setting/template/6-34'],
            },
          ],
        },
        {
          label: 'Expenses',
          icon: 'fas fa-money-check-alt',
          items: [
            {
              label: 'Management',
              icon: 'fas fa-money-check',
              visible: this.authorizationService.hasPermission([
                PermissionsScreensCodes.EXPENSES_MANAGEMENT_SCREEN,
              ]),
              routerLink: ['/reports/expenses/management'],
            },
            {
              label: 'Months',
              icon: 'fas fa-file-invoice-dollar',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.EXPENSES_MONTH_VIEW_SCREEN
              ),
              routerLink: ['/reports/expenses/months'],
            },
            {
              label: 'Request',
              icon: 'fas fa-cash-register',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.EXPENSES_REQUESTS_SCREEN
              ),
              routerLink: ['/reports/expenses/requests'],
            },
            {
              label: 'Reports',
              icon: 'fas fa-chart-line',
              items: [
                {
                  label: 'Expenses Reports',
                  icon: 'fas fa-coins',
                  visible: this.authorizationService.hasPermission(
                    PermissionsScreensCodes.EXPENSES_REPORTS_SCREEN
                  ),
                  routerLink: ['/reports/expenses/reports/ExpensesReports'],
                },
                {
                  label: 'Branches Expenses Reports',
                  icon: 'fas fa-chart-pie',
                  visible: this.authorizationService.hasPermission(
                    PermissionsScreensCodes.EXPENSES_BRANCHES_REPORTS_SCREEN
                  ),
                  routerLink: ['/reports/expenses/reports/BranchExpensesReports'],
                },
              ],
            },
          ],
        },
        {
          label: 'Affiliates',
          icon: 'fas fa-handshake',
          items: [
            {
              label: 'Affiliate Cellexpert Activities',
              icon: 'fas fa-chart-line',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_ACTIVITIES_SCREEN
              ),
              routerLink: ['/setting/template/3-55'],
            },
            {
              label: 'Affiliate Cellexpert Players',
              icon: 'fas fa-user-tag',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_PLAYERS_SCREEN
              ),
              routerLink: ['/setting/template/3-56'],
            },
            {
              label: 'Affiliate Cellexpert Transactions',
              icon: 'fas fa-table',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_TRANSACTIONS_SCREEN
              ),
              routerLink: ['/setting/template/3-57'],
            },
          ],
        },
      ]
    };
    this.checkIfToShowMenuRecursive(financialsMenu);
    return financialsMenu;
  }

  private initAdministrationMenu(): any {
    const administrationMenu = {
      label: 'Administration',
      icon: 'fas fa-toolbox',
      mega: true,
      items: [
        {
          label: 'Brand Settings',
          icon: 'fas fa-user-cog',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.SETTINGS_BRAND_SETTINGS_SCREEN
          ),
          routerLink: ['/administration/brand-settings'],
        },
        {
          label: 'System Configurations',
          icon: 'fas fa-sliders-h',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.DYNAMIC_REPORTS_SYSTEM_CONFIGURATION_SCREEN
          ),
          routerLink: ['/setting/template/3-28'],
        },
        {
          label: 'Roles and permissions',
          icon: 'fas fa-tasks',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.ROLES_AND_PERMISSIONS_SCREEN
          ),
          routerLink: ['/administration/roles-and-permissions'],
        },
        {
          label: 'Currency (new)',
          icon: 'fas fa-money-bill-alt',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.CURRENCIES_MANAGEMENT
          ),
          routerLink: ['/administration/currency']
        },
        {
          label: 'Currency',
          icon: 'fas fa-money-bill-alt',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.DYNAMIC_REPORTS_CURRENCY_CONFIGURATION_SCREEN
          ),
          routerLink: ['/setting/template/5-45'],
        },
        {
          label: 'Access List by IP',
          icon: 'fas fa-network-wired',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.DYNAMIC_REPORTS_ACCESS_LIST_BY_IP_SCREEN
          ),
          routerLink: ['/setting/template/5-42'],
        },
        {
          label: 'Access List by Country',
          icon: 'fas fa-globe-europe',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.DYNAMIC_REPORTS_ACCESS_LIST_BY_COUNTRY_SCREEN
          ),
          routerLink: ['/setting/template/5-44'],
        },
        {
          label: 'Products',
          icon: 'fas fa-box-open',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.DYNAMIC_REPORTS_PRODUCTS_CONFIGURATION_SCREEN
          ),
          routerLink: ['/setting/template/5-58'],
        },
      ],
    };
    this.checkIfToShowMenuRecursive(administrationMenu);
    return administrationMenu;
  }

  private initNotificationsMenu(): any {
    const notificationsMenu = {
      label: 'Notifications',
      icon: 'fas fa-bell',
      items: [
        {
          label: 'Notifications Report',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.NOTIFICATIONS_NOTIFICATIONS_SCREEN
          ),
          icon: 'fas fa-comments',
          routerLink: ['/setting/template/3-54']
        },
        {
          label: 'Template Management',
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.NOTIFICATIONS_NOTIFICATIONS_SCREEN
          ),
          icon: 'fas fa-folder-minus',
          routerLink: ['/notifications/template-management']
        }
      ],
    };
    this.checkIfToShowMenuRecursive(notificationsMenu);
    return notificationsMenu;
  }

  private initProductOfferingMenu(): any {
    const productOfferingMenu = {
      label: 'Product Offering',
      icon: 'fas fa-box-open',
      mega: true,
      items: [
        {
          label: 'Sport Betting',
          icon: 'far fa-futbol',
          items: [
            {
              label: 'Sport Margins',
              icon: 'fas fa-percent',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.DYNAMIC_REPORTS_MARGIN_CONFIGURATION_SCREEN
              ),
              routerLink: ['/setting/template/5-49'],
            },
            {
              label: 'Sport Types',
              icon: 'fas fa-medal',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.SPORT_TYPE_MANAGEMENT
              ),
              routerLink: ['/product-offering/sport-betting/sport-types'],
            },
            {
              label: 'Bet Types',
              icon: 'fas fa-list',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.BET_TYPE_MANAGEMENT
              ),
              routerLink: ['/product-offering/sport-betting/bet-types'],
            },
            {
              label: 'Bet Type Margins',
              icon: 'fas fa-percent',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.MARGINS_MANAGEMENT
              ),
              routerLink: ['/product-offering/sport-betting/bet-type-margins'],
            },
            {
              label: 'Default Bet Types',
              icon: 'fas fa-book',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.DEFAULT_BETTYPES_MANAGEMENT
              ),
              routerLink: ['/product-offering/sport-betting/default-bet-types'],
            },
            {
              label: 'Events lobby',
              icon: 'fas fa-calendar',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.EVENT_LOBBY_MANAGEMENT
              ),
              routerLink: ['/product-offering/sport-betting/events-lobby']
            },
            {
              label: 'Fixtures',
              icon: 'fas fa-folder-open',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.FIXTURE_TEMPLATES_MANAGEMENT
              ),
              items: [
                {
                  label: 'Templates',
                  icon: 'fas fa-gears',
                  visible: this.authorizationService.hasPermission(
                    PermissionsScreensCodes.FIXTURE_TEMPLATES_MANAGEMENT
                  ),
                  routerLink: ['/product-offering/sport-betting/templates']
                },
                {
                  label: 'Export',
                  icon: 'fas fa-file-excel',
                  visible: this.authorizationService.hasPermission(
                    PermissionsScreensCodes.FIXTURE_TEMPLATES_MANAGEMENT
                  ),
                  routerLink: ['/product-offering/sport-betting/templates/export']
                }
              ]
            },
          ]
        },
        {
          label: 'Casino',
          icon: 'fas fa-dice',
          items: [
            {
              label: 'Category Management',
              icon: 'fas fa-sitemap',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.CASINO_CATEGORIES_MANAGEMENT_SCREEN
              ),
              routerLink: ['/product-offering/casino/category-management'],
            },
            {
              label: 'Games Management',
              icon: 'fas fa-gamepad',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.CASINO_GAME_MANAGEMENT_SCREEN
              ),
              routerLink: ['/product-offering/casino/games-management'],
            },
          ],
        },
        {
          label: 'Jackpot',
          icon: 'fas fa-trophy',
          items: [
            {
              label: 'Jackpot Management',
              icon: 'fas fa-tasks',
              visible: this.authorizationService.hasPermission(PermissionsScreensCodes.POOL_TEMPLATES_SCREEN),
              routerLink: ['/product-offering/jackpot/jackpot-management'],
            },
            {
              label: 'Jackpot Scheduler',
              icon: 'fas fa-calendar-alt',
              visible: this.authorizationService.hasPermission(PermissionsScreensCodes.POOL_POOLS_SCREEN),
              routerLink: ['/product-offering/jackpot/jackpot-scheduler'],
            },
          ],
        },
        {
          label: 'Lotto',
          icon: 'fas fa-money-check-alt',
          items: [
            {
              label: 'Lotto Management',
              icon: 'fas fa-file-alt',
              visible: !environment.production && this.authorizationService.hasPermission(PermissionsScreensCodes.LOTTO_TYPE_MANAGEMENT),
              routerLink: ['/product-offering/lotto/lotto-management'],
            },
            {
              label: 'Lotto Report',
              icon: 'fas fa-chart-line',
              visible: this.authorizationService.hasPermission(PermissionsScreensCodes.LOTTO_TOTAL_REPORT_SCREEN),
              routerLink: ['/product-offering/lotto/lotto-report'],
            },
            {
              label: 'Lotto Game Editor',
              icon: 'pi pi-info-circle',
              routerLink: ['/product-offering/lotto/lotto-game-editor'],
              visible: this.authorizationService.hasPermission(PermissionsScreensCodes.LOTTO_GAME_EDITOR_SCREEN),
            },
            //{
            //   label: 'Betslips',
            //   icon: 'pi pi-info-circle',
            //   routerLink: ['sportsbook/pool/templates']
            // },
            {
              label: 'Logs',
              icon: 'pi pi-info-circle',
              routerLink: ['/product-offering/lotto/lotto-logs/logs/'],
              visible: this.authorizationService.hasPermission(PermissionsScreensCodes.LOTTO_LOGS_SCREEN),
            },
          ]
        },
        {
          label: 'Instant Win',
          icon: 'fa-solid fa-money-bill-1',
          items: [
            {
              label: 'Game management',
              icon: 'fas fa-puzzle-piece',
              visible: this.authorizationService.hasPermission(PermissionsScreensCodes.INSTANT_WIN_GAME_MANAGEMENT_SCREEN),
              routerLink: ['/product-offering/instant-win/gmgmt'],
            },
            {
              label: 'Batch management',
              icon: 'fas fa-layer-group',
              visible: this.authorizationService.hasPermission(PermissionsScreensCodes.INSTANT_WIN_BATCH_MANAGEMENT_SCREEN),
              routerLink: ['/product-offering/instant-win/batch-mgmt'],
            },
          ]
        }
      ]
    };

    this.checkIfToShowMenuRecursive(productOfferingMenu);
    return productOfferingMenu;
  }

  private initPromotionsMenu(): any {
    const promotionsMenu = {
      label: 'Promotions',
      icon: 'fas fa-gift',
      mega: true,
      items: [
        {
          label: 'Free Bets Bonus',
          icon: 'fas fa-gift',
          routerLink: ['/setting/template/7-62'],
          visible: this.authorizationService.hasPermission(PermissionsScreensCodes.BONUS_SPORTBETTING_FREE_BET_SCREEN),
        },
        {
          label: 'Free Bets',
          icon: 'fas fa-hand-holding-usd',
          items: [
            {
              label: 'Free Bet Report',
              icon: 'fas fa-chart-area',
              routerLink: ['/setting/template/7-63'],
              visible: this.authorizationService.hasPermission(PermissionsScreensCodes.DYNAMIC_REPORTS_FREE_BET_CAMPAIGN_SCREEN),
            },
            {
              label: 'Free Bet Report Per User',
              icon: 'fas fa-chart-bar',
              routerLink: ['/setting/template/7-61'],
              visible: this.authorizationService.hasPermission(PermissionsScreensCodes.DYNAMIC_REPORTS_FREE_BET_PER_USER_CAMPAIGN_SCREEN),
            }
          ]
        },
        {
          label: 'Selection Bonus',
          icon: 'fas fa-check-double',
          routerLink: ['/setting/template/2-15'],
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.TICKET_BONUS_SELECTION_BONUS_SCREEN
          ),
        },
        {
          label: 'Refund Bonus',
          icon: 'fas fa-funnel-dollar',
          routerLink: ['/setting/template/2-21'],
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.TICKET_BONUS_REFUND_BONUS_SCREEN
          ),
        },
        {
          label: 'Cashout Offer',
          icon: 'fas fa-coins',
          routerLink: ['/promotions/cashout-offer'],
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.SPORTSBOOK_CASHOUT_SCREEN
          ),
        },
        {
          label: 'Next Bet Bonus',
          icon: 'fas fa-forward',
          routerLink: ['/setting/template/2-37'],
          visible: this.authorizationService.hasPermission(
            PermissionsScreensCodes.TICKET_BONUS_NEXT_BET_BONUS_SCREEN
          ),
        },
        // {
        //   label: 'Sportsbook', //EVENT HIDE
        //   icon: 'far fa-futbol',
        //   items: [
        //     {
        //       label: 'Events',
        //       icon: 'sports',
        //       routerLink: ['/promotions/events'],
        //       visible:
        //         !environment.production &&
        //         this.authorizationService.hasPermission(PermissionsScreensCodes.SPORTSBOOK_EVENTS_LIST_SCREEN),
        //     },
        //     {
        //       label: 'Cashout Reports',
        //       icon: 'pi pi-info-circle',
        //       items: [
        //         {
        //           label: 'Profitability Report',
        //           icon: 'pi pi-info-circle',
        //           routerLink: ['/promotions/cashout-offer/reports/profitability-report/'],
        //           visible: this.authorizationService.hasPermission(
        //             PermissionsScreensCodes.SPORTSBOOK_CASHOUT_PROFITABILITY_REPORTS_SCREEN
        //           ),
        //         },
        //         {
        //           label: 'Profitability Summary Report',
        //           icon: 'pi pi-info-circle',
        //           routerLink: ['/promotions/cashout-offer/reports/profitability-summary-report/'],
        //           visible: this.authorizationService.hasPermission(
        //             PermissionsScreensCodes.SPORTSBOOK_CASHOUT_PROFITABILITY_SUMMARY_SREPORTS_SCREEN
        //           ),
        //         },
        //       ],
        //     },
        //   ],
        // },
      ]
    };
    this.checkIfToShowMenuRecursive(promotionsMenu);
    return promotionsMenu;
  }


  private initRiskMenu(): any {
    const riskMenu = {
      label: 'Risk',
      icon: 'fa-solid fa-money-bill-trend-up',
      mega: true,
      visible: this.authorizationService.hasPermission(
        [PermissionsScreensCodes.EXPOSURE_EVENT_LIMIT_REPORT_SCREEN, PermissionsScreensCodes.EVENT_LOBBY_MANAGEMENT]
      ),
      items: [
        {
          label: 'Exposure',
          icon: 'fa-solid fa-scale-unbalanced',
          items: [
            {
              label: 'Event Limit Report',
              icon: 'fa-solid fa-table',
              routerLink: ['/risk/exposure/event-limit-report'],
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.EXPOSURE_EVENT_LIMIT_REPORT_SCREEN
              )
            },
            {
              label: 'Monitoring Authorization',
              icon: 'fa-solid fa-stopwatch',
              visible: this.authorizationService.hasPermission(
                PermissionsScreensCodes.MONITORING_AUTHORIZATION
              ),
              routerLink: ['/risk/authorization-lobby']
            },
          ],
        },

      ]
    };
    this.checkIfToShowMenuRecursive(riskMenu);
    return riskMenu;
  }

}
