<div class="exception-body accessdenied">
    <div class="exception-panel text-center">
<!--        <div class="exception-image">
            <img src="assets/layout/images/exception/icon-access.png">
        </div>-->
      <a [routerLink]="'/home'" class="logo mt-6 pt-6">
        <img [src]="'assets/layout/images/login/icon_' + logo  + '.png'" />
      </a>

        <div class="exception-detail">
            <h1>ACCESS DENIED</h1>
          <p class="my-0 mx-auto">You do not have the necessary permissons.</p>
            <p-button label="Go back to home" [routerLink]="['/home']"></p-button>
        </div>
    </div>
</div>
