import { Component, OnDestroy, OnInit } from '@angular/core';
import { Authenticate } from '../models/user';
import { Store } from '@ngrx/store';
import * as Auth from '../store/actions/auth.actions';
import { Observable, Subject } from 'rxjs';
import * as fromAuth from '../store/reducers/auth';
import { ReducersConstants } from '../../core/app.config';
import { getLoadingStatus } from '../store/reducers/auth';
import { getGrowlMessage } from '../../core/store/reducers/layout';
import { distinctUntilChanged, finalize, takeUntil, tap } from 'rxjs/operators';
import { GrowlMessage } from '../../core/models/Growl-Messages';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'login-page-container',
  template: `
    <p-toast></p-toast>
    <app-login-form [loading]="(authState | async).loading" (submitted)="onSubmit($event)"> </app-login-form>
  `,
  styles: [],
})
export class LoginPageComponent implements OnInit, OnDestroy {
  authState: Observable<fromAuth.State>;
  loginLoadingStatus: any;
  destroyed$ = new Subject<boolean>();
  constructor(private store: Store<any>, private messageService: MessageService) {
    this.loginLoadingStatus = this.store.select(getLoadingStatus);

    this.store
      .select(getGrowlMessage)
      .pipe(
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
        tap((message: GrowlMessage) => {
          if (message) {
            this.messageService.add(message);
          }
        }),
        finalize(() => this.messageService.clear())
      )
      .subscribe();
  }

  ngOnInit() {
    this.authState = this.store.select(ReducersConstants.AUTH);
  }

  onSubmit($event: Authenticate) {
    this.store.dispatch(new Auth.Login($event));
  }
  ngOnDestroy() {
    console.log('login was destroyed');
    this.messageService.clear();
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
