import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Authenticate } from '../../models/user';
import { AppSettings } from '../../../core/app.config';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginFormComponent implements OnInit {
  @Input() loading: any;
  @Output() submitted = new EventEmitter<Authenticate>();
  loginMessage = AppSettings.BRAND_NAME + ' ' + AppSettings.VERSION;
  signInForm: FormGroup;
  userName: any;
  password: any;
  logo = AppSettings.BRAND_DOMAIN;

  

  ngOnInit() {
    this.signInForm = new FormGroup({
      username: new FormControl(null, Validators.required),
      password: new FormControl(null, Validators.required),
    });
  }

  onSubmit() {
    console.log('onSubmit');
    if (this.signInForm.invalid) {
      this.signInForm.controls.username.markAsDirty();
      this.signInForm.controls.password.markAsDirty();
      return;
    }

    this.submitted.emit(this.signInForm.value);
  }
}
