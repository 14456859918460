import { createAction, props } from '@ngrx/store';
import { SportType } from '../../models/entities';
import { FailAction } from 'src/app/sport-betting/common/models/fail-action.model';
import { UpdateSportType } from '../../models/update-entities';
import { Filtering } from 'src/app/sport-betting/common/models';

const SB_GET_SPORT_TYPES = '[SB] Get sport types';
const SB_SET_SPORT_TYPES = '[SB] Set sport types';
const SB_UPDATE_SPORT_TYPE = '[SB CRUD] Update sport type';
const SB_UPDATE_SPORT_TYPE_SUCCESS = '[SB] Update sport type SUCCESS';
const SB_SPORT_TYPES_FAILURE = '[SB CRUD] Sport type failure';


export const SbGetSportsTypes = createAction(SB_GET_SPORT_TYPES, props<{ payload: Filtering }>());
export const SbSetSportsTypes = createAction(SB_SET_SPORT_TYPES, props<{
  payload: {
    data: Array<SportType>,
    totalCount: number;
  }
}>());
export const SbUpdateSportType = createAction(SB_UPDATE_SPORT_TYPE, props<{ payload: UpdateSportType }>());
export const SbUpdateSportTypeSuccess = createAction(SB_UPDATE_SPORT_TYPE_SUCCESS, props<{ payload: UpdateSportType }>());
export const SportTypesFailure = createAction(SB_SPORT_TYPES_FAILURE, props<FailAction>());
