<div class="layout-breadcrumb-container flex-wrap">
  <div class="layout-breadcrumb-left-items">
    <a *ngIf="dashboardPermission && appMain.isStatic() && !appMain.isMobile()"
       class="menu-button"
       [routerLink]="['/home']"
       (click)="appMain.onMenuButtonClick($event)">
      <i class="pi pi-bars"></i>
    </a>
    <p-breadcrumb styleClass="layout-breadcrumb py-2"
                  [model]="menuItems">
    </p-breadcrumb>
  </div>
  <div class="layout-breadcrumb-right-items pr-5">
    <div *ngIf="showFeedHealthTracker"
         class="feed-status-icon">
      Feed Health
      <i class="pi pi-circle-fill"
         [ngClass]="getFeedHealtIconColor(feedStatus| async)">
      </i>
    </div>
    <app-app-global-searches>
    </app-app-global-searches>

    <ul *ngIf="checkPermission(screenPermissions.LOGGED_IN_USER_DETAILS)"
        class="user-balance">
      <li>
        Balance:
        <span class="balance-data"
              [ngStyle]="{'background-color': (userData | async).balance > 0 ? '' : 'red'}">{{(userData |
          async).balance | number : '1.0-2'}}</span>
        <span class="currency">{{(userData | async).currency.name}}</span>
      </li>
      <li class="divider"></li>
      <li>Holdings: {{(userData | async).holding | number : '1.0-2'}}</li>
      <li class="divider"></li>
      <li>Credit Limit: {{(creditLimit | async)}}</li>
    </ul>
  </div>
</div>