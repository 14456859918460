export class GrowlMessage {
  constructor(
    public severity: string = '',
    public summary: string = '',
    public detail: string = '',
    public life: number = 5000
  ) {}
}

export interface GrowlMessage {
  summary: string;
  detail: string;
  severity: string;
  life: number;
}

export class SeverityCode {
  public static SUCCESS = 'success';
  public static ERROR = 'error';
  public static INFO = 'info';
  public static WARN = 'warn';
}
