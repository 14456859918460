import { isNull, isUndefined, isString, upperFirst } from 'lodash';
import { ColumnFilter, FilterInstruction, FilterOperator, Filtering, SortArgument, SortDirection } from '../models';
import { FilterMetadata, SortMeta } from 'primeng/api';

export function getQueryString(options: Filtering): URLSearchParams {
  const params = new URLSearchParams();

  for (const key in options) {
    params.set(key, JSON.stringify(options[key]));
  }

  return params;
}

export function isNotNullOrUndefined(val): boolean {
  return !isNull(val) && !isUndefined(val);
}

export function toUpperCaseIfString(value: any): any {
  return isString(value) ? upperFirst(value) : value;
}

export function prepareOrderBy(
  newSortMeta: Array<SortMeta>,
  sortMode: string,
  currentSortMeta: Array<SortMeta>
): { newMetadata: Array<SortMeta>, orderBy: Array<SortArgument> } {
  if (sortMode !== 'multiple') {
    //TODO: need add 'single' sort mode handling
    return { newMetadata: currentSortMeta, orderBy: [] };
  }

  if (!newSortMeta || newSortMeta.length !== 1) {
    return { newMetadata: currentSortMeta, orderBy: [] };
  }

  const changedColumn = newSortMeta[0];

  if (!changedColumn?.field) {
    return { newMetadata: currentSortMeta, orderBy: [] };
  }

  if (!currentSortMeta) {
    return {
      newMetadata: [changedColumn],
      orderBy: [
        {
          columnName: changedColumn.field,
          direction: changedColumn.order === 1 ? SortDirection.Asc : SortDirection.Desc,
        }
      ]
    };
  }

  const column = currentSortMeta.find(el => el.field === changedColumn.field);

  if (!column) {
    const newMetadata = [...currentSortMeta, changedColumn];
    return {
      newMetadata,
      orderBy: newMetadata.map((item) => ({
        columnName: item.field,
        direction: item.order === 1 ? SortDirection.Asc : SortDirection.Desc,
      }))
    };
  }

  if (column.order === changedColumn.order) {
    return {
      newMetadata: currentSortMeta,
      orderBy: currentSortMeta.map((item) => ({
        columnName: item.field,
        direction: item.order === 1 ? SortDirection.Asc : SortDirection.Desc,
      }))
    };
  }

  let newMetadata = currentSortMeta;
  if (column.order === -1 && changedColumn.order === 1) {
    newMetadata = currentSortMeta.filter(it => it.field !== changedColumn.field);
  }

  const listOfOrders: Array<SortArgument> = [];
  newMetadata = newMetadata.map(item => {
    const element = item.field != changedColumn.field ? item : changedColumn;

    listOfOrders.push({
      columnName: element.field,
      direction: element.order === 1 ? SortDirection.Asc : SortDirection.Desc,
    });

    return element;
  });

  return { newMetadata, orderBy: listOfOrders };
}

export function prepareFilters(filters: { [s: string]: FilterMetadata | FilterMetadata[] }): Array<ColumnFilter> {
  const result: Array<ColumnFilter> = [];

  if (!filters) {
    return result;
  }

  Object.keys(filters).forEach((key) => {
    const filterArray = filters[key];

    if (!filterArray) {
      return;
    }

    const filter = filterArray[0] ?? filterArray;

    if (!isNotNullOrUndefined(filter?.value) || filter?.value === '') {
      return;
    }

    const item: ColumnFilter = {
      columnName: key,
      conditions: [{
        value: `${filter.value}`,
        instruction: FilterInstruction[filter.matchMode]
      }],
      flterColumnOperator: FilterOperator[filter.operator]
    };
    result.push(item);
  });

  return result;
}