import * as fromApp from "abs@core/store/reducers";
import { createSelector } from "@ngrx/store";
import { Batch, voucherStatus, voucherStatusByBatch } from "./models/batch.models";
import { GET_ONLINE_VOUCHER_DATA } from "./store/actions/online-voucher.actions";

export interface State {
    listData: Batch[];
    voucherStatus: voucherStatus[],
    totalRecords: number;
    voucherStatusByBatch: voucherStatusByBatch;
    loading: boolean;
}

export const initialState: { totalRecords: null, listData: null, voucherStatusByBatch: null, voucherStatus: null, loading: null } = {
    totalRecords: null, listData: null, voucherStatusByBatch: null, voucherStatus: null, loading: null
};

export const getVoucherList = (state: fromApp.AppState) => {
    return state.onlineListReducer.listData;
};

export const getStatus = (state: fromApp.AppState) => state.onlineListReducer.voucherStatus;


export const getStatusSelector = createSelector(getStatus, (status) => {
    if (status) {
        return status;
    }
    return null;
});

export const getTotalRecords = (state: fromApp.AppState) => {
    return state.onlineListReducer.totalRecords;
};
export const getTotalStatus = (state: fromApp.AppState) => {
    return state.onlineListReducer.voucherStatusByBatch;
};

export const getLoadingStatus = (state: fromApp.AppState) => {
  return state.onlineListReducer.loading;
};

export const getCustomerVoucherListSelector = createSelector(getVoucherList, (voucherList) => {
    if (voucherList) {
        return voucherList;
    }
    return null;
});
export const getTotalStatusSelector = createSelector(getTotalStatus, (voucherStatusByBatch) => {
    if (voucherStatusByBatch) {
        return voucherStatusByBatch;
    }
    return null;
});

export function OnlineListReducer(state = initialState, action: any): State {
  switch (action.type) {
      case GET_ONLINE_VOUCHER_DATA: {
        return {
          ...state,
          loading: true,
        };
      }
        case "SET_VOUCHER_LIST": {
            return {
                ...state,
                listData: action.payload.vouchers.items,
                totalRecords: action.payload.vouchers.count,
                voucherStatus: action.payload.voucherStatus,
                voucherStatusByBatch: action.payload.voucherStatusByBatch,
                loading: false,
            };
        }
        default: {
            return state;
        }
    }
}
