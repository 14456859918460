import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/country.actions';
import { OperationType } from 'src/app/sport-betting/common/models';
import { betTypeCountryMarginsAdapter, betTypeCountryMarginsInitialState } from '../adapters/country.adapter';

export const BetTypeCountryMarginsReducer = createReducer(
  betTypeCountryMarginsInitialState,
  on(Actions.SbSetCountryMargins, (state, { payload }) => {
    return betTypeCountryMarginsAdapter.setAll(payload.data, {
      ...state,
      totalCount: payload.totalCount
    });
  }),
  on(Actions.SbUpdateCountryMargin, Actions.SbDeleteCountryMargin, state => ({
    ...state,
    operationResult: null
  })),
  on(Actions.SbUpdateCountryMarginSuccess, (state, { payload }) => {
    return betTypeCountryMarginsAdapter.updateOne({
      id: payload.countryId,
      changes: {
        ...payload,
      }
    }, {
      ...state,
      operationResult: {
        id: payload.countryId,
        isSuccessful: true,
        operationType: OperationType.Update
      }
    });
  }),
  on(Actions.SbDeleteCountryMarginSuccess, (state, { payload }) => {
    return betTypeCountryMarginsAdapter.updateOne({
      id: payload.countryId,
      changes: {
        margin: null,
        marginId: null,
      }
    }, {
      ...state,
      operationResult: {
        id: payload.countryId,
        isSuccessful: true,
        operationType: OperationType.Update
      }
    });
  }),
  on(Actions.CountryMarginsFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);