import * as sportsbookActions from '../actions/sportsbook.actions';
import * as fromApp from 'abs@core/store/reducers';
import { isNullOrUndefined, isNullOrUndefinedOrEmptyArray } from 'abs@shared/shared-functions/functions';
import { createSelector } from '@ngrx/store';
import { BetType } from 'abs@core/models/coreModels';
import copy from 'fast-copy';

export interface State {
  prizeTemplates: any;
  poolTemplates: any;
  importedMatches: any;
  importedEvents: any;
  totalMatches: number;
  pools: any;
  poolById: any;
  eventsByPool: any;
  loading: boolean;
  poolBetTypes: BetType[];
}

export const initialState: State = {
  prizeTemplates: null,
  poolById: null,
  eventsByPool: null,
  pools: null,
  poolTemplates: null,
  importedMatches: null,
  importedEvents: null,
  loading: true,
  poolBetTypes: null,
  totalMatches: null,
};

export function SportsbookReducer(state = initialState, action: any): State {
  switch (action.type) {
    case sportsbookActions.GET_POOL_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case sportsbookActions.GET_POOL_TEMPLATE_PRIZE: {
      return {
        ...state,
        loading: true,
      };
    }
    case sportsbookActions.SET_POOL_TEMPLATE_PRIZE: {
      return {
        ...state,
        prizeTemplates: action.payload,
        loading: false,
      };
    }
    case sportsbookActions.SET_POOL_BET_TYPES: {
      return {
        ...state,
        poolBetTypes: action.payload.betTypes,
      };
    }
    case sportsbookActions.GET_POOLS: {
      return {
        ...state,
        loading: true,
      };
    }
    case sportsbookActions.SET_POOLS: {
      return {
        ...state,
        poolById: null,
        pools: action.payload,
        loading: false,
      };
    }
    case sportsbookActions.SET_POOL_BY_ID: {
      return {
        ...state,
        poolById: action.payload,
        loading: false,
      };
    }
    case sportsbookActions.GET_POOL_TEMPLATES: {
      return {
        ...state,
        loading: true,
      };
    }
    case sportsbookActions.SET_POOL_TEMPLATES: {
      return {
        ...state,
        poolTemplates: action.payload,
        loading: false,
      };
    }
    case sportsbookActions.GET_POOL_MATCHES: {
      return {
        ...state,
        loading: true,
      };
    }
    case sportsbookActions.SET_POOL_MATCHES: {
      return {
        ...state,
        eventsByPool: action.payload,
        loading: false,
      };
    }
    case sportsbookActions.SET_MATCHES: {
      return {
        ...state,
        importedMatches: action.payload.events,
        totalMatches: action.payload.size,
        loading: false,
      };
    }
    case sportsbookActions.SET_EVENTS: {
      return {
        ...state,
        importedEvents: action.payload.events,
        loading: false,
      };
    }
    // case sportsbookActions.SET_FAILURE
    // : {
    //   return {
    //     ...state,
    //     loading: false
    //   };
    // }
    default: {
      return state;
    }
  }
}

// export const getDrawsSummaryReport = (state: fromApp.AppState) => {
//   const  drawsSummaryReport = state.sportsBookReducer.lottoDrawsSummary;
//   return drawsSummaryReport;
// }

export const isPoolTemplateListReady = (state: fromApp.AppState) => {
  const isReady = isNullOrUndefined(state.sportsBookReducer.poolTemplates);
  return isReady;
};
export const getPoolTemplateList = (state: fromApp.AppState) => {
  return copy(state.sportsBookReducer.poolTemplates);
};
export const getImportedMatches = (state: fromApp.AppState) => {
  return state.sportsBookReducer.importedMatches;
};
export const getImportedEvents = (state: fromApp.AppState) => {
  return state.sportsBookReducer.importedEvents;
};
export const getPools = (state: fromApp.AppState) => {
  return copy(state.sportsBookReducer.pools);
};
export const getPoolById = (state: fromApp.AppState) => {
  return copy(state.sportsBookReducer.poolById);
};
export const getPoolPrizeTemplate = (state: fromApp.AppState) => {
  return copy(state.sportsBookReducer.prizeTemplates);
};
export const getPoolEvents = (state: fromApp.AppState) => {
  return copy(state.sportsBookReducer.eventsByPool);
};
export const getPoolBetTypes = (state: fromApp.AppState) => {
  return copy(state.sportsBookReducer.poolBetTypes);
};
export const getTotalMatches = (state: fromApp.AppState) => {
  return copy(state.sportsBookReducer.totalMatches);
};
export const getImportedMatchesSelector = createSelector(getImportedMatches, (importedMatches) => {
  if (!isNullOrUndefinedOrEmptyArray(importedMatches)) {
    // return importedMatches.filter(match => !isNullOrUndefinedOrEmptyArray(match.BetType))
    return importedMatches;
  }
});

export const getImportedEventsSelector = createSelector(getImportedEvents, (importedMatches) => {
  if (!isNullOrUndefinedOrEmptyArray(importedMatches)) {
    // return importedMatches.filter(match => !isNullOrUndefinedOrEmptyArray(match.BetType))
    return importedMatches;
  }
});

export const editPoolTemplateGuard = createSelector(getPoolTemplateList, getPoolBetTypes, (templates, betTypes) => {
  return { templates, betTypes };
});

export const editPoolGuard = createSelector(getPoolTemplateList, (templates) => {
  return { templates };
});

export const getPoolBetTypesDropDown = createSelector(getPoolBetTypes, (betTypes) => {
  if (betTypes) {
    return betTypes.map((betType) => {
      return {
        value: betType.id,
        label: betType.isLive ? '(Live) ' + betType.name : betType.name,
        isLive: betType.isLive,
        sportTypeId: betType.sportTypeId,
      };
    });
  }
  return null;
});
export const getPoolMatchesDropDown = createSelector(getPoolEvents, (events) => {
  if (events) {
    return events.map((match) => {
      return {
        value: match.Order,
        label: match.Order,
      };
    });
  }
});
