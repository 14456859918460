import { Action } from '@ngrx/store';
import {
  NotificationData,
  NotificationsData
} from '../../modules/template-management/models/notification-template.model';

/******************NOTIFICATION_TEMPLATES************************/

export const GET_NOTIFICATION_TEMPLATES = '[NOTIFICATION_TEMPLATES] Get Notification Templates';
export const GET_NOTIFICATION_TEMPLATE_BY_ID = '[NOTIFICATION_TEMPLATES] Get Notification Template By Id';
export const GET_ALL_NOTIFICATION_TEMPLATES = '[NOTIFICATION_TEMPLATES] Get All Notification Templates';
export const SAVE_NOTIFICATION = '[NOTIFICATION_TEMPLATES] Save Notification';
export const SAVE_NOTIFICATION_SUCCESS = '[NOTIFICATION_TEMPLATES] Save Notification Success';

export const SET_NOTIFICATION_TEMPLATES = '[NOTIFICATION_TEMPLATES] Set Notification Templates';
export const SET_NOTIFICATION_TEMPLATE = '[NOTIFICATION_TEMPLATE] Set Notification Template';
export const SET_ALL_NOTIFICATION_TEMPLATES = '[NOTIFICATION_TEMPLATES] Set All Notification Templates';

export const SET_FAILURE = '[NOTIFICATION_TEMPLATES] Set Failure';
export const FAILURE = '[NOTIFICATION_TEMPLATES] Failure';


export class GetNotificationTemplates implements Action {
  readonly type = GET_NOTIFICATION_TEMPLATES;
  constructor(public payload: any = null) {
  }
}
export class GetNotificationTemplateById implements Action {
  readonly type = GET_NOTIFICATION_TEMPLATE_BY_ID;
  constructor(public payload: number = null) {
  }
}

export class SaveNotification implements Action {
  readonly type = SAVE_NOTIFICATION;
  constructor(public payload: any = null) {}
}

export class SaveNotificationSuccess implements Action {
  readonly type = SAVE_NOTIFICATION_SUCCESS;
  constructor(public payload: boolean = false) {}
}

export class GetAllNotificationTemplates implements Action {
  readonly type = GET_ALL_NOTIFICATION_TEMPLATES;
  constructor(public payload: any = null) {}
}

export class SetNotificationTemplates implements Action {
  readonly type = SET_NOTIFICATION_TEMPLATES;
  constructor(public payload: NotificationsData = null) {}
}
export class SetNotificationTemplate implements Action {
  readonly type = SET_NOTIFICATION_TEMPLATE;
  constructor(public payload: NotificationData = null) {}
}

export class SetAllNotificationTemplates implements Action {
  readonly type = SET_ALL_NOTIFICATION_TEMPLATES;
  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;
  constructor(public payload: any) {}
}

export class SetFailure implements Action {
  readonly type = SET_FAILURE;
}

export type Actions =
  | GetNotificationTemplates
  | GetNotificationTemplateById
  | SaveNotification
  | SaveNotificationSuccess
  | GetAllNotificationTemplates
  | SetNotificationTemplates
  | SetNotificationTemplate
  | SetAllNotificationTemplates
  | Failure
  | SetFailure;
