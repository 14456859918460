import * as eventsMetadataActions from '../actions/events.metadata.actions';
export interface State {
  loading: boolean;
  inProcess: boolean;
}

export const initialState: State = {
  loading: false,
  inProcess: false,
};

export function EventsMetadataReducer(state = initialState, action: any): State {
  switch (action.type) {
    case eventsMetadataActions.SET_FAILURE: {
      return {
        ...state,
        loading: false,
        inProcess: false,
      };
    }
    default: {
      return state;
    }
  }
}
