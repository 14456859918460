import { createReducer, on } from "@ngrx/store";
import { EventsFailure, SbGetEvents, SbSetEvents } from "../actions";
import { eventAdapter, eventInitialState } from "../adapters";

export const EventReducer = createReducer(
  eventInitialState,
  on(SbGetEvents, state => ({ ...state })),
  on(SbSetEvents, (state, { payload }) => {
    return eventAdapter.setAll(payload.items, {
      ...state,
      totalCount: payload.totalCount
    });
  }),
  on(EventsFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);
