import {
  CMS_ADD_MENU_ITEM_RESET,
  CMS_ADD_MENU_ITEM_SUCCESS,
  CMS_MENU_ICON_RESET,
  CMS_MENU_UPLOAD_FILE_SUCCESS,
  CMS_SEO_SET_MENU_LIST,
  RESET_PAGES_URL,
  SET_SEO_PAGES_URLS,
} from '../actions/cms.actions';
import * as fromApp from 'abs@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { MenuItem } from '../../../menu-management/models/models';

export interface State {
  pageUrls: any;
  icon: any;
  itemAdded: boolean;
  menuList: MenuItem[];
  seoId: any;
}

export const initialState: State = {
  pageUrls: [],
  icon: null,
  itemAdded: false,
  menuList: [],
  seoId: 0
};

export function CmsReducer(state = initialState, action: any): State {
  switch (action.type) {
    case SET_SEO_PAGES_URLS: {
      return {
        ...state,
        pageUrls: action.payload,
      };
    }
    case RESET_PAGES_URL: {
      return {
        ...state,
        pageUrls: null,
      };
    }
    case CMS_MENU_UPLOAD_FILE_SUCCESS: {
      return {
        ...state,
        icon: action.payload,
      };
    }
    case CMS_MENU_ICON_RESET: {
      return {
        ...state,
        icon: null,
      };
    }
    case CMS_ADD_MENU_ITEM_SUCCESS: {
      return {
        ...state,
        itemAdded: true,
        seoId: action.payload.seoId
      };
    }
    case CMS_ADD_MENU_ITEM_RESET: {
      return {
        ...state,
        itemAdded: false,
      };
    }
    case CMS_SEO_SET_MENU_LIST: {
      return {
        ...state,
        menuList: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const getPagesUrls = (state: fromApp.AppState) => state.cmsReducer.pageUrls;

export const getPageUrlsPath = createSelector(getPagesUrls, (pages) => {
  if (pages) {
    return pages;
  }
});

export const getMenuSEOPageIcon = (state: fromApp.AppState) => state.cmsReducer.icon;
export const getMenuPageAdded = (state: fromApp.AppState) => state.cmsReducer;
export const getSeoMenuPageList = (state: fromApp.AppState) => state.cmsReducer.menuList;
