import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ePermissionsLevel, PermissionActionsCodes, PermissionsScreensCodes } from '../../app.config';
import { AuthorizationService } from 'abs@shared/authorization/authorization.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { take } from 'rxjs';
import { MessageService } from 'primeng/api';
import { SeverityCode } from 'abs@core/models/Growl-Messages';
import { isNullOrUndefined } from 'abs@shared/shared-functions/functions';

@Component({
  selector: 'app-app-global-searches',
  templateUrl: './app-global-searches.component.html',
  styleUrls: ['./app-global-searches.component.scss'],
})
export class AppGlobalSearchesComponent {
  public searchValueOrderId: any;
  public searchUserId: any;
  public eventId: number;
  public searchingEvent: boolean;

  public permissionScreen = PermissionsScreensCodes;
  public permission = PermissionActionsCodes;
  public permissionLevel = ePermissionsLevel;

  constructor(
    private readonly router: Router,
    private readonly httpClient: HttpClient,
    private readonly messageService: MessageService,
    private readonly authorizationService: AuthorizationService) {
  }

  @HostListener('input', ['$event']) public onKeyDown(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    input.value = input.value.trim();
  }

  public getByOrderIdOrBarcode(): void {
    this.router.navigate([`/reports/betslips/${this.searchValueOrderId}`]).then((res) => {
      if (res) {
        this.searchValueOrderId = null;
      }
    });
  }

  public getUser(): void {
    this.router.navigate([`/crm/search-players/${this.searchUserId}/profile`]).then((res) => {
      if (res) {
        this.searchUserId = null;
      }
    });
  }

  public checkPermission(permission, level = 1): boolean {
    return this.authorizationService.hasPermission(permission, level);
  }

  public navigateToEvent(): void {
    if (!this.eventId) {
      return;
    }

    this.searchingEvent = true;
    this.httpClient.get<number>(`${environment.newBackOfficeUrl}Event/exists/${this.eventId}`).pipe(
      take(1)
    ).subscribe((res) => {
      this.searchingEvent = false;
      if (!isNullOrUndefined(res)) {
        this.router.navigate([`product-offering/sport-betting/event/${res}`]).then(() => {
          this.eventId = null;
        });
        return;
      }
      this.eventId = null;
      this.messageService.add({
        closable: true,
        severity: SeverityCode.INFO,
        detail: 'Event not found',
        summary: 'Info'
      });
    }, () => {
      this.searchingEvent = false;
      this.messageService.add({
        closable: true,
        severity: SeverityCode.ERROR,
        detail: 'Cannot reach event service',
        summary: 'Error'
      });
    });
  }
}
