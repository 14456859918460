import { Action } from '@ngrx/store';

export const FAILURE = '[ERROR] Failure';

export const SET_FAILURE = '[ERROR] Set Failure';

export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  constructor(public payload: any) {}
}

export type Actions = Failure | SetFailure;
