import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as AuthActions from '../actions/auth.actions';
import { Router } from '@angular/router';
// import * as fromSetting from '../../../setting/store/actions/setting.actions';
import * as fromLayout from '../../../core/store/actions/layout.actions';
import * as fromApp from '../../../core/store/reducers';
import { User, Authenticate, UserData } from '../../models/user';
import { OutChannelService } from '../../../core/services/outchannel/outchannel.service';
import { APIMethods, APIMethodsGames, AppSettings, eSpecialRoles, RouteConstants } from '../../../core/app.config';
import { Store } from '@ngrx/store';
import { GrowlMessage, SeverityCode } from '../../../core/models/Growl-Messages';
import { isNullOrUndefined } from '../../../shared/shared-functions/functions';
import { of } from 'rxjs';
import { BaseResponse } from '../../../core/models/response';
import { GetTemplatesListRequest, GetTemplatesRequestBody } from '../../../setting/models/setting.requests';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { AuthorizationService } from '../../../shared/authorization/authorization.service';
import { LoginUserRequest } from '../../models/authRequests';
import { CachingService } from '../../../core/services/caching.service';
import * as layoutActions from '../../../core/store/actions/layout.actions';
import { GetData } from '../../../core/store/actions/metadata.actions';
import * as ErrorActions from '../../../core/store/actions/error.actions';
import { GetEnums } from '../../../core/models/request';
import { RoutingSuccess, SetPermissions } from '../actions/auth.actions';

@Injectable()
export class AuthEffects {
  token: string = null;
  redirectUrl: { url: null; queryParams: null } = null;
  showSuccess = false;
  userData: UserData;

  private readonly tokenKey = '*token';
  private readonly tableColumsKey = 'tableColums';

  constructor(
    private router: Router,
    private actions$: Actions<any>,
    private outputChannel: OutChannelService,
    private store: Store<fromApp.AppState>,
    private cachingService: CachingService,
    private authorizationServiceService: AuthorizationService
  ) { }

  authSignup = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LOGIN),
    map((action: AuthActions.Login) => {
      return action.payload;
    }),
    switchMap((authData: Authenticate) => {
      const request = new LoginUserRequest(authData.username, authData.password);
      return this.outputChannel
        .postRequest(AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API_V2, APIMethods.LOGIN_METHOD, request)
        .pipe(
          map((ResponseData: any) => {
            const data = ResponseData;
            if (data.isSuccessfull) {
              this.cachingService.setData('*token', data.token);
              this.showSuccess = true;
              const user: User = {
                token: data.token,
              };
              // const requestEnums: BaseRequest = new BaseRequest();
              // this.store.dispatch(new MetadataActions.GetEnums(requestEnums));
              // this.store.dispatch(new GetData(new GetEnums()));
              return new AuthActions.GetUserByToken(user, null);
            } else {
              return new AuthActions.LoginFailure(ResponseData);
            }
          }),
          catchError((error) => of(new AuthActions.LoginFailure(error)))
        );
    })
  ));


  keepAliveRequest = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.KEEP_ALIVE),
    map((action: AuthActions.KeepAlive) => {
      return action;
    }),
    switchMap(() => {
      const request = null;
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
          APIMethods.GET_KEEP_ALIVE,
          request
        )
        .pipe(
          map((ResponseData: any) => {
            const data = ResponseData;
            if (data.isSuccessfull && data?.userInfo?.isValid === 1) {
              return new AuthActions.KeepAliveSuccess(data.userInfo);
            }
            this.removeUserData();
            throw ResponseData;
          }),
          catchError((error) => {
            const message: GrowlMessage = new GrowlMessage(
              SeverityCode.ERROR,
              'Keep Alive Failed',
              error.Result ? error.Result.errorDescription : ''
            );
            this.removeUserData();
            this.router.navigate([RouteConstants.LOGIN_PAGE]);
            return of(new fromLayout.ErrorMsg(message));
          })
        );
    })
  ));

  getUserByTokenRequest = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.GET_USER_BY_TOKEN),
    map((action: AuthActions.GetUserByToken) => {
      return action;
    }),
    switchMap((baseRequest: any) => {
      this.redirectUrl = baseRequest.redirectRoute;
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API_V2,
          APIMethodsGames.DYNAMIC_QUERY + APIMethods.GET_USER_BY_TOKEN,
          null
        )
        .pipe(
          map((ResponseData: any) => {
            const data = ResponseData;
            if (data.isSuccessfull) {
              this.userData = data.data.userEntity;
              this.userData.creditLimit = data.userInfo.creditLimit;
              this.store.dispatch(new SetPermissions(this.userData.permissions));
              // this.authorizationServiceService.setUserPermission(userData.permissions);
              this.authorizationServiceService.setUserRole(this.userData.role.id);
              return new AuthActions.LoginSuccess(this.userData);
            } else {
              return new AuthActions.LoginFailure({
                isSuccessfull: data.isSuccessfull,
                Result: data.result,
              });
            }
          }),
          catchError((error) => of(new AuthActions.LoginFailure(error)))
        );
    })
  ));

  refreshUserSettingsRequest = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.REFRESH_USER_SETTINGS),
    map((action: AuthActions.RefreshUserSettings) => {
      return action;
    }),
    switchMap((baseRequest: any) => {
      this.redirectUrl = baseRequest.redirectRoute;
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API_V2,
          APIMethodsGames.DYNAMIC_QUERY + APIMethods.GET_USER_BY_TOKEN,
          null
        )
        .pipe(
          map((ResponseData: any) => {
            const data = ResponseData;
            if (data.isSuccessfull) {
              this.userData = data.data.userEntity;
              this.userData.creditLimit = data.userInfo.creditLimit;
              this.store.dispatch(new SetPermissions(this.userData.permissions));

              // this.authorizationServiceService.setUserPermission(userData.permissions);
              this.authorizationServiceService.setUserRole(this.userData.role.id);
              return new AuthActions.RefreshUserSettingsSuccess(this.userData);
            } else {
              const error: BaseResponse = {
                isSuccessfull: data.isSuccessfull,
                Result: data.result,
              };
              return new AuthActions.RefreshUserSettingsFailure(error);
            }
          }),
          catchError((error) => of(new AuthActions.RefreshUserSettingsFailure(error)))
        );
    })
  ));

  loginSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LOGIN_SUCCESS),
    tap(() => {
      const message: GrowlMessage = new GrowlMessage(SeverityCode.SUCCESS, 'Login', 'Login Successfully');

      if (this.showSuccess) {
        this.cachingService.clearSData();
        this.store.dispatch(new GetData(new GetEnums()));
        this.store.dispatch(new fromLayout.SuccesMsg(message));
        this.showSuccess = false;

        if (
          [eSpecialRoles.Tier1Support.toString(), eSpecialRoles.Tier1Manager.toString()].includes(
            this.userData.role.codeAttribute
          )
        ) {
          this.router.navigate([RouteConstants.CUSTOMER_CARE_SEARCH]).then((res) => {
            if (res) {
              this.store.dispatch(new RoutingSuccess());
            }
          });
          return;
        }
      }
      if (
        ![eSpecialRoles.Tier1Support.toString(), eSpecialRoles.Tier1Manager.toString()].includes(
          this.userData.role.codeAttribute
        )
      ) {
        this.store.dispatch(new GetData(new GetTemplatesListRequest(new GetTemplatesRequestBody())));
      }

      if (isNullOrUndefined(this.authorizationServiceService.redirectUrl)) {
        this.router.navigate([RouteConstants.DASHBOARD]).then((res) => {
          if (res) {
            this.store.dispatch(new RoutingSuccess());
          }
        });
      } else {
        this.router
          .navigate([this.authorizationServiceService.redirectUrl.url], {
            queryParams: JSON.parse(this.authorizationServiceService.redirectUrl.queryParams),
          })
          .then((res) => {
            if (res) {
              this.store.dispatch(new RoutingSuccess());
            }
          });
      }
    })
  ), { dispatch: false });

  loginFailure$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LOGIN_FAILURE),
    map((action: AuthActions.LoginFailure) => {
      return action.payload;
    }),
    map((error: any) => {
      return new ErrorActions.Failure(error);
    })
  ));

  authLogout = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.LOGOUT),
    switchMap(() => {
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
          APIMethods.LOGOUT_METHOD,
          null
        )
        .pipe(
          map((ResponseData: any) => {
            if (ResponseData.isSuccessfull) {
              this.cachingService.removeData(this.tokenKey);
              this.cachingService.clearSData();
              this.router.navigate([RouteConstants.LOGIN_PAGE]);
              this.store.dispatch(new layoutActions.ClearMessage());
              return new AuthActions.LogoutSuccess();
            }

            this.removeUserData();
            this.router.navigate([RouteConstants.LOGIN_PAGE]);
            this.store.dispatch(new layoutActions.ClearMessage());
            return new AuthActions.LogoutFailed();
          }),
          catchError(() => {
            this.removeUserData();
            this.router.navigate([RouteConstants.LOGIN_PAGE]);
            return of(new AuthActions.LogoutFailed());
          })
        );
    })
  ));

  private removeUserData(): void {
    this.cachingService.removeData(this.tokenKey);
    this.cachingService.removeData(this.tableColumsKey);
  }
}
