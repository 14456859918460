import { Action } from '@ngrx/store';

export const GET_METADATA = '[EVENTS-METADATA] Get MetaData';
export const FAILURE = '[EVENTS-METADATA] Failure';

export const SET_FAILURE = '[EVENTS-METADATA] Set Failure';

export class GetMetaData implements Action {
  readonly type = GET_METADATA;

  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions = GetMetaData | Failure | SetFailure;
