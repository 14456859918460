import { Action } from '@ngrx/store';

export const GET_HTMLS = '[CMS-HTML] Get Htmls';
export const GET_HTMLS_FAILURE = '[CMS-HTML] Get Htmls Failure';
export const SET_HTMLS = '[CMS-HTML] Set Htmls';
export const SET_FILTER_HTMLS = '[CMS-HTML] Set Filter Htmls';

export const LOAD_HTML_CONTENT = '[CMS-HTML] Load Html content';
export const LOAD_HTML_CONTENT_FAILURE = '[CMS-HTML] Load Html content Failure';
export const LOAD_HTML_CONTENT_SUCCESS = '[CMS-HTML] Load Html content Success';

export const SAVE_HTML = '[CMS-HTML] Save Html';
export const SAVE_HTML_SUCCESS = '[CMS-HTML] Save Html Success';
export const SAVE_HTML_FAILURE = '[CMS-HTML] Save Html Failure';

export const ADD_NEW_HTML = '[CMS-HTML] Add New Html';
export const ADD_NEW_HTML_SUCCESS = '[CMS-HTML] Add New Html Success';
export const ADD_NEW_HTML_FAILURE = '[CMS-HTML] Add New Html Failure';
export const RESET_HTML_PAGE_FILTERS = '[CMS-HTML] Reset Filters';

export class ResetHtmlPageFilters implements Action {
  readonly type = RESET_HTML_PAGE_FILTERS;

  
}
export class GetHtmls implements Action {
  readonly type = GET_HTMLS;

  constructor(public payload: any = null) {}
}
export class GeHtmlsFailure implements Action {
  readonly type = GET_HTMLS_FAILURE;

  
}
export class SetHtmls implements Action {
  readonly type = SET_HTMLS;

  constructor(public payload: any = null) {}
}
export class SetFilterHtmls implements Action {
  readonly type = SET_FILTER_HTMLS;

  constructor(public payload: any = null) {}
}

export class SaveHtml implements Action {
  readonly type = SAVE_HTML;

  constructor(public payload: any = null) {}
}
export class SaveHtmlFailure implements Action {
  readonly type = SAVE_HTML_FAILURE;

  
}
export class SaveHtmlSuccess implements Action {
  readonly type = SAVE_HTML_SUCCESS;

  
}

export class LoadHtmlContent implements Action {
  readonly type = LOAD_HTML_CONTENT;

  constructor(public payload: any = null) {}
}
export class LoadHtmlContentFailure implements Action {
  readonly type = LOAD_HTML_CONTENT_FAILURE;

  
}
export class LoadHtmlContentSuccess implements Action {
  readonly type = LOAD_HTML_CONTENT_SUCCESS;

  constructor(public payload: any = null) {}
}
export class AddNewHtml implements Action {
  readonly type = ADD_NEW_HTML;

  constructor(public payload: any = null) {}
}
export class AddNewHtmlFailure implements Action {
  readonly type = ADD_NEW_HTML_FAILURE;

  
}
export class AddNewHtmlSuccess implements Action {
  readonly type = ADD_NEW_HTML_SUCCESS;

  
}

export type Actions =
  | GetHtmls
  | GeHtmlsFailure
  | SetHtmls
  | SaveHtml
  | SaveHtmlFailure
  | SaveHtmlSuccess
  | LoadHtmlContent
  | LoadHtmlContentSuccess
  | LoadHtmlContentFailure
  | SetFilterHtmls
  | AddNewHtml
  | AddNewHtmlFailure
  | AddNewHtmlSuccess
  | ResetHtmlPageFilters;
