import { Pipe, PipeTransform } from '@angular/core';
import { GeneralConstants } from '../../../core/app.config';
import { Store } from '@ngrx/store';
import { getSelectedTimeZone } from '../../../core/store/reducers/metadata.reducer';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as moment from 'moment-timezone';
@Pipe({
  name: 'dateTimeExtendedTimeZoneAsync',
})
export class DateTimeExtendedTimeZoneAsyncPipe implements PipeTransform {
  constructor(private store: Store<any>) {}

  transform(value: any, args?: any): Observable<string> {
    return this.store.select(getSelectedTimeZone).pipe(
      map((selectedTimeZone) => {
        const date =
          moment(value, GeneralConstants.PIPE_DATE_TIME_FORMAT_MOMENT_USE).isValid() === false
            ? moment(value)
            : moment(value, GeneralConstants.PIPE_DATE_TIME_FORMAT_MOMENT_USE);
        return date
          .tz(selectedTimeZone.tzCode)
          .format(args == null ? GeneralConstants.PIPE_DATE_TIME_FORMAT_MOMENT_USE : args);
      })
    );
  }
}
