export enum ePrimeNGInputType {
  number,
  text,
  date,
  dropdown,
  multiselect,
  autocomplete,
  TBD,
}
export enum eSource {
  api,
  enum,
  custom,
}
export class ePatterns {
  public static email = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/; //https://stackoverflow.com/questions/201323/how-to-validate-an-email-address-using-a-regular-expression
  public static phone = /^(?:\+(?:[0-9]{10,13})|([0-9]{9}))$/; // https://stackoverflow.com/questions/48829930/regex-that-validate-for-a-number-with-length-8-11-13-followed-by-an-optional
  public static positiveNumbersAndZero = /^[0-9]*$/;
  public static userId = /^[0-9]{0,38}$/;
  /** Supports all Latin characters, accented and unaccented. */
  public static userIdOrName = /^(?=.{1,101}$)[a-zA-Z0-9À-ÿĀ-žĄ-ԯ]+( [a-zA-Z0-9À-ÿĀ-žĄ-ԯ]+)*$/;
  public static bookingCodePattern = /^[bB 0-9]{5,7}$/;
  public static prepaidNumberLengthPattern = /^[0-9]{16}$/;

  /** Supports all Latin characters, accented and unaccented. */
  public static alphaNumeric = (maxLength = 255): RegExp => new RegExp(`^[a-zA-ZÀ-ÿĀ-žĄ-ԯ0-9]{1,${maxLength}}$`);
  /** Supports all UTF-8 characters. */
  public static alphaNumeric_UTF8 = (maxLength = 255): RegExp => new RegExp(`^[a-zA-Z0-9\\u0080-\\uFFFF]{1,${maxLength}}$`);
}
