import { Action } from '@ngrx/store';

// export const GET_TEMPLATES = '[SETTING] Get Templates';
//
// export const SET_TEMPLATES = '[SETTING] Set Templates';

export const SET_EXPORTING_DATA = '[SETTING] Set Exporting Data';

export const GET_DATA_TEMPLATES = '[SETTING] Get Data Templates';

export const SET_DATA_TEMPLATES = '[SETTING] Set Data Templates';

export const SET_DATA_TEMPLATE = '[SETTING] Set Data Template';

export const SET_FIRST_TIME_FLAG = '[SETTING] Set First Time Flag';

export const GET_DATA_TEMPLATE = '[SETTING] Set Data Template';

export const SET_FAILURE = '[SETTING] Set Failure';

export const FAILURE = '[SETTING] Failure';

export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}

export class SetFirstTimeFlag implements Action {
  readonly type = SET_FIRST_TIME_FLAG;

  constructor(public payload: any) {}
}

export class SetDataTemplate implements Action {
  readonly type = SET_DATA_TEMPLATE;

  constructor(public payload: any) {}
}
export class SetExportingData implements Action {
  readonly type = SET_EXPORTING_DATA;

  constructor(public payload: any) {}
}

// export class GetTemplates implements Action {
//   readonly type = GET_TEMPLATES;
//
//   constructor(public payload: any) {
//   }
// }
//
// export class SetTemplates implements Action {
//   readonly type = SET_TEMPLATES;
//
//   constructor(public payload: any) {
//   }
// }

export class GetDataTemplates implements Action {
  readonly type = GET_DATA_TEMPLATES;

  constructor(public payload: any) {}
}

export class SetDataTemplates implements Action {
  readonly type = SET_DATA_TEMPLATES;

  constructor(public payload: any) {}
}

export class SetFailure implements Action {
  readonly type = SET_FAILURE;
}
export type Actions =
  | SetDataTemplate
  | SetFirstTimeFlag
  // | GetTemplates
  // | SetTemplates
  | GetDataTemplates
  | SetDataTemplates
  | SetExportingData
  | SetFailure
  | Failure;
