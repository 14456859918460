import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
  selector: 'app-popup-iframe-cell',
  templateUrl: './popup-iframe-cell.component.html',
  styleUrls: ['./popup-iframe-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupIFrameCellComponent {
  @Input() public val: any;
  @Input() public dismissable: boolean = true;
  @Input() public showCloseIcon: boolean = true;
}
