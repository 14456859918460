import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-total-records',
  templateUrl: './total-records.component.html',
  styleUrls: ['./total-records.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalRecordsComponent {
  @Input() total: number;
}
