import { createAction, props } from "@ngrx/store";
import { FilterBetTypeOdd } from "src/app/sport-betting/common/models/filters/filter-bet-type-odd.model";
import { BetTypeOdd } from "../../models/bet-type-odd.model";
import { UpdateBetTypeOdd } from "../../models/update-bet-type-odd.model";
import { FailAction } from "src/app/sport-betting/common/models";

const SB_GET_BET_TYPE_ODDS = '[SBBT] Get bet type odds';
const SB_SET_BET_TYPE_ODDS = '[SBBT] Set bet type odds';
const SB_UPDATE_BET_TYPE_ODD = '[SBBT] Update bet type odd';
const SB_UPDATE_BET_TYPE_ODD_SUCCESS = '[SBBT] Update bet type odd SUCCESS';
const BET_TYPE_ODD_FAILURE = '[SBBT] Bet Type Odd Failure';


export const SbGetBetTypeOdds = createAction(SB_GET_BET_TYPE_ODDS, props<{ payload: FilterBetTypeOdd }>());
export const SbSetBetTypeOdds = createAction(SB_SET_BET_TYPE_ODDS, props<{
  payload: {
    data: Array<BetTypeOdd>,
    totalCount: number;
  }
}>());
export const SbUpdateBetTypeOdd = createAction(SB_UPDATE_BET_TYPE_ODD, props<{ payload: UpdateBetTypeOdd }>());
export const SbUpdateBetTypeOddSuccess = createAction(SB_UPDATE_BET_TYPE_ODD_SUCCESS, props<{ payload: UpdateBetTypeOdd }>());

export const BetTypeOddsFailure = createAction(BET_TYPE_ODD_FAILURE, props<FailAction>());