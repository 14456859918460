import { Component } from '@angular/core';
import { AppSettings } from '../core/app.config';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-footer',
  template: `
    <div class="layout-footer">
      <div class="footer-logo-container">
        <img id="footer-logo" src="assets/layout/images/login/icon_{{ logo }}.png" alt="{{ logo }}" />
        <!--<img id="footer-logo" src="'assets/layout/images/logos/{{ logo }}-{{ app.colorScheme === 'light' ? 'dark' : 'light' }}.png" alt="sapphire-layout"/>-->
        <div class="app-name">{{ footerMessage }}</div>
      </div>
      <span class="copyright">All Rights Reserved</span>
    </div>
  `,
})
export class AppFooterComponent {
  footerMessage = AppSettings.BRAND_NAME + ' ' + AppSettings.VERSION;
  logo = AppSettings.BRAND_DOMAIN;
  constructor(public app: AppComponent) {}
}
