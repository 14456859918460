import * as Error from '../actions/error.actions';
export interface State {
  error: any;
}

export const initialState: State = {
  error: null,
};

export function ErrorReducer(state = initialState, action: Error.Actions): State {
  switch (action.type) {
    case Error.SET_FAILURE: {
      return {
        ...state,
        error: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}
