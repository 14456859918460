import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OperationResult } from 'src/app/sport-betting/common/models';
import { Margin } from '../../models';

export interface BetTypeCountryMarginsState extends EntityState<Margin> {
  totalCount: number;
  operationResult?: OperationResult;
}
export const betTypeCountryMarginsAdapter: EntityAdapter<Margin> = createEntityAdapter<Margin>({
  selectId: (item) => item.countryId,
});
export const betTypeCountryMarginsInitialState: BetTypeCountryMarginsState = betTypeCountryMarginsAdapter.getInitialState({
  totalCount: 0,
  entities: null,
});