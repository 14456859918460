import { Action } from '@ngrx/store';

export const GET_ORDER_DATA = '[ORDER] Get Order Data';
export const SET_ORDER_DATA = '[ORDER] Set Order Data';
export const ORDER_ACTION_SUCCESS = '[ORDER] Order Action Success';
export const ORDER_ACTION_FAILED = '[ORDER] Order Action Failed';
export const ORDER_ACTION = '[ORDER] Order Action';

export const EDIT_ROW_SUCCESS = '[ORDER] Edit Row Success';
export const EDIT_ROW_FAILED = '[ORDER] Edit Row Failed';

export const FAILURE = '[ORDER] Failure';

export const SET_FAILURE = '[ORDER] Set Failure';

export class GetOrderData implements Action {
  readonly type = GET_ORDER_DATA;

  constructor(public payload: any) {}
}

export class SetOrderData implements Action {
  readonly type = SET_ORDER_DATA;

  constructor(public payload: any) {}
}
export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}
export class OrderAction implements Action {
  readonly type = ORDER_ACTION;

  constructor(public payload: any) {}
}
export class OrderActionSuccess implements Action {
  readonly type = ORDER_ACTION_SUCCESS;

  
}
export class OrderActionFailed implements Action {
  readonly type = ORDER_ACTION_FAILED;

  
}
export class EditRowSuccess implements Action {
  readonly type = EDIT_ROW_SUCCESS;

  
}
export class EditRowFailed implements Action {
  readonly type = EDIT_ROW_FAILED;

  
}

export type Actions =
  | GetOrderData
  | SetOrderData
  | OrderAction
  | OrderActionSuccess
  | OrderActionFailed
  | EditRowSuccess
  | EditRowFailed
  | Failure
  | SetFailure;
