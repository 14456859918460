<div class="exception-body error">
    <div class="exception-panel text-center">
<!--        <div class="exception-image">-->
<!--            <img src="assets/layout/images/exception/icon-error.png">-->
<!--        </div>-->
      <a [routerLink]="'/home'" class="logo mt-6 pt-6">
        <img [src]="'assets/layout/images/login/icon_' + logo  + '.png'" />
      </a>
        <div class="exception-detail">
            <h1>ERROR OCCURED</h1>
          <p class="my-0 mx-auto"> Something went wrong.</p>
            <p-button label="Go back to home" [routerLink]="['/home']"></p-button>
        </div>
    </div>
</div>
