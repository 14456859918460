<ng-template *ngIf="inEditState then editTemplate else normal"></ng-template>
<ng-template #editTemplate>
  <input type="text" [className]="!authClassName ? '' :authClassName" *ngIf="!number" pInputText [(ngModel)]="val" (keyup)="typing()"/>
  <input type="number"  *ngIf="number" pInputText [(ngModel)]="val" (change)="typing()" (keyup)="typing()"/>
</ng-template>
<ng-template #normal>
  <span *ngIf="val !== null else noData" [class]="className">{{val}}</span>
</ng-template>
<ng-template #noData>
  <span *ngIf="noDataTemplate">
    <i class="fas fa-minus noData"></i>
  </span>
</ng-template>
