import { createAction, props } from '@ngrx/store';
import { FailAction, Filtering } from 'src/app/sport-betting/common/models';
import { UpdateMargin } from '../../models/update-margin.model';
import { Margin } from '../../models/margins.model';

const SB_GET_BET_TYPE_MARGINS = '[SBBTM] Get bet type margins';
const SB_SET_BET_TYPE_MARGINS = '[SBBTM] Set bet type margins';

const SB_UPDATE_BET_TYPE_MARGIN = '[SBBTM] Update bet type margins';
const SB_UPDATE_BET_TYPE_MARGIN_SUCCESS = '[SBBTM] Update bet type margins SUCCESS';

const SB_DELETE_BET_TYPE_MARGIN = '[SBBTM] Delete bet type margin';
const SB_DELETE_BET_TYPE_MARGIN_SUCCESS = '[SBBTM] Delete bet type margin SUCCESS';

const FAILURE = '[SBBTM] Update bet type margin failure';

export const SbGetBetTypeMargins = createAction(SB_GET_BET_TYPE_MARGINS, props<{ payload: Filtering }>());
export const SbSetBetTypeMargins = createAction(SB_SET_BET_TYPE_MARGINS, props<{
  payload: {
    data: Array<Margin>,
    totalCount: number;
  }
}>());
export const SbUpdateBetTypeMargin = createAction(SB_UPDATE_BET_TYPE_MARGIN, props<{ payload: UpdateMargin }>());
export const SbUpdateBetTypeMarginSuccess = createAction(SB_UPDATE_BET_TYPE_MARGIN_SUCCESS, props<{ payload: UpdateMargin }>());

export const SbDeleteBetTypeMargin = createAction(SB_DELETE_BET_TYPE_MARGIN, props<{
  payload: {
    marginId: number,
    betTypeId: number
  }
}>());
export const SbDeleteBetTypeMarginSuccess = createAction(SB_DELETE_BET_TYPE_MARGIN_SUCCESS, props<{
  payload: {
    marginId: number,
    betTypeId: number
  }
}>());
export const BetTypeMarginsFailure = createAction(FAILURE, props<FailAction>());