import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EventScore } from '../../models';

export interface EventScoresState extends EntityState<EventScore> {
  isLoading: boolean;
}

export const eventScoresAdapter: EntityAdapter<EventScore> = createEntityAdapter<EventScore>({
  selectId: (item) => item.periodId
});
export const eventScoresInitialState: EventScoresState = eventScoresAdapter.getInitialState({
  entities: null,
  isLoading: true
});