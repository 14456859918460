import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BetTypeOdd } from '../../models/bet-type-odd.model';
import { OperationResult } from 'src/app/sport-betting/common/models';

export interface BetTypeOddsState extends EntityState<BetTypeOdd> {
  totalCount: number;
  operationResult?: OperationResult;
}
export const betTypeOddsAdapter: EntityAdapter<BetTypeOdd> = createEntityAdapter<BetTypeOdd>({
  selectId: (item) => item.id,
});
export const betTypeOddsInitialState: BetTypeOddsState = betTypeOddsAdapter.getInitialState({
  totalCount: 0,
  entities: null
});