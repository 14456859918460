import { createAction, props } from '@ngrx/store';
import { DropdownItem } from 'src/app/sport-betting/common/models';

const SB_GET_SPORT_TYPES_FOR_DROPDOWN = '[SB] Get sport types for dropdowns';
const SB_SET_SPORT_TYPES_FOR_DROPDOWN = '[SB] Get sport types for dropdowns';

const SB_GET_LEAGUES_FOR_DROPDOWN = '[SB] Get leagues for dropdowns';
const SB_SET_LEAGUES_FOR_DROPDOWN = '[SB] Get leagues for dropdowns';

const SB_GET_TEAMS_FOR_DROPDOWN = '[SB] Get teams for dropdowns';
const SB_SET_TEAMS_FOR_DROPDOWN = '[SB] Get teams for dropdowns';

const SB_GET_COUNTRIES_FOR_DROPDOWN = '[SB] Get countries for dropdowns';
const SB_SET_COUNTRIES_FOR_DROPDOWN = '[SB] Get countries for dropdowns';

export const SbGetSportTypesForDropdown = createAction(SB_GET_SPORT_TYPES_FOR_DROPDOWN);
export const SbSetSportTypesForDropdown = createAction(SB_SET_SPORT_TYPES_FOR_DROPDOWN, props<{
  payload: Array<DropdownItem<number, string>>
}>());

export const SbGetLeaguesForDropdown = createAction(SB_GET_LEAGUES_FOR_DROPDOWN, props<{
  countryIds: Array<number>,
  skip?: number,
  take?: number
}>());
export const SbSetLeaguesForDropdown = createAction(SB_SET_LEAGUES_FOR_DROPDOWN, props<{
  payload: Array<DropdownItem<number, string>>,
}>());

export const SbGetTeamsForDropdown = createAction(SB_GET_TEAMS_FOR_DROPDOWN, props<{
  leagueIds: Array<number>,
  skip?: number,
  take?: number
}>());
export const SbSetTeamsForDropdown = createAction(SB_SET_TEAMS_FOR_DROPDOWN, props<{
  payload: Array<DropdownItem<number, string>>
}>());

export const SbGetCountriesForDropdown = createAction(SB_GET_COUNTRIES_FOR_DROPDOWN, props<{
  sportTypeId: number,
  skip?: number,
  take?: number
}>());
export const SbSetCountriesForDropdown = createAction(SB_SET_COUNTRIES_FOR_DROPDOWN, props<{
  payload: Array<DropdownItem<number, string>>,
}>());