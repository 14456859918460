import { Action } from '@ngrx/store';
import { GetBonusesByTypeRequest } from '../../models/coreModels';

export const GET_TEMPLATES = '[SETTING] Get Templates';
export const SET_TEMPLATES = '[SETTING] Set Templates';

export const SELECT_TIMEZONE = '[METADATA] Select Timezone';
export const GET_DATA = '[METADATA] Get Data';

export const EXPORT_ALL_DATA_TO_EXCEL = '[METADATA] Export all data to excel';
export const EXPORT_ALL_DATA_TO_EXCEL_SUCCESS = '[METADATA] Export all data to excel success';
export const EXPORT_ALL_DATA_TO_EXCEL_FAILURE = '[METADATA] Export all data to excel failure';
export const CLEAR_EXPORTED_DATA = '[METADATA] Clear exported data';

export const GET_USERS_AUTOCOMPLETE = '[METADATA] Get Users autocomplete';
export const SET_USERS_AUTOCOMPLETE = '[METADATA] Set Users autocomplete';

export const SET_PAYMENT_METHODS = '[METADATA] Set Payment methods';

export const GET_ONLINE_BRANCHS = '[METADATA] Get OnlineBranchs';
export const SET_ONLINE_BRANCHS = '[METADATA] Set OnlineBranchs';
export const SET_BRANCHES = '[METADATA] Set Branches';
export const SET_BET_TYPES = '[METADATA] Set Bet Types';
export const SET_MARKETING_CAMPAIGNS = '[METADATA] Set Marketing Campaigns';

export const SET_ACTIVE_CAMPAIGNS_PER_USER = '[METADATA] Set active campaigns per user';
export const CLEAR_ACTIVE_CAMPAIGNS_PER_USER = '[METADATA] Clear active campaigns per user';

export const GET_FILTERS_FOR_BRAND_SETTINGS = '[METADATA] Get filters for brand settings';
export const SET_FILTERS_FOR_BRAND_SETTINGS = '[METADATA] Set filters for brand settings';

export const GET_ENUMS = '[METADATA] Get Enums';
export const SET_ENUMS = '[METADATA] Set Enums';

export const CLEAR_CACHE = '[METADATA] Clear Cache';

export const FAILURE = '[METADATA] Failure';

export const SET_FAILURE = '[METADATA] Set Failure';

export const GET_SPORTS_TREE = '[EVENTS-METADATA] Get Sports Tree';
export const SET_SPORTS_TREE = '[EVENTS-METADATA] Set Sports Tree';

export const GET_TEAMS_BY_LEAGUES = '[EVENTS-METADATA] Get Teams By Leagues';
export const SET_TEAMS_BY_LEAGUES = '[EVENTS-METADATA] Set Teams By Leagues';
export const CLEAR_TEAMS = '[EVENTS-METADATA] Clear Teams';

export const GENERAL_POST_ACTION = '[METADATA] General post action';
export const GENERAL_POST_ACTION_COMPLETED = '[METADATA] General post action completed';

export const SET_ICOMOON_ICONS = '[METADATA] Set icomoon icons';

export const GET_BONUSES_BY_TYPE = '[ONLINE] Get Bonuses By Type';
export const SET_BONUSES_BY_TYPE = '[ONLINE] Set Bonuses By Type';

export class GeneralPostAction implements Action {
  readonly type = GENERAL_POST_ACTION;

  constructor(public payload: any = null) {}
}
export class GeneralPostActionCompleted implements Action {
  readonly type = GENERAL_POST_ACTION_COMPLETED;

  constructor(public payload: any = null) {}
}
export class GetData implements Action {
  readonly type = GET_DATA;

  constructor(public payload: any) {
  }
}
export class GetUsersAutocomplete implements Action {
  readonly type = GET_USERS_AUTOCOMPLETE;

  constructor(public payload: any) {}
}
export class SetActiveCampaignsPerUser implements Action {
  readonly type = SET_ACTIVE_CAMPAIGNS_PER_USER;

  constructor(public payload: any) {}
}
export class ClearActiveCampaignsPerUser implements Action {
  readonly type = CLEAR_ACTIVE_CAMPAIGNS_PER_USER;

  
}
export class SetUsersAutocomplete implements Action {
  readonly type = SET_USERS_AUTOCOMPLETE;

  constructor(public payload: any) {}
}
export class SetPaymentMethods implements Action {
  readonly type = SET_PAYMENT_METHODS;

  constructor(public payload: any) {}
}
export class ExportAllDataToExcel implements Action {
  readonly type = EXPORT_ALL_DATA_TO_EXCEL;

  constructor(public payload: any) {}
}
export class ExportAllDataToExcelSuccess implements Action {
  readonly type = EXPORT_ALL_DATA_TO_EXCEL_SUCCESS;

  constructor(public payload: any) {}
}
export class ExportAllDataToExcelFailure implements Action {
  readonly type = EXPORT_ALL_DATA_TO_EXCEL_FAILURE;

  
}
export class ClearExportedData implements Action {
  readonly type = CLEAR_EXPORTED_DATA;

  
}
export class SetBetTypes implements Action {
  readonly type = SET_BET_TYPES;

  constructor(public payload: any = null) {}
}
export class SetMarketingCampaigns implements Action {
  readonly type = SET_MARKETING_CAMPAIGNS;

  constructor(public payload: any = null) {}
}
export class SetBranches implements Action {
  readonly type = SET_BRANCHES;

  constructor(public payload: any) {}
}
export class ClearCache implements Action {
  readonly type = CLEAR_CACHE;

  
}
export class SelectTimeZone implements Action {
  readonly type = SELECT_TIMEZONE;

  constructor(public payload: any) {}
}
export class GetEnums implements Action {
  readonly type = GET_ENUMS;

  constructor(public payload: any) {}
}

export class SetEnums implements Action {
  readonly type = SET_ENUMS;

  constructor(public payload: any) {}
}
export class GetOnlineBranches implements Action {
  readonly type = GET_ONLINE_BRANCHS;

  constructor(public payload: any) {}
}

export class SetOnlineBranches implements Action {
  readonly type = SET_ONLINE_BRANCHS;

  constructor(public payload: any) {}
}

export class GetFiltersForBrandSettings implements Action {
  readonly type = GET_FILTERS_FOR_BRAND_SETTINGS;

  constructor(public payload: any) {}
}

export class SetFiltersForBrandSettings implements Action {
  readonly type = SET_FILTERS_FOR_BRAND_SETTINGS;

  constructor(public payload: any) {}
}

export class GetTemplates implements Action {
  readonly type = GET_TEMPLATES;

  constructor(public payload: any) {}
}

export class SetTemplates implements Action {
  readonly type = SET_TEMPLATES;

  constructor(public payload: any) {}
}

export class ClearTeams implements Action {
  readonly type = CLEAR_TEAMS;

  
}
export class GetTeamsByLeagues implements Action {
  readonly type = GET_TEAMS_BY_LEAGUES;

  constructor(public payload: any = null) {}
}
export class SetTeamsByLeagues implements Action {
  readonly type = SET_TEAMS_BY_LEAGUES;

  constructor(public payload: any = null) {}
}
export class GetSportsTree implements Action {
  readonly type = GET_SPORTS_TREE;

  constructor(public payload: any = null) {}
}
export class SetSportsTree implements Action {
  readonly type = SET_SPORTS_TREE;

  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export class SetIcomoonIcons implements Action {
  readonly type = SET_ICOMOON_ICONS;

  constructor(public payload = null) {}
}

export class GetBonusesByType implements Action {
  readonly type = GET_BONUSES_BY_TYPE;

  constructor(public payload: GetBonusesByTypeRequest = null) {}
}
export class SetBonusesByType implements Action {
  readonly type = SET_BONUSES_BY_TYPE;

  constructor(public payload: any = null) {}
}

export type Actions =
  | GetEnums
  | GeneralPostAction
  | GeneralPostActionCompleted
  | GetData
  | SetBranches
  | SetBetTypes
  | SetEnums
  | SelectTimeZone
  | GetOnlineBranches
  | GetTemplates
  | SetTemplates
  | ClearCache
  | ClearTeams
  | SetOnlineBranches
  | SetPaymentMethods
  | GetUsersAutocomplete
  | SetUsersAutocomplete
  | ExportAllDataToExcel
  | ExportAllDataToExcelSuccess
  | ExportAllDataToExcelFailure
  | ClearExportedData
  | SetMarketingCampaigns
  | GetSportsTree
  | SetSportsTree
  | GetTeamsByLeagues
  | SetTeamsByLeagues
  | SetActiveCampaignsPerUser
  | ClearActiveCampaignsPerUser
  | Failure
  | SetFailure
  | GetFiltersForBrandSettings
  | SetFiltersForBrandSettings
  | SetIcomoonIcons
  | GetBonusesByType
  | SetBonusesByType;
