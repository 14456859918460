import { Action } from '@ngrx/store';

export const GET_ACTION = '[CMS-MENU-MANAGEMENT] Get action';
export const POST_ACTION = '[CMS-MENU-MANAGEMENT] Post action';
export const SET_MENU_ITEMS = '[CMS-MENU-MANAGEMENT] Get menu items';
export const CLEAR_MENU_ITEMS = '[CMS-MENU-MANAGEMENT] Clear menu items';
export const UPDATE_MENU_ITEM = '[CMS-MENU-MANAGEMENT] Update menu item';
export const ACTIVATE_MENU_ITEM = '[CMS-MENU-MANAGEMENT] Activate menu item';
export const DEACTIVATE_MENU_ITEM = '[CMS-MENU-MANAGEMENT] DeActivate menu item';
export const REORDER_MENU_ITEM = '[CMS-MENU-MANAGEMENT] Reorder menu item';
export const EDIT_MENU_ITEMS = '[CMS-MENU-MANAGEMENT] Edit menu items';
export const CANCEL_EDIT_MENU_ITEMS = '[CMS-MENU-MANAGEMENT] Edit menu items';
export const EDIT_MENU_ITEMS_SUCCESS = '[CMS-MENU-MANAGEMENT] Edit menu items success';
export const EDIT_MENU_ITEMS_FAILURE = '[CMS-MENU-MANAGEMENT] Edit menu items failure';
export const DUPLICATE_MENU_ITEM_SUCCESS = '[CMS-MENU-MANAGEMENT] Duplicate menu item success';
export const DUPLICATE_MENU_ITEM_FAILURE = '[CMS-MENU-MANAGEMENT] Duplicate menu item failure';

export const FAILURE = '[CMS-MENU-MANAGEMENT] Failure';
export const SET_FAILURE = '[CMS-MENU-MANAGEMENT] Set Failure';

export class EditMenuItems implements Action {
  readonly type = EDIT_MENU_ITEMS;

  constructor(public payload: any = null) {}
}
export class EditMenuItemsSuccess implements Action {
  readonly type = EDIT_MENU_ITEMS_SUCCESS;

  
}
export class ClearMenuItems implements Action {
  readonly type = CLEAR_MENU_ITEMS;

  
}
export class DuplicateMenuItemSuccess implements Action {
  readonly type = DUPLICATE_MENU_ITEM_SUCCESS;

  
}
export class DuplicateMenuItemFailure implements Action {
  readonly type = DUPLICATE_MENU_ITEM_FAILURE;

  
}
export class EditMenuItemsFailure implements Action {
  readonly type = EDIT_MENU_ITEMS_FAILURE;

  
}
export class CancelEditMenuItems implements Action {
  readonly type = CANCEL_EDIT_MENU_ITEMS;

  
}
export class GetAction implements Action {
  readonly type = GET_ACTION;

  constructor(public payload: any = null) {}
}
export class PostAction implements Action {
  readonly type = POST_ACTION;

  constructor(public payload: any = null) {}
}
export class SetMenuItems implements Action {
  readonly type = SET_MENU_ITEMS;

  constructor(public payload: any = null) {}
}
export class UpdateMenuItem implements Action {
  readonly type = UPDATE_MENU_ITEM;

  constructor(public payload: any = null) {}
}
export class ReorderMenuItem implements Action {
  readonly type = REORDER_MENU_ITEM;

  constructor(public payload: any = null) {}
}
export class ActivateMenuItem implements Action {
  readonly type = ACTIVATE_MENU_ITEM;

  constructor(public payload: any = null) {}
}
export class DeActivateMenuItem implements Action {
  readonly type = DEACTIVATE_MENU_ITEM;

  constructor(public payload: any = null) {}
}
export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions =
  | GetAction
  | ClearMenuItems
  | PostAction
  | EditMenuItems
  | EditMenuItemsSuccess
  | EditMenuItemsFailure
  | CancelEditMenuItems
  | UpdateMenuItem
  | ReorderMenuItem
  | ActivateMenuItem
  | DeActivateMenuItem
  | SetMenuItems
  | Failure
  | SetFailure;
