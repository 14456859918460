import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EventBetType } from '../../models';

export interface EventBetTypeState extends EntityState<EventBetType> {
  totalCount: number;
  operationResult?: {
    [key: string]: {
      isSuccessful: boolean,
    }
  }
  oddsOperationResult?: {
    [key: string]: {
      isSuccessful: boolean,
    }
  }
}

export const eventBetTypesAdapter: EntityAdapter<EventBetType> = createEntityAdapter<EventBetType>({
  selectId: (item) => item.id
});
export const eventBetTypesInitialState: EventBetTypeState = eventBetTypesAdapter.getInitialState({
  totalCount: 0,
  entities: null
});