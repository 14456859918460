import { EntityAdapter, EntityState, createEntityAdapter } from '@ngrx/entity';
import { OperationResult } from 'src/app/sport-betting/common/models';
import { SoccerMatch } from '../../models';

export interface SoccerMatchState extends EntityState<SoccerMatch> {
  operationResult?: OperationResult;
}

export const soccerMatchAdapter: EntityAdapter<SoccerMatch> = createEntityAdapter<SoccerMatch>({
  selectId: (item) => item.eventId
});
export const soccerMatchInitialState: SoccerMatchState = soccerMatchAdapter.getInitialState({
});