import * as VoucherData from '../actions/create-batch.actions';
import * as fromApp from 'abs@core/store/reducers';
import { Amount, Distributor } from '../../models/models';

export interface State {
    distributors: Array<Distributor>,
    voucherAmounts: Array<Amount>,
    createBatchStatus: boolean,
    createBatchErrorMessage: string
}

export const initialState: State = {
    distributors: null,
    voucherAmounts: null,
    createBatchStatus: null,
    createBatchErrorMessage: null
};

export function voucherReducer(state = initialState, action: VoucherData.Actions): State {
    switch (action.type) {
        case VoucherData.GET_DISTRIBUTORS_DATA: {
            return {
                ...state
            };
        }
        case VoucherData.GET_DISTRIBUTORS_DATA_SUCCESS: {
            return {
                ...state,
                distributors: action.payload.data.distributors,
                voucherAmounts: action.payload.data.voucherAmounts
            };
        }
        case VoucherData.CREATE_BATCH: {
            return state;
        }
        case VoucherData.CREATE_BATCH_SUCCESS: {
            return {
                ...state,
                createBatchStatus: true,
                createBatchErrorMessage: null
            };
        }
        case VoucherData.CREATE_BATCH_FAIL: {
            return {
                ...state,
                createBatchStatus: false,
                createBatchErrorMessage: action.payload.error ? action.payload.error.result.errorDescription : action.payload.result.errorDescription
            };
        }
        case VoucherData.END_REQUEST: {
            return {
                ...state,
                createBatchStatus: null
            };
        }
        default:
            return state;
    }
}

export const getDistibutors = (state: fromApp.AppState) => {
    return state.voucherDataReducer.distributors;
};

export const getvoucherAmounts = (state: fromApp.AppState) => {
    return state.voucherDataReducer.voucherAmounts;
};

export const getCreateBatchStatus = (state: fromApp.AppState) => {
    return state.voucherDataReducer.createBatchStatus;
};

export const getCreateBatchErrorMessage = (state: fromApp.AppState) => {
    return state.voucherDataReducer.createBatchErrorMessage;
};
