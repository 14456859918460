<span *ngIf="value !== null else noData">
  <ng-container [ngSwitch]="wrapInBrackets">
    <ng-container *ngSwitchCase="true">
      ({{!useFormater ? value : (value | number : format) }})
    </ng-container>
    <ng-container *ngSwitchCase="false">
      {{!useFormater ? value : (value | number : format) }}
    </ng-container>
  </ng-container>
</span>
<ng-template #noData>
  <span *ngIf="noDataTemplate">
    <i class="fas fa-minus noData"></i>
  </span>
</ng-template>
