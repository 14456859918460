import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions';
import { currenciesInitialState, currencyAdapter } from '../adapters';
import { OperationType } from 'src/app/sport-betting/common/models';

export const CurrenciesReducer = createReducer(
  currenciesInitialState,
  on(Actions.SbSetCurrencies, (state, { payload }) => {
    return currencyAdapter.setAll(payload.data, {
      ...state,
      totalCount: payload.totalCount
    });
  }),
  on(Actions.SbUpdateCurrency, state => ({
    ...state,
    operationResult: null
  })),
  on(Actions.SbUpdateCurrencySuccess, (state, { payload }) => {
    return currencyAdapter.updateOne({
      id: payload.id,
      changes: payload
    }, {
      ...state,
      operationResult: {
        id: payload.id,
        isSuccessful: true,
        operationType: OperationType.Update
      }
    });
  }),
  on(Actions.SbUpdateDefaultCurrencySuccess, (state, { payload }) => {
    return currencyAdapter.updateOne({
      id: payload,
      changes: {
        isDefault: true,
      }
    }, {
      ...state,
      operationResult: {
        id: payload,
        isSuccessful: true,
        operationType: 'UpdateDefault'
      }
    });
  }),
  on(Actions.CurrenciesFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);