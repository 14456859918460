import { createReducer, on } from '@ngrx/store';
import { dropdownsDataInitialState } from '../adapters';
import { SbSetCountriesForDropdown, SbSetLeaguesForDropdown, SbSetSportTypesForDropdown, SbSetTeamsForDropdown } from '../actions';

export const DropdownsDataReducer = createReducer(
  dropdownsDataInitialState,
  on(SbSetSportTypesForDropdown, (state, { payload }) => {
    return {
      ...state,
      sportTypes: payload
    };
  }),
  on(SbSetLeaguesForDropdown, (state, { payload }) => {
    return {
      ...state,
      leagues: payload
    };
  }),
  on(SbSetTeamsForDropdown, (state, { payload }) => {
    return {
      ...state,
      teams: payload
    };
  }),
  on(SbSetCountriesForDropdown, (state, { payload }) => {
    return {
      ...state,
      countries: payload
    };
  }),
);