export class APIMethodsExposure {
  /*GET*/
  public static GET_BETS_FOR_AUTHORIZATION = 'sport/GetBetsForAuthorization';
  public static GET_EVENTS_LIMIT = 'sport/GetEventsLimit';
  public static CHECK_IF_THERE_ARE_NEW_AUTHORIZATION_REQUEST_FOR_THE_LAST_X_MS =
    'sport/ExistsNewEventsForAuthorization';
  public static GET_AUTHORIZATION_ORDER = 'sport/GetAuthorizationOrder';

  /*Actions*/
  public static UPDATE_AUTHORIZATION_COMMENT = 'sport/UpdateAuthorizationComment';
  public static SET_AUTHORIZATION = 'sport/SetAuthorization';
}
export class Configuration {
  public static CHEK_FOR_NEW_AUTHORIZATION_REQUESTS_MS = 3000;
  public static RELOAD_LIVE_AUTHORIZATION_DATA = 20000;

  public static TIME_TO_RESPOND_SEC = 60;

  public static RELOAD_EVENT_LIMIT_DATA_INSIDE_LIVE_AUTHORIZATION_SCREEN = 10000;
  public static RELOAD_EVENT_LIMIT_DATA_REPORT = 6000 * 10 * 5;
}
export class ExposurePermissions {
  public static EXPOSURE_AUTHORIZATION_SET_INCREASE_LIMIT_ACTION = 'EXPOSURE_AUTHORIZATION_SET_INCREASE_LIMIT_ACTION';
  public static EXPOSURE_AUTHORIZATION_REQUEST_AUTHORIZED_ODDS = 'EXPOSURE_AUTHORIZATION_REQUEST_AUTHORIZED_ODDS';
  public static EXPOSURE_AUTHORIZATION_REQUEST_AUTHORIZED_STAKE = 'EXPOSURE_AUTHORIZATION_REQUEST_AUTHORIZED_STAKE';
  public static BETSLIPS_SHOW_BARCODE_SECTION = 'BETSLIPS_SHOW_BARCODE_SECTION';
  public static EXPOSURE_AUTHORIZATION_RESPOND_ACTION = 'EXPOSURE_AUTHORIZATION_RESPOND_ACTION';
  public static EXPOSURE_AUTHORIZATION_COMMENTS_ACTION = 'EXPOSURE_AUTHORIZATION_COMMENTS_ACTION';
  public static BETSLIPS_DATA_SCREEN = 'BETSLIPS_DATA_SCREEN';
}
