import { FormControl, FormGroup } from '@angular/forms';
import { KeyValue } from '@angular/common';
import { FormValTranslateKey } from 'abs@core/models/coreModels';
import { catchError, debounceTime, map, skip, switchMap, takeUntil } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { UsersAutocompleteRequest } from 'abs@core/models/request';
import { APIMethods, APIMethodsGames, AppSettings, PermissionsScreensCodes } from 'abs@core/app.config';
import * as MetaDataActions from '../../core/store/actions/metadata.actions';
import { eAuthorizationStatus } from 'abs@shared/eNums';
import { IDynamicViewsConfig } from 'abs@shared/models/dynamic';
export function isNullOrUndefined(value): boolean {
  return value === undefined || value === null;
}
export function isNullOrUndefinedOrEmptyArray(value): boolean {
  return value === undefined || value === null || (Array.isArray(value) && value.length === 0);
}
export function isNullOrUndefinedOrEmptyString(value): boolean {
  return value === undefined || value === null || (typeof value === 'string' && (value === '' || value.trim() === ''));
}
export function isNullOrUndefinedOrEmptyStringOrEmptyArray(value): any {
  return value === undefined || value === null || value === '' || (Array.isArray(value) && value.length === 0)
    ? null
    : value;
}
export function hasEmptyValue(value): boolean {
  return (
    value === undefined ||
    value === null ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === 'string' && (value === '' || value.trim() === ''))
  );
}
export function noWhitespaceValidator(control: FormControl) {
  const isWhitespace = (control.value || '').trim().length === 0;
  const isValid = !isWhitespace;
  return isValid ? null : { whitespace: true };
}
export function getWinStatusColorGeneral(val) {
  const value = val?.toLowerCase();
  if (value === 'won' || value === 'winner') {
    return 'success';
  } else if (value === 'lost' || value === 'looser' || value === 'loose') {
    return 'danger';
  } else if (value === 'pending') {
    return 'info';
  } else if (value === 'refund') {
    return 'warning';
  } else if (value.startsWith('cashed')) {
    return 'success';
  } else {
    return '';
  }
}

export function getAuthorizationStatusColor(status) {
  if (status) {
    if (status.name == eAuthorizationStatus.Sold) {
      return 'success';
    } else if (status.name == eAuthorizationStatus.AuthorizationDeclined) {
      return 'danger';
    } else if (status.name == eAuthorizationStatus.WaitForAuthorization) {
      return 'success';
    } else if (status.name == eAuthorizationStatus.WaitForSell) {
      return 'danger';
    } else if (status.name == eAuthorizationStatus.Expired) {
      return 'info';
    }
    return '';
  }
  return '';
}

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      // return if another validator has already found an error on the matchingControl
      return;
    }

    // set error on matchingControl if validation fails
    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}
export function goToLink(urlToOpen: string) {
  let url = '';
  if (!/^http[s]?:\/\//.test(urlToOpen)) {
    url += 'http://';
  }
  window.open(url + urlToOpen, '_blank');
}
export function userNameTooltip(userName: string): string {
  return 'Username: ' + userName;
}
export function getMultiSortParams(event): any {
  if (event.multiSortMeta) {
    const sortingArray = [];
    for (let i = 0; i < event.multiSortMeta.length; i++) {
      const sortingRule = {};
      sortingRule[event.multiSortMeta[i].field] = event.multiSortMeta[i].order === 1 ? 'asc' : 'desc';
      sortingArray.push(sortingRule);
    }
    return sortingArray;
  }
  return null;
}
export function inputNumberLimitingFixed2(event: any) {
  if (event.currentTarget.value >= 0) {
    if (event.currentTarget.value === '00') {
      event.currentTarget.value = '0.0';
    } else {
      const indexOfDot = event.currentTarget.value.indexOf('.');
      if (indexOfDot != -1) {
        if (event.currentTarget.value.length - indexOfDot > 3) {
          event.currentTarget.value = parseFloat(event.currentTarget.value).toFixed(2);
        }
      }
    }
  } else {
    event.currentTarget.value = '';
  }
}

export function compareFunctionDisabledFirstOrderMapObject(
  a: KeyValue<string, FormValTranslateKey>,
  b: KeyValue<string, FormValTranslateKey>
): number {
  if (a.value.disabled && b.value.disabled) {
    return 0;
  }
  if (a.value.disabled && !b.value.disabled) {
    return -1;
  }
  if (!a.value.disabled && b.value.disabled) {
    return 1;
  }
  return null;
}
export const autocomplete = (time, selector) => (source$) =>
  source$.pipe(
    debounceTime(time),
    switchMap((...args: any[]) => selector(...args).pipe(takeUntil(source$.pipe(skip(1)))))
  );
export function fetchAutoComplete(term: string, outputChannel: any): Observable<any> {
  const request = new UsersAutocompleteRequest(term);
  return outputChannel
    .postRequest(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.AUTOCOMPLETE_USER,
      request
    )
    .pipe(
      map((ResponseData: any) => {
        if (ResponseData.isSuccessfull) {
          return ResponseData.data;
        } else {
          return new MetaDataActions.Failure(ResponseData);
        }
      }),
      catchError((error) => of(new MetaDataActions.Failure(error)))
    );
}

export function ClearUnnecessaryKeys(obj: object): object {
  Object.keys(obj).forEach((key: string) => {
    if (hasEmptyValue(obj[key])) {
      delete obj[key];
    }
  });
  return obj;
}

export function getDynamicViews(): IDynamicViewsConfig {
  return {
    '2-15': { fullBreadcrumb: 'Promotions/Selection Bonus', permission: PermissionsScreensCodes.TICKET_BONUS_SELECTION_BONUS_SCREEN },
    '2-21': { fullBreadcrumb: 'Promotions/Refund Bonus', permission: PermissionsScreensCodes.TICKET_BONUS_REFUND_BONUS_SCREEN },
    '2-37': { fullBreadcrumb: 'Promotions/Next Bet Bonus', permission: PermissionsScreensCodes.TICKET_BONUS_NEXT_BET_BONUS_SCREEN },
    '3-28': { fullBreadcrumb: 'Administration/System Configurations', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_SYSTEM_CONFIGURATION_SCREEN },
    '3-54': { fullBreadcrumb: 'Notifications/Notifications Report', permission: PermissionsScreensCodes.NOTIFICATIONS_NOTIFICATIONS_SCREEN },
    '3-58': { fullBreadcrumb: 'Notifications/Template Management', permission: PermissionsScreensCodes.NOTIFICATIONS_NOTIFICATIONS_SCREEN },
    '3-55': { fullBreadcrumb: 'Reports/Affiliates/Affiliate Cellexpert Activities', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_ACTIVITIES_SCREEN },
    '3-56': { fullBreadcrumb: 'Reports/Affiliates/Affiliate Cellexpert Players', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_PLAYERS_SCREEN },
    '3-57': { fullBreadcrumb: 'Reports/Affiliates/Affiliate Cellexpert Transactions', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_AFFILIATE_CELLEXPERT_TRANSACTIONS_SCREEN },
    '5-42': { fullBreadcrumb: 'Administration/Access List by Ip', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_ACCESS_LIST_BY_IP_SCREEN },
    '5-44': { fullBreadcrumb: 'Administration/Access List by Country', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_ACCESS_LIST_BY_COUNTRY_SCREEN },
    '5-45': { fullBreadcrumb: 'Administration/Currency', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_CURRENCY_CONFIGURATION_SCREEN },
    '5-49': { fullBreadcrumb: 'Product Offering/Sport Betting/Sport Margins', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_MARGIN_CONFIGURATION_SCREEN },
    '5-58': { fullBreadcrumb: 'Administration/Products', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_PRODUCTS_CONFIGURATION_SCREEN },
    '6-32': { fullBreadcrumb: 'Reports/Operational Reports/Order per Minute', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_ORDER_PER_MINUTE_SCREEN },
    '6-33': { fullBreadcrumb: 'Reports/Operational Reports/Gap per Seconds', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_GAP_PER_SECONDS_SCREEN },
    '6-34': { fullBreadcrumb: 'Reports/Operational Reports/Pending Settle Orders', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_PENDING_SETTLE_ORDERS_SCREEN },
    '7-61': { fullBreadcrumb: 'Promotions/Free Bets/Free Bet Report Per User', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_FREE_BET_PER_USER_CAMPAIGN_SCREEN },
    '7-62': { fullBreadcrumb: 'Promotions/Free Bets Bonus', permission: PermissionsScreensCodes.BONUS_SPORTBETTING_FREE_BET_SCREEN },
    '7-63': { fullBreadcrumb: 'Promotions/Free Bets/Free Bet Report', permission: PermissionsScreensCodes.DYNAMIC_REPORTS_FREE_BET_CAMPAIGN_SCREEN },
  };
}

/**
 * Convert seconds to hours:minutes:seconds (HH:MM:SS)
 * @param value
 */
export function secondsToHoursMinutesSeconds(value: number): string | null {
  if (value) {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor((value - (hours * 3600)) / 60);
    const seconds = value - (hours * 3600) - (minutes * 60);

    const hoursFormat: string = hours < 10 ? `0${hours}` : `${hours}`;
    const minutesFormat: string = minutes < 10 ? `0${minutes}` : `${minutes}`;
    const secondsFormat: string = seconds < 10 ? `0${seconds}` : `${seconds}`;

    return `${hoursFormat}:${minutesFormat}:${secondsFormat}`;
  }
  return null;
}
