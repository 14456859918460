import { Component } from '@angular/core';
import {AppSettings} from "../core/app.config";

@Component({
  selector: 'app-notfound',
  templateUrl: './app.notfound.component.html',
})
export class AppNotfoundComponent {
  logo = AppSettings.BRAND_DOMAIN;
}
