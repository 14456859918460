import * as cmsLabels from '../actions/cms.labels.actions';
import * as fromApp from 'abs@core/store/reducers';
import {Label} from '../../models/label';
import * as moment from 'moment';
import copy from 'fast-copy';
import {isNullOrUndefinedOrEmptyString} from 'abs@shared/shared-functions/functions';

export interface State {
  loading: boolean;
  labels: Label[];
  selectedChannel: string;
  savingLabel: boolean;
  showLabelForm: boolean;
  editedLabel: Label;
  supportedLanguages: any;
  totalLabels: number;
}

export const initialState: State = {
  loading: false,
  labels: null,
  selectedChannel: null,
  savingLabel: false,
  showLabelForm: false,
  editedLabel: null,
  supportedLanguages: null,
  totalLabels: null,
};

export function CmsLabelsReducer(state = initialState, action: cmsLabels.Actions): State {
  switch (action.type) {
    case cmsLabels.GET_LABEL_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case cmsLabels.GET_LABELS_FAILURE: {
      return {
        ...state,
        loading: false,
        labels: null,
      };
    }
    case cmsLabels.EDIT_NEW_LABEL: {
      return {
        ...state,
        editedLabel: null,
        showLabelForm: true,
      };
    }
    case cmsLabels.EDIT_EXISTING_LABEL: {
      return {
        ...state,
        showLabelForm: true,
        editedLabel: action.payload,
      };
    }
    case cmsLabels.SET_SUPPORTED_LANGUAGES: {
      return {
        ...state,
        supportedLanguages: action.payload,
      };
    }
    case cmsLabels.DELETE_LABEL_SUCCESS: {
      return {
        ...state,
        showLabelForm: false,
        editedLabel: null,
      };
    }
    case cmsLabels.CLOSE_EDITED_LABEL: {
      return {
        ...state,
        showLabelForm: false,
        editedLabel: null,
      };
    }
    default: {
      return state;
    }
  }
}

export const getSupportedLanguagesLabels = (state: fromApp.AppState) => state.cmsLabelsReducer.supportedLanguages;
export const getLabels = (state: fromApp.AppState) => {
  console.log('getLabels', state.cmsLabelsReducer.labels);
  if (state.cmsLabelsReducer.labels === null) {
    return null;
  }
  const labels = copy(state.cmsLabelsReducer.labels);
  labels.map((label) => {
    label.VisibleFrom = !isNullOrUndefinedOrEmptyString(label.VisibleFrom) ? moment(label.VisibleFrom).toDate() : null;
    label.VisibleTo = !isNullOrUndefinedOrEmptyString(label.VisibleTo) ? moment(label.VisibleTo).toDate() : null;
    return label;
  });
  return labels;
};

