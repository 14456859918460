import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { LoginPageComponent } from './containers/login-page.component';
import { AuthModule } from './auth.module';

export const authRoutes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginPageComponent },
];

export const AuthRoutes: ModuleWithProviders<AuthModule> = RouterModule.forChild(authRoutes);
