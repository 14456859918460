<ng-template *ngIf="val; then canShowTemplate; else cannotShowTemplate"></ng-template>
<ng-template #canShowTemplate>
  <button pButton
          type="button"
          (click)="op.show($event)"
          icon="fa fa-eye"
          class="ui-button p-button-outlined"></button>
</ng-template>
<ng-template #cannotShowTemplate>
  <button pButton
          type="button"
          icon="fa fa-eye"
          class="ui-button p-button-outlined"
          disabled></button>
</ng-template>

<p-overlayPanel #op
                appendTo="body"
                [style]="{'margin-left': '30px'}"
                [dismissable]="dismissable"
                [showCloseIcon]="showCloseIcon">
  <ng-template pTemplate>
    <iframe appIframeResizer
            [src]="val | safe: 'resourceUrl'"></iframe>
  </ng-template>
</p-overlayPanel>
