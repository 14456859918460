import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { EventLimit } from '../model/event-limit.model';
import { OperationResult, DropdownItem } from 'src/app/sport-betting/common/models';

export interface EventLimitsState extends EntityState<EventLimit> {
  totalCount: number;
  operationResult?: OperationResult;
  betTypeOptions: Array<DropdownItem<number, string>>;
}

export const eventLimitAdapter: EntityAdapter<EventLimit> = createEntityAdapter<EventLimit>({
  selectId: (eventLimit: EventLimit) => `${eventLimit.eventId}-${eventLimit.betTypeId}-${eventLimit.highestRiskOddName}`
});

export const eventLimitInitialState: EventLimitsState = eventLimitAdapter.getInitialState({
  totalCount: 0,
  betTypeOptions: []
});
