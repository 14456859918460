export enum ExportEntityName {
    Team = 'Team',
    League = 'League',
    SportType = 'SportType',
    Country = 'Country',
    BetType = 'BetType',
    Margins = 'Margins',
    Event = 'Event',
    Currency = 'Currency',
    Authorization = 'Monitoring Authorization'
}