import { Action } from "@ngrx/store";

export const GET_BATCH_DATA = "batch/getBatchData";
export const GET_BATCH_DATA_SUCCESS = "batch/getBatchDataSuccess";

export class GetBatchData implements Action {
    readonly type = GET_BATCH_DATA;

    constructor(public payload: any = null) { }
}

export class GetBatchDataSuccess implements Action {
    readonly type = GET_BATCH_DATA_SUCCESS;

    constructor(public payload: any = null) { }
}

export type Actions =
    GetBatchData
    | GetBatchDataSuccess
