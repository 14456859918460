import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/league.actions';
import { OperationType } from 'src/app/sport-betting/common/models';
import { betTypeLeagueMarginsAdapter, betTypeLeagueMarginsInitialState } from '../adapters/league.adapter';

export const BetTypeLeagueMarginsReducer = createReducer(
  betTypeLeagueMarginsInitialState,
  on(Actions.SbSetLeagueMargins, (state, { payload }) => {
    return betTypeLeagueMarginsAdapter.upsertOne({
      key: payload.countryId,
      data: payload.data,
      totalCount: payload.totalCount
    }, state);
  }),
  on(Actions.SbUpdateLeagueMargin, Actions.SbDeleteLeagueMargin, (state, { payload }) => {
    return betTypeLeagueMarginsAdapter.updateOne({
      id: payload.countryId,
      changes: {
        operationResult: null
      }
    }, state);
  }),
  on(Actions.SbUpdateLeagueMarginSuccess, (state, { payload }) => {
    return betTypeLeagueMarginsAdapter.updateOne({
      id: payload.countryId,
      changes: {
        data: state.entities[payload.countryId]?.data?.map((item) =>
          item.leagueId === payload.leagueId ? { ...item, margin: payload.margin, marginId: payload.marginId } : item
        ),
        operationResult: {
          id: payload.leagueId,
          isSuccessful: true,
          operationType: OperationType.Update
        }
      }
    }, state);
  }),
  on(Actions.SbDeleteLeagueMarginSuccess, (state, { payload }) => {
    return betTypeLeagueMarginsAdapter.updateOne({
      id: payload.countryId,
      changes: {
        data: state.entities[payload.countryId]?.data?.map((item) =>
          item.leagueId === payload.leagueId ? { ...item, margin: null, marginId: null } : item
        ),
        operationResult: {
          id: payload.leagueId,
          isSuccessful: true,
          operationType: OperationType.Update
        }
      }
    }, state);
  }),
  on(Actions.LeagueMarginsFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);