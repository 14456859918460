import { Action } from '@ngrx/store';

/******************CASINO************************/

export const GET_GAMES = '[CASINO] Get Games';
export const GET_ALL_GAMES = '[CASINO] Get All Games';

export const GET_CATEGORIES = '[CASINO] Get Categories';
export const SET_CATEGORIES = '[CASINO] Set Categories';
export const GET_ALL_CATEGORIES = '[CASINO] Get all Categories';
export const SET_ALL_CATEGORIES = '[CASINO] Set all Categories';
export const SET_GAMES = '[CASINO] Set Games';
export const SET_ALL_GAMES = '[CASINO] Set All Games';

export const EDIT_GAME = '[CASINO] Edit Game';
export const SAVE_GAME = '[CASINO] Save Game';
export const SAVE_GAME_SUCCESS = '[CASINO] Save Game Success';
export const CANCEL_EDIT_GAME = '[CASINO] Cancel Edit Game';
export const SAVE_GAME_FAIL = '[CASINO] Save Game Fail';
export const REORDER_GAME = '[CASINO] Reorder Game';
export const REORDER_GAME_SUCCESS = '[CASINO] Reorder Game Success';
export const REORDER_GAME_FAIL = '[CASINO] Reorder Game Fail';

export const EDIT_CATEGORY = '[CASINO] Edit Category';
export const CANCEL_EDIT_CATEGORY = '[CASINO] Cancel Edit Category';
export const SAVE_CATEGORY = '[CASINO] Save Category';
export const SAVE_CATEGORY_SUCCESS = '[CASINO] Save Category Success';
export const SAVE_CATEGORY_FAIL = '[CASINO] Save Category Fail';
export const REORDER_CATEGORY = '[CASINO] Reorder Category';
export const REORDER_CATEGORY_SUCCESS = '[CASINO] Reorder Category Success';
export const REORDER_CATEGORY_FAIL = '[CASINO] Reorder Category Fail';

export const SET_FAILURE = '[CASINO] Set Failure';
export const FAILURE = '[CASINO] Failure';

export class SaveCategorySuccess implements Action {
  readonly type = SAVE_CATEGORY_SUCCESS;

  constructor(public payload: any = null) {}
}

export class SaveCategoryFail implements Action {
  readonly type = SAVE_CATEGORY_FAIL;

  constructor(public payload: any = null) {}
}
export class SaveGame implements Action {
  readonly type = SAVE_GAME;

  constructor(public payload: any = null) {}
}

export class SaveGameSuccess implements Action {
  readonly type = SAVE_GAME_SUCCESS;

  constructor(public payload: any = null) {}
}

export class SaveGameFail implements Action {
  readonly type = SAVE_GAME_FAIL;

  constructor(public payload: any = null) {}
}

export class GetCategories implements Action {
  readonly type = GET_CATEGORIES;

  constructor(public payload: any = null) {}
}

export class SetCategories implements Action {
  readonly type = SET_CATEGORIES;

  constructor(public payload: any = null) {}
}

export class GetAllCategories implements Action {
  readonly type = GET_ALL_CATEGORIES;

  constructor(public payload: any = null) {}
}

export class SetAllCategories implements Action {
  readonly type = SET_ALL_CATEGORIES;

  constructor(public payload: any = null) {}
}

export class GetGames implements Action {
  readonly type = GET_GAMES;

  constructor(public payload: any = null) {}
}

export class GetAllGames implements Action {
  readonly type = GET_ALL_GAMES;

  constructor(public payload: any = null) {}
}

export class SetGames implements Action {
  readonly type = SET_GAMES;

  constructor(public payload: any = null) {}
}

export class SetAllGames implements Action {
  readonly type = SET_ALL_GAMES;

  constructor(public payload: any = null) {}
}

export class EditCategory implements Action {
  readonly type = EDIT_CATEGORY;

  constructor(public payload: any = null) {}
}
export class CancelEditCategory implements Action {
  readonly type = CANCEL_EDIT_CATEGORY;

  constructor(public payload: any = null) {}
}
export class CancelEditGame implements Action {
  readonly type = CANCEL_EDIT_GAME;

  constructor(public payload: any = null) {}
}

export class SaveCategory implements Action {
  readonly type = SAVE_CATEGORY;

  constructor(public payload: any = null) {}
}

export class EditGame implements Action {
  readonly type = EDIT_GAME;

  constructor(public payload: any = null) {}
}

export class ReorderGame implements Action {
  readonly type = REORDER_GAME;

  constructor(public payload: any = null) {}
}

export class ReorderGameSuccess implements Action {
  readonly type = REORDER_GAME_SUCCESS;

  
}

export class ReorderGameFail implements Action {
  readonly type = REORDER_GAME_FAIL;

  constructor(public payload: any = null) {}
}

export class ReorderCategory implements Action {
  readonly type = REORDER_CATEGORY;

  constructor(public payload: any = null) {}
}

export class ReorderCategorySuccess implements Action {
  readonly type = REORDER_CATEGORY_SUCCESS;

  
}

export class ReorderCategoryFail implements Action {
  readonly type = REORDER_CATEGORY_FAIL;

  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions =
  | GetGames
  | GetAllGames
  | SaveCategory
  | EditCategory
  | CancelEditCategory
  | SetCategories
  | GetAllCategories
  | SetAllCategories
  | SaveGameSuccess
  | SaveGameFail
  | SaveCategorySuccess
  | SaveCategoryFail
  | EditGame
  | CancelEditGame
  | GetCategories
  | SetGames
  | SetAllGames
  | SaveGame
  | ReorderGame
  | ReorderGameSuccess
  | ReorderGameFail
  | ReorderCategory
  | ReorderCategorySuccess
  | ReorderCategoryFail
  | Failure
  | SetFailure;
