import { Component, OnDestroy, Renderer2, NgZone } from '@angular/core';
import * as Auth from '../auth/store/actions/auth.actions';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { getLoggedIn } from '../auth/store/reducers/auth';
import { distinctUntilChanged, finalize, map, takeUntil, tap } from 'rxjs/operators';
import { getGrowlMessage } from '../core/store/reducers/layout';
import {GrowlMessage, SeverityCode} from '../core/models/Growl-Messages';
import * as fromLayout from '../core/store/actions/layout.actions';
import { MessageService, PrimeNGConfig } from 'primeng/api';
import { CachingService } from '../core/services/caching.service';
import { ClearCache } from '../core/store/actions/metadata.actions';
import * as AuthActions from '../auth/store/actions/auth.actions';
import { APIMethods, AppSettings, PermissionsScreensCodes } from '../core/app.config';
import { AppComponent } from '../app.component';
import { MenuService } from './app.menu.service';
import { OutChannelService } from 'abs@core/services/outchannel/outchannel.service';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-main',
  templateUrl: './app.main.component.html',
})
export class AppMainComponent implements OnDestroy {
  overlayMenuActive: boolean;

  staticMenuDesktopInactive = false;

  staticMenuMobileActive: boolean;

  sidebarActive = false;

  sidebarStatic = false;

  menuClick: boolean;

  menuHoverActive = false;

  topbarMenuActive: boolean;

  topbarItemClick: boolean;

  activeTopbarItem: any;

  configActive: boolean;

  configClick: boolean;

  rightMenuActive: boolean;

  rightMenuClick: boolean;

  searchActive: boolean;

  searchClick: boolean;

  activeInlineProfile: boolean;

  pinActive: boolean;
  permission = PermissionsScreensCodes;
  destroyed$ = new Subject<boolean>();
  constructor(
    public renderer: Renderer2,
    public zone: NgZone,
    private store: Store<any>,
    private messageService: MessageService,
    private cacheService: CachingService,
    private primengConfig: PrimeNGConfig,
    public app: AppComponent,
    private menuService: MenuService,
    private outputChannel: OutChannelService,
    private translateService: TranslateService,
  ) {
    this.store
      .select(getGrowlMessage)
      .pipe(
        // distinctUntilKeyChanged('detail'),
        takeUntil(this.destroyed$),
        distinctUntilChanged(),
        tap((message: GrowlMessage) => {
          if (message) {
            this.messageService.add(message);
          }
        }),
        // finalize(() => this.store.dispatch(new layoutActions.ClearMessage())),
        finalize(() => this.messageService.clear())
      )
      .subscribe();
  }

  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
      this.topbarMenuActive = false;
    }

    if (this.configActive && !this.configClick) {
      this.configActive = false;
    }

    if (this.rightMenuActive && !this.rightMenuClick) {
      this.rightMenuActive = false;
    }

    if (this.searchActive && !this.searchClick) {
      this.searchActive = false;
    }

    if (!this.menuClick) {
      if ((this.isSlim() || this.isHorizontal()) && !this.isMobile()) {
        this.menuService.reset();
        this.menuHoverActive = false;
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.overlayMenuActive = false;
        this.staticMenuMobileActive = false;
      }
    }

    this.configClick = false;
    this.rightMenuClick = false;
    this.searchClick = false;
    this.menuClick = false;
    this.topbarItemClick = false;
  }

  onSidebarClick() {
    this.menuClick = true;
  }

  onToggleMenu(event) {
    this.menuClick = true;

    if (this.overlayMenuActive) {
      this.overlayMenuActive = false;
    }

    if (this.sidebarActive) {
      this.sidebarStatic = !this.sidebarStatic;
    }

    event.preventDefault();
  }

  onSidebarMouseOver() {
    if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
      this.sidebarActive = this.isDesktop();
      setTimeout(() => {
        this.pinActive = this.isDesktop();
      }, 200);
    }
  }

  onSidebarMouseLeave() {
    if (this.app.menuMode === 'sidebar' && !this.sidebarStatic) {
      setTimeout(() => {
        this.sidebarActive = false;
        this.pinActive = false;
      }, 250);
    }
  }

  onMenuButtonClick(event) {
    this.menuClick = true;

    if (this.isOverlay()) {
      this.overlayMenuActive = !this.overlayMenuActive;
    }

    if (this.isDesktop()) {
      this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
    } else {
      this.staticMenuMobileActive = !this.staticMenuMobileActive;
    }

    event.preventDefault();
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;

    if (this.activeTopbarItem === item) {
      this.activeTopbarItem = null;
    } else {
      this.activeTopbarItem = item;
    }

    event.preventDefault();
  }

  onTopbarSubItemClick(event, action) {
    event.preventDefault();
    switch (action) {
      case 'logout':
        this.logOut();
        break;
      case 'clearCache':
        this.clearCache();
        break;
      case 'syncUserSettings':
        this.store.dispatch(new AuthActions.RefreshUserSettings());
        break;
      case 'purgeCloudflareCache':
        this.purgeCloudflareCache();
        break;
      default:
        break;
    }
  }

  onRippleChange(event) {
    this.app.ripple = event.checked;
    this.primengConfig.ripple = event.checked;
    localStorage.setItem('rippleEffect', event.checked);
  }

  onConfigClick() {
    this.configClick = true;
  }

  onRightMenuButtonClick() {
    this.rightMenuClick = true;
    this.rightMenuActive = true;
  }

  onRightMenuClick() {
    this.rightMenuClick = true;
  }

  isStatic() {
    return this.app.menuMode === 'static';
  }

  isOverlay() {
    return this.app.menuMode === 'overlay';
  }

  isSlim() {
    return this.app.menuMode === 'slim';
  }

  isHorizontal() {
    return this.app.menuMode === 'horizontal';
  }

  isSidebar() {
    return this.app.menuMode === 'sidebar';
  }

  isDesktop() {
    return window.innerWidth > 991;
  }

  isMobile() {
    return window.innerWidth <= 991;
  }

  isLogged(): Observable<any> {
    return this.store.select(getLoggedIn).pipe(
      map((islogged) => {
        return islogged;
      })
    );
  }

  logOut() {
    this.store.dispatch(new Auth.Logout());
  }
  clearCache() {
    this.cacheService.clearCache();
    this.store.dispatch(new ClearCache());
  }
  purgeCloudflareCache() {
    const baseUrl = AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API;
    this.outputChannel.postRequest(baseUrl, APIMethods.CLOUDFLARE_PURGE_CACHE, null).subscribe({
      error: () => this.store.dispatch(new fromLayout.ErrorMsg(new GrowlMessage(SeverityCode.ERROR, this.translateService.instant('Permission_Denied')))),
      complete: () => this.store.dispatch(new fromLayout.SuccesMsg(new GrowlMessage(SeverityCode.SUCCESS, 'Action Succeed')))
    });
  }

  selectorMatches(el, selector) {
    const matches = 'matches';
    const webkitMatchesSelector = 'webkitMatchesSelector';
    const mozMatchesSelector = 'mozMatchesSelector';
    const msMatchesSelector = 'msMatchesSelector';
    const p = Element.prototype;
    const f =
      p[matches] ||
      p[webkitMatchesSelector] ||
      p[mozMatchesSelector] ||
      p[msMatchesSelector] ||
      function (s) {
        return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
      };
    return f.call(el, selector);
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }
}
