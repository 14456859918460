import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { BaseUser } from 'abs@core/models/coreModels';
import { AuthorizationService } from '../../../authorization/authorization.service';
import { Router } from '@angular/router';
import { eRolesLevel, eUserTemplateForUserCell, PermissionsScreensCodes } from 'abs@core/app.config';
import { GrowlMessage, SeverityCode } from 'abs@core/models/Growl-Messages';
import * as fromLayout from 'abs@core/store/actions/layout.actions';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { isNullOrUndefined } from '../../../shared-functions/functions';

@Component({
  selector: 'app-user-detail-cell',
  templateUrl: './user-detail-cell.component.html',
  styleUrls: ['./user-detail-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserDetailCellComponent {
  @Input() user: BaseUser;
  @Input() templateToShow: number;
  permissionsScreen = PermissionsScreensCodes;
  template = eUserTemplateForUserCell;
  constructor(
    private authorizationService: AuthorizationService,
    private router: Router,
    private store: Store<any>,
    private translateService: TranslateService
  ) { }

  goToUser() {
    if (!isNullOrUndefined(this.user.isOnline)) {
      if (this.user.isOnline) {
        if (this.authorizationService.hasPermission(this.permissionsScreen.CUSTOMER_PROFILE_SCREEN)) {
          const url = this.router.serializeUrl(
            this.router.createUrlTree(['./crm/search-players/' + this.user.id])
          );
          window.open(url, '_blank');
        } else {
          const message = new GrowlMessage(SeverityCode.ERROR, this.translateService.instant('Permission_Denied'));
          this.store.dispatch(new fromLayout.ErrorMsg(message));
        }
      } else {
        const message = new GrowlMessage(
          SeverityCode.ERROR,
          this.translateService.instant('Admin_Users_Page_Not_Ready_Yet')
        );
        this.store.dispatch(new fromLayout.ErrorMsg(message));
      }
    } else if (this.user.userTypeId != null) {
      switch (this.user.userTypeId) {
        case 1: {
          const m = new GrowlMessage(
            SeverityCode.ERROR,
            this.translateService.instant('Admin_Users_Page_Not_Ready_Yet')
          );
          this.store.dispatch(new fromLayout.ErrorMsg(m));
        }
          break;
        case 2: {
          if (this.authorizationService.hasPermission(this.permissionsScreen.CUSTOMER_PROFILE_SCREEN)) {
            const url = this.router.serializeUrl(
              this.router.createUrlTree(['./crm/search-players/' + this.user.id])
            );
            window.open(url, '_blank');
            // return this.router.serializeUrl(this.router.createUrlTree(['./crm/search-players/' + this.user.id]));
          } else {
            const message = new GrowlMessage(SeverityCode.ERROR, this.translateService.instant('Permission_Denied'));
            this.store.dispatch(new fromLayout.ErrorMsg(message));
          }
        }
          break;
        default: {
          const message = new GrowlMessage(
            SeverityCode.ERROR,
            this.translateService.instant('User_Type_Id') + ' ' + this.user.userTypeId,
            this.translateService.instant('Not_Provided')
          );
          this.store.dispatch(new fromLayout.ErrorMsg(message));
        }
          break;
      }
    } else {
      const message = new GrowlMessage(
        SeverityCode.ERROR,
        this.translateService.instant('User_Type_Id'),
        this.translateService.instant('Not_Exist')
      );
      this.store.dispatch(new fromLayout.ErrorMsg(message));
    }
  }

  isRoleCSR() {
    return this.authorizationService.hasRole([eRolesLevel.OperatorCSR, eRolesLevel.ManagerCSR]);
  }
}
