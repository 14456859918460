
export enum FilterInstruction {
    Equals = <any>'equals',
    NotEquals = <any>'notEquals',
    GreaterThan = <any>'greaterThan',
    GreaterThanOrEqual = <any>'greaterThanOrEqual',
    LessThan = <any>'lessThan',
    LessThanOrEqual = <any>'lessThanOrEqual',
    Contains = <any>'contains',
    StartsWith = <any>'startsWith',
    EndsWith = <any>'endsWith'
}
