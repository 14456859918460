import * as auth from '../actions/auth.actions';
import { Permission, User, UserData } from '../../models/user';
import * as fromApp from '../../../core/store/reducers';
import { createSelector } from '@ngrx/store';
export interface State {
  loggedIn: boolean;
  user: User | null;
  error: any;
  loading: boolean;
  userData: UserData;
  permissionList: Permission[];
}

const initialState: State = {
  loggedIn: false,
  user: null,
  userData: null,
  error: null,
  loading: false,
  permissionList: null,
};

export function AuthReducer(state = initialState, action: auth.Actions): State {
  switch (action.type) {
    case auth.LOGIN: {
      return {
        ...state,
        loading: true,
      };
    }
    case auth.GET_USER_BY_TOKEN: {
      return {
        ...state,
        user: action.payload,
        loading: true,
      };
    }
    case auth.REFRESH_USER_SETTINGS: {
      return {
        ...state,
        // user: action.payload,
        loading: true,
      };
    }
    case auth.SET_PERMISSIONS: {
      return {
        ...state,
        permissionList: action.payload,
      };
    }
    case auth.REFRESH_USER_SETTINGS_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        userData: action.payload,
        loading: false,
      };
    }
    case auth.REFRESH_USER_SETTINGS_FAILURE: {
      return {
        ...state,
        loggedIn: false,
        user: null,
        error: action.payload,
        loading: false,
      };
    }
    case auth.LOGIN_SUCCESS: {
      return {
        ...state,
        loggedIn: true,
        userData: action.payload,
        // loading: false,
      };
    }
    case auth.ROUTING_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }
    case auth.LOGIN_FAILURE: {
      return {
        ...state,
        loggedIn: false,
        user: null,
        error: action.payload,
        loading: false,
      };
    }
    case auth.KEEP_ALIVE_SUCCESS: {
      return {
        ...state,
        userData: {
          ...state.userData,
          availableForWithdraw: action.payload.availableForWithdraw,
          balance: action.payload.balance,
          commission: action.payload.commission,
          creditLimit: action.payload.creditLimit,
          holding: action.payload.holding,
        },
      };
    }
    case auth.LOGOUT: {
      return {
        ...state,
        loading: false,
      };
    }
    case auth.LOGOUT_SUCCESS: {
      return initialState;
    }
    case auth.LOGOUT_FAILED: {
      return initialState;
    }

    default: {
      return state;
    }
  }
}
export const getPermissions = (state: fromApp.AppState) => {
  return state.authReducer.permissionList;
};
export const getLoggedIn = (state: fromApp.AppState) => {
  return state.authReducer.loggedIn;
};
export const getLoadingStatus = (state: fromApp.AppState) => {
  return state.authReducer.loading;
};
export const getAuthStatus = createSelector(getLoggedIn, getLoadingStatus, (loggedIn, loadingStatus) => {
  return { loggedIn, loadingStatus };
});
export const getUserId = (state: fromApp.AppState) => {
  return state.authReducer.userData.userId;
};
export const getUserToken = (state: fromApp.AppState) => {
  return state.authReducer.user.token;
};
export const getUserData = createSelector(
  ((state: fromApp.AppState) => state.authReducer.userData),
  (userData) => userData
);
export const getUser = (state: fromApp.AppState) => {
  return state.authReducer.user;
};
export const getInitials = createSelector(getUserData, (userData) => {
  if (!userData) {
    return "";
  }
  let initials = '';
  if (userData.firstName) {
    initials += userData.firstName.charAt(0);
  }
  if (userData.lastName) {
    initials += userData.lastName.charAt(0);
  }
  return initials;
});
export const getCreditLimit = createSelector(getUserData, (userData) => {
  if (!userData) {
    return "";
  }
  if (userData.creditLimit === 0 || userData.balance === 0) {
    return '0%';
  } else {
    const creditLimit: any = userData.balance / userData.creditLimit;
    const creditPercentage = creditLimit.toFixed(2) * 100;
    return creditPercentage.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + '%';
  }
});
