import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { OperationResult } from 'src/app/sport-betting/common/models';
import { Margin } from '../../models';

interface BetTypeLeagueMarginsModel {
  key: number;
  data: Array<Margin>;
  totalCount: number;
  operationResult?: OperationResult;
}

export type BetTypeLeagueMarginsState = EntityState<BetTypeLeagueMarginsModel>
export const betTypeLeagueMarginsAdapter: EntityAdapter<BetTypeLeagueMarginsModel> = createEntityAdapter<BetTypeLeagueMarginsModel>({
  selectId: (item) => item.key,
});
export const betTypeLeagueMarginsInitialState: BetTypeLeagueMarginsState = betTypeLeagueMarginsAdapter.getInitialState({
  totalCount: 0
});