import { createAction, props } from '@ngrx/store';
import { ChangeLogViewModel, ChangelogFiltering } from '../../models';
import { FailAction } from 'src/app/sport-betting/common/models';

export const getEventChangeLogs = createAction(
  '[Event Change Log] Get Event Change Logs',
  props<{ eventId: number; filters: ChangelogFiltering }>()
);

export const setEventChangeLogs = createAction(
  '[Event Change Log] Set Event Change Logs',
  props<{ changeLogs: ChangeLogViewModel[], totalCount: number }>()
);

export const eventChangeLogsFailure = createAction(
  '[Event Change Log] Event Change Logs Failure',
  props<FailAction>()
);
