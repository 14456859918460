import * as expensesActions from '../actions/expenses.actions';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { ExpenseItem } from '../../models/expenseItem';
import { ExpenseCategory } from '../../models/expenseCategory';
import { AppState } from 'abs@core/store/reducers';
import { getBranches, getEnumsMetadata } from 'abs@core/store/reducers/metadata.reducer';
import { ExpensesMonth } from '../../modules/expenses-months/models/expenseMonth';

export interface State {
  loadingItems: boolean;
  loadingCategories: boolean;
  itemsLoaded: boolean;
  categoriesLoaded: boolean;
  items: ExpenseItem[];
  categories: ExpenseCategory[];

  clonedCategoryForSave: ExpenseCategory;
  clonedItemForSave: ExpenseItem;
  clonedItemForSaveIndex: number;
  clonedCategoryForSaveIndex: number;

  loadingMonths: boolean;
  MonthsLoaded: boolean;
  months: ExpensesMonth[];
}

export const initialState: State = {
  loadingItems: false,
  loadingCategories: false,
  itemsLoaded: false,
  categoriesLoaded: false,
  items: null,
  categories: null,
  clonedCategoryForSave: null,
  clonedCategoryForSaveIndex: null,
  clonedItemForSave: null,
  clonedItemForSaveIndex: null,
  loadingMonths: false,
  MonthsLoaded: false,
  months: null,
};

export function ExpensesReducer(state = initialState, action: any) {
  switch (action.type) {
    case expensesActions.GET_EXPENSE_CATEGORIES: {
      return {
        ...state,
        loadingCategories: true,
        // categories: [],
        categoriesLoaded: false,
      };
    }
    case expensesActions.GET_EXPENSE_CATEGORIES_FAILURE: {
      return {
        ...state,
        loadingCategories: false,
        categoriesLoaded: true,
      };
    }
    case expensesActions.GET_EXPENSE_ITEMS: {
      return {
        ...state,
        loadingItems: true,
        itemsLoaded: false,
      };
    }
    case expensesActions.GET_EXPENSE_ITEMS_FAILURE: {
      return {
        ...state,
        loadingItems: false,
        itemsLoaded: true,
      };
    }
    case expensesActions.SET_EXPENSE_CATEGORIES: {
      return {
        ...state,
        loadingCategories: false,
        categories: action.payload?.Categories,
        categoriesLoaded: true,
      };
    }
    case expensesActions.SET_EXPENSE_ITEMS: {
      return {
        ...state,
        loadingItems: false,
        items: action.payload.Items,
        itemsLoaded: true,
      };
    }
    case expensesActions.SAVE_EXPENSE_CATEGORY: {
      return {
        ...state,
        loadingCategories: true,
        categoriesLoaded: false,
      };
    }
    case expensesActions.FAILURE: {
      return {
        ...state,
        loadingItems: false,
        loadingCategories: false,
      };
    }
    case expensesActions.SAVE_EXPENSE_ITEM: {
      return {
        ...state,
        loadingItems: true,
        itemsLoaded: false,
      };
    }
    case expensesActions.EDIT_EXPENSE_CATEGORY: {
      return {
        ...state,
        clonedCategoryForSave: action.payload.category,
        clonedCategoryForSaveIndex: action.payload.index,
      };
    }
    case expensesActions.SAVE_EXPENSE_CATEGORY_FAIL: {
      const newState = copy(state);
      newState.items[state.clonedCategoryForSaveIndex] = state.clonedCategoryForSave;

      return {
        ...newState,
        loadingCategories: false,
        clonedCategoryForSave: null,
        clonedCategoryForSaveIndex: null,
      };
    }
    case expensesActions.SAVE_EXPENSE_CATEGORY_SUCCESS: {
      return {
        ...state,
        loadingCategories: false,
        clonedCategoryForSave: null,
        clonedCategoryForSaveIndex: null,
      };
    }
    case expensesActions.EDIT_EXPENSE_ITEM: {
      return {
        ...state,
        clonedItemForSave: action.payload.item,
        clonedItemForSaveIndex: action.payload.index,
      };
    }
    case expensesActions.SAVE_EXPENSE_ITEM_FAIL: {
      const newState = copy(state);
      newState.items[state.clonedItemForSaveIndex] = state.clonedItemForSave;

      return {
        ...newState,
        loadingItems: false,
        clonedItemForSave: null,
        clonedItemForSaveIndex: null,
      };
    }

    case expensesActions.SAVE_EXPENSE_ITEM_SUCCESS: {
      return {
        ...state,
        loadingItems: false,
        clonedItemForSave: null,
        clonedItemForSaveIndex: null,
      };
    }
    case expensesActions.GET_EXPENSE_MONTHS: {
      return {
        ...state,
        loadingMonths: false,
        MonthsLoaded: false,
      };
    }
    case expensesActions.SET_EXPENSE_MONTHS: {
      return {
        ...state,
        loadingMonths: false,
        months: action.payload.Months,
        MonthsLoaded: true,
      };
    }
    case expensesActions.SAVE_EXPENSE_MONTH: {
      return {
        ...state,
        loadingMonths: true,
        MonthsLoaded: false,
      };
    }
    case expensesActions.SAVE_EXPENSE_MONTH_FAIL: {
      return {
        ...state,
        loadingMonths: false,
      };
    }
    case expensesActions.EDIT_EXPENSE_MONTH_FAIL: {
      return {
        ...state,
        loadingMonths: false,
        MonthsLoaded: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const getCategoriesLoadingStatus = (state: AppState) =>
  state.expensesReducer ? state.expensesReducer.categoriesLoaded : null;
export const getItemsLoadingStatus = (state: AppState) =>
  state.expensesReducer ? state.expensesReducer.itemsLoaded : null;

export const CategoriesList = (state: AppState) => (state.expensesReducer ? state.expensesReducer.categories : null);
export const ItemsList = (state: AppState) => (state.expensesReducer ? state.expensesReducer.items : null);
export const CategoriesLoading = (state: AppState) =>
  state.expensesReducer ? state.expensesReducer.loadingCategories : null;
export const ItemsLoading = (state: AppState) => (state.expensesReducer ? state.expensesReducer.loadingItems : null);

export const MonthsList = (state: AppState) => (state.expensesReducer ? copy(state.expensesReducer.months) : null);
export const getMonthsLoadingStatus = (state: AppState) =>
  state.expensesReducer ? state.expensesReducer.MonthsLoaded : null;

export const isExpensesMonthLoading = (state: AppState) =>
  state.expensesReducer ? state.expensesReducer.loadingMonths : null;
export const MonthsLoading = (state: AppState) => (state.expensesReducer ? state.expensesReducer.loadingMonths : null);

export const getOpenMonths = createSelector(MonthsList, (monthList) => {
  if (monthList) {
    return monthList.filter((month) => month.IsOpen === true);
  }
  return null;
});

export const expensesGuardData = createSelector(getBranches, getEnumsMetadata, (branches, enums) => {
  return { branches, enums };
});
export const getExpensesCategoriesDropDown = createSelector(CategoriesList, (categories) => {
  if (categories) {
    return categories.map((category) => {
      return {
        value: category.Id,
        label: category.Name,
      };
    });
  }
  return null;
});
export const getExpensesItemsDropDown = createSelector(ItemsList, (items) => {
  if (items) {
    return items.map((item) => {
      return {
        value: item.Id,
        label: item.Name,
      };
    });
  }
  return null;
});
