<div
  class="layout-menu-wrapper"
  [ngClass]="{ 'layout-sidebar-active': appMain.sidebarActive }"
  (click)="appMain.onSidebarClick()"
  (mouseover)="appMain.onSidebarMouseOver()"
  (mouseleave)="appMain.onSidebarMouseLeave()"
>
  <div class="menu-logo" *ngIf="!appMain.isHorizontal() || appMain.isMobile()">
    <a [routerLink]="'/home'" class="logo">
      <img [src]="'assets/layout/images/login/icon_' + logo  + '.png'"  onerror="this.src='assets/layout/images/login/icon_login_default.png';" />
    </a>

    <a [routerLink]="'/home'" class="app-name" *ngIf="!appMain.isSlim() || appMain.isMobile()">
      <img [src]="'assets/layout/images/logo/logo_b2tech' + (app.colorScheme === 'light' ? '_dark' : '') + '.png'" onerror="this.style.display='none'"/>
    </a>
    <a class="menu-pin" (click)="appMain.onToggleMenu($event)">
      <span *ngIf="appMain.isOverlay()" class="pi pi-times"></span>
      <span *ngIf="appMain.isSidebar() && !appMain.sidebarStatic && appMain.pinActive" class="pi pi-unlock"></span>
      <span *ngIf="appMain.isSidebar() && appMain.sidebarStatic && appMain.pinActive" class="pi pi-lock"></span>
    </a>
  </div>

  <div class="layout-menu-container">
    <ul class="layout-menu">
      <ng-container *ngFor="let item of model; let i = index">
        <li app-menuitem *ngIf="!item?.separator && item?.visible" [item]="item" [index]="i" [root]="true"></li>
        <li *ngIf="item?.separator && item?.visible" class="menu-separator"></li>
      </ng-container>
    </ul>
  </div>

  <app-inlinemenu></app-inlinemenu>
</div>
