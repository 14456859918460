import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/bet-type-groups.actions';
import { betTypeGroupsAdapter, betTypeGroupsInitialState } from '../adapters';

export const BetTypeGroupsReducer = createReducer(
  betTypeGroupsInitialState,
  on(Actions.SbSetBetTypeGroups, (state, { payload }) => {
    return betTypeGroupsAdapter.setAll(payload, {
      ...state,
    });
  }),

  on(Actions.Failure, state => ({ ...state }))
);