<div *ngIf="checkPermission(permissionScreen.EVENT_MANAGEMENT, permissionLevel.read)">
  <span class="p-input-icon-right">
    <i *ngIf="searchingEvent"
       class="pi pi-spin pi-spinner"></i>
    <p-inputNumber placeholder="Event ID/Fixture ID"
                   [useGrouping]="false"
                   [disabled]="searchingEvent"
                   (keyup.enter)="navigateToEvent()"
                   [(ngModel)]="eventId">
    </p-inputNumber>
  </span>
</div>
<div *ngIf="checkPermission(permissionScreen.BETSLIPS_DATA_SCREEN_SEARCH_ACTION, permissionLevel.read)"
     class="">
  <span class="p-float-label light-input">
    <input class="no-spin p-inputtext p-component"
           type="number"
           oninput="validity.valid || (value='')"
           maxlength="9"
           pInputText
           id="orderIdOrBarcodeSearch"
           (keyup.enter)="getByOrderIdOrBarcode()"
           [(ngModel)]="searchValueOrderId" />
    <label for="orderIdOrBarcodeSearch">{{'Order_ID_Barcode' | translate}}</label>
  </span>
</div>
<div *ngIf="checkPermission(permissionScreen.CUSTOMER_PROFILE_SCREEN_SEARCH_ACTION, permissionLevel.read)"
     class="">
  <span class="p-float-label light-input">
    <input class="no-spin p-inputtext p-component"
           type='text'
           oninput="validity.valid || (value='')"
           pInputText
           id="userSearch"
           (keyup.enter)="getUser()"
           [(ngModel)]='searchUserId'>
    <label for="userSearch">{{'UserNameId' | translate}}</label>
  </span>
</div>