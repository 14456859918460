import { SET_FEED_STATUS, SetFeedStatus } from '../actions/feed-health.actions';
import * as fromApp from './index';

export interface State {
  status: string;
}

export const initialState: State = {
  status: 'Unknown',
};

export function FeedHealtReducer(state = initialState, action: SetFeedStatus): State {
  switch (action.type) {
    case SET_FEED_STATUS: {
      return {
        ...state,
        status: action.payload
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}

export const getFeedHealthStatus = (state: fromApp.AppState): string => {
  return state.feedHealthReducer.status;
};
