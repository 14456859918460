import { createAction, props } from "@ngrx/store";
import { BetTypeGroup } from "../../models";

const SB_GET_BET_TYPE_GROUPS = '[SBBTG] Get bet type groups';
const SB_SET_BET_TYPE_GROUPS = '[SBBTG] Set bet type groups';

const FAILURE = '[SBBTG] Failure';

export const SbGetBetTypeGroups = createAction(SB_GET_BET_TYPE_GROUPS, props<{ payload: number }>());
export const SbSetBetTypeGroups = createAction(SB_SET_BET_TYPE_GROUPS, props<{
  payload: Array<BetTypeGroup>
}>());

export const Failure = createAction(FAILURE, props<{ error?: any, errorMsg?: string }>());