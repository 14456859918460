import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { OutChannelService } from '../../services/outchannel/outchannel.service';
import { APIMethods, APIMethodsCMS, APIMethodsGames, AppSettings } from '../../app.config';
import * as MetaDataActions from '../actions/metadata.actions';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  distinctUntilKeyChanged,
  map,
  mergeMap,
  switchMap,
  timeout,
} from 'rxjs/operators';
import { DataRequest, ExportAllRequest, GetOnlineBranchesRequest, PostActionRequest } from '../../models/request';
import * as fromLayout from '../actions/layout.actions';
import { GrowlMessage, SeverityCode } from '../../models/Growl-Messages';
import { of } from 'rxjs';
import {
  EXPORT_ALL_DATA_TO_EXCEL,
  ExportAllDataToExcel,
  GENERAL_POST_ACTION,
  GeneralPostAction,
  GeneralPostActionCompleted,
  GET_DATA,
  GetData,
} from '../actions/metadata.actions';
import * as ErrorActions from '../actions/error.actions';
import { CachingService } from '../../services/caching.service';
import * as EventsMetadataActions from '../../../events/store/actions/events.metadata.actions';
import { GetFiltersForBrandSettingsRequest } from '../../models/channel.requests';
import { Failure } from '../actions/error.actions';
import { Store } from '@ngrx/store';
import { GetBonusesByTypeRequest } from '../../models/coreModels';

@Injectable()
export class MetadataEffects {

  generalPostAction = createEffect(() => this.actions$.pipe(
    ofType(GENERAL_POST_ACTION),
    map((action: GeneralPostAction) => {
      return action.payload;
    }),
    switchMap((payload: PostActionRequest) => {
      return this.outputChannel.postRequest(payload.Url, payload.MethodName, payload.Payload).pipe(
        timeout(5000),
        map((ResponseData: any) => {
          if (ResponseData.isSuccessfull) {
            this.store.dispatch(new fromLayout.SuccesMsg(new GrowlMessage(SeverityCode.SUCCESS, 'Action Succeed')));
            this.store.dispatch(new GeneralPostActionCompleted());
            return {
              type: payload.SuccessFullActionToBeCalled,
              payload: ResponseData.data,
            };
          } else {
            this.store.dispatch({
              type: payload.FailureActionToBeCalled,
            });
            this.store.dispatch(new GeneralPostActionCompleted());
            throw ResponseData;
          }
        }),
        catchError((error) => {
          this.store.dispatch(new GeneralPostActionCompleted());
          return of(new ErrorActions.Failure(error));
        })
      );
    })
  ));


  dataRequest = createEffect(() => this.actions$.pipe(
    ofType(GET_DATA),
    map((action: GetData) => {
      return action.payload;
    }),
    mergeMap((payload: DataRequest) => {
      if (payload.Caching && this.cacheService.getData(payload.Caching.key) != null) {
        return of({
          type: payload.SuccessFullActionToBeCalled,
          payload: this.cacheService.getData(payload.Caching.key),
        });
      } else {
        return this.outputChannel.postRequest(payload.Url, payload.MethodName, payload.Payload).pipe(
          map((ResponseData: any) => {
            if (ResponseData.isSuccessfull) {
              if (payload.Caching) {
                this.cacheService.setData(payload.Caching.key, ResponseData.data, payload.Caching.duration);
              }
              return {
                type: payload.SuccessFullActionToBeCalled,
                payload: ResponseData.data,
              };
            } else {
              throw ResponseData;
            }
          }),
          catchError((error) => of(new ErrorActions.Failure(error)))
        );
      }
    })
  ));

  exportAllToExcelRequest = createEffect(() => this.actions$.pipe(
    ofType(EXPORT_ALL_DATA_TO_EXCEL),
    map((action: ExportAllDataToExcel) => {
      return action.payload;
    }),
    switchMap((payload: ExportAllRequest) => {
      return this.outputChannel.postRequest(payload.Url, payload.MethodName, payload.Payload).pipe(
        map((ResponseData: any) => {
          if (ResponseData.isSuccessfull) {
            return {
              type: payload.SuccessFullActionToBeCalled,
              payload: ResponseData.data,
            };
          } else {
            this.store.dispatch({
              type: payload.FailureActionToBeCalled,
            });
            throw ResponseData;
          }
        }),
        catchError((error: any) => {
          this.store.dispatch({
            type: payload.FailureActionToBeCalled,
          });
          return of(new Failure(error));
        })
      );
    })
  ));

  getTemplatesRequest = createEffect(() => this.actions$.pipe(
    ofType(MetaDataActions.GET_TEMPLATES),
    map((action: MetaDataActions.GetTemplates) => {
      return action.payload;
    }),
    switchMap((request: any) => {
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
          APIMethods.GET_TEMPLATES,
          request
        )
        .pipe(
          map((ResponseData: any) => {
            if (ResponseData.isSuccessfull) {
              return {
                type: MetaDataActions.SET_TEMPLATES,
                payload: ResponseData.data.templates,
              };
            } else {
              return new fromLayout.ErrorMsg(
                new GrowlMessage(SeverityCode.ERROR, 'Dynamic Settings', ResponseData.result.errorCodeDescription)
              );
            }
          }),
          catchError(() => {
            return of(
              new fromLayout.ErrorMsg(new GrowlMessage(SeverityCode.ERROR, 'Dynamic Reports', 'Error Occurred'))
            );
          })
        );
    })
  ));


  enumsRequest = createEffect(() => this.actions$.pipe(
    ofType(MetaDataActions.GET_ENUMS),
    map((action: MetaDataActions.GetEnums) => {
      return action.payload;
    }),
    switchMap((request: any) => {
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
          APIMethods.GET_ENUMS,
          request
        )
        .pipe(
          map((ResponseData: any) => {
            return {
              type: MetaDataActions.SET_ENUMS,
              payload: ResponseData.data,
            };
          })
        );
    })
  ));

  onlineBranchesRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MetaDataActions.GET_ONLINE_BRANCHS),
    map((action: MetaDataActions.GetOnlineBranches) => {
      return action.payload;
    }),
    switchMap((request: GetOnlineBranchesRequest) => {
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API_V2,
          APIMethodsCMS.DYNAMIC_QUERY,
          request
        )
        .pipe(
          map((ResponseData: any) => {
            if (ResponseData.isSuccessfull) {
              return {
                type: MetaDataActions.SET_ONLINE_BRANCHS,
                payload: ResponseData.data.branches,
              };
            } else {
              return new fromLayout.ErrorMsg(
                new GrowlMessage(SeverityCode.ERROR, 'Online Branches', ResponseData.result.errorCodeDescription)
              );
            }
          }),
          catchError(() => {
            return of(
              new fromLayout.ErrorMsg(new GrowlMessage(SeverityCode.ERROR, 'Online Branches', 'Error Occurred'))
            );
          })
        );
    })
  ));


  filtersForBrandSettingsRequest$ = createEffect(() => this.actions$.pipe(
    ofType(MetaDataActions.GET_FILTERS_FOR_BRAND_SETTINGS),
    map((action: MetaDataActions.GetFiltersForBrandSettings) => {
      return action.payload;
    }),
    switchMap((request: GetFiltersForBrandSettingsRequest) => {
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API_V2,
          AppSettings.DYNAMIC_API + '/' + APIMethods.GET_FILTERS_FOR_BRAND_SETTINGS,
          request
        )
        .pipe(
          map((ResponseData: any) => {
            if (ResponseData.isSuccessfull) {
              return {
                type: MetaDataActions.SET_FILTERS_FOR_BRAND_SETTINGS,
                payload: ResponseData.data,
              };
            } else {
              return new fromLayout.ErrorMsg(
                new GrowlMessage(SeverityCode.ERROR, 'Online Branches', ResponseData.result.errorCodeDescription)
              );
            }
          }),
          catchError(() => {
            return of(
              new fromLayout.ErrorMsg(new GrowlMessage(SeverityCode.ERROR, 'Online Branches', 'Error Occurred'))
            );
          })
        );
    })
  ));


  sportsTreeRequest = createEffect(() => this.actions$.pipe(
    ofType(MetaDataActions.GET_SPORTS_TREE),
    switchMap(() => {
      if (this.cacheService.getData('sportsTree') != null) {
        return of({
          type: MetaDataActions.SET_SPORTS_TREE,
          payload: this.cacheService.getData('sportsTree'),
        });
      } else {
        return (
          this.outputChannel
            .getRequestJSON(AppSettings.BASE_API_ENDPOINT + AppSettings.EVENT_API + APIMethods.GET_SPORTS_TREE, {statusId: 0, eventTypeId: 0})
            /*.postRequest(AppSettings.BASE_API_ENDPOINT + AppSettings.EVENT_API, APIMethods.GET_SPORTS_TREE, request)*/
            .pipe(
              map((ResponseData: any) => {
                if (ResponseData.isSuccessfull) {
                  this.cacheService.setData('sportsTree', ResponseData.data, 60000 * 60 * 24); // 24hr
                  return {
                    type: MetaDataActions.SET_SPORTS_TREE,
                    payload: ResponseData.data,
                  };
                } else {
                  return new MetaDataActions.Failure(ResponseData);
                }
              }),
              catchError((error) => of(new MetaDataActions.Failure(error)))
            )
        );
      }
    })
  ));

  teamsRequest = createEffect(() => this.actions$.pipe(
    ofType(MetaDataActions.GET_TEAMS_BY_LEAGUES),
    map((action: MetaDataActions.GetTeamsByLeagues) => {
      return action.payload;
    }),
    debounceTime(250),
    distinctUntilChanged(),
    switchMap((request: any) => {
      return this.outputChannel
        .postRequest(AppSettings.EV_API_ENDPOINT, APIMethods.GET_TEAMS_BY_LEAGUE_ID, request)
        .pipe(
          map((ResponseData: any) => {
            if (ResponseData.isSuccessfull) {
              return {
                type: MetaDataActions.SET_TEAMS_BY_LEAGUES,
                payload: ResponseData.data,
              };
            } else {
              return new EventsMetadataActions.Failure(ResponseData);
            }
          }),
          catchError((error) => of(new MetaDataActions.Failure(error)))
        );
    })
  ));

  autocompleteUsers = createEffect(() => this.actions$.pipe(
    ofType(MetaDataActions.GET_USERS_AUTOCOMPLETE),
    map((action: MetaDataActions.GetUsersAutocomplete) => {
      return action.payload;
    }),
    debounceTime(350),
    distinctUntilKeyChanged('usernameOrId'),
    switchMap((request: any) => {
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
          APIMethods.AUTOCOMPLETE_USER,
          request
        )
        .pipe(
          map((ResponseData: any) => {
            if (ResponseData.isSuccessfull) {
              return {
                type: MetaDataActions.SET_USERS_AUTOCOMPLETE,
                payload: ResponseData.data,
              };
            } else {
              return new MetaDataActions.Failure(ResponseData);
            }
          }),
          catchError((error) => of(new MetaDataActions.Failure(error)))
        );
    })
  ));

  getBonusNamesRequest = createEffect(() => this.actions$.pipe(
    ofType(MetaDataActions.GET_BONUSES_BY_TYPE),
    map((action: MetaDataActions.GetBonusesByType) => {
      return action.payload;
    }),
    switchMap((request: GetBonusesByTypeRequest) => {
      return this.outputChannel
        .postRequest(
          AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API_V2 + APIMethodsGames.DYNAMIC_QUERY,
          APIMethods.GET_BONUSES_BY_TYPE,
          request
        )
        .pipe(
          map((ResponseData: any) => {
            if (ResponseData.isSuccessfull) {
              return {
                type: MetaDataActions.SET_BONUSES_BY_TYPE,
                payload: ResponseData.data,
              };
            } else {
              this.store.dispatch({
                type: MetaDataActions.SET_BONUSES_BY_TYPE,
                payload: null,
              });
              return new fromLayout.ErrorMsg(
                new GrowlMessage(SeverityCode.ERROR, 'Bonuses', ResponseData.result.errorCodeDescription)
              );
            }
          }),
          catchError(() => {
            this.store.dispatch({
              type: MetaDataActions.SET_BONUSES_BY_TYPE,
              payload: null,
            });
            return of(new fromLayout.ErrorMsg(new GrowlMessage(SeverityCode.ERROR, 'Bonuses', 'Error Occurred')));
          })
        );
    })
  ));

  failure = createEffect(() => this.actions$.pipe(
    ofType(MetaDataActions.FAILURE),
    map((action: MetaDataActions.Failure) => {
      return action.payload;
    }),
    mergeMap((error: any) => {
      return [
        new ErrorActions.Failure(error),
        {
          type: MetaDataActions.SET_FAILURE,
        },
      ];
    })
  ));

  constructor(
    private store: Store<any>,
    private actions$: Actions<any>,
    private outputChannel: OutChannelService,
    private cacheService: CachingService
  ) {}
}
