import { Component, OnInit } from '@angular/core';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
  selector: 'app-table-dialog',
  templateUrl: './table-dialog.component.html',
  styleUrls: ['./table-dialog.component.css'],
})
export class TableDialogComponent implements OnInit {
  data: any;
  cols: any;
  dataHeader: any;
  constructor(public ref: DynamicDialogRef, public config: DynamicDialogConfig) {}

  ngOnInit(): void {
    this.data = this.config.data.data;
    this.cols = this.config.data.cols;
    this.dataHeader = this.config.data.dataHeader;
  }
}
