import { Action } from '@ngrx/store';
import { GrowlMessage } from '../../models/Growl-Messages';

export const SUCCESS_MESSAGE = '[Layout] Success';
export const ERR_MESSAGE = '[Layout] Error';
export const INFO_MESSAGE = '[Layout] Info';
export const WARN_MESSAGE = '[Layout] Warn';
export const CLEAR_MESSAGE = '[Layout] Clear';

export const CHANGE_LANGUAGE = '[Layout] Change Language';
export const SET_LANGUAGE = '[Layout] Set Language';

export class SuccesMsg implements Action {
  readonly type = SUCCESS_MESSAGE;

  constructor(public payload: GrowlMessage) {}
}
export class ErrorMsg implements Action {
  readonly type = ERR_MESSAGE;

  constructor(public payload: GrowlMessage) {}
}
export class InfoMsg implements Action {
  readonly type = INFO_MESSAGE;

  constructor(public payload: GrowlMessage) {}
}
export class WarnMsg implements Action {
  readonly type = WARN_MESSAGE;

  constructor(public payload: GrowlMessage) {}
}
export class ChangeLanguage implements Action {
  readonly type = CHANGE_LANGUAGE;

  constructor(public payload: any) {}
}
export class SetLanguage implements Action {
  readonly type = SET_LANGUAGE;

  constructor(public payload: any) {}
}
export class ClearMessage implements Action {
  readonly type = CLEAR_MESSAGE;

  
}
export type Actions = SuccesMsg | ErrorMsg | InfoMsg | WarnMsg | SetLanguage | ClearMessage | ChangeLanguage;
