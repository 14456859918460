<ng-template *ngIf="inEditState then editTemplate else normal"></ng-template>
<ng-template #editTemplate>
  <span *ngIf="value">
    <div *ngFor="let item of value | keyvalue">
       <span *ngIf="item.key" [className]="!authClassName ? '' :'page-list'">
           <span class="key">{{item.key}}:</span>
           <span class="val">
  <!--             <input type="text" pInputText [(ngModel)]="item.value" (keyup)="typing(item.key, item.value)"/>-->
               <input type="text" pInputText
                      [className]="!authClassName ? '' :authClassName"
                      [ngModel]="item.value"
                      (change)="changeKey(item.key, $event.target['value'])"/>
            </span>
       </span>
    </div>
  </span>

</ng-template>
<ng-template #normal>
  <span *ngIf="value">
    <div *ngFor="let item of value | keyvalue">
       <span *ngIf="item.key && item.value">
           <span class="key">{{item.key}}</span> : <span class="val">{{item.value}}</span>
       </span>
    </div>
  </span>
</ng-template>



