import { createAction, props } from '@ngrx/store';
import { FailAction, Filtering } from 'src/app/sport-betting/common/models';
import { UpdateMargin } from '../../models/update-margin.model';
import { Margin } from '../../models';

const SB_GET_LEAGUE_MARGINS = '[SBBTM] Get leagues margin';
const SB_SET_LEAGUE_MARGINS = '[SBBTM] Set leagues margin';

const SB_UPDATE_LEAGUE_MARGIN = '[SBBTM] Update league margin';
const SB_UPDATE_LEAGUE_MARGIN_SUCCESS = '[SBBTM] Update league margin SUCCESS';

const SB_DELETE_LEAGUE_MARGIN = '[SBBTM] Delete league margin';
const SB_DELETE_LEAGUE_MARGIN_SUCCESS = '[SBBTM] Delete league margin SUCCESS';

const FAILURE = '[SBBTM] Update league margin failure';

export const SbGetLeagueMargins = createAction(SB_GET_LEAGUE_MARGINS, props<{ payload: Filtering }>());
export const SbSetLeagueMargins = createAction(SB_SET_LEAGUE_MARGINS, props<{
  payload: {
    countryId: number,
    data: Array<Margin>,
    totalCount: number;
  }
}>());
export const SbUpdateLeagueMargin = createAction(SB_UPDATE_LEAGUE_MARGIN, props<{ payload: UpdateMargin }>());
export const SbUpdateLeagueMarginSuccess = createAction(SB_UPDATE_LEAGUE_MARGIN_SUCCESS, props<{ payload: UpdateMargin }>());

export const SbDeleteLeagueMargin = createAction(SB_DELETE_LEAGUE_MARGIN, props<{
  payload: {
    countryId: number,
    marginId: number,
    leagueId: number
  }
}>());
export const SbDeleteLeagueMarginSuccess = createAction(SB_DELETE_LEAGUE_MARGIN_SUCCESS, props<{
  payload: {
    countryId: number,
    marginId: number,
    leagueId: number
  }
}>());

export const LeagueMarginsFailure = createAction(FAILURE, props<FailAction>());