import { DropdownItem } from '../../models';

export interface DropdownsDataState {
  sportTypes?: Array<DropdownItem<number, string>>;
  leagues?: Array<DropdownItem<number, string>>;
  countries?: Array<DropdownItem<number, string>>;
  teams?: Array<DropdownItem<number, string>>;
}

export const dropdownsDataInitialState: DropdownsDataState = {
  sportTypes: [],
  leagues: [],
  countries: [],
  teams: []
};