import * as BranchSummaryActions from '../actions/branch-summary.actions';
import { AppState } from 'abs@core/store/reducers';
import { BranchSummary } from '../../models/branchSummary';
import { createSelector } from '@ngrx/store';
import { getBranches, getEnumsMetadata, getPaymentMethods } from 'abs@core/store/reducers/metadata.reducer';
import copy from 'fast-copy';

export interface State {
  loadingSummaryBranchReport: boolean;
  summaryBranchReportLoaded: boolean;

  loading: boolean;
  branchSummaryItemsCount: number;
  branchSummaryItems: BranchSummary[];
}

export const initialState: State = {
  loading: false,
  loadingSummaryBranchReport: false,
  summaryBranchReportLoaded: false,
  branchSummaryItemsCount: null,
  branchSummaryItems: null,
};

export function BranchSummaryReducer(state = initialState, action: any) {
  switch (action.type) {
    case BranchSummaryActions.GET_SUMMARY_BRANCH_REPORT: {
      return {
        ...state,
        loading: true,
        loadingSummaryBranchReport: true,
        summaryBranchReportLoaded: false,
        branchSummaryItems: [],
      };
    }
    case BranchSummaryActions.SET_SUMMARY_BRANCH_REPORT: {
      return {
        ...state,
        loading: false,
        loadingSummaryBranchReport: false,
        summaryBranchReportLoaded: true, // action.payload.Reports != null && action.payload.Reports.length > 0 ? true : false
        branchSummaryItems: action.payload,
        branchSummaryItemsCount: action.payload.length,
      };
    }
    case BranchSummaryActions.RESET_SUMMARY_BRANCH_REPORT: {
      return {
        ...state,
        summaryBranchReportLoaded: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const isSummaryBranchReportLoading = (state: AppState) =>
  state.branchSummaryReducer ? state.branchSummaryReducer.loadingSummaryBranchReport : null;
export const isSummaryBranchReportLoaded = (state: AppState) =>
  state.branchSummaryReducer ? state.branchSummaryReducer.summaryBranchReportLoaded : null;
export const getSummaryBranchReportItems = (state: AppState) =>
  state.branchSummaryReducer ? state.branchSummaryReducer.branchSummaryItems : null;
export const getSummaryBranchReportItemsCount = (state: AppState) =>
  state.branchSummaryReducer ? state.branchSummaryReducer.branchSummaryItemsCount : null;

export const getBranchReport = createSelector(getSummaryBranchReportItems, (branchReportItems) => {
  if (branchReportItems) {
    branchReportItems = copy(branchReportItems);
    branchReportItems.forEach((item) => {
      item.mappedBranchName = item.branch.name;
    });
    return branchReportItems;
  }
  return undefined;
});
export const reportsGuardData = createSelector(
  getBranches,
  getEnumsMetadata,
  getPaymentMethods,
  (branches, enums, paymentMethods) => {
    return { branches, enums, paymentMethods };
  });
