import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { OperationResult } from "src/app/sport-betting/common/models/operation-result.model";
import { SportCountry } from "../../models/entities";

interface SportCounriesStateModel {
  key: number;
  data: Array<SportCountry>;
  totalCount: number;
  operationResult?: OperationResult;
}
export type SportCounriesState = EntityState<SportCounriesStateModel>
export const sportCounriesAdapter: EntityAdapter<SportCounriesStateModel> =
  createEntityAdapter<SportCounriesStateModel>({
    selectId: (item) => item.key,
  });
export const sportCounriesInitialState: SportCounriesState = sportCounriesAdapter.getInitialState({
  totalCount: 0,
});