import * as setting from '../actions/setting.actions';
import * as fromApp from '../../../core/store/reducers';
import { createSelector } from '@ngrx/store';
import {
  CheckIfEnumListIsUpdatedWithCustomData,
  getBranches,
  getEnumsMetadata,
} from '../../../core/store/reducers/metadata.reducer';
import copy from 'fast-copy';
export interface State {
  templatesList: any;
  templatesDataList: any;
  templateData: any;
  loading: boolean;
  totalRecords: number;
  firstTimeFlag: boolean;
  exportingData: boolean;
}

export const initialState: State = {
  templatesList: null,
  templatesDataList: null,
  templateData: null,
  loading: true,
  totalRecords: 0,
  firstTimeFlag: true,
  exportingData: false,
};

export function SettingReducer(state = initialState, action: setting.Actions): State {
  switch (action.type) {
    // case setting.SET_TEMPLATES: {
    //   return {
    //     ...state,
    //     templatesList: action.payload,
    //     loading: false
    //   };
    // }
    case setting.GET_DATA_TEMPLATES: {
      return {
        ...state,
        loading: true,
      };
    }
    case setting.SET_DATA_TEMPLATES: {
      return {
        ...state,
        templatesDataList: action.payload,
      };
    }
    case setting.SET_DATA_TEMPLATE: {
      return {
        ...state,
        templateData: action.payload.list,
        loading: false,
        totalRecords: action.payload.totalRecords,
        //firstTimeFlag: true,
      };
    }
    case setting.SET_FAILURE: {
      return {
        ...state,
        loading: false,
        exportingData: false,
      };
    }
    case setting.SET_EXPORTING_DATA: {
      return {
        ...state,
        exportingData: action.payload,
      };
    }
    case setting.SET_FIRST_TIME_FLAG: {
      return {
        ...state,
        firstTimeFlag: action.payload,
      };
    }

    default: {
      return state;
    }
  }
}
export const getDataListTemplate = (state: fromApp.AppState) => {
  return copy(state.settingReducer.templatesDataList);
};
export const getTemplateData = (state: fromApp.AppState) => {
  return copy(state.settingReducer.templateData);
};

export const getEditDataTemplatePage = (state: fromApp.AppState) => {
  return {
    enums: state.metadataReducer.enums,
    data: copy(state.settingReducer.templatesDataList),
  };
};
// export const getTemplatesPage = (state: fromApp.AppState) => {
//   return state.settingReducer.templatesList;
// };
export const getFirstTimeFlag = (state: fromApp.AppState) => {
  return copy(state.settingReducer.firstTimeFlag);
};
export const getExportingToExcelStatus = (state: fromApp.AppState) => {
  return state.settingReducer.exportingData;
};

export const settingsGuardData = createSelector(
  getBranches,
  getEnumsMetadata,
  CheckIfEnumListIsUpdatedWithCustomData,
  (branches, enums, enumsListIsUpdatedToLatest) => {
    return { branches, enums, enumsListIsUpdatedToLatest };
  }
);
