import {
  ATTACH_BONUS_FAILURE,
  ATTACH_BONUS_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  DIRECT_TRANSFER_FAILURE,
  DIRECT_TRANSFER_SUCCESS,
  FAILURE,
  MANUAL_CONFIRM_ACTION_SUCCESS,
  ONLINE_ACTION,
  ONLINE_ACTION_FAILURE,
  ONLINE_ACTION_SUCCESS,
  TRANSFER_ACTION_FAILURE,
  TRANSFER_ACTION_SUCCESS,
  UPDATE_COMMENT_ACTION_FAILURE,
  UPDATE_COMMENT_ACTION_SUCCESS,
} from '../actions/online.actions';
import * as fromApp from 'abs@core/store/reducers';
import { createSelector } from '@ngrx/store';
import {
  getBranches,
  getEnumsMapSelector,
  getPaymentMethodsSelector,
} from './metadata.reducer';
import {
  ADD_CUSTOMER_NOTE_ACTION_SUCCESS
} from '../../../customer-list/store/actions/online-customers-list.actions';

export interface State {
  loading: boolean;
  processing: boolean;
}
export const initialState: State = {
  loading: false,
  processing: false,
};

export function OnlineReducer(state = initialState, action: any): State {
  switch (action.type) {
    case ONLINE_ACTION: {
      return {
        ...state,
        processing: true,
      };
    }
    case DIRECT_TRANSFER_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case ATTACH_BONUS_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case ATTACH_BONUS_FAILURE: {
      return {
        ...state,
        processing: false,
      };
    }
    case ONLINE_ACTION_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case TRANSFER_ACTION_FAILURE: {
      return {
        ...state,
        processing: false,
      };
    }
    case TRANSFER_ACTION_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case ONLINE_ACTION_FAILURE: {
      return {
        ...state,
        processing: false,
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case CHANGE_PASSWORD_FAILURE: {
      return {
        ...state,
        processing: false,
      };
    }
    case UPDATE_COMMENT_ACTION_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case UPDATE_COMMENT_ACTION_FAILURE: {
      return {
        ...state,
        processing: false,
      };
    }
    case ADD_CUSTOMER_NOTE_ACTION_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case MANUAL_CONFIRM_ACTION_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case DIRECT_TRANSFER_FAILURE: {
      return {
        ...state,
        processing: false,
      };
    }
    case FAILURE: {
      return {
        ...state,
        loading: false,
        processing: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const getInProcessStatus = (state: fromApp.AppState) => state.onlineReducer.processing;
export const getLoadingStatus = (state: fromApp.AppState) => state.onlineReducer.loading;

export const TransfersGuardData = createSelector(
  getBranches,
  // getEnumsMetadata,
  //getEnumsMapSelector,
  getPaymentMethodsSelector,
  (branches, paymentMethods) => {
    return { branches, paymentMethods };
  }
);
export const StatementsGuardData = createSelector(getEnumsMapSelector, (enums) => {
  return { enums };
});
