import { Action } from '@ngrx/store';

export const GET_ROLES_AND_PERMISSIONS_DATA = '[ROLES-AND-PERMISSIONS] Get roles and permissions data';
export const SET_CURRENT_USER_ROLES_AND_PERMISSIONS =
  '[ROLES-AND-PERMISSIONS] Set current user roles and permissions data';
export const SET_USER_PERMISSION_LOG = '[ROLES-AND-PERMISSIONS] Set user permission log';
export const SET_USERS_PER_PERMISSION = '[ROLES-AND-PERMISSIONS] Set users per permissions';
export const SET_ROLES_NAME_LIST = '[ROLES-AND-PERMISSIONS] Set roles name list';
export const SET_ACTIVITIES = '[ROLES-AND-PERMISSIONS] Set activities';

export const SAVE_ROLE_PERMISSIONS = '[ROLES-AND-PERMISSIONS] Save role Permissions';
export const SAVE_ROLE_PERMISSIONS_SUCCESS = '[ROLES-AND-PERMISSIONS] Save role Permissions success';
export const SAVE_ROLE_PERMISSIONS_FAILURE = '[ROLES-AND-PERMISSIONS] Save role Permissions failure';

export const GET_USERS_PER_ROLE = '[ROLES-AND-PERMISSIONS] Get users per role';
export const SET_USERS_PER_ROLE = '[ROLES-AND-PERMISSIONS] Set users per role';
export const GET_USERS_PER_ROLE_FAILURE = '[ROLES-AND-PERMISSIONS] Get users per role failure';

export const SAVE_ROLE_DETAILS = '[ROLES-AND-PERMISSIONS] Save role details';
export const SAVE_ROLE_DETAILS_SUCCESS = '[ROLES-AND-PERMISSIONS] Save role details success';
export const SAVE_ROLE_DETAILS_FAILURE = '[ROLES-AND-PERMISSIONS] Save role details failure';

export const CREATE_NEW_ROLE = '[ROLES-AND-PERMISSIONS] Create new role';
export const CREATE_NEW_ROLE_SUCCESS = '[ROLES-AND-PERMISSIONS] Create new role success';
export const CREATE_NEW_ROLE_FAILURE = '[ROLES-AND-PERMISSIONS] Create new role failure';

export const CLEAR_ALL_DATA = '[ROLES-AND-PERMISSIONS] Clear all data';

export const OPEN_USERS_PER_ROLE_POPUP = '[ROLES-AND-PERMISSIONS] Open users per role popup';

export const FAILURE = '[ROLES-AND-PERMISSIONS] Failure';
export const SET_FAILURE = '[ROLES-AND-PERMISSIONS] Set Failure';
export const RESET_CUSTOM_PERMISSIONS = '[RESET_CUSTOM_PERMISSIONS] Reset Custom Permissions';
export const RESET_CUSTOM_PERMISSIONS_SUCCESS = '[RESET_CUSTOM_PERMISSIONS_SUCCESS] Reset Custom Permissions Success';


export class ResetCustomPermissions implements Action {
  readonly type = RESET_CUSTOM_PERMISSIONS;
  constructor(public payload: any) {}
}

export class ResetCustomPermissionsSuccess implements Action {
  readonly type = RESET_CUSTOM_PERMISSIONS_SUCCESS;
}
export class GetRolesAndPermissionsData implements Action {
  readonly type = GET_ROLES_AND_PERMISSIONS_DATA;

  constructor(public payload: any = null) {}
}
export class SetRolesNameList implements Action {
  readonly type = SET_ROLES_NAME_LIST;

  constructor(public payload: any = null) {}
}
export class SetActivities implements Action {
  readonly type = SET_ACTIVITIES;

  constructor(public payload: any = null) {}
}
export class OpenUsersPerRolePopUp implements Action {
  readonly type = OPEN_USERS_PER_ROLE_POPUP;

  constructor(public payload: any = null) {}
}
export class GetUsersPerRole implements Action {
  readonly type = GET_USERS_PER_ROLE;

  constructor(public payload: any = null) {}
}
export class SetUsersPerRole implements Action {
  readonly type = SET_USERS_PER_ROLE;

  constructor(public payload: any = null) {}
}
export class GetUsersPerRoleFailure implements Action {
  readonly type = GET_USERS_PER_ROLE_FAILURE;


}
export class SaveRolePermissions implements Action {
  readonly type = SAVE_ROLE_PERMISSIONS;

  constructor(public payload: any = null) {}
}
export class SaveRolePermissionsSuccess implements Action {
  readonly type = SAVE_ROLE_PERMISSIONS_SUCCESS;


}
export class SaveRolePermissionsFailure implements Action {
  readonly type = SAVE_ROLE_PERMISSIONS_FAILURE;


}
export class SaveRoleDetails implements Action {
  readonly type = SAVE_ROLE_DETAILS;

  constructor(public payload: any = null) {}
}
export class CreateNewRole implements Action {
  readonly type = CREATE_NEW_ROLE;

  constructor(public payload: any = null) {}
}
export class CreateNewRoleSuccess implements Action {
  readonly type = CREATE_NEW_ROLE_SUCCESS;


}
export class CreateNewRoleFailure implements Action {
  readonly type = CREATE_NEW_ROLE_FAILURE;


}
export class SetCurrentUserRolesAndPermissions implements Action {
  readonly type = SET_CURRENT_USER_ROLES_AND_PERMISSIONS;

  constructor(public payload: any = null) {}
}
export class SetUserPermissionsLog implements Action {
  readonly type = SET_USER_PERMISSION_LOG;

  constructor(public payload: any = null) {}
}
export class SetUsersPerPermission implements Action {
  readonly type = SET_USERS_PER_PERMISSION;

  constructor(public payload: any = null) {}
}
export class SaveRoleDetailsSuccess implements Action {
  readonly type = SAVE_ROLE_DETAILS_SUCCESS;


}
export class SaveRoleDetailsFailure implements Action {
  readonly type = SAVE_ROLE_DETAILS_FAILURE;


}
export class ClearAllRolesAndPermissionsData implements Action {
  readonly type = CLEAR_ALL_DATA;


}
export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;


}
export type Actions =
  | ResetCustomPermissions
  | ResetCustomPermissionsSuccess
  | GetRolesAndPermissionsData
  | SetActivities
  | SetRolesNameList
  | GetUsersPerRole
  | SetUsersPerRole
  | GetUsersPerRoleFailure
  | SaveRolePermissions
  | SaveRolePermissionsSuccess
  | SaveRolePermissionsFailure
  | SaveRoleDetails
  | CreateNewRole
  | CreateNewRoleSuccess
  | CreateNewRoleFailure
  | SaveRoleDetailsSuccess
  | SaveRoleDetailsFailure
  | SetCurrentUserRolesAndPermissions
  | SetUserPermissionsLog
  | ClearAllRolesAndPermissionsData
  | OpenUsersPerRolePopUp
  | Failure
  | SetFailure;
