import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StringCellComponent } from './string-cell/string-cell.component';
import { DecimalCellComponent } from './decimal-cell/decimal-cell.component';
import { PopupImageCellComponent } from './popup-image-cell/popup-image-cell.component';
import { DateCellComponent } from './date-cell/date-cell.component';
import { SharedPipesModule } from '../../shared-pipes/shared-pipes.module';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { KeyValueCellComponent } from './key-value-cell/key-value-cell.component';
import { TotalRecordsComponent } from './total-records/total-records.component';
import { TranslateModule } from '@ngx-translate/core';
import { EnumValCallComponent } from './enum-val-call/enum-val-call.component';
import { UserDetailCellComponent } from './user-detail-cell/user-detail-cell.component';
import { SportNameLogoCellComponent } from './sport-name-logo-cell/sport-name-logo-cell.component';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { TooltipModule } from 'primeng/tooltip';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { NavigationCellComponent } from './navigation-cell/navigation-cell.component';
import { PopupIFrameCellComponent } from 'abs@shared/table/cells/iframe-cell/popup-iframe-cell.component';
import { SafePipeModule } from "safe-pipe";
import { IFrameResizerDirective } from "abs@shared/directives/iframe-resizer.directive";

@NgModule({
  declarations: [
    StringCellComponent,
    DecimalCellComponent,
    PopupImageCellComponent,
    DateCellComponent,
    KeyValueCellComponent,
    TotalRecordsComponent,
    EnumValCallComponent,
    UserDetailCellComponent,
    SportNameLogoCellComponent,
    NavigationCellComponent,
    PopupIFrameCellComponent,
    IFrameResizerDirective
  ],
  exports: [
    DateCellComponent,
    PopupImageCellComponent,
    StringCellComponent,
    KeyValueCellComponent,
    DecimalCellComponent,
    TotalRecordsComponent,
    EnumValCallComponent,
    SportNameLogoCellComponent,
    UserDetailCellComponent,
    NavigationCellComponent,
    PopupIFrameCellComponent,
  ],
  imports: [
    CommonModule,
    SharedPipesModule,
    ButtonModule,
    DialogModule,
    TranslateModule,
    FormsModule,
    InputTextModule,
    CalendarModule,
    TooltipModule,
    OverlayPanelModule,
    SafePipeModule
  ]
})
export class CellsModule {}
