import * as brandSettingsActions from '../actions/brand-settings.actions';
import * as fromApp from 'abs@core/store/reducers';

export interface State {
  JsonData: any;
  editedJsonData: any;
  loading: boolean;
  savingBrandSettings: boolean;
  brandSettingsLoaded: boolean;
  jsonWasTouched: boolean;
  selectedChannel: number;
  selectedOnlineBranch: number;
}

export const initialState: State = {
  JsonData: null,
  editedJsonData: null,
  selectedChannel: null,
  selectedOnlineBranch: null,
  loading: false,
  savingBrandSettings: false,
  brandSettingsLoaded: false,
  jsonWasTouched: false,
};

export function BrandSettingsReducer(state = initialState, action: any): State {
  switch (action.type) {
    case brandSettingsActions.GET_BRAND_SETTINGS: {
      return {
        ...state,
        loading: true,
        brandSettingsLoaded: false,
        selectedChannel: action.payload.requestedChannelId,
        selectedOnlineBranch: action.payload.requestedBranchId,
        jsonWasTouched: false,
      };
    }
    case brandSettingsActions.GET_BRAND_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        JsonData: null,
      };
    }
    case brandSettingsActions.BRAND_SETTINGS_PAGE_WAS_ROUTED: {
      return {
        ...state,
        JsonData: state.jsonWasTouched ? action.payload : null,
        selectedChannel: state.jsonWasTouched ? state.selectedChannel : null,
        selectedOnlineBranch: state.jsonWasTouched ? state.selectedOnlineBranch : null,
      };
    }
    case brandSettingsActions.SET_BRAND_SETTINGS: {
      return {
        ...state,
        JsonData: action.payload,
        loading: false,
        brandSettingsLoaded: true,
        jsonWasTouched: false,
      };
    }
    case brandSettingsActions.SAVE_BRAND_SETTINGS: {
      return {
        ...state,
        loading: true,
        savingBrandSettings: true,
      };
    }
    case brandSettingsActions.SAVE_BRAND_SETTINGS_SUCCESS: {
      return {
        ...state,
        loading: false,
        savingBrandSettings: false,
        jsonWasTouched: false,
      };
    }
    case brandSettingsActions.SAVE_BRAND_SETTINGS_FAILURE: {
      return {
        ...state,
        loading: false,
        savingBrandSettings: false,
      };
    }
    case brandSettingsActions.JSON_WAS_TOUCHED: {
      return {
        ...state,
        jsonWasTouched: true,
      };
    }
    case brandSettingsActions.SET_SELECTED_CHANNEL: {
      return {
        ...state,
        selectedChannel: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export const getJsonData = (state: fromApp.AppState) => {
  return state.brandSettingsReducer.JsonData;
};
export const getJsonWasTouched = (state: fromApp.AppState) => {
  return state.brandSettingsReducer.jsonWasTouched;
};
export const getSelectedChannel = (state: fromApp.AppState) => {
  return state.brandSettingsReducer.selectedChannel;
};
export const getSelectedOnlineBranch = (state: fromApp.AppState) => {
  return state.brandSettingsReducer.selectedOnlineBranch;
};
export const getSavingBrandSettingsStatus = (state: fromApp.AppState) => {
  return state.brandSettingsReducer.savingBrandSettings;
};
