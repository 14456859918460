import { createAction, props } from '@ngrx/store';
import { DropdownItem, FailAction, Filtering } from 'src/app/sport-betting/common/models';
import { CreateDefaultBetType, DefaultBetType, UpdateDefaultBetType } from '../../models';

const SB_GET_DEFAULT_BET_TYPES = '[SBDBT] Get default bet types';
const SB_SET_DEFAULT_BET_TYPES = '[SBDBT] Set default bet types';

const SB_UPDATE_DEFAULT_BET_TYPE = '[SBDBT] Update default bet type';
const SB_DELETE_DEFAULT_BET_TYPE = '[SBDBT] Delete default bet type';

const SB_DELETE_DEFAULT_BET_TYPE_SUCCESS = '[SBDBT] Delete default bet type SUCCESS';
const SB_UPDATE_DEFAULT_BET_TYPE_SUCCESS = '[SBDBT] Update default bet type SUCCESS';

const SB_GET_BET_TYPE_FOR_CREATE = '[SBDBT] Get bet types for create';
const SB_SET_BET_TYPE_FOR_CREATE = '[SBDBT] Set bet types for create';

const SB_INSERT_DEFAULT_BET_TYPES = '[SBDBT] Insert default bet types';
const SB_INSERT_DEFAULT_BET_TYPES_SUCESS = '[SBDBT] Insert default bet types SUCCESS';

const FAILURE = '[SBDBT] Failure';

export const SbGetDefaultBetTypes = createAction(SB_GET_DEFAULT_BET_TYPES, props<{ payload: Filtering }>());
export const SbSetDefaultBetTypes = createAction(SB_SET_DEFAULT_BET_TYPES, props<{
  payload: {
    data: Array<DefaultBetType>,
    totalCount: number;
  }
}>());

export const SbGetBetTypesForCreate = createAction(SB_GET_BET_TYPE_FOR_CREATE, props<{
  payload: {
    sportTypeId: number,
    productId: number,
  }
}>());
export const SbSetBetTypesForCreate = createAction(SB_SET_BET_TYPE_FOR_CREATE, props<{
  payload: {
    data: Array<DropdownItem<number, string>>,
    totalCount: number;
  }
}>());

export const SbInsertDefaultBetType = createAction(SB_INSERT_DEFAULT_BET_TYPES, props<{ payload: CreateDefaultBetType }>());
export const SbInsertDefaultBetTypesSuccess = createAction(SB_INSERT_DEFAULT_BET_TYPES_SUCESS);

export const SbDeleteDefaultBetType = createAction(SB_DELETE_DEFAULT_BET_TYPE, props<{ payload: number }>());
export const SbDeleteDefaultBetTypesSuccess = createAction(SB_DELETE_DEFAULT_BET_TYPE_SUCCESS);

export const SbUpdateDefaultBetType = createAction(SB_UPDATE_DEFAULT_BET_TYPE, props<{ payload: UpdateDefaultBetType }>());
export const SbUpdateDefaultBetTypeSuccess = createAction(SB_UPDATE_DEFAULT_BET_TYPE_SUCCESS, props<{ payload: UpdateDefaultBetType }>());

export const DefaultBetTypesFailure = createAction(FAILURE, props<FailAction>());