import { Action } from '@ngrx/store';

// UPLOAD Robots or Sitemap
export const UPLOAD_FILE = '[CMS-SEO Management] Upload File';
export const UPLOAD_FILE_SUCCESS = '[CMS-SEO Management] Upload File Success';

// Languages supported for branch
export const SET_SUPPORTED_LANGUAGES = '[CMS-SEO Management] Set Supported Languages';

// SEO PAGE MANAGEMENT
export const GET_SEO_PAGE_DATA = '[CMS-SEO Management] Get SEO page data';
export const SET_SEO_PAGE_DATA = '[CMS-SEO Management] Set SEO page data';
export const RESET_SEO_PAGE_DROPDOWNS = '[CMS-SEO Management] Reset SEO page dropdowns';
export const SAVE_SEO_PAGE_DATA = '[CMS-SEO Management] Save SEO page data';

// ACTIONS
export class UploadFile implements Action {
  readonly type = UPLOAD_FILE;

  constructor(public payload: any = null) {}
}

export class UploadFileSuccess implements Action {
  readonly type = UPLOAD_FILE_SUCCESS;

  constructor(public payload: any = null) {}
}

export class SetSupportedLanguages implements Action {
  readonly type = SET_SUPPORTED_LANGUAGES;

  constructor(public payload: any = null) {}
}

export class GetSEOPageData implements Action {
  readonly type = GET_SEO_PAGE_DATA;

  constructor(public payload: any = null) {}
}

export class SetSEOPageData implements Action {
  readonly type = SET_SEO_PAGE_DATA;

  constructor(public payload: any = null) {}
}

export class SaveSEOPageData implements Action {
  readonly type = SAVE_SEO_PAGE_DATA;

  constructor(public payload: any = null) {}
}

export class ResetSEOPageDropdowns implements Action {
  readonly type = RESET_SEO_PAGE_DROPDOWNS;
}

export type Actions =
  | UploadFile
  | UploadFileSuccess
  | SetSupportedLanguages
  | GetSEOPageData
  | ResetSEOPageDropdowns
  | SaveSEOPageData
  | SetSEOPageData;
