import * as gamesActions from '../actions/games.actions';
import * as fromApp from 'abs@core/store/reducers';
import {AppState} from 'abs@core/store/reducers';
import {Category} from '../../modules/casino-category-management/models/category';
import {isNullOrUndefinedOrEmptyString} from 'abs@shared/shared-functions/functions';
import {createSelector} from '@ngrx/store';
import copy from 'fast-copy';
import {Game} from '../../modules/casino-game-management/models/game';
import * as moment from "moment/moment";

export interface State {
  loading: boolean;
  gamesLoaded: boolean;
  allGamesLoaded: boolean;
  isLoadingAllGames: boolean;
  categoriesLoaded: boolean;
  games: Game[];
  allGames: Game[];
  allCategories: Category[];
  gamesCount: number;
  allGamesCount: number;
  categoriesCount: number;
  categories: Category[];
  allCategoriesDropdown: any[];
  clonedGameForSave: Game;
  clonedGameForSaveIndex: any;
  clonedCategoryForEdit: Category | -1;
  isLoadingGames: boolean;
  isLoadingCategories: boolean;
  //NEW
  clonedCategoriesForRestore: Category[];
  clonedGamesForRestore: Game[];
}

export const initialState: State = {
  loading: false,
  games: null,
  allGames: null,
  allCategories: null,
  gamesLoaded: false,
  isLoadingAllGames: false,
  allGamesLoaded: false,
  categoriesLoaded: false,
  allGamesCount: null,
  gamesCount: null,
  categoriesCount: null,
  categories: null,
  allCategoriesDropdown: null,
  clonedGameForSave: null,
  clonedGameForSaveIndex: null,
  clonedCategoryForEdit: -1,
  isLoadingGames: false,
  isLoadingCategories: false,
  //NEW
  clonedCategoriesForRestore: null,
  clonedGamesForRestore: null,
};

export function GamesReducer(state = initialState, action: any): State {
  switch (action.type) {
    // Games
    case gamesActions.GET_GAMES: {
      return {
        ...state,
        loading: true,
        isLoadingGames: true,
        gamesLoaded: false,
        gamesCount: null,
      };
    }
    case gamesActions.GET_ALL_GAMES: {
      return {
        ...state,
        isLoadingAllGames: true,
        allGamesLoaded: false,
        allGamesCount: null,
      };
    }
    case gamesActions.SET_GAMES: {
      return {
        ...state,
        loading: false,
        isLoadingGames: false,
        gamesLoaded: action.payload.Games != null && action.payload.Games.length > 0,
        gamesCount: +action.payload.Count,
        games: ProcessGames(action.payload.Games),
        clonedGamesForRestore: ProcessGames(action.payload.Games),
      };
    }
    case gamesActions.SET_ALL_GAMES: {
      return {
        ...state,
        allGames: action.payload,
      };
    }
    case gamesActions.SAVE_GAME: {
      return {
        ...state,
        loading: true,
        isLoadingGames: true,
        games: state.games.map((item) => (item.Id === action.payload.game.Id ? action.payload.game : item)),
      };
    }
    case gamesActions.EDIT_GAME: {
      return {
        ...state,
        games: state.games.map((item) => (item.Id === action.payload.game.Id ? action.payload.game : item)),
      };
    }
    case gamesActions.REORDER_GAME: {
      return {
        ...state,
        loading: true,
        isLoadingGames: true,
      };
    }
    case gamesActions.CANCEL_EDIT_GAME: {
      return {
        ...state,
        games: state.games.map((item) =>
          item.Id === action.payload.game.Id ? state.clonedGamesForRestore[action.payload.gameIndex] : item
        ),
      };
    }
    case gamesActions.SAVE_GAME_SUCCESS: {
      return {
        ...state,
        games: state.games.map((item) => (item.Id === action.payload.game.Id ? action.payload.game : item)),
        clonedGamesForRestore: state.clonedGamesForRestore.map((item) =>
          item.Id === action.payload.game.Id ? action.payload.game : item
        ),
        loading: false,
        isLoadingGames: false,
      };
    }

    // Categories
    case gamesActions.GET_CATEGORIES: {
      return {
        ...state,
        loading: true,
        isLoadingCategories: true,
        categoriesCount: null,
        categoriesLoaded: false,
      };
    }
    case gamesActions.SET_ALL_CATEGORIES: {
      return {
        ...state,
        allCategories: action.payload.Categories,
        allCategoriesDropdown: action.payload.Categories.map((cat) => {
          return {value: cat.Id, label: cat.Id + ' -  ' + (cat.Name || '')};
        }),
      };
    }
    case gamesActions.SET_CATEGORIES: {
      return {
        ...state,
        loading: false,
        isLoadingCategories: false,
        categories: action.payload.Categories,
        clonedCategoriesForRestore: action.payload.Categories,
        categoriesLoaded: action.payload.Categories != null && action.payload.Categories.length > 0,
        categoriesCount: action.payload.Count,
      };
    }
    case gamesActions.EDIT_CATEGORY: {
      return {
        ...state,
        clonedCategoryForEdit: action.payload,
      };
    }
    case gamesActions.CANCEL_EDIT_CATEGORY: {
      return {
        ...state,
        clonedCategoryForEdit: -1,
        games: null,
      };
    }
    case gamesActions.SAVE_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: false,
        isLoadingCategories: false,
        clonedCategoryForEdit: null,
      };
    }
    case gamesActions.REORDER_CATEGORY: {
      return {
        ...state,
        loading: true,
        isLoadingGames: true,
      };
    }

    case gamesActions.FAILURE: {
      return {
        ...state,
        loading: false,
        isLoadingGames: false,
        isLoadingCategories: false,
      };
    }
    default: {
      return state;
    }
  }
}

function ProcessGames(games: Game[]): any {
  return games.map((game) => {
    let ExtraData: any = null;
    if (!isNullOrUndefinedOrEmptyString(game.extraData)) {
      let isJson = true;
      try {
        ExtraData = JSON.parse(game.extraData as unknown as string);
      } catch (e: any) {
        isJson = false;
      }
      if (isJson) {
        if (
          !isNullOrUndefinedOrEmptyString(ExtraData.setting) &&
          !isNullOrUndefinedOrEmptyString(ExtraData.setting.isExitButton)
        ) {
          ExtraData.setting.isExitButton = ExtraData.setting.isExitButton == true;
          // ExtraData.setting.isExitButton == 'true' || ExtraData.setting.isExitButton === true;
        }
        if (
          !isNullOrUndefinedOrEmptyString(ExtraData.setting) &&
          !isNullOrUndefinedOrEmptyString(ExtraData.setting.isLandscape)
        ) {
          ExtraData.setting.isLandscape = ExtraData.setting.isLandscape === true;
          // ExtraData.setting.isLandscape === 'true' || ExtraData.setting.isLandscape === true;
        }
      }
    }
    return {
      Id: game.Id,
      Name: game.Name,
      Order: game.Order,
      ProviderId: game.ProviderId,
      CategoryName: game.CategoryName,
      ProviderName: game.ProviderName,
      Image: game.Image,
      ImageURL: game.ImageURL,
      LiveDate: game.LiveDate ? moment(game.LiveDate).format('DD/MM/YYYY HH:mm:ss') : null,
      IsActive: game.IsActive,
      GameCategory: game.GameCategory,
      IsTopRated: game.IsTopRated,
      IsNew: game.IsNew,
      EnableTryMe: game.EnableTryMe,
      EnableMobile: game.EnableMobile,
      EnableOnline: game.EnableOnline,
      SupportMobile: game.SupportMobile,
      SupportOnline: game.SupportOnline,
      SupportTryMode: game.SupportTryMode,
      extraData: game.extraData != null ? game.extraData : null,
      CategoryId: game.CategoryId,
    };
  });
}

export const getCategories = (state: fromApp.AppState): any => {
  return copy(state.gamesReducer.categories);
};

export const getAllCategoriesDropdown = (state: fromApp.AppState): any => {
  return state.gamesReducer.allCategoriesDropdown;
};

export const getCategoriesCount = (state: fromApp.AppState): any => state.gamesReducer.categoriesCount;
export const getCategoriesLoadingStatus = (state: fromApp.AppState): any => {
  return state.gamesReducer.categoriesLoaded;
};
export const isLoading = (state: fromApp.AppState): any => {
  return state.gamesReducer.loading;
};
export const getCategoriesDropDown = createSelector(getCategories, (categories) => {
  if (categories) {
    const categoriesDropDown = [];
    categoriesDropDown.push({
      label: 'None',
      value: null,
    });
    return categoriesDropDown.concat(
      categories.map((c) => {
        return {
          label: c.Id + ' - ' + c.Name,
          value: c.Id,
        };
      })
    );
  }
  return undefined;
});
export const getAreGamesLoading = (state: AppState): any => (state.gamesReducer ? state.gamesReducer.isLoadingGames : null);
export const getGamesCount = (state: AppState): any => (state.gamesReducer ? state.gamesReducer.gamesCount : null);
export const getGames = (state: AppState): any => {
  return copy(state.gamesReducer.games);
};

export const getAllGames = (state: AppState): any => {
  return JSON.parse(JSON.stringify(state.gamesReducer.allGames));
};

export const getCategoryForEdit = (state: AppState): any => {
  return state.gamesReducer.clonedCategoryForEdit;
};
