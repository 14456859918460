import { Action } from '@ngrx/store';

export const GET_EXPENSES_VIEW = '[EXPENSES REQUESTS] Get Expenses view';
export const SET_EXPENSES_VIEW = '[EXPENSES REQUESTS] Set Expenses view';

export const SAVE_EXPENSE_ITEM = '[EXPENSES REQUESTS] Save Expense Item';
export const SAVE_EXPENSE = '[EXPENSES REQUESTS] Save Expense';
export const SAVE_EXPENSE_SUCCESS = '[EXPENSES REQUESTS] Save Expense Success';
export const SAVE_EXPENSE_ITEM_SUCCESS = '[EXPENSES REQUESTS] Save Expense Item Success';
export const SAVE_EXPENSE_FAIL = '[EXPENSES REQUESTS] Save Expense Fail';
export const SAVE_EXPENSE_ITEM_FAIL = '[EXPENSES REQUESTS] Save Expense Item Fail';

export const FAILURE = '[EXPENSES REQUESTS] Failure';

export class SaveExpense implements Action {
  readonly type = SAVE_EXPENSE;

  constructor(public payload: any = null) {}
}
export class SaveExpenseSuccess implements Action {
  readonly type = SAVE_EXPENSE_SUCCESS;

  constructor(public payload: any = null) {}
}
export class SaveExpenseFail implements Action {
  readonly type = SAVE_EXPENSE_FAIL;

  constructor(public payload: any = null) {}
}

export class GetExpensesView implements Action {
  readonly type = GET_EXPENSES_VIEW;

  constructor(public payload: any = null) {}
}
export class SetExpensesView implements Action {
  readonly type = SET_EXPENSES_VIEW;

  constructor(public payload: any = null) {}
}

export class SaveExpenseItemFail implements Action {
  readonly type = SAVE_EXPENSE_ITEM_FAIL;

  constructor(public payload: any = null) {}
}

export class SaveExpenseItemSuccess implements Action {
  readonly type = SAVE_EXPENSE_ITEM_SUCCESS;

  constructor(public payload: any = null) {}
}

export class SaveExpenseItem implements Action {
  readonly type = SAVE_EXPENSE_ITEM;

  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;

  
}

export type Actions =
  | SaveExpenseItem
  | SaveExpenseItemSuccess
  | SaveExpenseItemFail
  | GetExpensesView
  | SetExpensesView
  | SaveExpense
  | SaveExpenseSuccess
  | SaveExpenseFail;
