import { Pipe, PipeTransform } from '@angular/core';
import { secondsToHoursMinutesSeconds } from 'abs@shared/shared-functions/functions';

@Pipe({
  name: 'secondsToHoursMinutesSecondsFormat',
})
export class secondsToHoursMinutesSecondsPipe implements PipeTransform {
  /**
   * Transform seconds to HH:MM:SS format
   * @param value
   */
  transform(value?: number): string | null {
    return secondsToHoursMinutesSeconds(value);
  }
}
