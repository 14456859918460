import * as Layout from '../actions/layout.actions';
import { AppSettings } from '../../app.config';
import * as fromApp from './index';
import { GrowlMessage } from '../../models/Growl-Messages';
export interface State {
  summary: string;
  detail: string;
  severity: string;
  life: number;

  message: GrowlMessage;
  language: string;
}

export const initialState: State = {
  summary: '',
  detail: '',
  severity: '',
  life: 3000,
  message: null,
  language: AppSettings.DEFAULT_LANGUAGE,
};

export function LayoutReducer(state = initialState, action: Layout.Actions): State {
  switch (action.type) {
    case Layout.SUCCESS_MESSAGE: {
      return {
        ...state,
        // summary: action.payload.summary,
        // detail: action.payload.detail,
        // severity: action.payload.severity,
        // life: action.payload.life,
        //
        message: action.payload,
      };
    }
    case Layout.ERR_MESSAGE: {
      return {
        ...state,
        // summary: action.payload.summary,
        // detail: action.payload.detail,
        // severity: action.payload.severity,
        // life: action.payload.life,
        //
        message: action.payload,
      };
    }
    case Layout.INFO_MESSAGE: {
      return {
        ...state,
        // summary: action.payload.summary,
        // detail: action.payload.detail,
        // severity: action.payload.severity,
        // life: action.payload.life,
        //
        message: action.payload,
      };
    }
    case Layout.WARN_MESSAGE: {
      return {
        ...state,
        // summary: action.payload.summary,
        // detail: action.payload.detail,
        // severity: action.payload.severity,
        // life: action.payload.life,
        //
        message: action.payload,
      };
    }
    case Layout.SET_LANGUAGE: {
      return {
        ...state,
        language: action.payload,
      };
    }
    case Layout.CLEAR_MESSAGE: {
      return {
        ...state,
        summary: '',
        detail: '',
        severity: '',
        life: 3000,
        message: null,
      };
    }
    default: {
      return state;
    }
  }
}

export const getLanguage = (state: fromApp.AppState) => {
  const language = state.layoutReducer.language;
  return language;
};
export const getGrowlMessage = (state: fromApp.AppState) => {
  const message = state.layoutReducer.message;
  return message;
};
