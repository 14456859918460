import * as notificationActions from '../actions/notification-templates.actions';
import * as fromApp from 'abs@core/store/reducers';
import {AppState} from 'abs@core/store/reducers';
import copy from 'fast-copy';
import { NotificationItem } from '../../modules/template-management/models/notification-template.model';

export interface State {
  notificationsLoaded: boolean;
  allNotificationsLoaded: boolean;
  isLoadingAllNotifications: boolean;
  notifications: NotificationItem[] | NotificationItem;
  allNotifications: NotificationItem[];
  notificationsCount: number;
  allNotificationsCount: number;
  isLoadingNotifications: boolean;
  isNotificationsSavedSuccess: boolean;
}

export const initialState: State = {
  notifications: null,
  allNotifications: null,
  notificationsLoaded: false,
  isLoadingAllNotifications: false,
  allNotificationsLoaded: false,
  allNotificationsCount: null,
  notificationsCount: null,
  isLoadingNotifications: false,
  isNotificationsSavedSuccess: false
};

export function NotificationTemplateReducer(state = initialState, action: notificationActions.Actions): State {
  switch (action.type) {
  case notificationActions.GET_NOTIFICATION_TEMPLATES: {
    return {
      ...state,
      isLoadingNotifications: true,
      notificationsLoaded: false,
      notificationsCount: null,
    };
  }
  case notificationActions.GET_NOTIFICATION_TEMPLATE_BY_ID: {
    return {
      ...state,
      isLoadingNotifications: true,
      notificationsLoaded: false,
      notificationsCount: null,
      isNotificationsSavedSuccess: false
    };
  }
  case notificationActions.SAVE_NOTIFICATION: {
    return {
      ...state,
      isLoadingNotifications: false,
    };
  }
  case notificationActions.SAVE_NOTIFICATION_SUCCESS: {
    return {
      ...state,
      isNotificationsSavedSuccess: true
    };
  }

  case notificationActions.GET_ALL_NOTIFICATION_TEMPLATES: {
    return {
      ...state,
      isLoadingAllNotifications: true,
      allNotificationsLoaded: false,
      allNotificationsCount: null,
    };
  }
  case notificationActions.SET_NOTIFICATION_TEMPLATES: {
    return {
      ...state,
      isLoadingNotifications: false,
      notificationsLoaded: action.payload.notifications.items != null && action.payload.notifications.items.length > 0,
      notificationsCount: +action.payload.notifications.totalCount,
      notifications: action.payload.notifications.items,
    };
  }
  case notificationActions.SET_NOTIFICATION_TEMPLATE: {
    return {
      ...state,
      isLoadingNotifications: false,
      notificationsLoaded: true,
      notifications: action.payload.template,
    };
  }
  case notificationActions.SET_ALL_NOTIFICATION_TEMPLATES: {
    return {
      ...state,
      allNotifications: action.payload,
    };
  }
  case notificationActions.FAILURE: {
    return {
      ...state,
      isLoadingNotifications: false,
    };
  }
  default: {
    return state;
  }
  }
}
export const isLoading = (state: fromApp.AppState): any => {
  return state.notificationTemplateReducer.isLoadingNotifications;
};

export const getAreNotificationsLoading = (state: AppState): boolean | null => (state.notificationTemplateReducer ? state.notificationTemplateReducer.isLoadingNotifications : null);
export const getNotificationsCount = (state: AppState): number | null => (state.notificationTemplateReducer ? state.notificationTemplateReducer.notificationsCount : null);
export const getSaveNotificationSuccess = (state: AppState): boolean => (state.notificationTemplateReducer ? state.notificationTemplateReducer.isNotificationsSavedSuccess : false);
export const getNotifications = (state: AppState): any => {
  return copy(state.notificationTemplateReducer.notifications);
};
export const getAllNotifications = (state: AppState): any => {
  return JSON.parse(JSON.stringify(state.notificationTemplateReducer.allNotifications));
};

