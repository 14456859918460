import { Action } from '@ngrx/store';

export const GET_BRAND_SETTINGS = '[BRAND-SETTINGS] Get BBrandSettings';
export const GET_BRAND_SETTINGS_FAILURE = '[BRAND-SETTINGS] Get BBrandSettings Failure';

export const SET_BRAND_SETTINGS = '[BRAND-SETTINGS] Set BBrandSettings';

export const SAVE_BRAND_SETTINGS = '[BRAND-SETTINGS] Save Brand Settings';
export const SAVE_BRAND_SETTINGS_FAILURE = '[BRAND-SETTINGS] Save Brand Settings Failure';
export const SAVE_BRAND_SETTINGS_SUCCESS = '[BRAND-SETTINGS] Save Brand Settings Success';

export const JSON_WAS_TOUCHED = '[BRAND-SETTINGS Editor] Json Was Touched';
export const BRAND_SETTINGS_PAGE_WAS_ROUTED = '[BRAND-SETTINGS Editor] Brand settings was routed';

export const SET_SELECTED_CHANNEL = '[BRAND-SETTINGS] Set Selected Channel';

export class BrandSettingsPageWasRouted implements Action {
  readonly type = BRAND_SETTINGS_PAGE_WAS_ROUTED;

  constructor(public payload: any = null) {}
}
export class JsonWasTouched implements Action {
  readonly type = JSON_WAS_TOUCHED;

  
}
export class GetBrandSettings implements Action {
  readonly type = GET_BRAND_SETTINGS;

  constructor(public payload: any = null) {}
}
export class GetBrandSettingsFailure implements Action {
  readonly type = GET_BRAND_SETTINGS_FAILURE;
}

export class SetBrandSettings implements Action {
  readonly type = SET_BRAND_SETTINGS;

  constructor(public payload: any = null) {}
}

export class SaveBrandSettings implements Action {
  readonly type = SAVE_BRAND_SETTINGS;

  constructor(public payload: any = null) {}
}
export class SaveBrandSettingsSuccess implements Action {
  readonly type = SAVE_BRAND_SETTINGS_SUCCESS;
}
export class SaveBrandSettingsFailure implements Action {
  readonly type = SAVE_BRAND_SETTINGS_FAILURE;
}

export class SetSelectedChannel implements Action {
  readonly type = SET_SELECTED_CHANNEL;

  constructor(public payload: number = null) {}
}

export type Actions =
  | GetBrandSettings
  | SetBrandSettings
  | GetBrandSettingsFailure
  | SaveBrandSettings
  | SaveBrandSettingsSuccess
  | SaveBrandSettingsFailure
  | JsonWasTouched
  | BrandSettingsPageWasRouted
  | SetSelectedChannel;
