<ng-template *ngIf="val; then canShowTemplate; else cannotShowTemplate"></ng-template>
<ng-template #canShowTemplate>
  <button
    pButton
    type="button"
    (click)="op.show($event)"
    icon="fa fa-eye"
    *ngIf="canDisplay"
    class="ui-button p-button-outlined"
  ></button>
  <p-dialog>
    <img [src]="val" (load)="canDisplay = true"/>
  </p-dialog>

  <i *ngIf="!canDisplay" class="material-icons" pTooltip="{{ 'Image_Cannot_Be_Loaded' | translate }}"> broken_image </i>
</ng-template>
<ng-template #cannotShowTemplate>
  <button pButton type="button" icon="fa fa-eye" class="ui-button p-button-outlined" disabled></button>
</ng-template>

<p-overlayPanel #op appendTo="body"
                [dismissable]="dismissable"
                [showCloseIcon]="showCloseIcon">
  <ng-template pTemplate>
    <img [src]="val" (load)="canDisplay = true" style="max-width:850px" class="overlay-panel-img"/>
  </ng-template>
</p-overlayPanel>
