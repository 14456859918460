import { Action } from '@ngrx/store';

export const GET_EXPENSES_REPORT = '[EXPENESES REPORT] Get Expenses REPORT';
export const GET_BRANCHES_EXPENSES_REPORT = '[EXPENESES REPORT] Get Branches Expenses REPORT';
export const SET_EXPENSES_REPORT = '[EXPENESES REPORT] Set Expenses REPORT';
export const SET_BRANCHES_EXPENSES_REPORT = '[EXPENESES REPORT] Set Branches Expenses REPORT';

export const FAILURE = '[EXPENESES REPORT] Failure';

export class GetBranchesExpensesReport implements Action {
  readonly type = GET_BRANCHES_EXPENSES_REPORT;

  constructor(public payload: any = null) {}
}

export class SetBranchesExpensesReport implements Action {
  readonly type = SET_BRANCHES_EXPENSES_REPORT;

  constructor(public payload: any = null) {}
}
export class GetExpensesReport implements Action {
  readonly type = GET_EXPENSES_REPORT;

  constructor(public payload: any = null) {}
}

export class SetExpensesReport implements Action {
  readonly type = SET_EXPENSES_REPORT;

  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;

  
}

export type Actions = GetExpensesReport | SetExpensesReport | GetBranchesExpensesReport | SetBranchesExpensesReport;
