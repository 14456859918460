<p-table
    #dt
    [value]="data"
    [columns]="cols"
    [responsive]="false"
    [paginator]="true"
    [rowsPerPageOptions]="[10, 20, 50, 100, 200]"
    [rows]="10"
    [totalRecords]="data.length">
    <ng-template pTemplate="caption">
       {{dataHeader}}
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th *ngFor="let col of cols"
                [pTooltip]="col.tooltip"
                [tooltipDisabled]="col.tooltip === null"
                tabindex="-1"
                [pSortableColumnDisabled]="!col.sort"
                [pSortableColumn]="col.sort ? col.field : null"
                [ngStyle]=col.headerStyle>
                <app-string-cell [val]="col.header"></app-string-cell>
                <p-sortIcon *ngIf="col.sort" [field]="col.field"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th *ngFor="let col of cols">
                <span *ngIf="col.filter">
                    <input pInputText type="text" (input)="dt.filter($event.target['value'], col.field, col.filter.type)">
                </span>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
        <tr>
            <td *ngFor="let col of cols">
                {{rowData[col.field]}}
            </td>
        </tr>
    </ng-template>
    <ng-template pTemplate="summary">
        <app-total-records [total]="data.length"></app-total-records>
<!--        {{'Total_Records' | translate:{ totalRecords: data.length} }}-->
    </ng-template>
</p-table>
