import { Action } from '@ngrx/store';

export const GET_LANGUAGES = '[CMS-Language Editor] Get Languages';
export const GET_LANGUAGES_FAILURE = '[CMS-Language Editor] Get Languages Failure';
export const SET_LANGUAGES = '[CMS-Language Editor] Set Languages';

export const GET_TRANSLATIONS = '[CMS-Language Editor] Get Translations';
export const GET_TRANSLATIONS_FAILURE = '[CMS-Language Editor] Get Translations Failure';
export const SET_TRANSLATIONS = '[CMS-Language Editor] Set Translations';

export const SAVE_TRANSLATIONS = '[CMS-Language Editor] Save Translations';
export const UNDO_CHANGES = '[CMS-Language Editor] Undo Changes';
export const SAVE_TRANSLATIONS_SUCCESS = '[CMS-Language Editor] Save Translations Success';
export const SAVE_TRANSLATIONS_FAILURE = '[CMS-Language Editor] Save Translations Failure';
export const JSON_WAS_TOUCHED = '[CMS-Language Editor] Json Was Touched';
export const JSON_EDITOR_WAS_ROUTED = '[CMS-Language Editor] Json Editor Was Routed';

export class JsonEditorWasRouted implements Action {
  readonly type = JSON_EDITOR_WAS_ROUTED;

  constructor(public payload: any = null) {}
}
export class JsonWasTouched implements Action {
  readonly type = JSON_WAS_TOUCHED;

  
}
export class UndoChanges implements Action {
  readonly type = UNDO_CHANGES;

  
}
export class GetLanguages implements Action {
  readonly type = GET_LANGUAGES;

  constructor(public payload: any = null) {}
}
export class GetLanguagesFailure implements Action {
  readonly type = GET_LANGUAGES_FAILURE;

  
}
export class SetLanguages implements Action {
  readonly type = SET_LANGUAGES;

  constructor(public payload: any = null) {}
}
export class GetTranslations implements Action {
  readonly type = GET_TRANSLATIONS;

  constructor(public payload: any = null) {}
}
export class GetTranslationsFailure implements Action {
  readonly type = GET_TRANSLATIONS_FAILURE;

  
}
export class SaveTranslations implements Action {
  readonly type = SAVE_TRANSLATIONS;

  constructor(public payload: any = null) {}
}
export class SaveTranslationsFailure implements Action {
  readonly type = SAVE_TRANSLATIONS_FAILURE;

  
}
export class SaveTranslationsSuccess implements Action {
  readonly type = SAVE_TRANSLATIONS_SUCCESS;

  
}
export class SetTranslations implements Action {
  readonly type = SET_TRANSLATIONS;

  constructor(public payload: any = null) {}
}

export type Actions =
  | GetLanguages
  | GetLanguagesFailure
  | SetLanguages
  | GetTranslations
  | GetTranslationsFailure
  | SetTranslations
  | SaveTranslations
  | SaveTranslationsSuccess
  | SaveTranslationsFailure
  | JsonWasTouched
  | UndoChanges
  | JsonEditorWasRouted;
