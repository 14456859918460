import { Component } from '@angular/core';
import {AppSettings} from "../core/app.config";

@Component({
  selector: 'app-accessdenied',
  templateUrl: './app.accessdenied.component.html',
})
export class AppAccessdeniedComponent {
  logo = AppSettings.BRAND_DOMAIN;
}
