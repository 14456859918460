<div
  class="layout-wrapper"
  (click)="onLayoutClick()"
  *ngIf="isLogged() | async"
  [ngClass]="{
    'layout-static': app.menuMode === 'static',
    'layout-overlay': app.menuMode === 'overlay',
    'layout-slim': app.menuMode === 'slim',
    'layout-horizontal': app.menuMode === 'horizontal',
    'layout-sidebar': app.menuMode === 'sidebar',
    'layout-sidebar-static': app.menuMode === 'sidebar' && sidebarStatic,
    'layout-overlay-active': overlayMenuActive,
    'layout-mobile-active': staticMenuMobileActive,
    'layout-static-inactive': staticMenuDesktopInactive && app.menuMode === 'static',
    'p-ripple-disabled': !app.ripple
  }"
>
  <p-toast></p-toast>
  <div class="layout-main">
    <app-topbar *ngIf="isMobile() || isOverlay() || isHorizontal()"></app-topbar>

    <app-menu *ngIf="!isMobile() && (isStatic() || isSlim() || isSidebar())"></app-menu>

    <app-breadcrumb></app-breadcrumb>

    <div class="layout-main-content">
      <app-progress-bar></app-progress-bar>
      <router-outlet></router-outlet>
    </div>

    <app-footer></app-footer>
  </div>

  <app-rightmenu></app-rightmenu>

  <app-config></app-config>
</div>
