import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { Store } from '@ngrx/store';
import { getCreditLimit, getUserData } from '../../auth/store/reducers/auth';
import { Actions, ofType } from '@ngrx/effects';
import { filter, take, takeUntil, tap } from 'rxjs/operators';
import * as AuthActions from '../../auth/store/actions/auth.actions';
import { AuthorizationService } from 'abs@shared/authorization/authorization.service';
import { PermissionsScreensCodes } from 'abs@core/app.config';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getDynamicViews, isNullOrUndefined } from '../../shared/shared-functions/functions';
import { DIRECT_TRANSFER_SUCCESS } from 'abs@core/store/actions/online.actions';
import { getTemplatesPage } from 'abs@core/store/reducers/metadata.reducer';
import { AppMainComponent } from '../app.main.component';
import { IDynamicViewsConfig } from 'abs@shared/models/dynamic';
import { UserData } from '../../auth/models/user';
import { getFeedHealthStatus } from 'abs@core/store/reducers/feed-health.reducer';
import { GetFeedStatus } from 'abs@core/store/actions/feed-health.actions';
import { AppConfigService } from 'abs@core/services/appConfig.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app.breadcrumb.component.html',
  styleUrls: ['./app.breadcrumb.component.scss']
})
export class AppBreadcrumbComponent implements OnDestroy, OnInit {
  public menuItems: MenuItem[];
  public userData: Observable<UserData>;
  public creditLimit: any;
  public permission = PermissionsScreensCodes;
  public dashboardPermission: boolean;
  public showFeedHealthTracker: boolean;
  public screenPermissions = PermissionsScreensCodes;
  public feedStatus: Observable<string>;

  private dynamicBreadcrumbMapping: IDynamicViewsConfig = getDynamicViews();
  private templates: any[] = [];
  private unsubscribe$ = new Subject<void>();
  private intervalId: any;

  constructor(
    private authorizationService: AuthorizationService,
    private store: Store<any>,
    private directTransferSuccess$: Actions,
    private route: ActivatedRoute,
    private router: Router,
    public appMain: AppMainComponent,
    private appConfigService: AppConfigService
  ) {
    this.userData = this.store.select(getUserData);
    this.creditLimit = this.store.select(getCreditLimit);

    // Initial navigation
    const isDynamic = this.router.url.includes('/setting/template/');
    this.menuItems = isDynamic ? this.createDynamicBreadcrumbs() : this.createBreadcrumbs(this.route.root);

    this.store
      .select(getTemplatesPage)
      .pipe(take(2))
      .subscribe((res) => {
        this.templates = res;
        if (this.templates && this.router.url.includes('/setting/template/')) {
          this.menuItems = this.createDynamicBreadcrumbs();
        }
      });

    this.directTransferSuccess$
      .pipe(
        ofType(DIRECT_TRANSFER_SUCCESS),
        takeUntil(this.unsubscribe$),
        tap(() => {
          this.store.dispatch(new AuthActions.KeepAlive());
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      const isDynamic = this.router.url.includes('/setting/template/');
      this.menuItems = isDynamic ? this.createDynamicBreadcrumbs() : this.createBreadcrumbs(this.route.root);
    });
    this.dashboardPermission = this.authorizationService.hasPermission(PermissionsScreensCodes.DASHBOARD_MAIN_SCREEN);
    this.showFeedHealthTracker = this.authorizationService.hasPermission(PermissionsScreensCodes.FEED_HEALTH_TRACKING);

    this.feedStatus = this.store.select(getFeedHealthStatus);

    if (this.showFeedHealthTracker) {
      this.store.dispatch(new GetFeedStatus(+this.appConfigService.getBrandId()));
      this.intervalId = setInterval(() => {
        this.store.dispatch(new GetFeedStatus(+this.appConfigService.getBrandId()));
      }, 30000);
    }
  }

  public checkPermission(permission, level = 1): boolean {
    return this.authorizationService.hasPermission(permission, level);
  }

  public getFeedHealtIconColor(status: string): string {
    if (status === 'Healthy') {
      return 'green';
    }
    if (status === 'Warning') {
      return 'orange';
    }
    if (status === 'Unhealthy') {
      return 'red';
    }
    return 'gray';
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  private createBreadcrumbs(route: ActivatedRoute, url = '/', breadcrumbs: MenuItem[] = []): MenuItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      breadcrumbs = breadcrumbs.map((b: MenuItem) => {
        if (b.url.endsWith('/')) {
          b.url = b.url.slice(0, -1);
        }
        return b;
      });
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url.map((segment) => segment.path).join('/');
      if (routeURL !== '') {
        url += routeURL + '/';
      }

      let label = child.snapshot.data['breadcrumb'];
      const disabled = !child.snapshot.data['canRedirect'];

      if (label === 'id') {
        label = child.snapshot.data.drb.breadcrumb; // GET ID FROM RESOLVER
      }

      if (!isNullOrUndefined(label)) {
        breadcrumbs.push({ label, url, disabled, target: '_self' });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
    return [];
  }

  private createDynamicBreadcrumbs(): MenuItem[] {
    const menuItems = [];
    const routes = this.router.url.split('/');
    const hasExtra = routes.length === 5 ? routes[4] : null;
    const templateIds = this.router.url.split('/')[3];
    const templateId = +templateIds.split('-')[0];
    const templateValueId = +templateIds.split('-')[1];

    if (this.dynamicBreadcrumbMapping[templateIds]) {
      const breadcrumbPaths = this.dynamicBreadcrumbMapping[templateIds]['fullBreadcrumb'].split('/');
      for (const bp of breadcrumbPaths) {
        const supportRedirect = (breadcrumbPaths[breadcrumbPaths.length - 1] === bp) && hasExtra;
        menuItems.push({
          label: bp,
          disabled: !supportRedirect,
          url: supportRedirect ? `/${routes.slice(0, 4).join('/')}` : '/',
          target: '_self'
        });
      }
      if (hasExtra) {
        menuItems.push({
          label: hasExtra,
          disabled: true,
        });
      }
    } else {
      menuItems.push({
        label: 'Dynamic Reports',
        disabled: true,
      });

      if (this.templates) {
        for (const template of this.templates) {
          if (template.id === templateId) {
            menuItems.push({
              label: template.title,
              disabled: true,
            });
            for (const templateValue of template.values) {
              if (templateValue.id === templateValueId) {
                menuItems.push({
                  label: templateValue.title as string,
                  disabled: hasExtra ? false : true,
                  url: hasExtra ? `/${routes.slice(0, 4).join('/')}` : '/',
                  target: '_self',
                });
                break;
              }
            }
            break;
          }
        }

        if (hasExtra) {
          menuItems.push({
            label: hasExtra,
            disabled: true,
          });
        }
      }
    }

    return menuItems;
  }
}
