import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as LayoutActions from '../actions/layout.actions';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { CachingService } from '../../services/caching.service';

@Injectable()
export class LayoutEffects {
  redirectRoute: string;
  
  changeLanguage = createEffect(() => this.actions$.pipe(
    ofType(LayoutActions.CHANGE_LANGUAGE),
    map((action: LayoutActions.ChangeLanguage) => {
      return action.payload;
    }),
    map((language: any) => {
      this.cachingService.setData('*language', language);
      this.redirectRoute = this.router.url;
      return new LayoutActions.SetLanguage(language);
    })
  ));

  constructor(private actions$: Actions<any>, private router: Router, private cachingService: CachingService) {}
}
