import { Action } from '@ngrx/store';
import { ConnectUsersToCampaignActionPayload } from 'abs@core/models/online-requests';

export const GET_ACTIVE_CAMPAIGNS = '[USERS] Get Active Campaigns';
export const SET_ACTIVE_CAMPAIGNS = '[USERS] Set Active Campaigns';

export const CONNECT_USERS_TO_CAMPAIGN = '[USERS] Connect Users To Campaign';
export const CONNECT_USERS_TO_CAMPAIGN_SUCCESS = '[USERS] Connect Users To Campaign Success';
export const CONNECT_USERS_TO_CAMPAIGN_FAILURE = '[USERS] Connect Users To Campaign Failure';

export class GetActiveCampaigns implements Action {
  readonly type = GET_ACTIVE_CAMPAIGNS;

  constructor(public payload: any = null) {}
}
export class SetActiveCampaigns implements Action {
  readonly type = SET_ACTIVE_CAMPAIGNS;

  constructor(public payload: any = null) {}
}
export class ConnectUsersToCampaign implements Action {
  readonly type = CONNECT_USERS_TO_CAMPAIGN;

  constructor(public payload: ConnectUsersToCampaignActionPayload = null) {}
}

export class ConnectUsersToCampaignSuccess implements Action {
  readonly type = CONNECT_USERS_TO_CAMPAIGN_SUCCESS;

  constructor(public payload: any = null) {}
}

export class ConnectUsersToCampaignFailure implements Action {
  readonly type = CONNECT_USERS_TO_CAMPAIGN_FAILURE;

  
}

export type Actions =
  | GetActiveCampaigns
  | SetActiveCampaigns
  | ConnectUsersToCampaign
  | ConnectUsersToCampaignSuccess
  | ConnectUsersToCampaignFailure;
