import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Margin } from '../../models';
import { OperationResult } from 'src/app/sport-betting/common/models';

export interface BetTypeMarginsState extends EntityState<Margin> {
  totalCount: number;
  operationResult?: OperationResult;
}
export const betTypeMarginsAdapter: EntityAdapter<Margin> = createEntityAdapter<Margin>({
  selectId: (item) => item.betTypeId,
});
export const betTypeMarginsInitialState: BetTypeMarginsState = betTypeMarginsAdapter.getInitialState({
  totalCount: 0,
  entities: null,
});