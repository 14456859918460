<div class="exception-body notfound">
    <div class="exception-panel text-center">
 <!--       <div class="exception-image">
            <img src="assets/layout/images/exception/icon-404.png">
        </div>-->
      <a [routerLink]="'/home'" class="logo mt-6 pt-6">
        <img [src]="'assets/layout/images/login/icon_' + logo  + '.png'" />
      </a>

        <div class="exception-detail">
            <h1>PAGE NOT FOUND</h1>
            <p class="my-0 mx-auto">Requested resource is not available.</p>
            <p-button label="Go back to home" [routerLink]="['/home']"></p-button>
        </div>
    </div>
</div>
