import { Component } from '@angular/core';
import { AuthorizationService } from 'abs@shared/authorization/authorization.service';
import { ePermissionsLevel, PermissionActionsCodes, PermissionsScreensCodes } from 'abs@core/app.config';

@Component({
  templateUrl: './dashboard.component.html',
})
export class DashboardComponent {
  permissionScreen = PermissionsScreensCodes;
  permission = PermissionActionsCodes;
  permissionLevel = ePermissionsLevel;
  constructor(private authorizationService: AuthorizationService) { }

  checkPermission(permission, level = 1): boolean {
    return this.authorizationService.hasPermission(permission, level);
  }
}
