import { Action } from '@ngrx/store';
import { BatchManagement, FastGames, NewBatchData } from "../../models/models";

export const LOAD_BATCH_MANAGEMENT_DATA = '[Batch Management] Load Data';
export const LOAD_BATCH_MANAGEMENT_DATA_SUCCESS = '[Batch Management] Load Data Success';
export const LOAD_BATCH_MANAGEMENT_DATA_FAILURE = '[Batch Management] Load Data Failure';

export const UPDATE_BATCH_TABLE = '[Batch Management] Update Batch Status';
export const UPDATE_BATCH_STATUS_SUCCESS = '[Batch Management] Update Batch Status Success';
export const UPDATE_BATCH_STATUS_FAILURE = '[Batch Management] Update Batch Status Failure';

export const SAVE_BATCH_TEMPLATE = '[Batch Management] Save Batch Template';
export const SAVE_BATCH_TEMPLATE_SUCCESS = '[Batch Management] Save Batch Template Success';
export const SAVE_BATCH_TEMPLATE_FAILURE = '[Batch Management] Save Batch Template Failure';

//Game Management

export const LOAD_FAST_GAMES_MANAGEMENT_DATA = '[Fast Games Management] Load Data';
export const LOAD_FAST_GAMES_MANAGEMENT_DATA_SUCCESS = '[Fast Games Management] Load Data Success';
export const LOAD_FAST_GAMES_MANAGEMENT_DATA_FAILURE = '[Fast Games Management] Load Data Failure';

export const UPDATE_FAST_GAMES_TABLE = '[Fast Games Management] Update Fast Games Status';
export const UPDATE_FAST_GAMES_STATUS_SUCCESS = '[Fast Games Management] Update Fast Games Status Success';
export const UPDATE_FAST_GAMES_STATUS_FAILURE = '[Fast Games Management] Update Fast Games Status Failure';




export class loadBatchManagementData implements Action {
  public readonly type = LOAD_BATCH_MANAGEMENT_DATA;
  constructor(public payload: any = null) {
  }
}

export class loadBatchManagementDataSuccess implements Action {
  public readonly type = LOAD_BATCH_MANAGEMENT_DATA_SUCCESS;

  constructor(public payload: BatchManagement[]) {}
}

export class loadBatchManagementDataFailure implements Action {
  public readonly type = LOAD_BATCH_MANAGEMENT_DATA_FAILURE;

  constructor(public payload: { error: any }) {}
}

//UpdateBatch
export class UpdateBatchTable implements Action {
  public readonly type = UPDATE_BATCH_TABLE;
  constructor(public payload: NewBatchData) {
  }
}
export class UpdateBatchStatusSuccess implements Action {
  public readonly type = UPDATE_BATCH_STATUS_SUCCESS;
  constructor(public payload: BatchManagement[]) {}
}
export class UpdateBatchStatusFailure implements Action {
  public readonly type = UPDATE_BATCH_STATUS_FAILURE;
  public payload: any;
  constructor(public error: any) {}
}

//SaveBatch

export class SaveBatchTemplate implements Action {
  public readonly type = SAVE_BATCH_TEMPLATE;

  constructor(public payload: any,  public skipSuccesMsg: boolean = false) {
  }
}
export class SaveBatchTemplateSuccess implements Action {
  public readonly type = SAVE_BATCH_TEMPLATE_SUCCESS;

  constructor(public payload: any) {
  }
}
export class SaveBatchTemplateFailure implements Action {
  public readonly type = SAVE_BATCH_TEMPLATE_FAILURE;

  constructor(public payload: any) {
  }
}

//Game Management Fast Games

export class loadFastGamesData implements Action {
  public readonly type = LOAD_FAST_GAMES_MANAGEMENT_DATA;
  constructor(public payload: any = null) {}
}

export class loadFastGamesDataSuccess implements Action {
  public readonly type = LOAD_FAST_GAMES_MANAGEMENT_DATA_SUCCESS;
  constructor(public payload: FastGames[]) {}
}

export class loadFastGamesDataFailure implements Action {
  public readonly type = LOAD_FAST_GAMES_MANAGEMENT_DATA_FAILURE;
  constructor(public payload: { error: any }) {}
}

export class UpdateFastGamesTable implements Action {
  public readonly type = UPDATE_FAST_GAMES_TABLE;
  constructor(public payload: any) {}
}

export class UpdateFastGamesStatusSuccess implements Action {
  public readonly type = UPDATE_FAST_GAMES_STATUS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateFastGamesStatusFailure implements Action {
  public readonly type = UPDATE_FAST_GAMES_STATUS_FAILURE;
  public payload: any;
  constructor(public error: any) {}
}

export type Actions =
  | loadBatchManagementData
  | loadBatchManagementDataSuccess
  | loadBatchManagementDataFailure
  | UpdateBatchTable
  | UpdateBatchStatusSuccess
  | UpdateBatchStatusFailure
  | SaveBatchTemplate
  | SaveBatchTemplateSuccess
  | SaveBatchTemplateFailure
  | loadFastGamesData
  | loadFastGamesDataSuccess
  | loadFastGamesDataFailure
  | UpdateFastGamesTable
  | UpdateFastGamesStatusSuccess
  | UpdateFastGamesStatusFailure;
