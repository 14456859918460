import { Action } from '@ngrx/store';

export const GET_BETSLIPS_DATA = '[BETSLIPS] Get Betslips Data';
export const SET_BETSLIPS_DATA = '[BETSLIPS] Set Betslips Data';

export const CLEAR_BETSLIPS_DATA = '[BETSLIPS] Clear Betslips Data';

export const EXPORT_ALL_DATA_TO_EXCEL = '[BETSLIPS] Export all data to excel';
export const EXPORT_ALL_DATA_TO_EXCEL_SUCCESS = '[BETSLIPS] Export all data to excel success';

export const FAILURE = '[BETSLIPS] Failure';

export const SET_FAILURE = '[BETSLIPS] Set Failure';

export class ExportAllDataToExcel implements Action {
  readonly type = EXPORT_ALL_DATA_TO_EXCEL;

  constructor(public payload: any) {}
}
export class ExportAllDataToExcelSuccess implements Action {
  readonly type = EXPORT_ALL_DATA_TO_EXCEL_SUCCESS;

  
}
export class GetBetslipsData implements Action {
  readonly type = GET_BETSLIPS_DATA;

  constructor(public payload: any) {}
}
export class SetBetslipsData implements Action {
  readonly type = SET_BETSLIPS_DATA;

  constructor(public payload: any) {}
}
export class ClearBetslipsData implements Action {
  readonly type = CLEAR_BETSLIPS_DATA;

  
}
export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions =
  | GetBetslipsData
  | SetBetslipsData
  | ClearBetslipsData
  | ExportAllDataToExcel
  | ExportAllDataToExcelSuccess
  | Failure
  | SetFailure;
