import { Action } from '@ngrx/store';
import { Authenticate, UserData } from '../../models/user';
import { BaseResponse } from '../../../core/models/response';

export const GET_USER_BY_TOKEN = '[Auth] Get User By Token';
export const LOGIN = '[Auth] Login';
export const LOGOUT_SUCCESS = '[Auth] Logout Success';
export const LOGOUT_FAILED = '[Auth] Logout Failed';
export const LOGOUT = '[Auth]  Logout';
export const LOGIN_SUCCESS = '[Auth] Login Success';
export const LOGIN_FAILURE = '[Auth] Login Failure';
export const ROUTING_SUCCESS = '[Auth] Routing success';
export const KEEP_ALIVE = '[Auth]  Keep Alive';
export const KEEP_ALIVE_SUCCESS = '[Auth] Keep Alive Success';
export const KEEP_ALIVE_FAILURE = '[Auth] Keep Alive Failure';

export const REFRESH_USER_SETTINGS = '[Auth] Refresh user settings';
export const REFRESH_USER_SETTINGS_SUCCESS = '[Auth] Refresh user settings Success';
export const REFRESH_USER_SETTINGS_FAILURE = '[Auth] Refresh user settings Failure';

export const SET_PERMISSIONS = '[Auth] Set Permissions';

export const SET_FAILURE = '[Auth] Set Failure';

export class Login implements Action {
  readonly type = LOGIN;

  constructor(public payload: Authenticate) {}
}

export class GetUserByToken implements Action {
  readonly type = GET_USER_BY_TOKEN;

  constructor(public payload: any, public redirectRoute: any) {}
}

export class LoginSuccess implements Action {
  readonly type = LOGIN_SUCCESS;

  constructor(public payload: UserData) {}
}

export class LoginFailure implements Action {
  readonly type = LOGIN_FAILURE;

  constructor(public payload: BaseResponse) {}
}

export class Logout implements Action {
  readonly type = LOGOUT;
}

export class LogoutSuccess implements Action {
  readonly type = LOGOUT_SUCCESS;
}
export class RoutingSuccess implements Action {
  readonly type = ROUTING_SUCCESS;
}
export class LogoutFailed implements Action {
  readonly type = LOGOUT_FAILED;
}

export class KeepAlive implements Action {
  readonly type = KEEP_ALIVE;
}
export class KeepAliveSuccess implements Action {
  readonly type = KEEP_ALIVE_SUCCESS;
  constructor(public payload: any = null) {}
}
export class KeepAliveFailure implements Action {
  readonly type = KEEP_ALIVE_FAILURE;
}

export class RefreshUserSettings implements Action {
  readonly type = REFRESH_USER_SETTINGS;
}
export class RefreshUserSettingsSuccess implements Action {
  readonly type = REFRESH_USER_SETTINGS_SUCCESS;
  constructor(public payload: any = null) {}
}
export class RefreshUserSettingsFailure implements Action {
  readonly type = REFRESH_USER_SETTINGS_FAILURE;
  constructor(public payload: any = null) {}
}
export class SetPermissions implements Action {
  readonly type = SET_PERMISSIONS;
  constructor(public payload: any = null) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions =
  | Login
  | LoginSuccess
  | LoginFailure
  | Logout
  | LogoutSuccess
  | LogoutFailed
  | GetUserByToken
  | KeepAlive
  | SetFailure
  | KeepAliveSuccess
  | RefreshUserSettings
  | RefreshUserSettingsSuccess
  | RefreshUserSettingsFailure
  | SetPermissions
  | RoutingSuccess
  | KeepAliveFailure;
