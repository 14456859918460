import { NgModule } from '@angular/core';
import { LoginPageComponent } from './containers/login-page.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { LoginFormComponent } from './components/login-form/login-form.component';
import { AuthRoutes } from './auth.routes';
import { AuthComponent } from './auth.component';
import { TranslateModule } from '@ngx-translate/core';
import { StoreModule } from '@ngrx/store';
import { AuthReducer } from './store/reducers/auth';
import { EffectsModule } from '@ngrx/effects';
import { AuthEffects } from './store/effects/auth.effects';
// import {AuthGuardService} from './guards/auth-guard.service';
import { ReducersConstants } from '../core/app.config';
import { ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
@NgModule({
  declarations: [LoginPageComponent, LoginFormComponent, AuthComponent],
  imports: [
    ProgressSpinnerModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    AuthRoutes,
    TranslateModule,
    InputTextModule,
    ButtonModule,
    StoreModule.forFeature(ReducersConstants.AUTH, AuthReducer),
    EffectsModule.forRoot([AuthEffects]),
    ToastModule,
  ],
})
export class AuthModule {}
