import { Action } from '@ngrx/store';

export const GET_SEO_PAGES_URLS = '[CMS] Get SEO pages urls';
export const SET_SEO_PAGES_URLS = '[CMS] Set SEO pages urls';
export const RESET_PAGES_URL = '[CMS] Reset pages urls';

export const FAILURE = '[CMS] Failure';
export const SET_FAILURE = '[CMS] Set Failure';

/////// SHARED WITH MENU MANAGEMENT PAGE ////////////
export const CMS_MENU_UPLOAD_FILE = '[CMS-SEO/Menu Management] Upload SVG icon';
export const SET_MENU_ICON = '[CMS-SEO/Menu Management] Set SVG icon url and key';
export const CMS_MENU_UPLOAD_FILE_SUCCESS = '[CMS-SEO/Menu Management] Upload SVG icon success';
export const CMS_MENU_ICON_RESET = '[CMS-SEO/Menu Management] Rest CMS menu icon';
export const CMS_ADD_MENU_ITEM = '[CMS-SEO/Menu Management] CMS Add menu item';
export const CMS_ADD_MENU_ITEM_SUCCESS = '[CMS-SEO/Menu Management] CMS Add menu item success';
export const CMS_ADD_MENU_ITEM_RESET = '[CMS-SEO/Menu Management] CMS Add menu item reset';
export const CMS_SEO_GET_MENU_LIST = '[CMS-SEO Management] CMS Get menu list';
export const CMS_SEO_SET_MENU_LIST = '[CMS-SEO Management] CMS Set menu list';

export const SEO_DELETE_MENU_ITEM_ACTION = '[SEO Management] Delete menu item';

export class SEODeleteMenuItem implements Action {
  readonly type = SEO_DELETE_MENU_ITEM_ACTION;

  constructor(public payload: any = null) {}
}

export class GetSEOPagesUrls implements Action {
  readonly type = GET_SEO_PAGES_URLS;

  constructor(public payload: any = null) {}
}

export class SetSEOPagesUrls implements Action {
  readonly type = SET_SEO_PAGES_URLS;

  constructor(public payload: any = null) {}
}
export class ResetPagesUrl implements Action {
  readonly type = RESET_PAGES_URL;

  
}
export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export class ResetCMSPageIcon implements Action {
  readonly type = CMS_MENU_ICON_RESET;
}

export class UploadMenuSeoSvgIcon implements Action {
  readonly type = CMS_MENU_UPLOAD_FILE;

  constructor(public payload: any = null) {}
}

export class UploadMenuSeoSvgIconSuccess implements Action {
  readonly type = CMS_MENU_UPLOAD_FILE_SUCCESS;

  constructor(public payload: any = null) {}
}

export class CmsAddMenuItem implements Action {
  readonly type = CMS_ADD_MENU_ITEM;

  constructor(public payload: any = null) {}
}

export class CmsAddMenuItemSuccess implements Action {
  readonly type = CMS_ADD_MENU_ITEM_SUCCESS;

  constructor(public payload: any = null) {}
}

export class CmsAddMenuItemReset implements Action {
  readonly type = CMS_ADD_MENU_ITEM_RESET;
}

export class CmsSeoGetMenuList implements Action {
  readonly type = CMS_SEO_GET_MENU_LIST;

  constructor(public payload: any = null) {}
}

export class CmsSeoSetMenuList implements Action {
  readonly type = CMS_SEO_SET_MENU_LIST;

  constructor(public payload: any = null) {}
}

export type Actions =
  | GetSEOPagesUrls
  | SetSEOPagesUrls
  | ResetPagesUrl
  | Failure
  | SetFailure
  | UploadMenuSeoSvgIcon
  | UploadMenuSeoSvgIconSuccess
  | ResetCMSPageIcon
  | CmsAddMenuItem
  | CmsAddMenuItemSuccess
  | CmsAddMenuItemReset
  | CmsSeoGetMenuList
  | CmsSeoSetMenuList
  | SEODeleteMenuItem;
