import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BetTypes } from '../../models';
import { OperationResult } from 'src/app/sport-betting/common/models';

export interface BetTypesState extends EntityState<BetTypes> {
  totalCount: number;
  operationResult?: OperationResult;
}
export const betTypesAdapter: EntityAdapter<BetTypes> = createEntityAdapter<BetTypes>({
  selectId: (item) => item.id,
});
export const betTypesInitialState: BetTypesState = betTypesAdapter.getInitialState({
  totalCount: 0,
  entities: null,
});