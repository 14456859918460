import { Action } from '@ngrx/store';
import { ExpenseCategory } from '../../models/expenseCategory';

export const GET_EXPENSE_ITEMS = '[EXPENESES] Get Expense items';
export const GET_EXPENSE_ITEMS_FAILURE = '[EXPENESES] Get Expense items Failure';
export const GET_EXPENSE_CATEGORIES = '[EXPENESES] Get Expense Categories';
export const GET_EXPENSE_CATEGORIES_FAILURE = '[EXPENESES] Get Expense Categories Failure';
export const SET_EXPENSE_ITEMS = '[EXPENESES] Set Expense items';
export const SET_EXPENSE_CATEGORIES = '[EXPENESES] Set Expense Categories';

export const EDIT_EXPENSE_ITEM = '[EXPENSES] Edit Expense Item';
export const SAVE_EXPENSE_ITEM = '[EXPENSES] Save Expense Item';
export const SAVE_EXPENSE_ITEM_SUCCESS = '[EXPENSES] Save Expense Item Success';
export const SAVE_EXPENSE_ITEM_FAIL = '[EXPENSES] Save Expense Item Fail';

export const EDIT_EXPENSE_CATEGORY = '[EXPENSES] Edit Category';
export const SAVE_EXPENSE_CATEGORY = '[EXPENSES] Save Category';
export const SAVE_EXPENSE_CATEGORY_SUCCESS = '[EXPENSES] Save Category Success';
export const SAVE_EXPENSE_CATEGORY_FAIL = '[EXPENSES] Save Category Fail';

export const EDIT_EXPENSE_MONTH = '[EXPENSES MONTHS] Edit Expense Month';
export const EDIT_EXPENSE_MONTH_SUCCESS = '[EXPENSES MONTHS] Edit Expense Month Success';
export const EDIT_EXPENSE_MONTH_FAIL = '[EXPENSES MONTHS] Edit Expense Month Fail';
export const SAVE_EXPENSE_MONTH = '[EXPENSES MONTHS] Save Expense Month';
export const SAVE_EXPENSE_MONTH_SUCCESS = '[EXPENSES MONTHS] Save Expense Month Success';
export const SAVE_EXPENSE_MONTH_FAIL = '[EXPENSES MONTHS] Save Expense Month Fail';

export const GET_EXPENSE_MONTHS = '[EXPENSES MONTHS] Get Expense Months';
export const SET_EXPENSE_MONTHS = '[EXPENSES MONTHS] Set Expense Months';

export const FAILURE = '[EXPENESES] Failure';

export class GetExpenseItems implements Action {
  readonly type = GET_EXPENSE_ITEMS;

  constructor(public payload: any = null) {}
}
export class GetExpenseItemsFailure implements Action {
  readonly type = GET_EXPENSE_ITEMS_FAILURE;

  
}

export class SetExpenseItems implements Action {
  readonly type = SET_EXPENSE_ITEMS;

  constructor(public payload: any = null) {}
}

export class GetExpenseCategories implements Action {
  readonly type = GET_EXPENSE_CATEGORIES;

  constructor(public payload: any = null) {}
}
export class GetExpenseCategoriesFailure implements Action {
  readonly type = GET_EXPENSE_CATEGORIES_FAILURE;

  
}

export class SetExpenseCategories implements Action {
  readonly type = SET_EXPENSE_CATEGORIES;

  constructor(public payload: ExpenseCategory[] = null) {}
}

export class SaveExpenseItemFail implements Action {
  readonly type = SAVE_EXPENSE_ITEM_FAIL;

  constructor(public payload: any = null) {}
}

export class SaveExpenseItemSuccess implements Action {
  readonly type = SAVE_EXPENSE_ITEM_SUCCESS;

  constructor(public payload: any = null) {}
}

export class SaveExpenseCategoryFail implements Action {
  readonly type = SAVE_EXPENSE_CATEGORY_FAIL;

  constructor(public payload: any = null) {}
}

export class SaveExpenseCategorySuccess implements Action {
  readonly type = SAVE_EXPENSE_CATEGORY_SUCCESS;

  constructor(public payload: any = null) {}
}

export class SaveExpenseItem implements Action {
  readonly type = SAVE_EXPENSE_ITEM;

  constructor(public payload: any = null) {}
}

export class EditExpenseCategory implements Action {
  readonly type = EDIT_EXPENSE_CATEGORY;

  constructor(public payload: any = null) {}
}

export class SaveExpenseCategory implements Action {
  readonly type = SAVE_EXPENSE_CATEGORY;

  constructor(public payload: any = null) {}
}

export class EditExpenseItem implements Action {
  readonly type = EDIT_EXPENSE_ITEM;

  constructor(public payload: any = null) {}
}

export class GetExpenseMonths implements Action {
  readonly type = GET_EXPENSE_MONTHS;

  constructor(public payload: any = null) {}
}

export class SetExpenseMonths implements Action {
  readonly type = SET_EXPENSE_MONTHS;

  constructor(public payload: any = null) {}
}

export class EditExpenseMonth implements Action {
  readonly type = EDIT_EXPENSE_MONTH;

  constructor(public payload: any = null) {}
}

export class EditExpenseMonthSuccess implements Action {
  readonly type = EDIT_EXPENSE_MONTH_SUCCESS;

  constructor(public payload: any = null) {}
}

export class EditExpenseMonthFail implements Action {
  readonly type = EDIT_EXPENSE_MONTH_FAIL;

  constructor(public payload: any = null) {}
}

export class SaveMonthItem implements Action {
  readonly type = SAVE_EXPENSE_MONTH;

  constructor(public payload: any = null) {}
}

export class SaveExpenseMonthFail implements Action {
  readonly type = SAVE_EXPENSE_MONTH_FAIL;

  constructor(public payload: any = null) {}
}

export class SaveExpenseMonthSuccess implements Action {
  readonly type = SAVE_EXPENSE_MONTH_SUCCESS;

  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;

  
}

export type Actions =
  | GetExpenseItems
  | GetExpenseCategories
  | SetExpenseCategories
  | SetExpenseItems
  | GetExpenseCategoriesFailure
  | GetExpenseItemsFailure
  | EditExpenseCategory
  | EditExpenseItem
  | SaveExpenseItem
  | SaveExpenseCategory
  | SaveExpenseCategorySuccess
  | SaveExpenseCategoryFail
  | SaveExpenseItemSuccess
  | SaveExpenseItemFail
  | SaveMonthItem
  | SaveExpenseMonthFail
  | SaveExpenseMonthSuccess
  | EditExpenseMonth
  | EditExpenseMonthSuccess
  | EditExpenseMonthFail
  | GetExpenseMonths
  | SetExpenseMonths;
