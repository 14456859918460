import { createAction, props } from '@ngrx/store';
import { FailAction, Filtering } from 'src/app/sport-betting/common/models';
import { Currency, UpdateCurrency } from '../../models';

const SB_GET_CURRENCIES = '[SBCR] Get currencies';
const SB_SET_CURRENCIES = '[SBCR] Set currencies';
const SB_UPDATE_CURRENCY = '[SBCR] Update currency';
const SB_UPDATE_CURRENCY_SUCCESS = '[SBCR] Update currency SUCCESS';
const SB_UPDATE_DEFAULT_CURRENCY = '[SBCR] Update default currency';
const SB_UPDATE_DEFAULT_CURRENCY_SUCCESS = '[SBCR] Update default currency SUCCESS';

const FAILURE = '[SBCR] Failure';

export const SbGetCurrencies = createAction(SB_GET_CURRENCIES, props<{ payload: Filtering }>());
export const SbSetCurrencies = createAction(SB_SET_CURRENCIES, props<{
  payload: {
    data: Array<Currency>,
    totalCount: number;
  }
}>());
export const SbUpdateCurrency = createAction(SB_UPDATE_CURRENCY, props<{ payload: UpdateCurrency }>());
export const SbUpdateCurrencySuccess = createAction(SB_UPDATE_CURRENCY_SUCCESS, props<{ payload: UpdateCurrency }>());

export const SbUpdateDefaultCurrency = createAction(SB_UPDATE_DEFAULT_CURRENCY, props<{ payload: number }>());
export const SbUpdateDefaultCurrencySuccess = createAction(SB_UPDATE_DEFAULT_CURRENCY_SUCCESS, props<{ payload: number }>());

export const CurrenciesFailure = createAction(FAILURE, props<FailAction>());