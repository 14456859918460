import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CanActivate } from '@angular/router';
import { RouteConstants } from '../../core/app.config';
import { CachingService } from '../../core/services/caching.service';

@Injectable()
export class NonAuthGuardService implements CanActivate {
  constructor(
    private router: Router,
    private cachingService: CachingService
  ) { }

  canActivate(): boolean {
    const token = this.cachingService.getData('*token');
    if (token) {
      this.router.navigate([RouteConstants.DASHBOARD]);
      return false;
    } else {
      return true;
    }
  }
}
