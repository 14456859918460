import { EditedMenuItem, MenuItem } from '../../models/models';
import {
  ACTIVATE_MENU_ITEM,
  CANCEL_EDIT_MENU_ITEMS,
  CLEAR_MENU_ITEMS,
  DEACTIVATE_MENU_ITEM,
  FAILURE,
  GET_ACTION,
  REORDER_MENU_ITEM,
  SET_MENU_ITEMS,
  UPDATE_MENU_ITEM,
} from '../actions/menu-management.actions';
import * as fromApp from 'abs@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { DropDownBuilder } from 'abs@core/store/reducers/metadata.reducer';
import copy from 'fast-copy';
import { getPagesUrls } from '../../../cms/store/reducers/cms.reducer';
import { differenceWith, filter, orderBy, find } from 'lodash';

export interface State {
  menuItems: MenuItem[];
  clonedMenuItems: MenuItem[];
  editWasMade: boolean;
  maxMenuItems: number;
  loading: boolean;
  processing: boolean;
}
export const initialState: State = {
  menuItems: null,
  clonedMenuItems: null,
  maxMenuItems: null,
  editWasMade: false,
  loading: false,
  processing: false,
};
export function CmsMenuManagementReducer(state = initialState, action: any): State {
  switch (action.type) {
    case GET_ACTION: {
      return {
        ...state,
        loading: true,
        processing: true,
      };
    }
    case REORDER_MENU_ITEM: {
      return {
        ...state,
        menuItems: ReorderItem(
          copy(state.menuItems),
          action.payload.dragIndex,
          action.payload.dropIndex
        ),
        editWasMade: true,
      };
    }
    case ACTIVATE_MENU_ITEM: {
      return {
        ...state,
        menuItems: ActivateItem(copy(action.payload), copy(state.menuItems)),
        editWasMade: true,
      };
    }
    case DEACTIVATE_MENU_ITEM: {
      return {
        ...state,
        menuItems: DeActivateItem(copy(action.payload), copy(state.menuItems)),
        editWasMade: true,
      };
    }
    case UPDATE_MENU_ITEM: {
      return {
        ...state,
        menuItems: state.menuItems.map((item) => (item.id === action.payload.id ? action.payload : item)),
        editWasMade: true,
      };
    }
    case SET_MENU_ITEMS: {
      return {
        ...state,
        menuItems: action.payload.menues,
        clonedMenuItems: action.payload.menues,
        maxMenuItems:
          action.payload.limitActiveMenuItems != null && action.payload.limitActiveMenuItems != ''
            ? action.payload.limitActiveMenuItems
            : null,
        loading: false,
        processing: false,
        editWasMade: false,
      };
    }
    case CANCEL_EDIT_MENU_ITEMS: {
      return {
        ...state,
        menuItems: state.clonedMenuItems,
        editWasMade: false,
      };
    }
    case CLEAR_MENU_ITEMS: {
      return {
        ...state,
        menuItems: null,
        clonedMenuItems: null,
      };
    }
    case FAILURE: {
      return {
        ...state,
        processing: false,
      };
    }
    default: {
      return state;
    }
  }
}
export const getMenuList = (state: fromApp.AppState) => {
  return copy(state.cmsMenuManagementReducer.menuItems);
};
export const getMaxMenuItems = (state: fromApp.AppState) => state.cmsMenuManagementReducer.maxMenuItems;
export const getLoadingStatus = (state: fromApp.AppState) => state.cmsMenuManagementReducer.loading;
export const getProcessingStatus = (state: fromApp.AppState) => state.cmsMenuManagementReducer.processing;
export const getEditWasMadeState = (state: fromApp.AppState) => state.cmsMenuManagementReducer.editWasMade;

export const getMenuListProcessed = createSelector(getMenuList, (menus) => {
  if (menus) {
    const clonedMenus: MenuItem[] = copy(menus);
    clonedMenus.forEach((menu) => {
      menu.order = menu.isActive ? menu.order : null;
      menu.iconURLToShow = menu.iconURL ? menu.iconURL + '?decache=' + Math.random() : null;
      menu.activeFromForEdit = menu.activeFrom ? new Date(Date.parse(menu.activeFrom)) : null;
      menu.activeToForEdit = menu.activeTo ? new Date(Date.parse(menu.activeTo)) : null;
    });
    return clonedMenus;
  }
  return undefined;
});

export const prepareItemsToBeSentToServer = createSelector(
  getMenuList,
  getEditWasMadeState,
  (menuList, changesWereMade) => {
    if (changesWereMade && menuList) {
      const changes: EditedMenuItem[] = [];
      menuList.forEach((menu) => {
        const change: EditedMenuItem = { id: null, isActive: null, isDeleted: null };
        change.id = menu.id;
        change.languagesTitle = menu.languagesTitle;
        change.title = menu.title;
        change.iconURL = menu.iconURL;
        change.iconKey = menu.iconKey;
        change.order = menu.isActive ? menu.order : null;
        change.activeFrom = menu.activeFrom;
        change.activeTo = menu.activeTo;
        change.targetAttribute = menu.targetAttribute;
        change.iconLabel = menu.iconLabel;
        change.isDeleted = menu.wasDeletedByUser;
        change.isActive = menu.isActive;
        change.path = menu.path;
        change.itemKey = menu.itemKey;
        change.data = menu.data;
        change.auth = menu.auth;
        change.multilanguage = menu.multilanguage;
        changes.push(change);
      });
      return changes;
    } else {
      return null;
    }
  }
);
export const selectMenuManagementPageGuardData = createSelector(
  DropDownBuilder('eMenuConfiguration'),
  (menuConfigurationList) => {
    return { menuConfigurationList };
  }
);

export const selectOriginalSeoIdByRelatedMenuItemId = (relatedMenuItemId: number, seoId: number) =>
  createSelector(getPagesUrls, (pagesUrl: any) => {
    if (!relatedMenuItemId) return seoId;
    const filterPagesUrl = filter(pagesUrl, ['configurationId', 29]);
    const seoPage = find(filterPagesUrl, ['menuId', relatedMenuItemId]);
    return seoPage ? seoPage.seoId : null;
  });

//Do the difference between the 2 groups

const iter_equal = (pageUrl: any, menu: any) => {
  return pageUrl.menuId === menu.relatedMenuItemId;
};

export const getAvailablePagesToDuplicate = createSelector(getMenuList, getPagesUrls, (menus, pagesUrl: any) => {
  if (menus && pagesUrl != null && pagesUrl.length > 0) {
    return differenceWith(filter(pagesUrl, ['configurationId', 29]), menus, iter_equal);
  }
  return undefined;
});
function ReorderItem(menuList: MenuItem[], dragI, dropI): MenuItem[] {
  let activeMenus = menuList.filter((menu) => menu.isActive);
  activeMenus = orderBy(activeMenus, ['order'], ['asc']);
  const activeMenusLength = activeMenus.length;
  if (dragI == 0) {
    for (let i = 1; i <= dropI; i++) {
      activeMenus[i].order--;
    }
  } else if (dragI == activeMenusLength - 1) {
    for (let i = dropI; i < activeMenusLength; i++) {
      activeMenus[i].order++;
    }
  } else {
    // in the middle
    if (dropI < dragI) {
      for (let i = dropI; i <= dragI; i++) {
        activeMenus[i].order++;
      }
    } else {
      // dropI > dragI
      for (let i = dragI; i <= dropI; i++) {
        activeMenus[i].order--;
      }
    }
  }
  activeMenus[dragI].order = dropI;
  activeMenus.map((menu) => {
    console.log(menu.order, menu.title);
  });
  for (let i = 0; i < menuList.length; i++) {
    if (menuList[i].isActive) {
      menuList[i] = activeMenus.find((activeMenu) => activeMenu.id == menuList[i].id);
    }
  }
  return menuList;
}
function ActivateItem(menuItemToActivate: MenuItem, menuList: MenuItem[]): MenuItem[] {
  const indexToUpdate = menuList.findIndex((menu) => menu.id == menuItemToActivate.id);
  menuList[indexToUpdate].order = menuList.filter((menu) => menu.isActive).length;
  menuList[indexToUpdate].isActive = true;
  return menuList;
}
function DeActivateItem(menuItemToDeActivate: MenuItem, menuList: MenuItem[]): MenuItem[] {
  let activeMenus = menuList.filter((menu) => menu.isActive);
  activeMenus = orderBy(activeMenus, ['order'], ['asc']);
  const indexToDeactivate = activeMenus.findIndex((menu) => menu.id == menuItemToDeActivate.id);
  const orderToDeactivate = menuItemToDeActivate.order;
  const activeMenusLength = activeMenus.length;

  if (activeMenusLength > 1) {
    if (orderToDeactivate == 0) {
      for (let i = 1; i < activeMenusLength; i++) {
        activeMenus[i].order--;
      }
    } else if (orderToDeactivate == activeMenusLength - 1) {
      // do nothing
    } else {
      // in the middle
      for (let i = orderToDeactivate; i < activeMenusLength; i++) {
        activeMenus[i].order--;
      }
    }
  }

  activeMenus[indexToDeactivate].order = null;
  activeMenus[indexToDeactivate].isActive = false;

  for (let i = 0; i < menuList.length; i++) {
    if (menuList[i].isActive) {
      menuList[i] = activeMenus.find((activeMenu) => activeMenu.id == menuList[i].id);
    }
  }
  return menuList;
  // return activeMenus;
}
