import * as metadata from '../actions/metadata.actions';
import { BaseEntity } from 'abs@shared/models/user';
import { isNullOrUndefined } from 'abs@shared/shared-functions/functions';

import * as fromApp from './index';
import { createSelector } from '@ngrx/store';
import { TimeZoneModel } from '../../models/timeZone.model';
import timezones from 'timezones-list';
import {
  AutoCompleteUser,
  BaseIdName,
  BetType,
  BonusByType,
  MarketingCampaign,
  ActiveCampaign,
} from '../../models/coreModels';
import { CLEAR_CACHE } from '../actions/metadata.actions';
import { SportsTree, Team } from '../../../events/models/events.metadata.models';
import { getSportsTreeFilter } from '../../../events/store/reducers/events.reducer';
import { Channel } from 'abs@shared/models/channel';
import copy from 'fast-copy';

export interface State {
  templatesList: any;
  branches: BaseEntity[];
  betTypes: BetType[];
  currencies: BaseEntity;
  bonusesByType: BonusByType[];
  bonusesByTypeLoaded: boolean;
  enums: {
    enumType: string;
    name: string;
    value: number;
  }[];
  enumsLoading: boolean;
  onlineBranchesLoading: boolean;
  onlineBranches: BaseEntity[];
  filtersByBrandSettingsLoading: boolean;
  filtersByBrandSettings: Channel[];
  timeZones: TimeZoneModel[];
  selectedTimeZone: string;

  sportsTree: SportsTree;
  teams: Team[];
  sportsTreeLoaded: boolean;
  sportsTreeLoading: boolean;
  marketingCampaigns: MarketingCampaign[];
  exportedData: any;
  exportingData: boolean;
  usersAutoCompleteResults: AutoCompleteUser[];

  paymentMethods: BaseIdName[];
  paymentProviders: BaseIdName[];
  activeCampaignsPerUser: ActiveCampaign[];
  generalProcessing: boolean;

  icomoonIcons: any;
}

export const initialState: State = {
  templatesList: null,
  branches: null,
  betTypes: null,
  currencies: null,
  bonusesByType: null,
  bonusesByTypeLoaded: false,
  enums: null,
  enumsLoading: false,
  onlineBranchesLoading: false,
  onlineBranches: null,
  filtersByBrandSettingsLoading: false,
  filtersByBrandSettings: null,
  timeZones: timezones,
  selectedTimeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  marketingCampaigns: null,
  sportsTree: null,
  teams: null,
  sportsTreeLoaded: false,
  sportsTreeLoading: false,
  exportedData: null,
  exportingData: false,
  usersAutoCompleteResults: null,
  paymentMethods: null,
  paymentProviders: null,
  activeCampaignsPerUser: null,
  generalProcessing: false,
  icomoonIcons: null,
};

export function MetaDataReducer(state = initialState, action: metadata.Actions): State {
  switch (action.type) {
    case metadata.GENERAL_POST_ACTION: {
      return {
        ...state,
        generalProcessing: true,
      };
    }
    case metadata.GENERAL_POST_ACTION_COMPLETED: {
      return {
        ...state,
        generalProcessing: false,
      };
    }
    case metadata.SET_ACTIVE_CAMPAIGNS_PER_USER: {
      return {
        ...state,
        activeCampaignsPerUser: action.payload.campaigns,
      };
    }
    case metadata.CLEAR_ACTIVE_CAMPAIGNS_PER_USER: {
      return {
        ...state,
        activeCampaignsPerUser: null,
      };
    }
    case metadata.SET_USERS_AUTOCOMPLETE: {
      return {
        ...state,
        usersAutoCompleteResults: action.payload,
      };
    }
    case metadata.SET_PAYMENT_METHODS: {
      return {
        ...state,
        paymentMethods:
          action.payload.paymentMethods != null && action.payload.paymentMethods.length > 0
            ? action.payload.paymentMethods
            : null,
        paymentProviders:
          action.payload.paymentProviders != null && action.payload.paymentProviders.length > 0
            ? action.payload.paymentProviders
            : null,
      };
    }
    case metadata.EXPORT_ALL_DATA_TO_EXCEL: {
      return {
        ...state,
        exportingData: true,
      };
    }
    case metadata.EXPORT_ALL_DATA_TO_EXCEL_SUCCESS: {
      return {
        ...state,
        exportingData: false,
        exportedData: action.payload,
      };
    }
    case metadata.EXPORT_ALL_DATA_TO_EXCEL_FAILURE: {
      return {
        ...state,
        exportingData: false,
      };
    }
    case metadata.CLEAR_EXPORTED_DATA: {
      return {
        ...state,
        exportingData: false,
        exportedData: null,
      };
    }
    case metadata.SET_TEMPLATES: {
      return {
        ...state,
        templatesList: action.payload.templates,
        // loading: false
      };
    }
    case metadata.SELECT_TIMEZONE: {
      return {
        ...state,
        selectedTimeZone: action.payload,
      };
    }
    case metadata.SET_BRANCHES: {
      return {
        ...state,
        branches: action.payload.branches,
      };
    }
    case metadata.SET_BET_TYPES: {
      return {
        ...state,
        betTypes: action.payload.betTypes,
      };
    }
    case metadata.SET_MARKETING_CAMPAIGNS: {
      return {
        ...state,
        marketingCampaigns: action.payload,
      };
    }
    case metadata.SET_ENUMS: {
      return {
        ...state,
        enums: action.payload,
        enumsLoading: false,
      };
    }
    case metadata.GET_ENUMS: {
      return {
        ...state,
        enumsLoading: true,
      };
    }
    case metadata.SET_ONLINE_BRANCHS: {
      return {
        ...state,
        onlineBranches: action.payload,
        onlineBranchesLoading: false,
      };
    }
    case metadata.GET_ONLINE_BRANCHS: {
      return {
        ...state,
        onlineBranchesLoading: true,
      };
    }
    case metadata.SET_FILTERS_FOR_BRAND_SETTINGS: {
      return {
        ...state,
        filtersByBrandSettings: action.payload,
        filtersByBrandSettingsLoading: false,
      };
    }
    case metadata.GET_FILTERS_FOR_BRAND_SETTINGS: {
      return {
        ...state,
        filtersByBrandSettingsLoading: true,
      };
    }
    case metadata.GET_SPORTS_TREE: {
      return {
        ...state,
        sportsTreeLoading: true,
        sportsTreeLoaded: false,
      };
    }
    case metadata.SET_SPORTS_TREE: {
      return {
        ...state,
        sportsTreeLoading: false,
        sportsTreeLoaded: true,
        sportsTree: action.payload,
        teams: null,
      };
    }
    case metadata.SET_TEAMS_BY_LEAGUES: {
      return {
        ...state,
        sportsTreeLoading: false,
        teams: action.payload,
      };
    }
    case metadata.CLEAR_TEAMS: {
      return {
        ...state,
        teams: null,
      };
    }
    case CLEAR_CACHE: {
      return {
        ...state,
        sportsTreeLoaded: false,
        sportsTree: null,
        teams: null,
        betTypes: null,
      };
    }
    case metadata.SET_FAILURE: {
      return {
        ...state,
        sportsTreeLoaded: false,
      };
    }
    case metadata.SET_ICOMOON_ICONS: {
      return {
        ...state,
        icomoonIcons: action.payload,
      };
    }
    case metadata.GET_BONUSES_BY_TYPE: {
      return {
        ...state,
        bonusesByType: null,
        bonusesByTypeLoaded: false,
      };
    }
    case metadata.SET_BONUSES_BY_TYPE: {
      return {
        ...state,
        bonusesByType: action.payload,
        bonusesByTypeLoaded: true,
      };
    }

    default: {
      return state;
    }
  }
}
export const getGeneralProcessingState = (state: fromApp.AppState) => {
  return state.metadataReducer.generalProcessing;
};
export const getExportingAllInProcess = (state: fromApp.AppState) => {
  return state.metadataReducer.exportingData;
};
export const getUsersAutocompleteResults = (state: fromApp.AppState) => {
  return state.metadataReducer.usersAutoCompleteResults;
};
export const getTemplatesPage = (state: fromApp.AppState) => {
  return copy(state.metadataReducer.templatesList);
};
export const getTimeZonesSelector = (state: fromApp.AppState) => state.metadataReducer.timeZones;
export const getSelectedTimeZoneSelector = (state: fromApp.AppState) => state.metadataReducer.selectedTimeZone;
export const getBranches = (state: fromApp.AppState) => state.metadataReducer.branches;
export const getPaymentMethods = (state: fromApp.AppState) => state.metadataReducer.paymentMethods;
export const getPaymentProviders = (state: fromApp.AppState) => state.metadataReducer.paymentProviders;
export const getActiveCampaignsPerUser = (state: fromApp.AppState) => state.metadataReducer.activeCampaignsPerUser;

export const isEnumsMetadataReady = (state: fromApp.AppState) => {
  const isReady = !isNullOrUndefined(state.metadataReducer.enums);
  return isReady;
};
export const getEnumsMetadata = (state: fromApp.AppState) => {
  // if (state.metadataReducer.enums) {
  return state.metadataReducer.enums;
  //}
};

export const getEnumsLoadingStatus = (state: fromApp.AppState) => {
  return state.metadataReducer.enumsLoading;
};
export const getOnlineBranchesLoadingStatus = (state: fromApp.AppState) => {
  return state.metadataReducer.onlineBranchesLoading;
};
export const getOnlineBranches = (state: fromApp.AppState) => {
  return state.metadataReducer.onlineBranches;
};
export const getIcomoonIcons = (state: fromApp.AppState) => {
  return state.metadataReducer.icomoonIcons;
};
export const getFiltersByBrandSettingLoadingStatus = (state: fromApp.AppState) => {
  return state.metadataReducer.filtersByBrandSettingsLoading;
};
export const getFiltersByBrandSettings = (state: fromApp.AppState) => {
  return state.metadataReducer.filtersByBrandSettings;
};
export const getBetTypes = (state: fromApp.AppState) => state.metadataReducer.betTypes;

export const getMarketingCampaigns = (state: fromApp.AppState) => state.metadataReducer.marketingCampaigns;
export const getActiveCampaignsPerUserDropdown = createSelector(
  getActiveCampaignsPerUser,
  (campaigns: ActiveCampaign[]) => {
    if (campaigns) {
      const copyCampaigns = copy(campaigns);
      return copyCampaigns.map((c: ActiveCampaign) => {
        c.label = c.name + ' - [' + c.category + '] (' + c.id + ')';
        c.value = c.id;
        return c;
      });
    }
    return null;
  }
);
export const getBranchesConverted = createSelector(getBranches, (branches) => {
  if (branches) {
    const mapObject = new Map();
    branches.forEach((branch) => {
      mapObject.set(branch.id, branch);
    });
    if (!(mapObject.has(-1) && mapObject.has(-2))) {
      mapObject.set(-1, { id: -1, name: 'All' });
      mapObject.set(-2, { id: -1, name: 'All except Online' });
    }
    return mapObject;
  }
  return null;
});
export const getOnlineBranchesConverted = createSelector(getOnlineBranches, (branches) => {
  if (branches) {
    const mapObject = new Map();
    branches.forEach((branch) => {
      mapObject.set(branch.id, branch);
    });
    if (!(mapObject.has(-1) && mapObject.has(-2))) {
      mapObject.set(-1, { id: -1, name: 'All' });
      mapObject.set(-2, { id: -1, name: 'All except Online' });
    }
    return mapObject;
  }
  return null;
});
export const getBranchById = (id) =>
  createSelector(getBranchesConverted, (branchesMap) => {
    if (branchesMap.size > 0) {
      return branchesMap.get(id).name;
    }
  });
export const getOnlineBranchById = (id) =>
  createSelector(getOnlineBranchesConverted, (branchesMap) => {
    if (branchesMap.size > 0) {
      return branchesMap.get(id).name.split(' ')[0];
    }
  });
export const getBetTypesConverted = createSelector(getBetTypes, (betTypes) => {
  if (betTypes) {
    const mapObject = new Map();
    betTypes.forEach((betType) => {
      mapObject.set(betType.id, betType);
    });
    return mapObject;
  }
  return null;
});
export const getBetTypesById = (id) =>
  createSelector(getBetTypesConverted, (betTypesMap) => {
    if (betTypesMap.size > 0) {
      return betTypesMap.get(id);
    }
  });

export const getMetadataResolverSelector = createSelector(
  getEnumsMetadata,
  isEnumsMetadataReady,
  getEnumsLoadingStatus,
  (metadataValues, isReady, loading) => {
    return { metadataValues, isReady, loading };
  }
);
export const getSelectedTimeZone = createSelector(
  getTimeZonesSelector,
  getSelectedTimeZoneSelector,
  (timeZones: TimeZoneModel[], selectedTimeZone: any) => {
    if (timeZones && selectedTimeZone) {
      return timeZones.find((timeZone) => {
        return timeZone.tzCode === selectedTimeZone;
      });
    }
    return null;
  }
);
export const getMarketingCampaignDropDown = createSelector(getMarketingCampaigns, (marketingCampaignList) => {
  if (marketingCampaignList) {
    return marketingCampaignList.map((object) => {
      return {
        label: object.campaignName,
        value: object.campaignName,
      };
    });
  }
  return null;
});
export const getTimeZonesDropDown = createSelector(getTimeZonesSelector, (timeZones: TimeZoneModel[]) => {
  if (timeZones) {
    return timeZones.map((timeZone) => {
      return {
        label: timeZone.label,
        value: timeZone,
      };
    });
  }
  return null;
});
export const getOnlineBranchesDropDown = createSelector(getOnlineBranches, (onlineBranchesList: any) => {
  if (onlineBranchesList) {
    return onlineBranchesList.map((branch) => {
      return {
        label: branch.name,
        value: branch.id,
      };
    });
  }
  return undefined;
});

export const getEventPayoutMethodEnum = createSelector(getEnumsMetadata, (enumsList: any) => {
  if (enumsList) {
    return enumsList.filter((eNum) => eNum.enumType === 'ePayoutMethod');
  }
  return undefined;
});

export const getPayoutMethodStatus = (payoutMethod: number) =>
  createSelector(getEventPayoutMethodEnum, () => {
    if (payoutMethod === 0) {
      return 'Cash';
    } else if (payoutMethod === 1) {
      return 'FreeBet';
    }
    return null;
  });

export const getEventWinStatusEnum = createSelector(getEnumsMetadata, (enumsList: any) => {
  if (enumsList) {
    return enumsList.filter((eNum) => eNum.enumType === 'eWinStatus');
  }
});
export const getEventWinStatusNameById = (id: number) =>
  createSelector(getEventWinStatusEnum, (enumsList: any) => {
    return enumsList.find((enuM) => enuM.value === id)?.name;
  });
export const getEventWinStatusById = (id: number) =>
  createSelector(getEventWinStatusEnum, (enumsList: any) => {
    return enumsList.find((enuM) => enuM.value === id);
  });

export const getOrderStatusAndWinStatus = (winStatusId: number, orderStatusId: number) =>
  createSelector(getEventWinStatusEnum, (winStatusList) => {
    if (winStatusId === 2 && orderStatusId === 3) {
      //win and sold= won not paid
      return 'Won - Not Paid';
    } else if (winStatusId === 2 && orderStatusId === 4) {
      return 'Won - Paid';
    } else if (winStatusId === 3 && orderStatusId === 3) {
      //win and sold= won not paid
      return 'Refund - Not Paid';
    } else if (winStatusId === 3 && orderStatusId === 4) {
      return 'Refund - Paid';
    } else if (winStatusId === 6 && orderStatusId === 3) {
      //win and sold= won not paid
      return 'Cashed Out - Not Paid';
    } else if (winStatusId === 6 && orderStatusId === 4) {
      return 'Cashed Out - Paid';
    } else if (orderStatusId === 6) {
      //canceled
      return 'Canceled';
    } else {
      // return winStatusList.find(winStatus => winStatus.value === winStatusId).name + ' ' + orderStatusList.find(orderStatus => orderStatus.value === orderStatusId).name;
      return winStatusList.find((winStatus) => winStatus.value === winStatusId)?.name;
    }
  });

export const getEventWinStatusManageBetslipFromEnum = createSelector(getEnumsMetadata, (enumsList: any) => {
  if (enumsList) {
    return enumsList.filter((eNum) => eNum.enumType === 'eWinStatusManageBetslipFrom');
  }
});

export const getEventWinStatusDropDown = createSelector(getEventWinStatusEnum, (eventsEnums) => {
  if (eventsEnums && eventsEnums.length > 0) {
    return eventsEnums
      .filter((event) => event.value !== 6)
      .map((event) => {
        return {
          label: event.name,
          value: event.value,
        };
      });
  }
});
export const getEventWinStatusCompleteListDropDown = createSelector(getEventWinStatusEnum, (eventsEnums) => {
  if (eventsEnums && eventsEnums.length > 0) {
    return eventsEnums.map((event) => {
      return {
        label: event.name,
        value: event.value,
      };
    });
  }
});
export const getEventWinStatusBetslipFromDropDown = createSelector(
  getEventWinStatusManageBetslipFromEnum,
  (eventsEnums) => {
    if (eventsEnums && eventsEnums.length > 0) {
      return eventsEnums
        .filter((event) => event.value !== -1)
        .map((event) => {
          return {
            label: event.name,
            value: event.value,
          };
        });
    }
  }
);

export const getCurrenciesEnum = createSelector(getEnumsMetadata, (enumsList: any) => {
  if (enumsList) {
    return enumsList.filter((eNum) => eNum.enumType === 'eCurrency');
  }
});
export const getCurrenciesEnumMap = createSelector(getEnumsMetadata, (enumsList: any) => {
  if (enumsList) {
    const mapObject = new Map();
    const currenciesList = enumsList.filter((eNum) => eNum.enumType === 'eCurrency');
    // return enumsList.filter(eNum => eNum.enumType === 'eCurrency').reduce((mapObject, item) => {
    currenciesList.forEach((item) => {
      mapObject.set(item.value, item);
    });
    return mapObject;
  }
  return null;
});

export const getEventOddStatusById = (id: number) =>
  createSelector(getEnumsMapSelector, (enumsMap) => {
    if (enumsMap.has('eOddStatus_' + id)) {
      return enumsMap.get('eOddStatus_' + id).name;
    }
  });
export const getTurboCashoutFeeTypeEnumMap = createSelector(getEnumsMetadata, (enumsList: any) => {
  if (enumsList) {
    const mapObject = new Map();
    const list = enumsList.filter((eNum) => eNum.enumType === 'eTurboCashOutFeeType');
    list.forEach((feeType) => {
      mapObject.set(feeType.value, feeType.name);
    });
    return mapObject;
  }
  return null;
});
export const getTurboCashoutFeeTypeEnum = createSelector(getEnumsMetadata, (enumsList: any) => {
  if (enumsList) {
    return enumsList.filter((eNum) => eNum.enumType === 'eTurboCashOutFeeType');
  }
  return [];
});

export const getTurboCashoutFeeTypeDropDown = createSelector(getTurboCashoutFeeTypeEnum, (currencyList: any) => {
  if (currencyList) {
    return currencyList.map((currency) => {
      return {
        label: currency.name,
        value: currency.value,
      };
    });
  }
  return [];
});

export const getCasinoGamesEnum = createSelector(getEnumsMetadata, (enumsList: any) => {
  if (enumsList) {
      return enumsList.filter((eNum) => eNum.enumType === 'eGameNamesCasinoAll');
  }
  return [];
});

export const getCasinoGameNamesDropdown = createSelector(getCasinoGamesEnum, (casinoList: any) => {
  if (casinoList) {
    return casinoList.map((casinoGame) => {
      return {
        label: casinoGame.name,
        value: casinoGame.value+ '_' + casinoGame.name,
      };
    });
  }
  return [];
});

export const getEnumsMapSelector = createSelector(getEnumsMetadata, (enumsList) => {
  if (enumsList) {
    const mapObject = new Map();
    enumsList.forEach((enuM) => {
      mapObject.set(enuM.enumType + '_' + enuM.value, enuM);
    });
    return mapObject;
  }
  return null;
});
export const getPaymentMethodsSelector = createSelector(
  getPaymentMethods,
  getPaymentProviders,
  getEnumsMapSelector,
  (payments, providers, enumsMap) => {
    if (payments) {
      payments.forEach((payment) => {
        if (enumsMap && !enumsMap.has('ePaymentMethodCustom_' + payment.id)) {
          enumsMap.set('ePaymentMethodCustom_' + payment.id, {
            enumType: 'ePaymentMethodCustom',
            name: payment.name,
            value: payment.id,
          });
        }
      });
    }
    if (providers) {
      providers.forEach((provider) => {
        if (enumsMap && !enumsMap.has('ePaymentProviderCustom_' + provider.id)) {
          enumsMap.set('ePaymentProviderCustom_' + provider.id, {
            enumType: 'ePaymentProviderCustom',
            name: provider.name,
            value: provider.id,
          });
        }
      });
    }
    if (enumsMap) {
      enumsMap.set('ePaymentActionCustom_' + 5, { enumType: 'ePaymentActionCustom', name: 'Deposit', value: 5 });
      enumsMap.set('ePaymentActionCustom_' + 6, { enumType: 'ePaymentActionCustom', name: 'Withdraw', value: 6 });
    }
    return enumsMap && payments && providers ? 1 : null;
  }
);
export const getBetTypesDropDown = createSelector(getBetTypes, getEnumsMapSelector, (betTypes, enumsMap) => {
  if (betTypes) {
    return betTypes.map((betType) => {
      if (enumsMap) {
        enumsMap.set('eMarketCustom_' + betType.id, {
          enumType: 'eMarketCustom',
          name: betType.name,
          value: betType.id,
        });
      }
      return {
        value: betType.id,
        label: betType.isLive ? '(Live) ' + betType.name : betType.name,
        isLive: betType.isLive,
        sportTypeId: betType.sportTypeId,
      };
    });
  }
  return null;
});

export const getBetTypesDropDownBySportTypesIds = (sportTypesIds, currentBetTypesIds) =>
  createSelector(getBetTypes, getEnumsMapSelector, (betTypes, enumsMap) => {
    if (betTypes) {
      const results = [];
      if ((sportTypesIds && sportTypesIds.length > 0) || (currentBetTypesIds && currentBetTypesIds.length > 0)) {
        if (sportTypesIds && currentBetTypesIds) {
          for (let i = 0, n = betTypes.length; i < n; ++i) {
            if (sportTypesIds.includes(betTypes[i].sportTypeId) || currentBetTypesIds.includes(betTypes[i].id)) {
              results.push({
                value: betTypes[i].id,
                label: betTypes[i].isLive
                  ? '(Live) ' + betTypes[i].name + ' (' + betTypes[i].id + ')'
                  : betTypes[i].name + ' (' + betTypes[i].id + ')',
              });
              if (enumsMap) {
                enumsMap.set('eMarketCustom_' + betTypes[i].id, {
                  enumType: 'eMarketCustom',
                  name: betTypes[i].name,
                  value: betTypes[i].id,
                });
              }
            }
          }
        } else if (sportTypesIds) {
          betTypes.forEach((betType) => {
            if (sportTypesIds.includes(betType.sportTypeId)) {
              results.push({
                value: betType.id,
                label: betType.isLive
                  ? '(Live) ' + betType.name + ' (' + betType.id + ')'
                  : betType.name + ' (' + betType.id + ')',
              });
              if (enumsMap) {
                enumsMap.set('eMarketCustom_' + betType.id, {
                  enumType: 'eMarketCustom',
                  name: betType.name,
                  value: betType.id,
                });
              }
            }
          });
        } else if (currentBetTypesIds) {
          betTypes.forEach((betType) => {
            if (currentBetTypesIds.includes(betType.id)) {
              results.push({
                value: betType.id,
                label: betType.isLive
                  ? '(Live) ' + betType.name + ' (' + betType.id + ')'
                  : betType.name + ' (' + betType.id + ')',
              });
              if (enumsMap) {
                enumsMap.set('eMarketCustom_' + betType.id, {
                  enumType: 'eMarketCustom',
                  name: betType.name,
                  value: betType.id,
                });
              }
            }
          });
        }
      }
      return results;
    }
    return [];
  });
export const getEnumByIdSelector = (id, enumName) =>
  createSelector(getEnumsMapSelector, (enumsMap) => {
    if (enumsMap) {
      return enumsMap.get(enumName + '_' + id);
    }
  });
export const getEnumByIdsTranslator = (ids: number[], enumName: string, signById: boolean) =>
  createSelector(getEnumsMapSelector, (enumsMap) => {
    if (enumsMap && ids != null) {
      const translatedArray = [];
      if (Array.isArray(ids)) {
        ids.forEach((id) => {
          if (enumsMap.has(enumName + '_' + id)) {
            if (signById)
              translatedArray.push(
                id > 0
                  ? ' (+) ' + enumsMap.get(enumName + '_' + id).name
                  : ' (-) ' + enumsMap.get(enumName + '_' + id).name
              );
            else translatedArray.push(' ' + enumsMap.get(enumName + '_' + id).name);
          } else {
            translatedArray.push(id);
          }
        });
      } else {
        if (enumsMap.has(enumName + '_' + ids)) {
          if (signById)
            translatedArray.push(
              ids > 0
                ? ' (+) ' + enumsMap.get(enumName + '_' + ids).name
                : ' (-) ' + enumsMap.get(enumName + '_' + ids).name
            );
          else translatedArray.push(' ' + enumsMap.get(enumName + '_' + ids).name);
        } else {
          translatedArray.push(ids);
        }
      }

      return translatedArray;
    }
    return null;
  });
export const getBranchesDropDown = createSelector(getBranches, getEnumsMapSelector, (branchesList, enumsMap) => {
  if (branchesList) {
    const dropDown = branchesList.map((branch) => {
      if (enumsMap) {
        enumsMap.set('eBranch_' + branch.id, { enumType: 'eBranch', name: branch.name, value: branch.id });
      }
      return {
        label: '(' + branch.id + ') ' + branch.name,
        value: branch.id,
      };
    });
    dropDown.unshift({ label: '(-2) All except Online', value: -2 });
    enumsMap.set('eBranch_' + '-2', { enumType: 'eBranch', name: 'All except Online', value: -2 });
    return dropDown;
  }
  return null;
});

export const CheckIfEnumListIsUpdatedWithCustomData = createSelector(
  getBranches,
  getEnumsMapSelector,
  (branchesList, enumsMap) => {
    if (branchesList && enumsMap) {
      branchesList.map((branch) => {
        if (enumsMap) {
          enumsMap.set('eBranch_' + branch.id, { enumType: 'eBranch', name: branch.name, value: branch.id });
        }
      });
      return true;
    }
    return false;
  }
);

export const getEventsMetadataInLoading = (state: fromApp.AppState) => state.metadataReducer.sportsTreeLoading;
export const getSportsTreeLoadedStatus = (state: fromApp.AppState) => state.metadataReducer.sportsTreeLoaded;
export const getEventsMetadataSportsTree = (state: fromApp.AppState) => state.metadataReducer.sportsTree;
export const getEventsMetadataTeams = (state: fromApp.AppState) => state.metadataReducer.teams;

export const getTeamsDropDown = createSelector(getEventsMetadataTeams, (teams: Team[]) => {
  if (teams) {
    return teams.map((team) => {
      return {
        value: team.id,
        label: team.name,
      };
    });
  }
  return null;
});
export const getTeamById = (id) =>
  createSelector(getTeamsDropDown, (teams) => {
    return teams.find((type) => type.value == id);
  });
export const getEventsMetadataResolverSelector = createSelector(
  getEventsMetadataInLoading,
  getSportsTreeLoadedStatus,
  getEventsMetadataSportsTree,
  (isLoading, loaded, sportsTree) => ({ isLoading, loaded, sportsTree })
);
export const getSportsTypesDropDown = createSelector(
  getEventsMetadataSportsTree,
  getEnumsMapSelector,
  (sportTree, enumsMap) => {
    if (sportTree && sportTree.cl) {
      return sportTree.cl.map((sport) => {
        if (enumsMap) {
          enumsMap.set('eSportTypeCustom_' + sport.id, {
            enumType: 'eSportTypeCustom',
            name: sport.n,
            value: +sport.id,
          });
        }
        return {
          value: +sport.id,
          label: sport.n,
        };
      });
    }
    return null;
  }
);
export const getSportsTypeMAP = createSelector(getEventsMetadataSportsTree, (sportTree) => {
  if (sportTree && sportTree.cl) {
    const mapObject = new Map();
    sportTree.cl.map((sport) => {
      mapObject.set(+sport.id, sport);
    });
    return mapObject;
  }
  return null;
});
export const getSportTypeById = (id) =>
  createSelector(getSportsTypesDropDown, (sportTypes) => {
    return sportTypes.find((type) => type.value == id);
  });
export const getCountriesDropDown = createSelector(
  getEventsMetadataSportsTree,
  getSportsTreeFilter,
  getEnumsMapSelector,
  (sportTree, filter, enumsMap) => {
    if (sportTree && filter && filter.SportTypeIds) {
      // const filteredTypes: SportsTree[] = sportTree.children.filter(type => filter.SportTypeIds.includes(type.id));
      const filteredTypes: SportsTree[] = filterTree(sportTree, filter.SportTypeIds);
      const returnValue = [];
      filteredTypes.forEach((type) => {
        type.cl.map((country) => {
          if (enumsMap) {
            enumsMap.set('eCountriesCustom_' + country.id, {
              enumType: 'eCountriesCustom',
              name: country.n,
              value: +country.id,
            });
          }
          returnValue.push({
            value: +country.id,
            label: country.n + ' (' + type.n + ')',
          });
        });
      });
      // return sortBy(returnValue, 'label') ;
      return returnValue;
    }
    return null;
  }
);
export const getCountryById = (id) =>
  createSelector(getCountriesDropDown, (countries) => {
    return countries.find((type) => type.value == id);
  });
export const getLeaguesDropDown = createSelector(
  getEventsMetadataSportsTree,
  getSportsTreeFilter,
  getEnumsMapSelector,
  (sportTree, filter, enumsMap) => {
    if (sportTree && filter && filter.SportTypeIds && filter.CountryIds && filter.CountryIds.length > 0) {
      const filteredTypes: SportsTree[] = filterTree(sportTree, filter.SportTypeIds);
      const returnValue = [];
      filteredTypes.forEach((type) => {
        // const filteredCountries: SportsTree[] = type.children.filter(country => filter.CountryIds.includes(country.id));
        const filteredCountries: SportsTree[] = filterTree(type, filter.CountryIds);
        filteredCountries.forEach((country) => {
          country.cl.forEach((league) => {
            if (enumsMap) {
              enumsMap.set('eLeaguesCustom_' + league.id, {
                enumType: 'eLeaguesCustom',
                name: league.n,
                value: +league.id,
              });
            }
            returnValue.push({
              value: +league.id,
              label: league.n + ' (' + country.n + ')',
            });
          });
        });
      });
      // return sortBy(returnValue, 'label') ;
      return returnValue;
    }
    return null;
  }
);
export const getLeagueById = (id) =>
  createSelector(getLeaguesDropDown, (leagues) => {
    return leagues.find((type) => type.value == id);
  });
function filterTree(position: SportsTree, filterBy: any): SportsTree[] {
  if (Array.isArray(filterBy)) {
    return position.cl.filter((type) => filterBy.includes(+type.id));
  } else {
    return position.cl.filter((type) => +type.id == filterBy);
  }
}

export const DropDownBuilder = (enumType, valueType = 'value') =>
  createSelector(getEnumsMapSelector, (enumMap) => {
    if (enumMap && enumMap.size > 0) {
      const dropDown = [];
      for (const k of enumMap.keys()) {
        if (k.startsWith(enumType + '_')) {
          dropDown.push({
            value: valueType === 'value' ? enumMap.get(k).value : enumMap.get(k).name,
            label: enumMap.get(k).name,
          });
        }
      }
      dropDown.sort(function (a, b) {
        return (a.value || 0) - (b.value || 0);
      });
      return dropDown;
    }
    return null;
  });

export const DropDownMapBuilder = (enumType, valueType = 'value') =>
  createSelector(getEnumsMapSelector, (enumMap) => {
    if (enumMap && enumMap.size > 0) {
      const dropdownMap = new Map<any, string>();
      const dropdown = [];

      for (const k of enumMap.keys()) {
        if (k.startsWith(enumType + '_')) {
          const value = valueType === 'value' ? enumMap.get(k).value : enumMap.get(k).name;

          dropdownMap.set(value, enumMap.get(k).name);
          dropdown.push({
            value: value,
            label: enumMap.get(k).name,
          });
        }
      }
      dropdown.sort(function (a, b) {
        return (a.value || 0) - (b.value || 0);
      });
      return {
        dropdownArray: dropdown,
        dropdownMap: dropdownMap,
      };
    }
    return null;
  });

export const selectCMSGuardData = createSelector(getOnlineBranches, getEnumsMetadata, (branches, enums) => {
  return { branches, enums };
});

export const GetEnumsByName = (enumName: string) =>
  createSelector(getEnumsMetadata, (enums) => {
    if (enums && enumName) {
      return enums.filter((e) => e.enumType == enumName);
    }
    return null;
  });

/**
 * Get specific value using enumType and name
 * @param enumType
 * @param enumName
 * @example enumType - 'eBonusType', name - 'FreeBet'
 */
export const GetEnumValueByTypeAndName = (type: string, name: string) =>
  createSelector(GetEnumsByName(type), (enums: { enumType: string; name: string; value: number }[]) => {
    if (enums) {
      return enums?.find((e) => e.name === name)?.value;
    }
    return null;
  });

export const GetEnumsSuggestionsForAutoComplete = (enumType: string, query) =>
  createSelector(getEnumsMetadata, (enums) => {
    if (enums && enumType && query) {
      return enums
        .filter((e) => e.enumType == enumType)
        .filter((v) => v.name.toLowerCase().includes(query.toLowerCase()));
    }
    return null;
  });

export const getBonusesByType = (state: fromApp.AppState) => state.metadataReducer.bonusesByType;

export const getBonusByTypeNamesDropDown = createSelector(getBonusesByType, (bonuses: BonusByType[]) => {
  if (bonuses) {
    return bonuses.map((bn: BonusByType) => {
      return {
        value: bn.id,
        label: bn.name + ` (${bn.id})`,
      };
    });
  }
  return null;
});

export const areBonusesByTypeLoaded = (state: fromApp.AppState) => state.metadataReducer?.bonusesByTypeLoaded;
