import * as cmsBanners from '../actions/cms.banners.actions';
import * as fromApp from 'abs@core/store/reducers';
import { Banner, Placement } from '../../models/banner';
import * as moment from 'moment';
import copy from 'fast-copy';
import { isNullOrUndefinedOrEmptyString } from 'abs@shared/shared-functions/functions';

export interface State {
  loading: boolean;
  banners: Banner[];
  selectedChannel: string;
  savingBanner: boolean;
  showBannerForm: boolean;
  editedBanner: Banner;
  placements: Placement[];
  supportedLanguages: any;
  totalBanners: number;
  totalPlacementBanner: number;
}
export const initialState: State = {
  loading: false,
  banners: null,
  selectedChannel: null,
  savingBanner: false,
  showBannerForm: false,
  editedBanner: null,
  placements: null,
  supportedLanguages: null,
  totalBanners: null,
  totalPlacementBanner: null,
};

export function CmsBannersReducer(state = initialState, action: cmsBanners.Actions): State {
  switch (action.type) {
    case cmsBanners.GET_BANNER_PLACEMENTS: {
      return {
        ...state,
        loading: true,
      };
    }
    case cmsBanners.SET_BANNER_PLACEMENTS: {
      return {
        ...state,
        loading: false,
        placements: action.payload.bannerPlacements,
        totalPlacementBanner: action.payload.totalNumber,
      };
    }
    case cmsBanners.GET_BANNERS_FAILURE: {
      return {
        ...state,
        loading: false,
        banners: null,
      };
    }
    case cmsBanners.SET_BANNER_DATA: {
      return {
        ...state,
        banners: action.payload.banners.map((banner) => {
          banner = copy(banner);
          banner.activeFrom = !isNullOrUndefinedOrEmptyString(banner.activeFrom)
            ? moment(banner.activeFrom).toDate()
            : null;
          banner.activeTo = !isNullOrUndefinedOrEmptyString(banner.activeTo) ? moment(banner.activeTo).toDate() : null;
          banner.branchId = banner.branch.id;
          banner.placementId = banner.placement.id;
          banner.targetAttributeId = banner.targetAttribute.id;
          banner.targetTypeId = banner.targetType.id;
          return banner;
        }),
        loading: false,
        totalBanners: action.payload.totalNumber,
      };
    }
    case cmsBanners.EDIT_NEW_BANNER: {
      return {
        ...state,
        editedBanner: null,
        showBannerForm: true,
      };
    }
    case cmsBanners.EDIT_EXISTING_BANNER: {
      return {
        ...state,
        showBannerForm: true,
        editedBanner: action.payload,
      };
    }
    case cmsBanners.SET_SUPPORTED_LANGUAGES: {
      return {
        ...state,
        supportedLanguages: action.payload,
      };
    }
    case cmsBanners.DELETE_BANNER_SUCCESS: {
      return {
        ...state,
        showBannerForm: false,
        editedBanner: null,
      };
    }
    case cmsBanners.UPDATE_BANNER_DATA_SUCCESS: {
      return {
        ...state,
        showBannerForm: false,
        editedBanner: null,
      };
    }
    case cmsBanners.CLOSE_EDITED_BANNER: {
      return {
        ...state,
        showBannerForm: false,
        editedBanner: null,
      };
    }
    default: {
      return state;
    }
  }
}

export const getSelectedChannel = (state: fromApp.AppState) => state.cmsBannersReducer.selectedChannel;
export const getSavingBannerStatus = (state: fromApp.AppState) => state.cmsBannersReducer.savingBanner;
export const getEditedBanner = (state: fromApp.AppState) => state.cmsBannersReducer.editedBanner;
export const showBannerForm = (state: fromApp.AppState) => state.cmsBannersReducer.showBannerForm;
export const getBannerPlacements = (state: fromApp.AppState) => copy(state.cmsBannersReducer.placements);
export const getSupportedLanguages = (state: fromApp.AppState) => state.cmsBannersReducer.supportedLanguages;
export const getTotalBanners = (state: fromApp.AppState) => state.cmsBannersReducer.totalBanners;
export const getTotalPlacementBanner = (state: fromApp.AppState) => state.cmsBannersReducer.totalPlacementBanner;
export const getSelectedBannerPlacementDesktopURL = (state: fromApp.AppState) => {
  if (state.cmsBannersReducer.placements && state.cmsBannersReducer.placements.length === 1) {
    return state.cmsBannersReducer.placements[0].desktopURL;
  } else {
    return null;
  }
};
export const getSelectedBannerPlacementTabletURL = (state: fromApp.AppState) => {
  if (state.cmsBannersReducer.placements && state.cmsBannersReducer.placements.length === 1) {
    return state.cmsBannersReducer.placements[0].tabletURL;
  } else {
    return null;
  }
};
export const getSelectedBannerPlacementMobileURL = (state: fromApp.AppState) => {
  if (state.cmsBannersReducer.placements && state.cmsBannersReducer.placements.length === 1) {
    return state.cmsBannersReducer.placements[0].mobileURL;
  } else {
    return null;
  }
};

export const getBanners = (state: fromApp.AppState) => {
  if (state.cmsBannersReducer.banners === null) {
    return null;
  }
  const banners = copy(state.cmsBannersReducer.banners);
  banners.map((banner) => {
    banner.activeFrom = !isNullOrUndefinedOrEmptyString(banner.activeFrom) ? moment(banner.activeFrom).toDate() : null;
    banner.activeTo = !isNullOrUndefinedOrEmptyString(banner.activeTo) ? moment(banner.activeTo).toDate() : null;
    banner.branchId = banner.branch.id;
    banner.placementId = banner.placement.id;
    banner.targetAttributeId = banner.targetAttribute.id;
    banner.targetTypeId = banner.targetType.id;
    return banner;
  });
  return banners;
};
