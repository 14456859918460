import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { BetTypeGroup } from '../../models';

export type BetTypeGroupsState = EntityState<BetTypeGroup>

export const betTypeGroupsAdapter: EntityAdapter<BetTypeGroup> = createEntityAdapter<BetTypeGroup>({
  selectId: (item) => item.id,
});
export const betTypeGroupsInitialState: BetTypeGroupsState = betTypeGroupsAdapter.getInitialState({
  entities: null
});