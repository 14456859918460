import { Action } from '@ngrx/store';
import { BOFilter } from '../../models/models';

export const SET_BO_FILTERS = '[BO-FILTER] Set Filters Values';
export const UPDATE_BO_FILTERS_CONFIG = '[BO-FILTER] Update BO Filters Config';
export const GET_BO_FILTERS = '[BO-FILTER] Get Filters Values';

export const RESET_ALL_FILTERS = '[BO-FILTER] Reset All Filters';
export const DESTROY_ALL_FILTERS = '[BO-FILTER] Destroy All Filters';
export const CLEAR_ONE_FILTER = '[BO-FILTER] Clear One Filter'; // clear filter from active filters component by pressing the button

export const SET_BO_FILTER_TIME_ZONE = '[BO-FILTER] Set BO Filter Time Zone';

export class SetBOFilters implements Action {
  readonly type = SET_BO_FILTERS;

  constructor(public payload: { boFilters: Map<string, BOFilter>; firstTime: boolean }) {}
}

export class UpdateBOFiltersConfig implements Action {
  readonly type = UPDATE_BO_FILTERS_CONFIG;

  constructor(public payload: { boFilters: Map<string, BOFilter>; config: any }) {}

}

export class GetBOFilters implements Action {
  readonly type = GET_BO_FILTERS;
}

export class ClearOneFilter implements Action {
  readonly type = CLEAR_ONE_FILTER;

  constructor(public payload: any) {}
}

export class ResetAllFilters implements Action {
  readonly type = RESET_ALL_FILTERS;
}

export class DestroyAllFilters implements Action {
  readonly type = DESTROY_ALL_FILTERS;
}

export class SetBOFIlterTimeZone implements Action {
  readonly type = SET_BO_FILTER_TIME_ZONE;

  constructor(public payload: any) {}
}

export type Actions =
  | SetBOFilters
  | ResetAllFilters
  | GetBOFilters
  | DestroyAllFilters
  | ClearOneFilter
  | SetBOFIlterTimeZone
  | UpdateBOFiltersConfig;
