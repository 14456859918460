// enums file
export enum eChannels {
  Online = 1,
  CashBox = 2,
  Pos = 3,
  Mobile = 4,
  BackOffice = 5,
  Kiosk = 6,
  External = 7,
  Server = 8,
  USSD = 9,
}

export enum eEventStatus {
  Prematch = 0,
  Live = 1,
  Closed = 2,
  Canceled = 3,
  Interrupted = 4,
  Void = 5,
  AwaitingLive = 6,
}

export enum eFastGameStatus {
  "Creating" = 1,
  "Creation Failed" = 2,
  "Reserved" = 3,
  "New" = 4,
  "In Progress" = 5,
  "Completed" = 6,
  "Editing" = 7,
}

export enum eOddStatus {
  Unknown = -1,
  Open = 0,
  Suspended = 1,
  Closed = 2,
}

export enum ePrepaidUserStatus {
  Unknown = 0,
  Disabled = 1,
  NotActivated = 2,
  NotSecured = 3,
  Secured = 4,
  Converted = 5,
  Canceled = 6,
}

export enum eUserStatus {
  Active = 1,
  Inactive,
  Locked,
  Dormant,
  SelfExcluded
}

export const ORDER_STATUS_SOLD = 'Sold';
export const ORDER_STATUS_PAID = 'Paid';
export const ORDER_STATUS_CANCELLED = 'Cancelled';
export const ORDER_STATUS_EXPIRED = 'Expired';

export const WIN_STATUS_PENDING = 'Pending';
export const WIN_STATUS_LOST = 'Lost';
export const WIN_STATUS_WON = 'Won';
export const WIN_STATUS_REFUND = 'Refund';
export const WIN_STATUS_CASHED_OUT = 'Cashed Out';

export interface BOComment {
  date: string;
  description: string;
  userId: number;
  userName: string;
}

export enum eAuthorizationStatus {
  AuthorizationDeclined = 'AuthorizationDeclined',
  Expired = 'Expired',
  Sold = 'Sold',
  WaitForAuthorization = 'WaitForAuthorization',
  WaitForSell = 'WaitForSell',
}

export enum eAuthorizationStatusForRespond {
  AuthorizationDeclined = 2,
  WaitForSell = 3,
}

export enum eValidationErrorKey {
  Required = 'required',
  Pattern = 'pattern',
}

// Prime-NG Table Column Enum
export enum ePTableColumnType {
  String = 'string',
  Number = 'number',
  Date = 'date',
  Object = 'object',
  Link = 'link',
  ENum = 'eNum',
  Actions = 'actions'
}
