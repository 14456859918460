import { createReducer, on } from '@ngrx/store';
import * as Actions from '../actions/bet-type-odds.actions';
import { betTypeOddsAdapter, betTypeOddsInitialState } from '../adapters';
import { OperationType } from 'src/app/sport-betting/common/models';

export const BetTypeOddsReducer = createReducer(
  betTypeOddsInitialState,
  on(Actions.SbSetBetTypeOdds, (state, { payload }) => {
    return betTypeOddsAdapter.setAll(payload.data, {
      ...state,
      totalCount: payload.totalCount
    });
  }),
  on(Actions.SbUpdateBetTypeOdd, state => ({
    ...state,
    operationResult: null
  })),
  on(Actions.SbUpdateBetTypeOddSuccess, (state, { payload }) => {
    return betTypeOddsAdapter.updateOne({
      id: payload.betTypeOddId,
      changes: {
        ...payload,
      }
    }, {
      ...state,
      operationResult: {
        id: payload.betTypeOddId,
        isSuccessful: true,
        operationType: OperationType.Update
      }
    });
  }),
  on(Actions.BetTypeOddsFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);