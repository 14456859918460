import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { GET_FEED_STATUS, GetFeedStatus, SetFeedStatus } from '../actions/feed-health.actions';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class FeedHealthEffects {
  public FeedHeal$ = createEffect(() => this.actions$.pipe(
    ofType(GET_FEED_STATUS),
    map((action: GetFeedStatus) => {
      return action.payload;
    }),
    exhaustMap((brandId) => {
      return this.httpClient.get<any>(`${environment.feedStatusUrl}Status?brandId=${brandId}`).pipe(
        map(({ statusDescription }) => {
          return new SetFeedStatus(statusDescription);
        }),
        catchError(() =>
          of(new SetFeedStatus('Unknown'))
        ));
    })
  ));

  constructor(
    private readonly httpClient: HttpClient,
    private readonly actions$: Actions<any>
  ) { }
}
