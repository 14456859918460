import * as activeCampaigns from '../actions/activeCampaigns.actions';
import { ErrorForUser } from '../../models/activeCampaign';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import { AppState } from 'abs@core/store/reducers';
import { ActiveCampaign } from 'abs@core/models/coreModels';

export interface State {
  activeCampaignsList: ActiveCampaign[];
  usersErrorList: ErrorForUser[];
  loading: boolean;
  campaignsLoaded: boolean;
}

export const initialState: State = {
  activeCampaignsList: null,
  loading: true,
  campaignsLoaded: false,
  usersErrorList: null,
};

export function ActiveCampaignsReducer(state = initialState, action: any): State {
  switch (action.type) {
    case activeCampaigns.GET_ACTIVE_CAMPAIGNS: {
      return {
        ...state,
        loading: true,
        campaignsLoaded: false,
        usersErrorList: null,
      };
    }
    case activeCampaigns.CONNECT_USERS_TO_CAMPAIGN: {
      return {
        ...state,
        loading: true,
      };
    }
    case activeCampaigns.CONNECT_USERS_TO_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        loading: false,
        usersErrorList: action.payload,
      };
    }
    case activeCampaigns.SET_ACTIVE_CAMPAIGNS: {
      return {
        ...state,
        activeCampaignsList: action.payload.campaigns,
        loading: false,
        campaignsLoaded: true,
      };
    }
    case activeCampaigns.CONNECT_USERS_TO_CAMPAIGN_FAILURE: {
      return {
        ...state,
        activeCampaignsList: null,
        loading: false,
        campaignsLoaded: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const ActiveCampaignsList = (state: AppState) =>
  state.activeCampaignsReducer ? state.activeCampaignsReducer.activeCampaignsList : null;

export const GetActiveCampaignListDropDown = createSelector(ActiveCampaignsList, (campaigns: ActiveCampaign[]) => {
  if (campaigns) {
    const copyCampaigns = copy(campaigns);
    return copyCampaigns.map((c: ActiveCampaign) => {
      c.label = c.name + ' - [' + c.category + '] (' + c.id + ')';
      c.value = c.id;
      return c;
    });
  }
  return undefined;
});
export const IsPageLoading = (state: AppState) =>
  state.activeCampaignsReducer ? state.activeCampaignsReducer.loading : null;

export const AreCampaignsLoaded = (state: AppState) =>
  state.activeCampaignsReducer ? state.activeCampaignsReducer.campaignsLoaded : null;

export const UsersErrorsList = (state: AppState) =>
  state.activeCampaignsReducer ? copy(state.activeCampaignsReducer.usersErrorList) : null;
