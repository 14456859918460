import { Action } from '@ngrx/store';
import { GetUserAccessRequest, UserAccessResponse } from '../../models/models';
export const GET_USER_ACCESS_DATA = '[USER-ACCESS] Get user access data';
export const SET_USER_ACCESS_DATA = '[USER-ACCESS] Set user access data';
export const SET_USER_ACCESS_FAILURE = '[USER-ACCESS] Set user access failure';
export const CLEAR_USER_ACCESS_STATE = '[USER-ACCESS] Clear user access state';

export class GetUserAccessData implements Action {
  readonly type = GET_USER_ACCESS_DATA;
  constructor(public payload: GetUserAccessRequest = null) {}
}

export class SetUserAccessData implements Action {
  readonly type = SET_USER_ACCESS_DATA;
  constructor(public payload: UserAccessResponse = null) {}
}

export class SetUserAccessFailure implements Action {
  readonly type = SET_USER_ACCESS_FAILURE;
  constructor(public payload: Error = null) {}
}

export class ClearUserAccessState implements Action {
  readonly type = CLEAR_USER_ACCESS_STATE;
  
}

export type Actions =
  GetUserAccessData
  | SetUserAccessData
  | SetUserAccessFailure
  | ClearUserAccessState;
