import { Action } from '@ngrx/store';

export const GET_SUMMARY_BRANCH_REPORT = '[REPORTS] Get Summary branch report';
export const SET_SUMMARY_BRANCH_REPORT = '[REPORTS] Set Summary branch report';
export const RESET_SUMMARY_BRANCH_REPORT = '[REPORTS] Redirect Summary Branch Report';
export const FAILURE = '[REPORTS] Failure';

export class GetSummaryBranchReport implements Action {
  readonly type = GET_SUMMARY_BRANCH_REPORT;

  constructor(public payload: any = null) {}
}

export class SetSummaryBranchReport implements Action {
  readonly type = SET_SUMMARY_BRANCH_REPORT;

  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;

  
}

export class ResetSummaryBranchReport implements Action {
  readonly type = RESET_SUMMARY_BRANCH_REPORT;

  
}

export type Actions = GetSummaryBranchReport | SetSummaryBranchReport | ResetSummaryBranchReport;
