import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';
import { DashboardComponent } from './home/dashboard.component';
import { AppMainComponent } from './layout/app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { LoginPageComponent } from './auth/containers/login-page.component';
import { AuthGuardService } from './auth/guards/auth-guard.service';
import { PermissionsScreensCodes, RouteConstants } from './core/app.config';
import { EmptyComponent } from './empty/empty.component';
import { AppModule } from './app.module';

export const routes: Routes = [
  {
    path: '',
    component: AppMainComponent,
    canActivateChild: [AuthGuardService],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home',
        component: DashboardComponent,
        data: {
          authorization: [PermissionsScreensCodes.DASHBOARD_MAIN_SCREEN],
          noAuthorizationRedirect: RouteConstants.LOGIN_PAGE,
          breadcrumb: 'Home',
        },
      },
      // {
      //   path: 'design-palette',
      //   loadChildren: () => import('./design-palette/design-palette.module').then((m) => m.DesignPaletteModule),
      // },
      {
        path: 'administration',
        loadChildren: () => import('./administration/administration.module').then((m) => m.AdministrationModule),
      },
      {
        path: 'risk',
        loadChildren: () => import('./risk/risk.module').then((m) => m.RiskModule),
      },
      {
        path: 'crm',
        loadChildren: () => import('./crm/crm.module').then((m) => m.CrmModule),
      },
      {
        path: 'cms',
        loadChildren: () => import('./cms/cms.module').then((m) => m.CmsModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./financials/financials.module').then((m) => m.FinancialsModule),
      },
      {
        path: 'product-offering',
        loadChildren: () => import('./product-offering/product-offering.module').then((m) => m.ProductOfferingModule),
      },
      {
        path: 'promotions',
        loadChildren: () => import('./promotions/promotions.module').then((m) => m.PromotionsModule),
      },
      {
        path: 'setting',
        loadChildren: () => import('./setting/setting.module').then((m) => m.SettingModule),
        data: {
          // authorization: [
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_ONLINE_PLAYERS_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_ONLINE_REGISTRATION_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_IDOPAY_TRANSACTIONS_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_ACCESS_LIST_BY_IP_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_ACCESS_LIST_BY_COUNTRY_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_PREPAID_CUSTOMERS_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_USER_BONUS_STATUS_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_ORDER_PER_MINUTE_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_GAP_PER_SECONDS_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_PENDING_SETTLE_ORDERS_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_OPERATIONAL_PREPAID_TICKETS_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_BONUSES_REFUND_BONUS_SUMMARY_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_BONUSES_NEXT_BET_MONTHLY_REPORT_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_RISK_MARKETS_SINGLES_BY_AMOUNT_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_RISK_MARKETS_BY_CLICK_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_RISK_BY_MARKETS_COMBO_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_RISK_BY_MARKETS_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_USERS_BALANCE_PER_WALLET_SCREEN,
          //   PermissionsScreensCodes.DYNAMIC_REPORTS_USERS_USER_PAYOUT_SCREEN,
          // ],
        },
      },
      {
        path: 'notifications',
        loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule),
      },
      { path: 'home', component: EmptyComponent },
      // { path: 'sample', component: SampleDemoComponent },
      // { path: 'forms', component: FormsDemoComponent },
      // { path: 'data', component: DataDemoComponent },
      // { path: 'panels', component: PanelsDemoComponent },
      // { path: 'overlays', component: OverlaysDemoComponent },
      // { path: 'menus', component: MenusDemoComponent },
      // { path: 'messages', component: MessagesDemoComponent },
      // { path: 'misc', component: MiscDemoComponent },
      // { path: 'empty1', component: EmptyDemoComponent },
      // { path: 'charts', component: ChartsDemoComponent },
      // { path: 'file', component: FileDemoComponent },
      // { path: 'utils', component: UtilsDemoComponent },
      // { path: 'documentation', component: DocumentationComponent }
    ],
  },
  { path: 'error', component: AppErrorComponent },
  { path: 'accessdenied', component: AppAccessdeniedComponent },
  { path: '404', component: AppNotfoundComponent },
  { path: 'login', component: LoginPageComponent },
  { path: '**', redirectTo: '/404' },
];

export const AppRoutes: ModuleWithProviders<AppModule> = RouterModule.forRoot(routes, {
  scrollPositionRestoration: 'enabled',
});
