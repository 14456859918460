import { Action } from "@ngrx/store";
export const GET_REPORT_DATA = "GET_REPORT_DATA";
export const SET_REPORT_DATA = "SET_REPORT_DATA";
export const GET_AGENT_DATA = 'GET_AGENT_DATA';
export const SET_AGENT_DATA = 'SET_AGENT_DATA';
export const GET_RESOLUTION = 'GET_RESOLUTION';
export class GetReportData implements Action {
  readonly type = GET_REPORT_DATA;

  constructor(public payload: any = null) { }
}

export class SetReportData implements Action {
  readonly type = SET_REPORT_DATA;

  constructor(public payload: any = null) { }
}

export class GetAgentData implements Action {
  readonly type = GET_AGENT_DATA;

  constructor(public payload: any = null) { }
}

export class GetResolution implements Action {
  readonly type = GET_RESOLUTION;

  constructor(public payload: any = null) {}
}

export class SetAgentData implements Action {
  readonly type = SET_AGENT_DATA;

  constructor(public payload: any = null) { }
}


export type Actions =
  GetReportData
 | SetReportData
 | GetAgentData
 | SetAgentData
 | GetResolution
