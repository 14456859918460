import { Action } from '@ngrx/store';

export const ONLINE_ACTION = '[ONLINE] Online action';
export const ONLINE_ACTION_SUCCESS = '[ONLINE] Online action success';
export const ONLINE_ACTION_FAILURE = '[ONLINE] Online action failure';
export const CHANGE_PASSWORD_SUCCESS = '[ONLINE] Change password success';
export const CHANGE_PASSWORD_FAILURE = '[ONLINE] Change password failure';

export const DIRECT_TRANSFER_SUCCESS = '[ONLINE] Direct transfer success';
export const DIRECT_TRANSFER_FAILURE = '[ONLINE] Direct transfer failure';

export const ATTACH_BONUS_SUCCESS = '[ONLINE] Attach bonus success';
export const ATTACH_BONUS_FAILURE = '[ONLINE] Attach bonus failure';

export const TRANSFER_ACTION_SUCCESS = '[ONLINE] Transfer action success';
export const TRANSFER_ACTION_FAILURE = '[ONLINE] Transfer action failure';

export const RESEND_OTP = '[ONLINE] Resend OTP';
export const RESEND_OTP_SUCCESS = '[ONLINE] Resend OTP action success';
export const RESEND_OTP_FAILURE = '[ONLINE] Resend OTP action failure';

export const UPDATE_COMMENT_ACTION_SUCCESS = '[ONLINE] Update comment action success';
export const UPDATE_COMMENT_ACTION_FAILURE = '[ONLINE] Update comment action failure';

export const MANUAL_CONFIRM_ACTION_SUCCESS = '[ONLINE] Manual confirm action success';
export const MANUAL_CONFIRM_ACTION_FAILURE = '[ONLINE] Manual confirm action failure';

export const FAILURE = '[ONLINE] Failure';
export const SET_FAILURE = '[ONLINE] Set Failure';

export class OnlineAction implements Action {
  readonly type = ONLINE_ACTION;

  constructor(public payload: any = null) {}
}
export class ResendOTP implements Action {
  readonly type = RESEND_OTP;

  constructor(public payload: any = null) {}
}
export class OnlineActionSuccess implements Action {
  readonly type = ONLINE_ACTION_SUCCESS;

  
}
export class OnlineActionFailure implements Action {
  readonly type = ONLINE_ACTION_FAILURE;

  
}
export class ChangePasswordSuccess implements Action {
  readonly type = CHANGE_PASSWORD_SUCCESS;

  
}
export class ChangePasswordFailure implements Action {
  readonly type = CHANGE_PASSWORD_FAILURE;
  
}

export class UpdateCommentSuccess implements Action {
  readonly type = UPDATE_COMMENT_ACTION_SUCCESS;

  
}
export class UpdateCommentFailure implements Action {
  readonly type = UPDATE_COMMENT_ACTION_FAILURE;

  
}

export class DirectTransferSuccess implements Action {
  readonly type = DIRECT_TRANSFER_SUCCESS;

  
}
export class DirectTransferFailure implements Action {
  readonly type = DIRECT_TRANSFER_FAILURE;

  
}
export class AttachBonusSuccess implements Action {
  readonly type = ATTACH_BONUS_SUCCESS;

  
}
export class AttachBonusFailure implements Action {
  readonly type = ATTACH_BONUS_FAILURE;

  
}
export class TransferActionSuccess implements Action {
  readonly type = TRANSFER_ACTION_SUCCESS;

  
}
export class TransferActionFailure implements Action {
  readonly type = TRANSFER_ACTION_FAILURE;

  
}

export class ResendOTPSuccess implements Action {
  readonly type = RESEND_OTP_SUCCESS;

  
}
export class ResendOTPFailure implements Action {
  readonly type = RESEND_OTP_FAILURE;

  
}

export class ManualConfirmActionSuccess implements Action {
  readonly type = MANUAL_CONFIRM_ACTION_SUCCESS;

  
}
export class ManualConfirmActionFailure implements Action {
  readonly type = MANUAL_CONFIRM_ACTION_FAILURE;

  
}

export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions =
  | OnlineAction
  | OnlineActionSuccess
  | OnlineActionFailure
  | DirectTransferSuccess
  | DirectTransferFailure
  | AttachBonusSuccess
  | AttachBonusFailure
  | UpdateCommentSuccess
  | UpdateCommentFailure
  | ChangePasswordSuccess
  | ChangePasswordFailure
  | TransferActionSuccess
  | TransferActionFailure
  | ManualConfirmActionSuccess
  | ManualConfirmActionFailure
  | ResendOTP
  | ResendOTPSuccess
  | ResendOTPFailure
  | SetFailure
  | Failure;
