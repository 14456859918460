import { Action } from '@ngrx/store';

export const GET_EXPOSURE_DATA = '[RISK-EXPOSURE] Get exposure data';
export const CLEAR_LIVE_AUTHORIZATION_DATA = '[RISK-EXPOSURE] Clear live authorization data';
export const GET_LIVE_AUTHORIZATION_DATA = '[RISK-EXPOSURE] Get live authorization data';
export const SET_LIVE_AUTHORIZATION_DATA = '[RISK-EXPOSURE] Set live authorization data';
export const CLEAR_EVENT_LIMIT_DATA = '[RISK-EXPOSURE] Clear event limit data';
export const GET_EVENT_LIMIT_DATA = '[RISK-EXPOSURE] Get event limit data';
export const SET_EVENT_LIMIT_DATA = '[RISK-EXPOSURE] Set event limit data';
export const GET_AUTHORIZATION_ORDER_DATA = '[RISK-EXPOSURE] Get authorization order data';
export const SET_AUTHORIZATION_ORDER_DATA = '[RISK-EXPOSURE] Set authorization order data';
export const CHECK_IF_NEW_AUTHORIZATION_REQUEST_EXIST = '[RISK-EXPOSURE] Check if new authorization exist';
export const SET_IF_NEW_AUTHORIZATION_REQUEST_EXIST = '[RISK-EXPOSURE] Set if New authorization exist';

export const UPDATE_COMMENT_ACTION_SUCCESS_RISK = '[Risk] Update comment action success';
export const UPDATE_COMMENT_ACTION_FAILURE_RISK = '[Risk] Update comment action failure';

export const UPDATE_AUTHORIZATION_STATUS = '[Risk] Update authorization status';
export const UPDATE_AUTHORIZATION_STATUS_SUCCESS = '[Risk] Update authorization status success';
export const UPDATE_AUTHORIZATION_STATUS_FAILURE = '[Risk] Update authorization status failure';

export const CLEAR_AUTHORIZATION_ORDER_DATA = '[Risk] Clear authorization order data';

export const FAILURE = '[ROLES-AND-PERMISSIONS] Failure';
export const SET_FAILURE = '[ROLES-AND-PERMISSIONS] Set Failure';

export class UpdateCommentSuccessRisk implements Action {
  readonly type = UPDATE_COMMENT_ACTION_SUCCESS_RISK;

  
}
export class UpdateCommentFailureRisk implements Action {
  readonly type = UPDATE_COMMENT_ACTION_FAILURE_RISK;

  
}
export class GetExposureData implements Action {
  readonly type = GET_EXPOSURE_DATA;

  constructor(public payload: any = null) {}
}
export class UpdateAuthorizationStatus implements Action {
  readonly type = UPDATE_AUTHORIZATION_STATUS;

  constructor(public payload: any = null) {}
}
export class UpdateAuthorizationStatusSuccess implements Action {
  readonly type = UPDATE_AUTHORIZATION_STATUS_SUCCESS;

  
}
export class UpdateAuthorizationStatusFailure implements Action {
  readonly type = UPDATE_AUTHORIZATION_STATUS_FAILURE;

  
}
export class CheckIfNewAuthorizationRequestExist implements Action {
  readonly type = CHECK_IF_NEW_AUTHORIZATION_REQUEST_EXIST;

  constructor(public payload: any = null) {}
}
export class SetIfNewAuthorizationRequestExist implements Action {
  readonly type = SET_IF_NEW_AUTHORIZATION_REQUEST_EXIST;

  
}
export class GetLiveAuthorizationData implements Action {
  readonly type = GET_LIVE_AUTHORIZATION_DATA;

  constructor(public payload: any = null) {}
}
export class SetLiveAuthorizationData implements Action {
  readonly type = SET_LIVE_AUTHORIZATION_DATA;

  constructor(public payload: any = null) {}
}
export class GetAuthorizationOrderData implements Action {
  readonly type = GET_AUTHORIZATION_ORDER_DATA;

  constructor(public payload: any = null) {}
}
export class SetAuthorizationOrderData implements Action {
  readonly type = SET_AUTHORIZATION_ORDER_DATA;

  constructor(public payload: any = null) {}
}
export class SetEventLimitData implements Action {
  readonly type = SET_EVENT_LIMIT_DATA;

  constructor(public payload: any = null) {}
}
export class GetEventLimitData implements Action {
  readonly type = GET_EVENT_LIMIT_DATA;

  constructor(public payload: any = null) {}
}
export class ClearLiveAuthorizationData implements Action {
  readonly type = CLEAR_LIVE_AUTHORIZATION_DATA;

  
}
export class ClearAuthorizationOrderData implements Action {
  readonly type = CLEAR_AUTHORIZATION_ORDER_DATA;

  
}
export class ClearEventLimitData implements Action {
  readonly type = CLEAR_EVENT_LIMIT_DATA;

  
}
export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions =
  | GetExposureData
  | ClearAuthorizationOrderData
  | UpdateAuthorizationStatus
  | UpdateAuthorizationStatusSuccess
  | UpdateAuthorizationStatusFailure
  | UpdateCommentSuccessRisk
  | UpdateCommentFailureRisk
  | GetAuthorizationOrderData
  | SetAuthorizationOrderData
  | ClearEventLimitData
  | ClearLiveAuthorizationData
  | GetEventLimitData
  | GetLiveAuthorizationData
  | CheckIfNewAuthorizationRequestExist
  | SetIfNewAuthorizationRequestExist
  | SetLiveAuthorizationData
  | SetEventLimitData
  | SetFailure
  | Failure;
