import { EntityAdapter, EntityState, createEntityAdapter } from "@ngrx/entity";
import { OperationResult } from "src/app/sport-betting/common/models/operation-result.model";
import { SportTeam } from "../../models/entities";

interface SportTeamsStateModel {
  key: number;
  data: Array<SportTeam>;
  totalCount: number;
  operationResult?: OperationResult;
}

export type SportTeamsState = EntityState<SportTeamsStateModel>
export const sportTeamsAdapter: EntityAdapter<SportTeamsStateModel> = createEntityAdapter<SportTeamsStateModel>({
  selectId: (item) => item.key,
});
export const sportTeamsInitialState: SportTeamsState = sportTeamsAdapter.getInitialState({
  totalCount: 0,
});
