import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { DefaultBetType } from '../../models';
import { DropdownItem, OperationResult } from 'src/app/sport-betting/common/models';

export interface DefaultBetTypesState extends EntityState<DefaultBetType> {
  totalCount: number;
  betTypesForCreation: Array<DropdownItem<number, string>>
  operationResult?: OperationResult;
}
export const defaultBetTypesAdapter: EntityAdapter<DefaultBetType> = createEntityAdapter<DefaultBetType>({
  selectId: (item) => item.id,
});
export const defaultBetTypesInitialState: DefaultBetTypesState = defaultBetTypesAdapter.getInitialState({
  totalCount: 0,
  betTypesForCreation: [],
  entities: null,
});