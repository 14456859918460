import * as fromApp from "abs@core/store/reducers";
import { createSelector } from "@ngrx/store";
import { vouchers, Distributor } from "./models/batch.models";

export interface State {
  distributor: Distributor[],
  voucherData: vouchers[];
  totalRecords: number;
  loading: boolean;
}
import * as BatchData from './store/actions/batch.actions';
import { GET_BATCH_DATA } from "./store/actions/batch.actions";

export const initialState: { voucherData: null, totalRecords: null, distributor: null, loading: null } = {
    voucherData: null, totalRecords: null, distributor: null, loading: null
};

export const getVoucherBatches = (state: fromApp.AppState) => {
    return state.onlineVoucherListReducer.voucherData;
};
export const getDistributor = (state: fromApp.AppState) => state.onlineVoucherListReducer.distributor;

export const getTotalRecords = (state: fromApp.AppState) => {
    return state.onlineVoucherListReducer.totalRecords;
};

export const getLoadingStatus = (state: fromApp.AppState) => {
  return state.onlineVoucherListReducer.loading;
};

export const getCustomerVoucherBatchesSelector = createSelector(getVoucherBatches, (voucherBatches) => {
    if (voucherBatches) {
        return voucherBatches;
    }
    return undefined;
});

export const getDistributorSelector = createSelector(getDistributor, (distributor) => {
    if (distributor) {
        return distributor;
    }
    return undefined;
});


export function OnlineVoucherReducer(state = initialState, action: any): State {
    switch (action.type) {
      case GET_BATCH_DATA: {
        return {
          ...state,
          loading: true,
        };
      }
        case BatchData.GET_BATCH_DATA_SUCCESS: {
          return {
            ...state,
            voucherData: action.payload.data.batches.items,
            totalRecords: action.payload.data.batches.count,
            distributor: action.payload.data.distributor,
            loading: false
          };
        }
      default: {
        return state;
      }
    }
}
