import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-popup-image-cell',
  templateUrl: './popup-image-cell.component.html',
  styleUrls: ['./popup-image-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupImageCellComponent {
  public canDisplay: boolean;
  public display: boolean;
  @Input() public val: any;
  @Input() public dismissable = true;
  @Input() public showCloseIcon = true;
}
