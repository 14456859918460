import { Action } from '@ngrx/store';

export const GET_LOTTO_DATA = '[LOTTO] Get lotto data';
export const GET_SPECIFIC_LOTTO_DRAW = '[LOTTO] Get specific lotto draw';
export const SET_SPECIFIC_LOTTO_DRAW = '[LOTTO] Set specific lotto draw';

export const SET_LOTTO_TYPE_BY_ID_SUCCESS = '[LOTTO] Set lotto type by id Success';
export const SET_BET_LOTTO_TYPE_BY_ID_SUCCESS = '[LOTTO] Set bet lotto type by id Success';
export const SET_BET_LOTTO_LINE_SUCCESS = '[LOTTO] Set bet lotto line Success';
export const SET_BET_LOTTO_LINE_CONFIGURATION_SUCCESS = '[LOTTO] Set bet lotto line Configuration Success';
export const CLOSE_LOTTO_BET_LINE = '[LOTTO] Close lotto bet line';

export const EXPORT_TO_EXCEL = '[LOTTO] Export To Excel';
export const EXPORT_TO_EXCEL_SUCCESS = '[LOTTO] Export To Excel Success';

export const SET_LOTTO_TYPES = '[LOTTO] Set lotto Types';

export const RESET_LOTTO_FILTER = '[LOTTO] Reset Lotto Filter';

export const SET_LOTTO_LOGS_FILTER = '[LOTTO] Set Lotto Logs Filter';

export const GET_LOTTO_LOGS = '[LOTTO] Get lotto Logs';

export const SET_LOTTO_LOGS = '[LOTTO] Set lotto Logs';

export const SET_LOTTO_TOTALS_REPORT = '[LOTTO] Set lotto Totals Report';

export const GET_DRAWS_SUMMARY_REPORT = '[LOTTO] Get Draws Summary Report';

export const SET_LOTTO_DRAWS = '[LOTTO] Set lotto Draws';

export const SET_LOTTO_PRIZES = '[LOTTO] Set lotto Prizes';

export const DOWNLOAD_LOTTO_EXCEL = '[LOTTO] download lotto excel';

export const FAILURE = '[LOTTO] Failure';

export const SET_FAILURE = '[LOTTO] Set Failure';

export const CALCULATE_WINNERS_RESULTS = '[LOTTO] calculateWinnersResults';

export const CALCULATE_WINNERS = '[LOTTO] calculateWinners';

export const SET_WINNERS_RESULTS = '[LOTTO] set winners results';

export const SET_WINNERS = '[LOTTO] set winners';

export const SET_DRAWS_SUMMARY_REPORT = '[LOTTO] Set Draws Summary Report';

export const UPDATE_LOTTO_DATA = '[LOTTO] Update Lotto Data';
export const UPDATE_LOTTO_DATA_SUCCESS = '[LOTTO] Update Lotto Data Success';

export class CloseLottoBetLine implements Action {
  readonly type = CLOSE_LOTTO_BET_LINE;

  
}
export class UpdateLottoData implements Action {
  readonly type = UPDATE_LOTTO_DATA;

  constructor(public payload: any = null) {}
}
export class UpdateLottoDataSuccess implements Action {
  readonly type = UPDATE_LOTTO_DATA_SUCCESS;

  constructor(public payload: any = null) {}
}
export class ExportToExcelSuccess implements Action {
  readonly type = EXPORT_TO_EXCEL_SUCCESS;

  
}
export class ExportToExcel implements Action {
  readonly type = EXPORT_TO_EXCEL;

  
}
export class ResetLottoFilter implements Action {
  readonly type = RESET_LOTTO_FILTER;

  
}
export class SetLottoLogsFilter implements Action {
  readonly type = SET_LOTTO_LOGS_FILTER;

  constructor(public payload: any = null) {}
}
export class SetLottoTypeByIdSuccess implements Action {
  readonly type = SET_LOTTO_TYPE_BY_ID_SUCCESS;

  constructor(public payload: any = null) {}
}
export class SetBetLottoTypeByIdSuccess implements Action {
  readonly type = SET_BET_LOTTO_TYPE_BY_ID_SUCCESS;

  constructor(public payload: any = null) {}
}
export class SetBetLottoLineSuccess implements Action {
  readonly type = SET_BET_LOTTO_LINE_SUCCESS;

  constructor(public payload: any = null) {}
}
export class SetBetLottoLineConfigurationSuccess implements Action {
  readonly type = SET_BET_LOTTO_LINE_CONFIGURATION_SUCCESS;

  
}
export class GetLottoLogs implements Action {
  readonly type = GET_LOTTO_LOGS;

  constructor(public payload: any = null) {}
}
export class GetSpecificLottoDraw implements Action {
  readonly type = GET_SPECIFIC_LOTTO_DRAW;

  constructor(public payload: any = null) {}
}
export class SetSpecificLottoDraw implements Action {
  readonly type = SET_SPECIFIC_LOTTO_DRAW;

  constructor(public payload: any = null) {}
}
export class SetLottoLogs implements Action {
  readonly type = SET_LOTTO_LOGS;

  constructor(public payload: any = null) {}
}
export class SetLottoTotalsReport implements Action {
  readonly type = SET_LOTTO_TOTALS_REPORT;

  constructor(public payload: any = null) {}
}
export class SetWinnersResults implements Action {
  readonly type = SET_WINNERS_RESULTS;

  constructor(public payload: any = null) {}
}

export class CalculateWinnersResults implements Action {
  readonly type = CALCULATE_WINNERS_RESULTS;

  constructor(public payload: any = null) {}
}

export class CalculateWinners implements Action {
  readonly type = CALCULATE_WINNERS;

  constructor(public payload: any = null) {}
}

export class DownloadLottoExcel implements Action {
  readonly type = DOWNLOAD_LOTTO_EXCEL;

  constructor(public payload: any = null) {}
}

export class GetLottoData implements Action {
  readonly type = GET_LOTTO_DATA;

  constructor(public payload: any = null) {}
}

export class SetLottoTypesData implements Action {
  readonly type = SET_LOTTO_TYPES;

  constructor(public payload: any = null) {}
}

export class SetLottoDrawsData implements Action {
  readonly type = SET_LOTTO_DRAWS;

  constructor(public payload: any = null) {}
}

export class SetLottoPrizesData implements Action {
  readonly type = SET_LOTTO_PRIZES;

  constructor(public payload: any = null) {}
}

export class SetDrawsSummaryReport implements Action {
  readonly type = SET_DRAWS_SUMMARY_REPORT;
  constructor(public payload: any = null) {}
}

export class GetDrawsSummaryReport implements Action {
  readonly type = GET_DRAWS_SUMMARY_REPORT;
  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}

export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions =
  | SetLottoPrizesData
  | SetLottoDrawsData
  | SetLottoTypesData
  | GetLottoData
  | Failure
  | SetFailure
  | DownloadLottoExcel
  | CalculateWinnersResults
  | CalculateWinners
  | SetWinnersResults
  | GetLottoLogs
  | SetLottoLogs
  | ResetLottoFilter
  | SetLottoLogsFilter
  | SetLottoTotalsReport
  | ExportToExcel
  | ExportToExcelSuccess
  | UpdateLottoData
  | UpdateLottoDataSuccess
  | SetLottoTypeByIdSuccess
  | SetBetLottoTypeByIdSuccess
  | SetBetLottoLineSuccess
  | SetBetLottoLineConfigurationSuccess
  | CloseLottoBetLine
  | GetSpecificLottoDraw
  | SetSpecificLottoDraw
  | GetDrawsSummaryReport;
