import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeExtendedTimeZoneAsyncPipe } from '../pipes/date-time-timezone/date-time-timezone.pipe';
import { SecondsToDateTimePipe } from '../pipes/seconds-to-minutesSeconds/seconds-to-time.pipe';
import { DateTimeTimezoneStaticPipe } from '../pipes/date-time-timezone-static.pipe';
import {
  secondsToHoursMinutesSecondsPipe
} from 'abs@shared/pipes/seconds-to-hours-minutes-seconds/seconds-to-hours-minutes-seconds.pipe';

@NgModule({
  declarations: [DateTimeExtendedTimeZoneAsyncPipe, SecondsToDateTimePipe, DateTimeTimezoneStaticPipe, secondsToHoursMinutesSecondsPipe],
  imports: [CommonModule],
  providers: [DateTimeTimezoneStaticPipe],
  exports: [DateTimeExtendedTimeZoneAsyncPipe, SecondsToDateTimePipe, DateTimeTimezoneStaticPipe, secondsToHoursMinutesSecondsPipe],
})
export class SharedPipesModule {}
