import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';

@Component({
  selector: 'app-key-value-cell',
  templateUrl: './key-value-cell.component.html',
  styleUrls: ['./key-value-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KeyValueCellComponent implements OnDestroy {
  @Input() value: any;
  @Input() inEditState: boolean;
  @Input() authClassName: string;
  @Output() finishedEditEvent = new EventEmitter<any>();
  typingTimer: any;
  doneTypingInterval = 1000;
  typing() {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(() => {
      this.finishedEditEvent.emit(this.value);
    }, this.doneTypingInterval);
  }
  changeKey(key, val) {
    this.value[key] = val;
    this.finishedEditEvent.emit(this.value);
  }
  ngOnDestroy() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }
}
