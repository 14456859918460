import { Betslip, OrderData, Totals } from '../../models/betslip.models';
import {
  CLEAR_BETSLIPS_DATA,
  EXPORT_ALL_DATA_TO_EXCEL,
  EXPORT_ALL_DATA_TO_EXCEL_SUCCESS,
  FAILURE,
  GET_BETSLIPS_DATA,
  SET_BETSLIPS_DATA,
} from '../actions/betslips.actions';
import * as fromApp from 'abs@core/store/reducers';
import { createSelector } from '@ngrx/store';
import {
  getBetTypes,
  getBranches,
  getEnumsMetadata,
  getEventsMetadataSportsTree,
} from 'abs@core/store/reducers/metadata.reducer';

export interface State {
  loading: boolean;
  processing: boolean;
  orders: Betslip[];
  totalOrders: number;
  totals: Totals;
  orderData: OrderData;
}
export const initialState: State = {
  loading: false,
  processing: false,
  orders: null,
  totalOrders: null,
  totals: null,
  orderData: null,
};

export function BetslipsReducer(state = initialState, action: any): State {
  switch (action.type) {
    case GET_BETSLIPS_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_BETSLIPS_DATA: {
      return {
        ...state,
        loading: false,
        orders: action.payload.orders,
        totalOrders: +action.payload.totalNumber,
      };
    }
    case CLEAR_BETSLIPS_DATA: {
      return {
        ...state,
        loading: false,
        orders: null,
        totalOrders: 0,
      };
    }
    case EXPORT_ALL_DATA_TO_EXCEL: {
      return {
        ...state,
        processing: true,
      };
    }
    case EXPORT_ALL_DATA_TO_EXCEL_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case FAILURE: {
      return {
        ...state,
        orders: null,
        totalOrders: null,
        loading: false,
        processing: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const getLoadingStatus = (state: fromApp.AppState) => state.betslipsReducer.loading;
export const getOrders = (state: fromApp.AppState) => state.betslipsReducer.orders;
export const getTotalOrders = (state: fromApp.AppState) => state.betslipsReducer.totalOrders;
export const getProcessingState = (state: fromApp.AppState) => state.betslipsReducer.processing;
export const getTotals = createSelector(getOrders, (orders) => {
  if (orders) {
    const totals = new Totals();
    orders.forEach((order) => {
      if (order.statusId != 6) {
        totals.totalBonus += order?.totalBonus;
        totals.totalCommission += order.commission;
        totals.totalStake += order.stake;
        totals.totalInitialStake += order.initialStake;
        totals.totalPayout += order.payout;
        totals.totalMaxPayout += order.maxPayout;
        totals.totalNetPayout += order.netPayout;
      }
    });
    return totals;
  }
  return null;
});
export const betslipsGuardData = createSelector(
  getBranches,
  getEnumsMetadata,
  getBetTypes,
  getEventsMetadataSportsTree,
  (branches, enums, betTypes, sportTypes) => {
    return { branches, enums, betTypes, sportTypes };
  }
);
