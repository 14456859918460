import {
  AgentGrid,
  Branches,
  Currency,
  Distributor,
  internalDistributors,
  Reports
} from "./models/report.models";
import * as fromApp from "abs@core/store/reducers";
import { GET_REPORT_DATA, GET_RESOLUTION, SET_REPORT_DATA } from "./store/actions/report.actions";
import { createSelector } from "@ngrx/store";

export interface State {
  reportList: Array<Reports>;
  distributors: Array<Distributor>;
  branchModel: Array<Branches>;
  currencyModel: Array<Currency>;
  totalRecords: number;
  loading: boolean;
  agent: Array<AgentGrid>;
  resolutionType: number;
  internalDistributor: internalDistributors;
}


export const initialState: { reportList: null, distributors: null, branchModel: null, currencyModel: null, totalRecords: number, loading: null, agent: null, resolutionType: null, internalDistributor: null } = {
  reportList: null, distributors: null, branchModel: null, currencyModel: null, totalRecords: null, loading: null, agent: null , resolutionType: null, internalDistributor: null
};

export const getVoucherReport = (state: fromApp.AppState) => {
  return state.reportDataReducer.reportList;
};

export const getDistributors = (state: fromApp.AppState) => {
  return state.reportDataReducer.distributors;
};

export const getAgent = (state: fromApp.AppState) => {
  return state.reportDataReducer.agent;
};

export const getResolutionType = (state: fromApp.AppState) => {
  return state.reportDataReducer.resolutionType;
};

export const getBranches = (state: fromApp.AppState) => {
  return state.reportDataReducer.branchModel;
};

export const getCurrency = (state: fromApp.AppState) => {
  return state.reportDataReducer.currencyModel;
};

export const getTotalRecords = (state: fromApp.AppState) => {
  return state.reportDataReducer.totalRecords;
};

export const getLoading = (state: fromApp.AppState) => {
  return state.reportDataReducer.loading;
};

export const getDistributorSelector = createSelector(getDistributors, (distributor) => {
  if (distributor) {
    return distributor;
  }
  return null;
});

export function OnlineReportReducer(state = initialState, action: any): State {
  switch (action.type) {
    case GET_REPORT_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SET_REPORT_DATA: {
      const distributors = action.payload.distributorModel ? action.payload.distributorModel : action.payload.internalDistributorsModel;
      const currencyModel = action.payload.currencyModel ? action.payload.currencyModel : action.payload.internalDistributorsModel;
      const totalRecords = action.payload.distributorResolutionModel ? action.payload.distributorResolutionModel.count : action.payload.agentResolutionModel.count;
      return {
        ...state,
        reportList: action.payload.distributorResolutionModel?.items,
        agent: action?.payload?.agentResolutionModel?.items,
        branchModel: action.payload.branchModel,
        currencyModel: currencyModel,
        distributors: distributors,
        totalRecords: totalRecords,
        loading: false,
      };
    }
    case GET_RESOLUTION: {
      return {
        ...state,
        resolutionType: action.payload,
      };
    }
    default:
      return state;
  }
}
