import { BaseRequest, DataRequest } from '../../core/models/request';
import { APIMethods, APIMethodsGames, AppSettings } from '../../core/app.config';
import * as MetaDataActions from '../../core/store/actions/metadata.actions';
import { ColumnFilter, ListFilter, Pagination, RangeFilter } from '../../common/models/generic-filters/filters';

export class GetTemplatesRequest extends BaseRequest {
  constructor(
    public formTemplateId: string = null,
    public formTemplateValueId: string = null,
    public ColumnFilters: ColumnFilter[] = null,
    public ListFilters: ListFilter[] = null,
    public RangeFilters: RangeFilter[] = null,
    public Pagination: Pagination = null
  ) {
    super('', '');
  }
}
export class GetTemplatesListRequest extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_TEMPLATES,
      MetaDataActions.SET_TEMPLATES,
      request
    );
  }
}

export class SetFormTemplateDataRequest extends BaseRequest {
  constructor(
    public formTemplateId: string = null,
    public formTemplateValueId: string = null,
    public rowId: string = null,
    public dataRows: any = null
  ) {
    super('', '');
  }
}

export class GetTemplatesRequestBody {
  constructor(public formTemplateId: number = null, public formTemplateValueId: number = null) {}
}

export class GetDataTemplateRequest extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethods.GET_TEMPLATES,
      MetaDataActions.SET_TEMPLATES,
      request
    );
  }
}
