import { createAction, props } from '@ngrx/store';
import { FailAction, Filtering } from 'src/app/sport-betting/common/models';
import { UpdateMargin } from '../../models/update-margin.model';
import { Margin } from '../../models';

const SB_GET_COUNTRY_MARGINS = '[SBBTM] Get countries margins';
const SB_SET_COUNTRY_MARGINS = '[SBBTM] Set countries margins';

const SB_UPDATE_COUNTRY_MARGIN = '[SBBTM] Update country margin';
const SB_UPDATE_COUNTRY_MARGIN_SUCCESS = '[SBBTM] Update country margin SUCCESS';

const SB_DELETE_COUNTRY_MARGIN = '[SBBTM] Delete country margin';
const SB_DELETE_COUNTRY_MARGIN_SUCCESS = '[SBBTM] Delete country margin SUCCESS';

const FAILURE = '[SBBTM] Update country margin failure';

export const SbGetCountryMargins = createAction(SB_GET_COUNTRY_MARGINS, props<{ payload: Filtering }>());
export const SbSetCountryMargins = createAction(SB_SET_COUNTRY_MARGINS, props<{
  payload: {
    data: Array<Margin>,
    totalCount: number;
  }
}>());
export const SbUpdateCountryMargin = createAction(SB_UPDATE_COUNTRY_MARGIN, props<{ payload: UpdateMargin }>());
export const SbUpdateCountryMarginSuccess = createAction(SB_UPDATE_COUNTRY_MARGIN_SUCCESS, props<{ payload: UpdateMargin }>());

export const SbDeleteCountryMargin = createAction(SB_DELETE_COUNTRY_MARGIN, props<{
  payload: {
    marginId: number,
    countryId: number
  }
}>());
export const SbDeleteCountryMarginSuccess = createAction(SB_DELETE_COUNTRY_MARGIN_SUCCESS, props<{
  payload: {
    marginId: number,
    countryId: number
  }
}>());
export const CountryMarginsFailure = createAction(FAILURE, props<FailAction>());