import { Component } from '@angular/core';
import {AppSettings} from "../core/app.config";

@Component({
  selector: 'app-error',
  templateUrl: './app.error.component.html',
})
export class AppErrorComponent {
  logo = AppSettings.BRAND_DOMAIN;
}
