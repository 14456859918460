import { Injectable } from '@angular/core';
import { CachingModel } from '../models/cachingModels';
import { UserData } from '../../auth/models/user';
import { getUserData } from '../../auth/store/reducers/auth';
import { tap } from 'rxjs/operators';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class CachingService {
  private currUserData: UserData;
  constructor(private store: Store<any>) {
    this.store
      .select(getUserData)
      .pipe(tap((userData) => (this.currUserData = userData)))
      .subscribe();
  }
  getData(key: string) {
    const value = localStorage.getItem(key);
    if (!value) {
      return null;
    }
    const val: CachingModel = JSON.parse(value);
    if (val.expirationTime == null) {
      return val.data;
    }
    
    const currentDate = Date.now();
    if (val.expirationTime >= currentDate && this.currUserData && val.userId == this.currUserData.userId) {
      return val.data;
    } else {
      localStorage.removeItem(key);
      return null;
    }
  }
  setData(key: string, val: any, expiration: number = null) {
    const newEntry: CachingModel = {
      data: val,
      expirationTime: expiration ? Date.now() + expiration : null,
      userId: this.currUserData ? this.currUserData.userId : null,
    };
    localStorage.setItem(key, JSON.stringify(newEntry));
  }
  removeData(key: string) {
    if (localStorage.getItem(key)) {
      localStorage.removeItem(key);
    }
  }
  clearSData() {
    for (const key in localStorage) {
      if (key.startsWith('#')) {
        localStorage.removeItem(key);
      }
    }
  }
  clearCache() {
    for (const key in localStorage) {
      if (!key.startsWith('*')) {
        localStorage.removeItem(key);
      }
    }
  }
}
