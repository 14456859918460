import { createAction, props } from '@ngrx/store';
import { FailAction } from 'src/app/sport-betting/common/models';
import { Event, RevertEventManualField, UpdateEventManualField, SetSettlement } from '../../models';

const SB_GET_EVENT = '[SB] Get event';
const SB_SET_EVENT = '[SB] Set event';
const SB_EVENTS_FAILURE = '[SB CRUD] Event failure';

const SB_REQUEST_PREMATCH_SETTLEMENTS = '[SB] Request prematch settlements';
const SB_REQUEST_LIVE_SETTLEMENTS = '[SB] Request live settlements';

const SB_REQUEST_SETTLEMENTS_SUCCESS = '[SB] Request settlements SUCCESS';
const SB_REQUEST_SETTLEMENTS_FAILED = '[SB] Request settlements FAILED';

const SB_UPDATE_EVENT_MANUL_PROPERTY = '[SB] Update event manual property';
const SB_UPDATE_EVENT_MANUL_PROPERTY_RESULTED = '[SB] Update event manual property RESULTED';

const SB_REVERT_EVENT_MANUL_PROPERTY = '[SB] Revert event manual property';
const SB_REVERT_EVENT_MANUL_PROPERTY_RESULTED = '[SB] Revert event manual property RESULTED';

const SB_SET_SETTLEMENT = '[SB] Set event settlement';
const SB_SET_SETTLEMENT_SUCCESS = '[SB] Set event settlement SUCCESS';

const SB_DISABLE_WATCHDOG = '[SB] Disable watchdog';
const SB_ENABLE_WATCHDOG = '[SB] Enable watchdog';
const SB_CHANGE_WATCHDOG_SUCCESS = '[SB] Set change watchdog SUCCESS';

export const SbGetEvent = createAction(SB_GET_EVENT, props<{ id: number }>());
export const SbSetEvent = createAction(SB_SET_EVENT, props<{
  payload: Event
}>());

export const EventsFailure = createAction(SB_EVENTS_FAILURE, props<FailAction>());

export const SbRequestPrematchSettlements = createAction(SB_REQUEST_PREMATCH_SETTLEMENTS, props<{ id: number }>());
export const SbRequestLiveSettlements = createAction(SB_REQUEST_LIVE_SETTLEMENTS, props<{ id: number }>());

export const SbRequestSettlementsSuccess = createAction(SB_REQUEST_SETTLEMENTS_SUCCESS);
export const SbRequestSettlementsFailed = createAction(SB_REQUEST_SETTLEMENTS_FAILED, props<FailAction>());

export const SbUpdateEventManualProperty = createAction(SB_UPDATE_EVENT_MANUL_PROPERTY, props<{ payload: UpdateEventManualField }>());
export const SbUpdateEventManualPropertyResulted = createAction(SB_UPDATE_EVENT_MANUL_PROPERTY_RESULTED, props<{
  payload: {
    request: UpdateEventManualField,
    response: Event,
    isSuccessful: boolean,
  }
}>());

export const SbRevertEventManualProperty = createAction(SB_REVERT_EVENT_MANUL_PROPERTY, props<{ payload: RevertEventManualField }>());
export const SbRevertEventManualPropertyResulted = createAction(SB_REVERT_EVENT_MANUL_PROPERTY_RESULTED, props<{
  payload: {
    request: RevertEventManualField,
    response: Event,
    isSuccessful: boolean,
  }
}>());

export const SbSetEventSettlement = createAction(SB_SET_SETTLEMENT, props<{ payload: SetSettlement }>());
export const SbSetEventSettlementSuccess = createAction(SB_SET_SETTLEMENT_SUCCESS, props<{ payload: SetSettlement }>());

export const SbDisableWatchDog = createAction(SB_DISABLE_WATCHDOG, props<{ payload: number }>());
export const SbEnableWatchDog = createAction(SB_ENABLE_WATCHDOG, props<{ payload: number }>());
export const SbChangeWatchDogSuccess = createAction(SB_CHANGE_WATCHDOG_SUCCESS, props<{ payload: number }>());