import * as lottoSportsbookActions from '../actions/lotto.sportsbook.actions';
import * as fromApp from 'abs@core/store/reducers';
import { BetLotteryLine, eLottoBetTypes, LottoDraw, LottoTotal, LottoType } from '../../models/lotto.models';
import { createSelector } from '@ngrx/store';
import { isNullOrUndefined } from 'abs@shared/shared-functions/functions';
import copy from 'fast-copy';
export interface State {
  loading: boolean;
  lotteryDraws: LottoDraw[];
  lottoTotal: LottoTotal[];
  lotteryTypes: LottoType[];
  lotteryTypesLoaded: boolean;
  currentLotteryType: LottoType;
  currentLotteryBetTypeId: number;
  currentBetLotteryLine: BetLotteryLine[];
  lotteryPrizes: any;
  winnerResults: any;
  lottoLogs: any;
  lottoLogsTotalCount: number;
  winners: any;
  lottoDrawsSummary: any;
  inProcess: boolean;
  currentDrawId: number;
}
export const initialState: State = {
  lotteryTypes: null,
  lotteryTypesLoaded: false,
  currentLotteryType: null,
  currentBetLotteryLine: null,
  currentLotteryBetTypeId: null,
  lotteryDraws: null,
  lotteryPrizes: null,
  lottoLogs: null,
  lottoLogsTotalCount: null,
  loading: false,
  winnerResults: null,
  winners: null,
  lottoDrawsSummary: null,
  inProcess: false,
  currentDrawId: null,
  lottoTotal: null,
};

export function LottoSportsBookReducer(state = initialState, action: any): State {
  switch (action.type) {
    case lottoSportsbookActions.EXPORT_TO_EXCEL: {
      return {
        ...state,
        inProcess: true,
      };
    }
    case lottoSportsbookActions.EXPORT_TO_EXCEL_SUCCESS: {
      return {
        ...state,
        inProcess: false,
      };
    }
    case lottoSportsbookActions.RESET_LOTTO_FILTER: {
      return {
        ...state,
        lotteryTypes: null,
        lotteryDraws: null,
      };
    }
    case lottoSportsbookActions.GET_LOTTO_DATA: {
      return {
        ...state,
        loading: true,
        currentLotteryBetTypeId: !isNullOrUndefined(action.payload.betLotteryTypeId)
          ? action.payload.betLotteryTypeId
          : null,
        lotteryTypesLoaded: action.payload.methodName === 'GetLotteryTypes' ? false : null,
        currentDrawId: !isNullOrUndefined(action.payload.drawId) ? action.payload.drawId : state.currentDrawId,
      };
    }
    case lottoSportsbookActions.GET_SPECIFIC_LOTTO_DRAW: {
      return {
        ...state,
        loading: true,
      };
    }
    case lottoSportsbookActions.SET_SPECIFIC_LOTTO_DRAW: {
      return {
        ...state,
        loading: false,
        lotteryDraws:
          action.payload.length > 0
            ? [action.payload[0], ...state.lotteryDraws.filter((draw) => draw.id !== action.payload[0].id)]
            : state.lotteryDraws,
        // currentDrawId: action.payload.length > 0 ? action.payload[0].id : state.currentDrawId,
      };
    }
    case lottoSportsbookActions.SET_LOTTO_LOGS: {
      return {
        ...state,
        lottoLogs: action.payload.logs,
        lottoLogsTotalCount: action.payload.totalRecords,
        loading: false,
      };
    }
    case lottoSportsbookActions.SET_LOTTO_TOTALS_REPORT: {
      return {
        ...state,
        lottoTotal: action.payload,
        loading: false,
      };
    }
    case lottoSportsbookActions.UPDATE_LOTTO_DATA: {
      return {
        ...state,
        // lotteryTypes: [...state.lotteryTypes],
        // currentLotteryType: Object.assign({}, state.currentLotteryType),
        inProcess: true,
      };
    }
    case lottoSportsbookActions.UPDATE_LOTTO_DATA_SUCCESS: {
      return {
        ...state,
        currentLotteryType: Object.assign({}, state.currentLotteryType),
        inProcess: false,
      };
    }
    case lottoSportsbookActions.SET_LOTTO_TYPE_BY_ID_SUCCESS: {
      return {
        ...state,
        inProcess: false,
        loading: false,
        currentLotteryType: action.payload,
      };
    }
    case lottoSportsbookActions.SET_BET_LOTTO_LINE_SUCCESS: {
      return {
        ...state,
        inProcess: false,
        loading: false,
        currentBetLotteryLine: action.payload,
      };
    }
    case lottoSportsbookActions.SET_BET_LOTTO_LINE_CONFIGURATION_SUCCESS: {
      return {
        ...state,
        inProcess: false,
        loading: false,
      };
    }
    case lottoSportsbookActions.CLOSE_LOTTO_BET_LINE: {
      return {
        ...state,
        currentBetLotteryLine: null,
        currentLotteryBetTypeId: null,
      };
    }
    case lottoSportsbookActions.CALCULATE_WINNERS: {
      return {
        ...state,
        winners: [],
        loading: true,
        inProcess: true,
        currentDrawId: action.payload.drawId,
      };
    }
    case lottoSportsbookActions.SET_WINNERS_RESULTS: {
      return {
        ...state,
        winnerResults: action.payload,
        loading: false,
      };
    }
    case lottoSportsbookActions.SET_WINNERS: {
      return {
        ...state,
        winners: action.payload,
        loading: false,
        inProcess: false,
      };
    }
    case lottoSportsbookActions.SET_LOTTO_PRIZES: {
      return {
        ...state,
        lotteryPrizes: action.payload,
        loading: false,
      };
    }
    case lottoSportsbookActions.SET_LOTTO_TYPES: {
      return {
        ...state,
        lotteryTypes: action.payload,
        loading: false,
      };
    }
    case lottoSportsbookActions.SET_LOTTO_DRAWS: {
      return {
        ...state,
        lotteryDraws: action.payload,
        loading: false,
        lotteryTypesLoaded: true,
      };
    }
    case lottoSportsbookActions.SET_FAILURE: {
      return {
        ...state,
        loading: false,
        inProcess: false,
        lotteryTypesLoaded: true,
      };
    }
    default: {
      return state;
    }
  }
}
export const getLogs = (state: fromApp.AppState) => {
  return state.lottoSportsBookReducer.lottoLogs;
};
export const getLotteryTypesIsLoaded = (state: fromApp.AppState) => {
  return state.lottoSportsBookReducer.lotteryTypesLoaded;
};
export const getTotalLogsCount = (state: fromApp.AppState) => {
  return state.lottoSportsBookReducer.lottoLogsTotalCount;
};
export const getWinnerResults = (state: fromApp.AppState) => {
  const winnerResults = state.lottoSportsBookReducer.winnerResults;
  return winnerResults;
};
export const getWinners = (state: fromApp.AppState) => {
  const winners = state.lottoSportsBookReducer.winners;
  return winners;
};
export const getLottoTypes = (state: fromApp.AppState) => {
  const types = state.lottoSportsBookReducer.lotteryTypes;
  return types;
};
export const getLottoDraws = (state: fromApp.AppState) => {
  const draws = state.lottoSportsBookReducer.lotteryDraws;
  return draws;
};
export const getInProcess = (state: fromApp.AppState) => {
  return state.lottoSportsBookReducer.inProcess;
};
export const getCurrentDraw = (state: fromApp.AppState) => {
  return state.lottoSportsBookReducer.currentDrawId;
};
export const getIfLoading = (state: fromApp.AppState) => {
  return state.lottoSportsBookReducer.loading;
};
export const getLottoTotals = (state: fromApp.AppState) => {
  return copy(state.lottoSportsBookReducer.lottoTotal);
};
export const getCurrentLottoType = (state: fromApp.AppState) => {
  return state.lottoSportsBookReducer.currentLotteryType;
};
export const getCurrentLottoBetTypeId = (state: fromApp.AppState) => {
  return state.lottoSportsBookReducer.currentLotteryBetTypeId;
};
export const getCurrentBetLottoLine = (state: fromApp.AppState) => {
  return state.lottoSportsBookReducer.currentBetLotteryLine;
};
export const getUnderOverLineConfigData = createSelector(
  getCurrentLottoBetTypeId,
  getCurrentLottoType,
  getCurrentBetLottoLine,
  (betTypeId, currentLotto, currentBetLottoLine) => {
    if (betTypeId && currentLotto && currentBetLottoLine) {
      const betType = currentLotto.betLotteryTypes.find((bet) => bet.betLotteryTypeId === betTypeId);
      if (betType && betType.betLotteryTypeText === eLottoBetTypes.UnderOver) {
        if (
          currentBetLottoLine[0] &&
          currentBetLottoLine[0].betLotteryLineOdds &&
          currentBetLottoLine[0].betLotteryLineOdds[0]
        ) {
          return {
            maxNumber: currentLotto.maxNumber,
            settings: currentBetLottoLine[0].betLotteryLineOdds[0].setting,
          };
        } else {
          return {
            maxNumber: currentLotto.maxNumber,
          };
        }
      }
    }
    return null;
  }
);
export const getRangeLineConfigData = createSelector(
  getCurrentLottoBetTypeId,
  getCurrentLottoType,
  getCurrentBetLottoLine,
  (betTypeId, currentLotto, currentBetLottoLine) => {
    if (betTypeId && currentLotto && currentBetLottoLine) {
      const betType = currentLotto.betLotteryTypes.find((bet) => bet.betLotteryTypeId === betTypeId);
      if (betType && betType.betLotteryTypeText === eLottoBetTypes.Range) {
        if (
          currentBetLottoLine[0] &&
          currentBetLottoLine[0].betLotteryLineOdds &&
          currentBetLottoLine[0].betLotteryLineOdds[0]
        ) {
          return {
            maxNumber: currentLotto.maxNumber,
            settings: currentBetLottoLine[0].betLotteryLineOdds[0].setting,
            data: currentBetLottoLine,
          };
        } else {
          return {
            maxNumber: currentLotto.maxNumber,
            settings: { divCount: 3 },
            data: currentBetLottoLine,
          };
        }
      }
    }
    return null;
  }
);
export const getLottoTypeResolverSelector = createSelector(getCurrentLottoType, getIfLoading, (lottoType, loading) => {
  return !loading && lottoType != null;
});
export const getLottoTypesSelector = createSelector(getLottoTypes, (lotteries) => {
  if (lotteries) {
    return lotteries;
  }
  return undefined;
});
export const getSelectedDraw = createSelector(getLottoDraws, getCurrentDraw, (draws, id) => {
  if (draws && id) {
    return draws.filter((draw) => draw.id === id);
  }
  return undefined;
});

export const getDrawById = (id) =>
  createSelector(getLottoDraws, (allCurrentDraws) => {
    if (allCurrentDraws) {
      return allCurrentDraws.filter((draw) => draw.id === id);
    }
    return undefined;
  });
export const getLottoById = (id) =>
  createSelector(getLottoTypes, (allCurrentLottos) => {
    if (allCurrentLottos) {
      return allCurrentLottos.find((lotto) => lotto.id === id);
    }
    return undefined;
  });


export const getLottoNamesDropDown = createSelector(getLottoTypes, (lottoTypes) => {
  if (lottoTypes) {
    return lottoTypes.map((lotto) => {
      return {
        value: lotto.id,
        label: lotto.name,
      };
    });
  }
  return undefined;
});
export const getLottoDrawsDropDown = createSelector(getLottoDraws, (lottoDraws) => {
  if (lottoDraws) {
    return lottoDraws.map((draw) => {
      return {
        value: draw.id,
        label: draw.displayText,
      };
    });
  }
  return undefined;
});
export const getLotttoTypesResolver = createSelector(
  getLottoTypes,
  getIfLoading,
  getLotteryTypesIsLoaded,
  (lottoTypes, isLoading, isLoaded) => ({ lottoTypes, isLoading, isLoaded })
);
