import { Action } from "@ngrx/store";

export const GET_ONLINE_VOUCHER_DATA = "GET_ONLINE_VOUCHER_DATA";

export class GetOnlineVoucherData implements Action {
  readonly type = GET_ONLINE_VOUCHER_DATA;

  constructor(public payload: any = null) {
  }
}
