import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import * as layoutActions from '../actions/layout.actions';
import * as ErrorActions from '../actions/error.actions';
import { GrowlMessage, SeverityCode } from '../../models/Growl-Messages';
import { map, mergeMap } from 'rxjs/operators';
import { isNullOrUndefined } from '../../../shared/shared-functions/functions';
@Injectable()
export class ErrorEffects {
  
  failure = createEffect(() => this.actions$.pipe(
    ofType(ErrorActions.FAILURE),
    map((action: ErrorActions.Failure) => {
      return action.payload;
    }),
    mergeMap((error: any) => {
      const message = this.failMessage(error);
      return [
        new layoutActions.ErrorMsg(message),
        {
          type: ErrorActions.SET_FAILURE,
          payload: message,
        },
      ];
    })
  ));

  constructor(private actions$: Actions<any>) {}
  failMessage(error: any) {
    let message: GrowlMessage = null;
    if (error && !isNullOrUndefined(error.result)) {
      if (!isNullOrUndefined(error.result.errorDescription)) {
        message = new GrowlMessage(SeverityCode.ERROR, 'Error', error.result.errorDescription);
      } else if (!isNullOrUndefined(error.result.errorCodeDescription)) {
        message = new GrowlMessage(SeverityCode.ERROR, 'Error', error.result.errorCodeDescription);
      }
    } else if (error && !isNullOrUndefined(error.message)) {
      message = new GrowlMessage(SeverityCode.ERROR, 'Error', error.message);
    } else {
      message = new GrowlMessage(SeverityCode.ERROR, 'Error', 'Internal Error');
    }
    return message;
  }
}
