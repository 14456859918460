import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { OperationResult } from "src/app/sport-betting/common/models/operation-result.model";
import { SportLeague } from "../../models/entities";

interface SportLeagueStateModel {
  key: number;
  data: Array<SportLeague>;
  totalCount: number;
  operationResult?: OperationResult;
}


export type SportLeagueState = EntityState<SportLeagueStateModel>
export const sportLeaguesAdapter: EntityAdapter<SportLeagueStateModel> = createEntityAdapter<SportLeagueStateModel>({
  selectId: (item) => item.key,
});
export const sportLeaguesInitialState: SportLeagueState = sportLeaguesAdapter.getInitialState({
  totalCount: 0,
});