import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { AppSettings } from "abs@core/app.config";
import { AppConfigService } from "abs@core/services/appConfig.service";
import { Observable, tap } from "rxjs";
import * as fromApp from "../store/reducers/index";
import { getUser } from "src/app/auth/store/reducers/auth";
import { getLanguage } from "abs@core/store/reducers/layout";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private readonly interceptorSkipContentType = 'X-Skip-Content-Type';
  private token: string;
  private language: string;

  constructor(
    private store: Store<fromApp.AppState>,
    private configService: AppConfigService
  ) {
    this.store
      .select(getUser)
      .pipe(tap((user) => this.token = user?.token))
      .subscribe();
    this.store
      .select(getLanguage)
      .pipe(
        tap((language) => this.language = language)
      ).subscribe();

  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = request.headers;

    if (this.language) {
      headers = headers.set(
        "Language", this.language
      );
    }

    if (this.token) {
      headers = headers.set(
        "Token", this.token
      );
    }

    if (!headers.has(this.interceptorSkipContentType)) {
      headers = headers.set('Content-Type', 'application/json');
    }

    headers = headers
      .delete(this.interceptorSkipContentType)
      .set("ChannelId", `${AppSettings.CHANNELID}`)
      .set("BrandId", `${this.configService.getBrandId()}`);

    request = request.clone({
      headers
    });
    return next.handle(request);
  }

}
