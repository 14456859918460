import {
  ProfitabilityReportRow,
  ProfitabilityReportSummaryRow,
  TurboCashoutRules,
} from 'src/app/jackpot/models/settings.sportsbook.models';
import {
  GET_SPORTSBOOK_SETTINGS_DATA,
  SET_FAILURE,
  SET_PROFITABILITY_REPORT,
  SET_PROFITABILITY_REPORT_SUMMARY,
  SET_TURBO_CASHOUT_RULES,
  SPORTSBOOK_SETTINGS_ACTION,
  SPORTSBOOK_SETTINGS_ACTION_CHANGE_MARGIN_RATIO_SUCCESS,
  SPORTSBOOK_SETTINGS_ACTION_CHANGE_ODDS_THRESHOLD_SUCCESS,
  SPORTSBOOK_SETTINGS_ACTION_CHANGE_STATUS_SUCCESS,
  SPORTSBOOK_SETTINGS_ACTION_SUCCESS,
  SPORTSBOOK_SETTINGS_DELETE_RULE_SUCCESS,
} from '../actions/settings.sportsbook.actions';
import * as fromApp from 'abs@core/store/reducers';
import { createSelector } from '@ngrx/store';
import {
  CheckIfEnumListIsUpdatedWithCustomData,
  DropDownBuilder,
  getBetTypes,
  getBetTypesConverted,
  getBetTypesDropDown,
  getBranches,
  getBranchesConverted,
  getBranchesDropDown,
  getCurrenciesEnumMap,
  getEnumsMetadata,
  getSportsTypeMAP,
  getSportsTypesDropDown,
  getTurboCashoutFeeTypeEnumMap,
} from 'abs@core/store/reducers/metadata.reducer';
import { BetType, IdNameExtraRow } from 'abs@core/models/coreModels';
import copy from 'fast-copy';

export interface State {
  loading: boolean;
  processing: boolean;
  turboCashoutStatus: boolean;
  turboCashoutRules: TurboCashoutRules[];
  turboCashOutOddsThreshold: number;
  turboCashOutMarginRatio: number;
  profitabilityReportTotalRecords: number;
  profitabilityReportSummaryTotalRecords: number;
  turboCashOutProfitabilityReport: ProfitabilityReportRow[];
  turboCashOutProfitabilityReportSummary: ProfitabilityReportSummaryRow[];
  maxAmountToWin: number;
}
export const initialState: State = {
  loading: false,
  processing: false,
  turboCashoutRules: null,
  turboCashoutStatus: null,
  turboCashOutOddsThreshold: null,
  turboCashOutMarginRatio: null,
  turboCashOutProfitabilityReport: null,
  profitabilityReportTotalRecords: null,
  profitabilityReportSummaryTotalRecords: null,
  turboCashOutProfitabilityReportSummary: null,
  maxAmountToWin: null,
};

export function SettingsSportsBookReducer(state = initialState, action: any): State {
  switch (action.type) {
    case GET_SPORTSBOOK_SETTINGS_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case SPORTSBOOK_SETTINGS_ACTION: {
      return {
        ...state,
        processing: true,
      };
    }
    case SPORTSBOOK_SETTINGS_ACTION_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case SPORTSBOOK_SETTINGS_DELETE_RULE_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case SPORTSBOOK_SETTINGS_ACTION_CHANGE_STATUS_SUCCESS: {
      return {
        ...state,
        processing: false,
        turboCashoutStatus: !state.turboCashoutStatus,
      };
    }
    case SPORTSBOOK_SETTINGS_ACTION_CHANGE_ODDS_THRESHOLD_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case SPORTSBOOK_SETTINGS_ACTION_CHANGE_MARGIN_RATIO_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case SET_TURBO_CASHOUT_RULES: {
      return {
        ...state,
        loading: false,
        turboCashoutRules: action.payload.rules,
        turboCashOutOddsThreshold: action.payload.turboCashOutOddsThreshold,
        turboCashOutMarginRatio: action.payload.turboCashOutMarginRatio,
        turboCashoutStatus: !!+action.payload.turboCashOutEnabled,
        maxAmountToWin: action.payload.maxAmountToWin,
      };
    }
    case SET_PROFITABILITY_REPORT: {
      return {
        ...state,
        turboCashOutProfitabilityReport: action.payload.reportRows,
        profitabilityReportTotalRecords: +action.payload.totalRecords,
        loading: false,
      };
    }
    case SET_PROFITABILITY_REPORT_SUMMARY: {
      return {
        ...state,
        turboCashOutProfitabilityReportSummary: action.payload.reportRows,
        profitabilityReportSummaryTotalRecords: +action.payload.totalRecords,
        loading: false,
      };
    }
    case SET_FAILURE: {
      return {
        ...state,
        loading: false,
        processing: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const getLoading = (state: fromApp.AppState) => state.settingsSportsBookReducer.loading;
export const getTurboCashoutRules = (state: fromApp.AppState) => state.settingsSportsBookReducer.turboCashoutRules;
export const getTurboCashoutStatus = (state: fromApp.AppState) => state.settingsSportsBookReducer.turboCashoutStatus;
export const getInProccess = (state: fromApp.AppState) => state.settingsSportsBookReducer.processing;
export const getOddsThreshold = (state: fromApp.AppState) => state.settingsSportsBookReducer.turboCashOutOddsThreshold;
export const getMarginRatio = (state: fromApp.AppState) => state.settingsSportsBookReducer.turboCashOutMarginRatio;
export const getMaxAmountToWin = (state: fromApp.AppState) => state.settingsSportsBookReducer.maxAmountToWin;
export const getProfitabilityReport = (state: fromApp.AppState) =>
  state.settingsSportsBookReducer.turboCashOutProfitabilityReport;
export const getProfitabilityReportToTalRecords = (state: fromApp.AppState) =>
  state.settingsSportsBookReducer.profitabilityReportTotalRecords;
export const getProfitabilityReportSummary = (state: fromApp.AppState) => {
  return copy(state.settingsSportsBookReducer.turboCashOutProfitabilityReportSummary);
};

export const getProfitabilityReportSummaryToTalRecords = (state: fromApp.AppState) =>
  state.settingsSportsBookReducer.profitabilityReportSummaryTotalRecords;
export const getBetTypesFromCore = (state: fromApp.AppState) => state.metadataReducer.betTypes;

export const GetProfitabilityReportSummaryCustomizedData = createSelector(getProfitabilityReportSummary, (data) => {
  if (data) {
    data.forEach((row) => {
      row.groupingKey = row.createBranch + '-' + row.currency;
    });
    return data;
  }
  return undefined;
});

// export const getTurboCashoutClientRules = createSelector(getTurboCashoutRules, getCurrenciesEnum,
//     (rules, currencies) => {
//         if (rules && rules.length > 0
//             && currencies && currencies.length > 0
//             ) {
//             rules.forEach(rule => {
//                 if (rule.currencies && rule.currencies.length > 0) {
//                     rule.clientCurrencies = new Array<string>();
//                     rule.currencies.forEach(currencyId => {
//                         const foundCurrency = currencies.find(curr => curr.value === currencyId);
//                         if (foundCurrency) {
//                             rule.clientCurrencies.push(foundCurrency.name);
//                         }
//                     });
//                 }
//             });
//             return rules;
//         }
//         return rules;
//     });
export const getTurboCashoutClientRulesById = (id) =>
  createSelector(getTurboCashoutClientRules, getBetTypesFromCore, (rules, betTypes) => {
    if (rules) {
      const rule = rules.find((rule) => rule.id === id);
      if (rule && betTypes) {
        rule.clientBetTypes = new Array<BetType>();
        rule.sportTypes = new Array<number>();
        rule.betTypes.forEach((betTypeId) => {
          const foundBetType = betTypes.find((betType) => betType.id == betTypeId);
          if (foundBetType) {
            rule.clientBetTypes.push(foundBetType);
            if (!rule.sportTypes.find((sportTypeId) => sportTypeId == foundBetType.sportTypeId)) {
              rule.sportTypes.push(foundBetType.sportTypeId);
            }
          }
        });
        return rule;
      }
    }
    return null;
  });

export const getTurboCashoutGridGuard = createSelector(
  getTurboCashoutRules,
  getBetTypes,
  getBranches,
  getEnumsMetadata,
  (rules, betTypes, branches, enums) => {
    return { rules, betTypes, branches, enums };
  }
);

export const getBetTypesByIds = (ids) =>
  createSelector(getBetTypes, (betTypes) => {
    if (ids) {
      const results = [];
      ids.forEach((id) => {
        const found = betTypes.find((betType) => betType.id == id);
        if (found) {
          results.push(found);
        }
      });
      return results;
    }
    return [];
  });

export const getTurboCashoutClientRules = createSelector(
  getTurboCashoutRules,
  getCurrenciesEnumMap,
  getBetTypesConverted,
  getBranchesConverted,
  getTurboCashoutFeeTypeEnumMap,
  (rules, currenciesMap, betTypes, branches, feeTypes) => {
    if (rules && rules.length > 0) {
      rules = copy(rules);
      rules.forEach((rule) => {
        //this is after the guard so i have all the data already
        rule.clientCurrencies = new Array<string>();
        rule.clientBetTypesForGrid = new Array<string>();
        rule.clientBranches = new Array<string>();
        rule.currencies.forEach((currencyId) => {
          if (currenciesMap.has(currencyId)) {
            rule.clientCurrencies.push(currenciesMap.get(currencyId).name);
          }
        });
        rule.clientFeeType = feeTypes.get(rule.feeType);
        rule.branches.forEach((branch) => {
          if (branches.has(branch)) {
            rule.clientBranches.push(branches.get(branch).name);
          }
        });
        rule.betTypes.forEach((betType) => {
          if (betTypes.get(betType)) {
            rule.clientBetTypesForGrid.push(betTypes.get(betType).name);
          }
        });
      });
      return rules;
    }
    return rules;
  }
);

export const getTurboCashoutRuleGuard = createSelector(
  getBetTypesDropDown,
  getBranchesDropDown,
  DropDownBuilder('eCurrency'),
  getTurboCashoutClientRules,
  getSportsTypesDropDown,
  (betTypes, branches, currencies, rules, sportsTypes) => {
    return { betTypes, branches, currencies, rules, sportsTypes };
  }
);
export const getBetTypesForTulTipByID = (ids) =>
  createSelector(getBetTypesConverted, (betTypesMap) => {
    if (ids && ids.length > 0 && betTypesMap.size > 0) {
      const infoDataList = [];
      ids.forEach((id) => {
        const betType = betTypesMap.get(id);
        const isLive = betType.isLive ? ' #LIVE#' : '';
        const infoToShow = '(' + betType.id + ') ' + betType.name + isLive;
        infoDataList.push(infoToShow);
      });
      return infoDataList;
    }
    return [];
  });
export const getBranchesForTulTipByID = (ids) =>
  createSelector(getBranchesConverted, (map) => {
    if (ids && ids.length > 0 && map.size > 0) {
      const infoDataList = [];
      ids.forEach((id) => {
        const val = map.get(id);
        if (val) {
          const infoToShow = '(' + val.id + ') ' + val.name;
          infoDataList.push(infoToShow);
        }
      });
      return infoDataList;
    }
    return [];
  });
export const getCurrenciesForTulTipByID = (ids) =>
  createSelector(
    getCurrenciesEnumMap,
    (map) => {
      if (ids && ids.length > 0 && map.size > 0) {
        const infoDataList = [];
        ids.forEach((id) => {
          const val = map.get(id);
          const infoToShow = '(' + val.value + ') ' + val.name;
          infoDataList.push(infoToShow);
        });
        return infoDataList;
      }
      return [];
    }
  );
export const getSportTypesForTulTipByID = (ids) =>
  createSelector(
    getSportsTypeMAP,
    (map) => {
      if (ids && ids.length > 0 && map.size > 0) {
        const infoDataList = [];
        ids.forEach((id) => {
          const val = map.get(id);
          const infoToShow = '(' + val.id + ') ' + val.name;
          infoDataList.push(infoToShow);
        });
        return infoDataList;
      }
      return [];
    }
    // (sportsMap) => {
    // if (sportsMap.size > 0 && sportsMap.has(id)) {
    //     const sport = sportsMap.get(id);
    //     let infoToShow = '(' +sport.id + ') ' + sport.name;
    //     return infoToShow;
    // }
    // }
  );
export const settingsReportsGuardData = createSelector(
  getBranches,
  getEnumsMetadata,
  CheckIfEnumListIsUpdatedWithCustomData,
  (branches, enums, enumsListIsUpdatedToLatest) => {
    return { branches, enums, enumsListIsUpdatedToLatest };
  }
);

export const GetSelectedBetTypes = (ids) =>
  createSelector(getBetTypesConverted, (betTypesMap) => {
    if (betTypesMap.size > 0) {
      const returnArray: IdNameExtraRow[] = [];
      ids.forEach((id) => {
        if (betTypesMap.has(id)) {
          const betType: BetType = betTypesMap.get(id);
          returnArray.push({
            id: id,
            name: betType.name,
            extra: betType.isLive ? 'Live' : null,
          });
        }
      });
      return returnArray;
    }
    return [];
  });
export const GetSelectedBranches = (ids) =>
  createSelector(getBranchesConverted, (branchesMap) => {
    if (branchesMap.size > 0) {
      const returnArray: IdNameExtraRow[] = [];
      ids.forEach((id) => {
        if (branchesMap.has(id)) {
          const branch = branchesMap.get(id);
          returnArray.push({
            id: id,
            name: branch.name,
          });
        }
      });
      return returnArray;
    }
    return [];
  });
