<form [formGroup]="form" (submit)="onSubmit()">
    <div class="grid">
        <div class="col-12 comments" *ngIf="showExternalId">
            <span class="md-inputfield">
                <input class="no-spin" pInputText [type]="data.extra.type" [formControlName]="data.extra.controlName" [placeholder]="data.extra.placeHolder">
                <div *ngIf="submitted && f[data.extra.controlName].errors" class="invalid-feedback">
                    <small class="p-invalid" *ngIf="f[data.extra.controlName]?.errors?.required">
                    {{'Required_Field' | translate}}
                </small>
                </div>
            </span>
        </div>
        <div class="col-12 comments">
            <textarea  pInputTextarea class="form-control" [placeholder]="data.placeHolder" formControlName="comments" [rows]="3"></textarea>
        </div>

        <div class="col-12 grid justify-content-end">
            <div class="col-4">
                <button class="p-button-secondary" (click)="close()" type="button" pButton icon="pi pi-times" label="{{'Cancel' | translate}}"></button>
            </div>
            <div class="col-3">
                <button class="p-button" type="submit" pButton icon="pi pi-check" label="{{'Submit' | translate}}" ></button>
            </div>
        </div>
    </div>
</form>
