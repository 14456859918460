import * as cms from '../actions/cms.languages.actions';
import * as fromApp from 'abs@core/store/reducers';

export interface State {
  loading: boolean;
  languages: any;
  selectedLanguage: string;
  selectedOnlineBranch: string;
  selectedChannel: string;
  selectedLanguageLoaded: boolean;
  jsonWasTouched: boolean;
  translationData: any;
  savingTranslation: boolean;
}
const initialState: State = {
  loading: false,
  languages: null,
  selectedLanguage: null,
  selectedOnlineBranch: null,
  selectedChannel: null,
  selectedLanguageLoaded: false,
  jsonWasTouched: false,
  savingTranslation: false,
  translationData: null,
};
export function CmsLanguagesReducer(state = initialState, action: cms.Actions): State {
  switch (action.type) {
    case cms.GET_LANGUAGES: {
      return {
        ...state,
        loading: true,
        languages: null,
        selectedLanguage: null,
        selectedLanguageLoaded: false,
      };
    }
    case cms.JSON_EDITOR_WAS_ROUTED: {
      return {
        ...state,
        translationData: state.jsonWasTouched ? action.payload : null,
        selectedChannel: state.jsonWasTouched ? state.selectedChannel : null,
        selectedLanguage: state.jsonWasTouched ? state.selectedLanguage : null,
        selectedOnlineBranch: state.jsonWasTouched ? state.selectedOnlineBranch : null,
      };
    }
    case cms.GET_LANGUAGES_FAILURE: {
      return {
        ...state,
        loading: false,
      };
    }
    case cms.SET_LANGUAGES: {
      return {
        ...state,
        languages: action.payload,
        loading: false,
      };
    }
    case cms.GET_TRANSLATIONS: {
      return {
        ...state,
        loading: true,
        selectedLanguage: action.payload.requestedLangCode,
        selectedChannel: action.payload.requestedChannelId,
        selectedOnlineBranch: action.payload.requestedBranchId,
      };
    }
    case cms.GET_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        translationData: null,
      };
    }
    case cms.SET_TRANSLATIONS: {
      return {
        ...state,
        translationData: action.payload,
        loading: false,
        selectedLanguageLoaded: true,
        jsonWasTouched: false,
      };
    }
    case cms.SAVE_TRANSLATIONS: {
      return {
        ...state,
        loading: true,
        savingTranslation: true,
      };
    }
    case cms.UNDO_CHANGES: {
      return {
        ...state,
      };
    }
    case cms.SAVE_TRANSLATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        jsonWasTouched: false,
        savingTranslation: false,
      };
    }
    case cms.SAVE_TRANSLATIONS_FAILURE: {
      return {
        ...state,
        loading: false,
        savingTranslation: false,
      };
    }
    case cms.JSON_WAS_TOUCHED: {
      return {
        ...state,
        jsonWasTouched: true,
      };
    }
    default: {
      return state;
    }
  }
}

export const getTranslations = (state: fromApp.AppState) => {
  return state.cmsLanguagesReducer.translationData;
};
export const getSelectedLanguage = (state: fromApp.AppState) => {
  //if (state.cmsLanguagesReducer.selectedLanguageLoaded) {
  return state.cmsLanguagesReducer.selectedLanguage;
  //}
};
export const getSelectedChannel = (state: fromApp.AppState) => {
  if (state.cmsLanguagesReducer.selectedLanguageLoaded) {
    return state.cmsLanguagesReducer.selectedChannel;
  }
  return undefined;
};

export const getSelectedOnlineBranch = (state: fromApp.AppState) => {
  if (state.cmsLanguagesReducer.selectedLanguageLoaded) {
    return state.cmsLanguagesReducer.selectedOnlineBranch;
  }
  return undefined;
};
export const getLanguages = (state: fromApp.AppState) => {
  return state.cmsLanguagesReducer.languages;
};
export const getJsonWasTouched = (state: fromApp.AppState) => {
  return state.cmsLanguagesReducer.jsonWasTouched;
};
export const getSavingTranslationStatus = (state: fromApp.AppState) => {
  return state.cmsLanguagesReducer.savingTranslation;
};
