import { Action } from '@ngrx/store';
import { GET_ONLINE_VOUCHER_DATA } from "../../../voucher/store/actions/online-voucher.actions";
export const SET_ONLINE_CUSTOMERS = '[ONLINE-CUSTOMERS] Set online customers data';
export const GET_ONLINE_CUSTOMERS_DATA = '[ONLINE-CUSTOMERS] Get online customers data';
export const CLEAR_ONLINE_CUSTOMERS_DATA = '[ONLINE-CUSTOMERS] Clear online customers data';
export const SET_ONLINE_CUSTOMERS_LOADING = '[ONLINE-CUSTOMERS] Set online customers loading';
export const EXPORT_ALL_DATA_TO_EXCEL = '[ONLINE-CUSTOMERS] Export all data to excel';
export const EXPORT_ALL_DATA_TO_EXCEL_SUCCESS = '[ONLINE-CUSTOMERS] Export all data to excel success';

export const SET_ONLINE_CUSTOMER_PROFILE = '[ONLINE-CUSTOMERS] Set online customer profile data';
export const RESET_ONLINE_CUSTOMER_PROFILE = '[ONLINE-CUSTOMERS] Reset online customer profile data';
export const SET_ONLINE_CUSTOMER_PROFILE_SELF_EXCLUDED = '[ONLINE-CUSTOMERS] Set online customer profile self excluded data';
export const SET_PROCESSING_SELF_EXCLUDED = '[ONLINE-CUSTOMERS] Set Processing self excluded';
export const SET_ONLINE_CUSTOMER_DEPOSIT_LIMITS = '[ONLINE-CUSTOMERS] Set deposit limits';
export const SET_SUPPORTED_LANGUAGES = '[ONLINE-CUSTOMERS] Set supported languages';
export const SET_ONLINE_CUSTOMER_PROFILE_NOTES = '[ONLINE-CUSTOMERS] Set online customer profile data notes';
export const SET_ONLINE_CUSTOMER_PROFILE_LINK_ACCOUNTS =
  '[ONLINE-CUSTOMERS] Set online customer profile data connected users';
export const SET_ONLINE_CUSTOMER_BALANCE_DATA = '[ONLINE-CUSTOMERS] Set online customer balance data';
export const SET_ONLINE_CUSTOMER_MONTHLY_DEPOSIT_WITHDRAW_BALANCE_DATA =
  '[ONLINE-CUSTOMERS] Set online customer monthly deposit withdraw balance data';
export const SET_ONLINE_CUSTOMER_MONTHLY_REVENUE_BALANCE_DATA =
  '[ONLINE-CUSTOMERS] Set online customer monthly revenue balance data';
export const SET_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1 =
  '[ONLINE-CUSTOMERS] Set online customer balance data for specific date range 1';
export const SET_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2 =
  '[ONLINE-CUSTOMERS] Set online customer balance data for specific date range 2';
export const LOADING_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1 =
  '[ONLINE-CUSTOMERS] Loading online customer balance data for specific date range 1';
export const LOADING_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2 =
  '[ONLINE-CUSTOMERS] Loading online customer balance data for specific date range 2';

export const CLEAR_ONLINE_CUSTOMER_BALANCE_DATA = '[ONLINE-CUSTOMERS] Clear online customer balance data';
export const CLEAR_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1 =
  '[ONLINE-CUSTOMERS] Clear online customer balance data for specific date range 1';
export const CLEAR_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2 =
  '[ONLINE-CUSTOMERS] Clear online customer balance data for specific date range 2';

export const LOCK_UNLOCK_CUSTOMER_ACTION_FAILURE = '[ONLINE-CUSTOMERS] Lock/Unlock action failure'; //ONLINE_ACTION_SUCCESS
export const ADD_CUSTOMER_NOTE_ACTION_FAILURE = '[ONLINE-CUSTOMERS] Add customer note action failure'; //ONLINE_ACTION_SUCCESS
export const ADD_CUSTOMER_NOTE_ACTION_SUCCESS = '[ONLINE-CUSTOMERS] Add customer note action success'; //ONLINE_ACTION_SUCCESS

export const ADD_LINKED_ACCOUNT_ACTION_SUCCESS = '[ONLINE-CUSTOMERS] Add linked account action success';
export const ADD_LINKED_ACCOUNT_ACTION_FAILURE = '[ONLINE-CUSTOMERS] Add linked account action failure';

export const REMOVE_LINKED_ACCOUNT_ACTION_SUCCESS = '[ONLINE-CUSTOMERS] remove linked account action success';
export const REMOVE_LINKED_ACCOUNT_ACTION_FAILURE = '[ONLINE-CUSTOMERS] remove linked account action failure';

export const GET_EXTERNALTX_DATA = '[ONLINE-CUSTOMERS] Get ExternalTX data';
export const SET_ONLINE_CUSTOMER_TRANSFERS = '[ONLINE-CUSTOMERS] Set online transfers';
export const CLEAR_ONLINE_CUSTOMER_TRANSFERS = '[ONLINE-CUSTOMERS] Clear online transfers';
export const CLEAR_BATCH_DATA = 'Clear batch data;';
export const SET_CUSTOMER_STATEMENT = '[ONLINE-CUSTOMERS] Set customer statement';
export const SET_CUSTOMER_BONUSES = '[ONLINE-CUSTOMERS] Set customer bonuses';
export const FAILURE = '[ONLINE-CUSTOMERS] Failure';
export const SET_FAILURE = '[ONLINE-CUSTOMERS] Set Failure';

export class SetOnlineCustomerTransfers implements Action {
  readonly type = SET_ONLINE_CUSTOMER_TRANSFERS;

  constructor(public payload: any = null) { }
}

export class ClearOnlineCustomerTransfers implements Action {
  readonly type = CLEAR_ONLINE_CUSTOMER_TRANSFERS;
}

export class ClearBatchData implements Action {
  readonly type = CLEAR_BATCH_DATA;
}

export class GetExternalTXData implements Action {
  readonly type = GET_EXTERNALTX_DATA;

  constructor(public payload: any = null) { }
}

export class LockUnlockActionFailed implements Action {
  readonly type = LOCK_UNLOCK_CUSTOMER_ACTION_FAILURE;

  constructor(public payload: any = null) { }
}
export class AddCustomerNoteSuccess implements Action {
  readonly type = ADD_CUSTOMER_NOTE_ACTION_SUCCESS;

  
}
export class AddCustomerNoteFailure implements Action {
  readonly type = ADD_CUSTOMER_NOTE_ACTION_FAILURE;

  
}

export class AddLinkedAccountSuccess implements Action {
  readonly type = ADD_LINKED_ACCOUNT_ACTION_SUCCESS;

  
}
export class AddLinkedAccountFailure implements Action {
  readonly type = ADD_LINKED_ACCOUNT_ACTION_FAILURE;

  
}

export class RemoveLinkedAccountSuccess implements Action {
  readonly type = REMOVE_LINKED_ACCOUNT_ACTION_SUCCESS;

  
}
export class RemoveLinkedAccountFailure implements Action {
  readonly type = REMOVE_LINKED_ACCOUNT_ACTION_FAILURE;

  
}

export class GetOnlineCustomersData implements Action {
  readonly type = GET_ONLINE_CUSTOMERS_DATA;

  constructor(public payload: any = null) { }
}

export class GetOnlineVoucherData implements Action {
  readonly type = GET_ONLINE_VOUCHER_DATA;

  constructor(public payload: any = null) { }
}

export class SetOnlineCustomers implements Action {
  readonly type = SET_ONLINE_CUSTOMERS;

  constructor(public payload: any = null) { }
}

export class SetOnlineCustomersLoading implements Action {
  readonly type = SET_ONLINE_CUSTOMERS_LOADING;

  constructor(public payload: any = null) { }
}

export class SetCustomerStatementsData implements Action {
  readonly type = SET_CUSTOMER_STATEMENT;

  constructor(public payload: any = null) { }
}

export class SetCustomerBonusesData implements Action {
  readonly type = SET_CUSTOMER_BONUSES;

  constructor(public payload: any = null) { }
}

export class SetOnlineCustomerProfile implements Action {
  readonly type = SET_ONLINE_CUSTOMER_PROFILE;

  constructor(public payload: any = null) { }
}

export class SetProcessingSelfExcluded implements Action {
  readonly type = SET_PROCESSING_SELF_EXCLUDED;

  constructor(public payload: boolean = null) {
  }
}

export class ResetOnlineCustomerProfile implements Action {
  readonly type = RESET_ONLINE_CUSTOMER_PROFILE;

  constructor(public payload: any = null) { }
}

export class SetSupportedLanguages implements Action {
  readonly type = SET_SUPPORTED_LANGUAGES;

  constructor(public payload: any = null) { }
}

export class SetOnlineCustomerProfileNotes implements Action {
  readonly type = SET_ONLINE_CUSTOMER_PROFILE_NOTES;

  constructor(public payload: any = null) { }
}
export class SetOnlineCustomerProfileLinkAccounts implements Action {
  readonly type = SET_ONLINE_CUSTOMER_PROFILE_LINK_ACCOUNTS;

  constructor(public payload: any = null) { }
}
export class SetOnlineCustomerBalanceData implements Action {
  readonly type = SET_ONLINE_CUSTOMER_BALANCE_DATA;

  constructor(public payload: any = null) { }
}
export class SetOnlineCustomerMonthlyRevenueBalanceData implements Action {
  readonly type = SET_ONLINE_CUSTOMER_MONTHLY_REVENUE_BALANCE_DATA;

  constructor(public payload: any = null) { }
}
export class SetOnlineCustomerMonthlyDepositWithdrawBalanceData implements Action {
  readonly type = SET_ONLINE_CUSTOMER_MONTHLY_DEPOSIT_WITHDRAW_BALANCE_DATA;

  constructor(public payload: any = null) { }
}
export class SetOnlineCustomerBalanceDataSpecificDateRange1 implements Action {
  readonly type = SET_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1;

  constructor(public payload: any = null) { }
}
export class SetOnlineCustomerBalanceDataSpecificDateRange2 implements Action {
  readonly type = SET_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2;

  constructor(public payload: any = null) { }
}
export class ClearOnlineCustomerBalanceData implements Action {
  readonly type = CLEAR_ONLINE_CUSTOMER_BALANCE_DATA;

  
}
export class ClearOnlineCustomerBalanceDataSpecificDateRange1 implements Action {
  readonly type = CLEAR_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1;

  
}
export class ClearOnlineCustomerBalanceDataSpecificDateRange2 implements Action {
  readonly type = CLEAR_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2;

  
}
export class LoadingOnlineCustomerBalanceDataSpecificDateRange1 implements Action {
  readonly type = LOADING_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1;

  
}
export class LoadingOnlineCustomerBalanceDataSpecificDateRange2 implements Action {
  readonly type = LOADING_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2;

  
}
export class ClearOnlineCustomersData implements Action {
  readonly type = CLEAR_ONLINE_CUSTOMERS_DATA;

  constructor(public payload: any = null) { }
}
export class ExportAllDataToExcel implements Action {
  readonly type = EXPORT_ALL_DATA_TO_EXCEL;

  constructor(public payload: any) { }
}
export class ExportAllDataToExcelSuccess implements Action {
  readonly type = EXPORT_ALL_DATA_TO_EXCEL_SUCCESS;

  
}
export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) { }
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions =
  | GetOnlineCustomersData
  | SetOnlineCustomerTransfers
  | ClearOnlineCustomerTransfers
  | SetOnlineCustomersLoading
  | SetOnlineCustomers
  | SetOnlineCustomerProfile
  | ResetOnlineCustomerProfile
  | SetOnlineCustomerProfileNotes
  | SetOnlineCustomerProfileLinkAccounts
  | ClearOnlineCustomersData
  | ExportAllDataToExcel
  | ExportAllDataToExcelSuccess
  | LockUnlockActionFailed
  | AddCustomerNoteSuccess
  | AddCustomerNoteFailure
  | AddLinkedAccountSuccess
  | AddLinkedAccountFailure
  | RemoveLinkedAccountSuccess
  | RemoveLinkedAccountFailure
  | SetFailure
  | GetExternalTXData
  | SetCustomerStatementsData
  | SetCustomerBonusesData
  | SetOnlineCustomerBalanceData
  | SetOnlineCustomerMonthlyDepositWithdrawBalanceData
  | SetOnlineCustomerMonthlyRevenueBalanceData
  | SetOnlineCustomerBalanceDataSpecificDateRange1
  | SetOnlineCustomerBalanceDataSpecificDateRange2
  | ClearOnlineCustomerBalanceData
  | ClearOnlineCustomerBalanceDataSpecificDateRange1
  | ClearOnlineCustomerBalanceDataSpecificDateRange2
  | LoadingOnlineCustomerBalanceDataSpecificDateRange1
  | LoadingOnlineCustomerBalanceDataSpecificDateRange2
  | Failure
  | SetSupportedLanguages
  | SetProcessingSelfExcluded;
