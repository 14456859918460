import * as BatchData from '../actions/batch.actions';
import { Batch, Distributor, voucherStatus, voucherStatusByBatch } from '../../models/batch.models';
import * as fromApp from 'abs@core/store/reducers';

export interface State {
    batchList: Array<Batch>;
    distributors: Array<Distributor>;
    totalRecords: number;

    voucherStatus: voucherStatus[],
    voucherStatusByBatch: voucherStatusByBatch;
    loading: boolean;
}

export const initialState: State =
{
    batchList: null,
    distributors: null,
    totalRecords: null,
    loading: null,

    voucherStatus: null,
    voucherStatusByBatch: null
};

export function BatchReducer(state = initialState, action: BatchData.Actions): State {
    switch (action.type) {
        case BatchData.GET_BATCH_DATA: {
            return {
                ...state,
                loading: true
            };
        }
        case BatchData.GET_BATCH_DATA_SUCCESS: {
            return {
                ...state,
                batchList: action.payload.data.batches.items,
                distributors: action.payload.data.distributor,
                totalRecords: action.payload.data.batches.count,
                loading: false
            };
        }
        default:
            return state;
    }
}

export const getVoucherBatches = (state: fromApp.AppState) => {
    return state.batchDataReducer.batchList;
};

export const getDistributors = (state: fromApp.AppState) => {
    return state.batchDataReducer.distributors;
};

export const getTotalRecords = (state: fromApp.AppState) => {
    return state.batchDataReducer.totalRecords;
};

export const getLoadingStatus = (state: fromApp.AppState) => {
    return state.batchDataReducer.loading;
};
