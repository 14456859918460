import * as expensesReportActions from '../actions/expenses-report.actions';
import { AppState } from 'abs@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { getBranches, getEnumsMetadata } from 'abs@core/store/reducers/metadata.reducer';
import copy from 'fast-copy';

export interface State {
  loadingBranchesExpensesReport: boolean;
  loadingExpensesReport: boolean;
  branchesExpensesReportData: any;
  expensesReportData: any;
  expensesReportLoaded: boolean;
  branchesExpensesReportLoaded: boolean;
}

export const initialState: State = {
  loadingBranchesExpensesReport: false,
  loadingExpensesReport: false,
  branchesExpensesReportData: null,
  expensesReportData: null,
  expensesReportLoaded: false,
  branchesExpensesReportLoaded: false,
};

export function ExpensesReportReducer(state = initialState, action: any) {
  switch (action.type) {
    case expensesReportActions.GET_EXPENSES_REPORT: {
      return {
        ...state,
        loadingExpensesReport: true,
        expensesReportData: [],
        expensesReportLoaded: false,
      };
    }
    case expensesReportActions.SET_EXPENSES_REPORT: {
      return {
        ...state,
        loadingExpensesReport: false,
        expensesReportData: action.payload,
        expensesReportLoaded: true,
      };
    }
    case expensesReportActions.GET_BRANCHES_EXPENSES_REPORT: {
      return {
        ...state,
        loadingBranchesExpensesReport: true,
        branchesExpensesReportData: [],
        branchesExpensesReportLoaded: false,
      };
    }
    case expensesReportActions.SET_BRANCHES_EXPENSES_REPORT: {
      return {
        ...state,
        loadingBranchesExpensesReport: false,
        branchesExpensesReportData: action.payload,
        branchesExpensesReportLoaded: true,
      };
    }
    case expensesReportActions.FAILURE: {
      return {
        ...state,
        loadingItems: false,
        loadingCategories: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const isBranchesExpensesReportLoading = (state: AppState) =>
  state.expensesReportReducer ? state.expensesReportReducer.loadingBranchesExpensesReport : null;
export const isBranchesExpensesReportLoaded = (state: AppState) =>
  state.expensesReportReducer ? state.expensesReportReducer.branchesExpensesReportLoaded : null;
export const getBranchesExpensesReportData = (state: AppState) =>
  state.expensesReportReducer ? copy(state.expensesReportReducer.branchesExpensesReportData) : null;

export const getExpensesReportData = (state: AppState) =>
  state.expensesReportReducer ? state.expensesReportReducer.expensesReportData : null;
export const isExpensesReportLoading = (state: AppState) =>
  state.expensesReportReducer ? state.expensesReportReducer.loadingExpensesReport : null;
export const isExpensesReportLoaded = (state: AppState) =>
  state.expensesReportReducer ? state.expensesReportReducer.expensesReportLoaded : null;

// export const selectSumAndTotalExpensesView = createSelector(getExpensesViewItems,
//   (viewItems) => {
//   if (viewItems) {
//     return viewItems.reduce(function (sum, expense) {
//       return sum + expense.Amount
//     }, 0)
//   }
//   })
export const expensesGuardData = createSelector(getBranches, getEnumsMetadata, (branches, enums) => {
  return { branches, enums };
});
