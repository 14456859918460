import { createAction, props } from '@ngrx/store';
import { Filtering } from 'src/app/sport-betting/common/models';
import { EventBetType, RevertEventBetTypeManualField, RevertEventBetTypeOddManualField, UpdateEventBetTypeManualField, UpdateEventBetTypeOddManualField } from '../../models';

const SB_GET_EVENT_BET_TYPES = '[SB] Get event bet-types';
const SB_SET_EVENT_BET_TYPES = '[SB] Set event bet-types';

const SB_GET_EVENT_BET_TYPE_BY_ID = '[SB] Get event bet-type by id';
const SB_SET_EVENT_BET_TYPES_BY_ID = '[SB] Set event bet-types by id';

const SB_UPDATE_BETTYPE_MANUL_PROPERTY = '[SB] Update bettype manual property';
const SB_UPDATE_BETTYPE_MANUL_PROPERTY_RESULTED = '[SB] Update bettype manual property RESULTED';

const SB_REVERT_BETTYPE_MANUL_PROPERTY = '[SB] Revert bettype manual property';
const SB_REVERT_BETTYPE_MANUL_PROPERTY_RESULTED = '[SB] Revert bettype manual property RESULTED';

const SB_UPDATE_BETTYPE_ODD_MANUL_PROPERTY = '[SB] Update bettype odd manual property';
const SB_UPDATE_BETTYPE_ODD_MANUL_PROPERTY_RESULTED = '[SB] Update bettype odd manual property RESULTED';

const SB_REVERT_BETTYPE_ODD_MANUL_PROPERTY = '[SB] Revert bettype odd manual property';
const SB_REVERT_BETTYPE_ODD_MANUL_PROPERTY_RESULTED = '[SB] Revert bettype odd manual property RESULTED';


export const SbGetEventBetTypes = createAction(SB_GET_EVENT_BET_TYPES, props<{ payload: Filtering }>());
export const SbSetEventBetTypes = createAction(SB_SET_EVENT_BET_TYPES, props<{
  payload: {
    eventId: number,
    items: Array<EventBetType>,
    totalCount: number;
  }
}>());

export const SbGetEventBetTypeById = createAction(SB_GET_EVENT_BET_TYPE_BY_ID, props<{
  payload: {
    eventId: number,
    betTypeId: number
  }
}>());
export const SbSetEventBetTypesById = createAction(SB_SET_EVENT_BET_TYPES_BY_ID, props<{
  payload: EventBetType
}>());

export const SbUpdateBetTypeManualProperty = createAction(SB_UPDATE_BETTYPE_MANUL_PROPERTY, props<{ payload: UpdateEventBetTypeManualField }>());
export const SbUpdateBetTypeManualPropertyResulted = createAction(SB_UPDATE_BETTYPE_MANUL_PROPERTY_RESULTED, props<{
  payload: {
    request: UpdateEventBetTypeManualField,
    response: EventBetType,
    isSuccessful: boolean,
  }
}>());

export const SbRevertBetTypeManualProperty = createAction(SB_REVERT_BETTYPE_MANUL_PROPERTY, props<{ payload: RevertEventBetTypeManualField }>());
export const SbRevertBetTypeManualPropertyResulted = createAction(SB_REVERT_BETTYPE_MANUL_PROPERTY_RESULTED, props<{
  payload: {
    request: RevertEventBetTypeManualField,
    response: EventBetType,
    isSuccessful: boolean,
  }
}>());

export const SbUpdateBetTypeOddManualProperty = createAction(SB_UPDATE_BETTYPE_ODD_MANUL_PROPERTY, props<{ payload: UpdateEventBetTypeOddManualField }>());
export const SbUpdateBetTypeOddManualPropertyResulted = createAction(SB_UPDATE_BETTYPE_ODD_MANUL_PROPERTY_RESULTED, props<{
  payload: {
    request: UpdateEventBetTypeOddManualField,
    response: EventBetType,
    isSuccessful: boolean,
  }
}>());

export const SbRevertBetTypeOddManualProperty = createAction(SB_REVERT_BETTYPE_ODD_MANUL_PROPERTY, props<{ payload: RevertEventBetTypeOddManualField }>());
export const SbRevertBetTypeOddManualPropertyResulted = createAction(SB_REVERT_BETTYPE_ODD_MANUL_PROPERTY_RESULTED, props<{
  payload: {
    request: RevertEventBetTypeOddManualField,
    response: EventBetType,
    isSuccessful: boolean,
  }
}>());

