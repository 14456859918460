import { createReducer, on } from '@ngrx/store';
import { eventScoresAdapter, eventScoresInitialState } from '../adapters';
import { EventScoresFailure, SbRevertEventScore, SbSetEventScores, SbUpdateEventScore } from '../actions';

export const EventScoresReducer = createReducer(
  eventScoresInitialState,
  on(SbSetEventScores, (state, { payload }) => {
    return eventScoresAdapter.setAll(payload, {
      ...state,
      isLoading: false
    });
  }),
  on(SbUpdateEventScore, SbRevertEventScore, (state) => {
    return {
      ...state,
      isLoading: true
    };
  }),
  on(EventScoresFailure, (state) => {
    return {
      ...state,
      isLoading: false
    };
  })
);