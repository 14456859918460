import * as expensesRequestsActions from '../actions/expenses-requests.actions';
import { createSelector } from '@ngrx/store';
import { Expense } from '../../../../models/expense';
import { AppState } from 'abs@core/store/reducers';
import { getBranches, getEnumsMetadata } from 'abs@core/store/reducers/metadata.reducer';

export interface State {
  loadingExpensesView: boolean;
  expensesViewLoaded: boolean;
  expensesViewItems: Expense[];
  expensesViewCount: number;
  expensesViewTotalAmount: number;
  savingExpense: boolean;
}

export const initialState: State = {
  loadingExpensesView: false,
  expensesViewLoaded: false,
  expensesViewItems: null,
  expensesViewCount: null,
  expensesViewTotalAmount: null,
  savingExpense: false,
};

export function ExpensesRequestsReducer(state = initialState, action: any) {
  switch (action.type) {
    case expensesRequestsActions.SAVE_EXPENSE: {
      return {
        ...state,
        savingExpense: true,
      };
    }
    case expensesRequestsActions.SAVE_EXPENSE_SUCCESS: {
      return {
        ...state,
        savingExpense: false,
      };
    }
    case expensesRequestsActions.SAVE_EXPENSE_FAIL: {
      return {
        ...state,
        savingExpense: false,
      };
    }
    case expensesRequestsActions.GET_EXPENSES_VIEW: {
      return {
        ...state,
        loadingExpensesView: true,
        expensesViewItems: [],
        expensesViewLoaded: false,
      };
    }
    case expensesRequestsActions.SET_EXPENSES_VIEW: {
      return {
        ...state,
        loadingExpensesView: false,
        expensesViewItems: action.payload.Expenses,
        expensesViewLoaded: true,
        expensesViewCount:
          typeof action.payload.TotalRecords === 'string'
            ? Number(action.payload.TotalRecords)
            : action.payload.TotalRecords,
        expensesViewTotalAmount:
          typeof action.payload.TotalAmount === 'string'
            ? Number(action.payload.TotalAmount)
            : action.payload.TotalAmount,
      };
    }
    case expensesRequestsActions.FAILURE: {
      return {
        ...state,
        loadingItems: false,
        loadingCategories: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const getExpensesViewItems = (state: AppState) =>
  state.expensesRequestsReducer ? state.expensesRequestsReducer.expensesViewItems : null;
export const getExpensesViewItemsCount = (state: AppState) =>
  state.expensesRequestsReducer ? state.expensesRequestsReducer.expensesViewCount : null;
export const getExpensesViewItemsTotals = (state: AppState) =>
  state.expensesRequestsReducer ? state.expensesRequestsReducer.expensesViewTotalAmount : null;
export const isExpensesViewLoading = (state: AppState) =>
  state.expensesRequestsReducer ? state.expensesRequestsReducer.loadingExpensesView : null;
export const isExpensesViewLoaded = (state: AppState) =>
  state.expensesRequestsReducer ? state.expensesRequestsReducer.expensesViewLoaded : null;

export const getSavingExpenseStatus = (state: AppState) =>
  state.expensesRequestsReducer ? state.expensesRequestsReducer.savingExpense : null;

// export const selectSumAndTotalExpensesView = createSelector(getExpensesViewItems,
//   (viewItems) => {
//   if (viewItems) {
//     return viewItems.reduce(function (sum, expense) {
//       return sum + expense.Amount
//     }, 0)
//   }
//   })
export const expensesGuardData = createSelector(getBranches, getEnumsMetadata, (branches, enums) => {
  return { branches, enums };
});
