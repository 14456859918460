import { Action } from '@ngrx/store';

export const GET_BANNERS_FAILURE = '[CMS-Banner] Get Banner Failure';

export const EDIT_NEW_BANNER = '[CMS-Banner] Edit new banner';
export const EDIT_EXISTING_BANNER = '[CMS-Banner] Edit existing banner';
export const BANNERS_ACTION_REQUEST = '[CMS-Banner] Banner action request';
export const BANNERS_ACTION_FORM_REQUEST = '[CMS-Banner] Banner action form request';

export const SAVE_BANNER_FAILURE = '[CMS-Banner] Save Banner Failure';

export const GET_BANNER_DATA = '[CMS-Banner] Get Banner Data';
export const SET_BANNER_DATA = '[CMS-Banner] Set Banner Data';
export const UPDATE_BANNER_DATA_SUCCESS = '[CMS-Banner] Update Banner Data success';
export const CLONE_BANNER_DATA_SUCCESS = '[CMS-Banner] Clone Banner Data success';
export const DELETE_BANNER_SUCCESS = '[CMS-Banner] Delete Banner success';
export const SET_SUPPORTED_LANGUAGES = '[CMS-Banner] Set Supported Languages';
export const GET_BANNER_PLACEMENTS = '[CMS-Banner] Get banners placements';
export const SET_BANNER_PLACEMENTS = '[CMS-Banner] Set banners placements';
export const EDIT_BANNER_PLACEMENT = '[CMS-Banner] Edit Banner placement';
export const EDIT_BANNER_PLACEMENT_SUCCESS = '[CMS-Banner] Edit Banner placement success';
export const GET_PLACEMENT_FAILURE = '[CMS-Banner] Get placement failure';
export const CLOSE_EDITED_BANNER = '[CMS-Banner] Remove edited banner';

export class CloneBannerSuccess implements Action {
  readonly type = CLONE_BANNER_DATA_SUCCESS;

  constructor(public payload: any = null) {}
}
export class DeleteBannerSuccess implements Action {
  readonly type = DELETE_BANNER_SUCCESS;

  constructor(public payload: any = null) {}
}
export class UpdateBannerSuccess implements Action {
  readonly type = UPDATE_BANNER_DATA_SUCCESS;

  constructor(public payload: any = null) {}
}

export class GetBannerData implements Action {
  readonly type = GET_BANNER_DATA;

  constructor(public payload: any = null) {}
}
export class SetBannerData implements Action {
  readonly type = SET_BANNER_DATA;
  constructor(public payload: any = null) {}
}
export class EditNewBanner implements Action {
  readonly type = EDIT_NEW_BANNER;
  constructor(public payload: any = null) {}
}
export class EditExistingBanner implements Action {
  readonly type = EDIT_EXISTING_BANNER;
  constructor(public payload: any = null) {}
}
export class BannersActionRequest implements Action {
  readonly type = BANNERS_ACTION_REQUEST;
  constructor(public payload: any = null) {}
}
export class BannersActionFormRequest implements Action {
  readonly type = BANNERS_ACTION_FORM_REQUEST;
  constructor(public payload: any = null) {}
}
export class GetBannersFailure implements Action {
  readonly type = GET_BANNERS_FAILURE;

  
}
export class GetBannerPlacements implements Action {
  readonly type = GET_BANNER_PLACEMENTS;

  constructor(public payload: any = null) {}
}
export class SetBannerPlacements implements Action {
  readonly type = SET_BANNER_PLACEMENTS;

  constructor(public payload: any = null) {}
}
export class EditBannerPlacement implements Action {
  readonly type = EDIT_BANNER_PLACEMENT;

  constructor(public payload: any = null) {}
}
export class EditBannerPlacementSuccess implements Action {
  readonly type = EDIT_BANNER_PLACEMENT_SUCCESS;

  constructor(public payload: any = null) {}
}
export class GetPlacementFailure implements Action {
  readonly type = GET_PLACEMENT_FAILURE;

  constructor(public payload: any = null) {}
}

export class SaveBannerFailure implements Action {
  readonly type = SAVE_BANNER_FAILURE;

  
}

export class SetSupportedLanguages implements Action {
  readonly type = SET_SUPPORTED_LANGUAGES;

  constructor(public payload: any = null) {}
}

export class CloseEditedBanner implements Action {
  readonly type = CLOSE_EDITED_BANNER;

  constructor(public payload: any = null) {}
}

export type Actions =
  | UpdateBannerSuccess
  | DeleteBannerSuccess
  | CloneBannerSuccess
  | BannersActionRequest
  | BannersActionFormRequest
  | GetBannersFailure
  | SaveBannerFailure
  | GetBannerData
  | SetBannerData
  | EditNewBanner
  | EditExistingBanner
  | SetSupportedLanguages
  | GetBannerPlacements
  | SetBannerPlacements
  | GetPlacementFailure
  | EditBannerPlacement
  | EditBannerPlacementSuccess
  | CloseEditedBanner;
