import { createAction, props } from '@ngrx/store';
import { FailAction } from 'src/app/sport-betting/common/models';
import { EventScore, RevertEventScore, UpdateEventScore } from '../../models';

const SB_GET_EVENT_SCORES = '[SB] Get event scores';
const SB_SET_EVENT_SCORES = '[SB] Set event scores';

const SB_UPDATE_EVENT_SCORE = '[SB] Update event score';
const SB_REVERT_EVENT_SCORE = '[SB] Revert event score';

const SB_EVENT_SCORES_FAILURE = '[SB CRUD] Soccer Match failure';

export const SbGetEventScores = createAction(SB_GET_EVENT_SCORES, props<{ eventId: number }>());
export const SbSetEventScores = createAction(SB_SET_EVENT_SCORES, props<{
  payload: Array<EventScore>
}>());

export const SbUpdateEventScore = createAction(SB_UPDATE_EVENT_SCORE, props<{ payload: UpdateEventScore }>());
export const SbRevertEventScore = createAction(SB_REVERT_EVENT_SCORE, props<{ payload: RevertEventScore }>());
export const EventScoresFailure = createAction(SB_EVENT_SCORES_FAILURE, props<FailAction>());