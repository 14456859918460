import { Injectable } from '@angular/core';
import { ePermissionsLevel, eRolesLevel } from '../../core/app.config';
import { Store } from '@ngrx/store';
import { getPermissions } from '../../auth/store/reducers/auth';
import { tap } from 'rxjs/operators';
import { Permission } from '../../auth/models/user';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private userPermissions = new Map();
  private userRole: eRolesLevel;
  public redirectUrl: any;

  constructor(private store: Store<any>) {
    this.store
      .select(getPermissions)
      .pipe(
        tap((permissionList: Permission[]) => {
          this.setUserPermission(permissionList);
        })
      )
      .subscribe();
  }

  setUserPermission(permissionMatrix) {
    if (permissionMatrix) {
      for (let i = 0; i < permissionMatrix.length; i++) {
        this.userPermissions.set(permissionMatrix[i].codeAttribute, permissionMatrix[i].permission);
      }
    }
  }
  setUserRole(roleNumber) {
    this.userRole = roleNumber;
  }
  hasRole(permittedRoles: eRolesLevel | eRolesLevel[]) {
    if (permittedRoles === undefined) {
      return true;
    }
    if (typeof permittedRoles === 'number') {
      // case only one Role permitted
      return permittedRoles === this.userRole;
    } else if (typeof permittedRoles === 'object') {
      return permittedRoles.includes(this.userRole);
    }
    return false;
  }
  hasPermission(permittedPermissions: string | string[], requiredPermissionLevel = ePermissionsLevel.read): boolean {
    if (permittedPermissions === undefined) {
      return false;
    }
    if (typeof permittedPermissions === 'string' && permittedPermissions !== '') {
      // case its only one permission
      if (this.userPermissions.get(permittedPermissions) >= requiredPermissionLevel) {
        return true;
      } else {
        return false;
      }
    } else if (typeof permittedPermissions === 'object') {
      // case of few permission can access
      const permit = permittedPermissions.find((permission) => {
        if (this.userPermissions.get(permission) >= requiredPermissionLevel) {
          return true;
        }
        return false;
      });
      if (permit) {
        return true;
      } else {
        return false;
      }
    }
    return false;
  }

  hasPermissionNameAndLevel(permittedPermissions: string[], permittedLevel: ePermissionsLevel[]): boolean {
    if (permittedLevel.length != permittedPermissions.length) {
      return false;
    }
    for (let i = 0; i < permittedPermissions.length; i++) {
      if (this.userPermissions.get(permittedPermissions[i]) >= permittedLevel[i]) {
        return true;
      }
    }
    return false;
  }
}
