import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Event } from '../../models';

export interface EventsState extends EntityState<Event> {
  operationResult?: {
    [key: string]: {
      isSuccessful: boolean,
    }
  };
}

export const eventDetailAdapter: EntityAdapter<Event> = createEntityAdapter<Event>({
  selectId: (item) => item.eventId
});
export const eventDetailInitialState: EventsState = eventDetailAdapter.getInitialState({
});