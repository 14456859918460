<div class="home-page grid">
  <div class="col-12 ml-2"><h2>Most Popular</h2></div>
  <div class="col-12 sm:col-6 lg:col-3 scalein animation-duration-500 animation-iteration-1"
       *ngIf="checkPermission(permissionScreen.CUSTOMER_LIST_SCREEN, permissionLevel.read)">
    <div class="card text-center cursor-pointer mx-2" [routerLink]="'/crm/search-players'">
      <img src="assets/layout/images/custom/customer_list.png">
      <h4>Search Players</h4>
    </div>
  </div>
  <div class="col-12 sm:col-6 lg:col-3 scalein animation-duration-500 animation-iteration-1"
       *ngIf="checkPermission(permissionScreen.CUSTOMERS_DEPOSIT_WITHDRAW_SCREEN, permissionLevel.read)">
    <div class="card text-center cursor-pointer mx-2" [routerLink]="'/reports/deposit-withdraw'">
      <img src="assets/layout/images/custom/deposit_withdraw.png">
      <h4>Deposit / Withdraw</h4>
    </div>
  </div>
  <div class="col-12 sm:col-6 lg:col-3 scalein animation-duration-500 animation-iteration-1"
       *ngIf="checkPermission(permissionScreen.BETSLIPS_LIST_SCREEN, permissionLevel.read)">
    <div class="card text-center cursor-pointer mx-2" [routerLink]="'/reports/betslips'">
      <img src="assets/layout/images/custom/betslip_report.png">
      <h4>Betslip Report</h4>
    </div>
  </div>
  <div class="col-12 sm:col-6 lg:col-3 scalein animation-duration-500 animation-iteration-1"
    *ngIf="checkPermission(permissionScreen.DYNAMIC_REPORTS_FREE_BET_CAMPAIGN_SCREEN, permissionLevel.read)">
    <div class="card text-center cursor-pointer mx-2" [routerLink]="'/setting/template/7-63'">
      <img src="assets/layout/images/custom/menu_management.png">
      <h4>FreeBet Management</h4>
    </div>
  </div>
  <div class="col-12 sm:col-6 lg:col-3 scalein animation-duration-500 animation-iteration-1"
       *ngIf="checkPermission(permissionScreen.SETTINGS_BRAND_SETTINGS_SCREEN, permissionLevel.read)">
    <div class="card text-center cursor-pointer mx-2" [routerLink]="'/administration/brand-settings'">
      <img src="assets/layout/images/custom/brand_settings.png">
      <h4>Brand Settings</h4>
    </div>
  </div>
</div>
