import { AuthorizationOrder, EventLimit, LiveAuthorizationResponse } from '../../models/models';
import {
  CHECK_IF_NEW_AUTHORIZATION_REQUEST_EXIST,
  CLEAR_AUTHORIZATION_ORDER_DATA,
  CLEAR_EVENT_LIMIT_DATA,
  CLEAR_LIVE_AUTHORIZATION_DATA,
  GET_AUTHORIZATION_ORDER_DATA,
  GET_EVENT_LIMIT_DATA,
  GET_EXPOSURE_DATA,
  GET_LIVE_AUTHORIZATION_DATA,
  SET_AUTHORIZATION_ORDER_DATA,
  SET_EVENT_LIMIT_DATA,
  SET_IF_NEW_AUTHORIZATION_REQUEST_EXIST,
  SET_LIVE_AUTHORIZATION_DATA,
} from '../actions/exposure.actions';
import { GetEventsLimitRequestModel } from '../../models/requests';
import { FAILURE } from '../../../../roles-and-permissions/store/actions/roles-and-permissions.actions';
import * as fromApp from '../../../../core/store/reducers';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';
import {
  getBetTypes,
  getBranches,
  getEnumsMetadata,
  getEventsMetadataSportsTree,
} from '../../../../core/store/reducers/metadata.reducer';
import * as moment from 'moment';
import { APIMethodsExposure } from '../../exposure.config';

export interface State {
  processing: boolean;
  liveAuthorizationModel: LiveAuthorizationResponse;
  loadingLiveAuthorizationGrid: boolean;
  eventLimitModel: EventLimit[];
  totalEventsInEventLimitReport: number;
  loadingEventLimitModel: boolean;
  failureJustOccurred: boolean;
  existsNewEvents: boolean;
  liveAuthorizationRequestData: AuthorizationOrder;
  liveAuthorizationRequestPageProcessing: boolean;
}
export const initialState: State = {
  processing: false,
  liveAuthorizationModel: null,
  loadingLiveAuthorizationGrid: false,
  eventLimitModel: null,
  loadingEventLimitModel: false,
  totalEventsInEventLimitReport: null,
  failureJustOccurred: false,
  existsNewEvents: false,
  liveAuthorizationRequestData: null,
  liveAuthorizationRequestPageProcessing: false,
};
export function ExposureReducer(state = initialState, action: any): State {
  switch (action.type) {
    case GET_EXPOSURE_DATA: {
      return {
        ...state,
        loadingLiveAuthorizationGrid:
          action.payload.MethodName == APIMethodsExposure.GET_BETS_FOR_AUTHORIZATION
            ? true
            : state.loadingLiveAuthorizationGrid,
        loadingEventLimitModel:
          action.payload.Payload instanceof GetEventsLimitRequestModel ? true : state.loadingEventLimitModel,
        // failureJustOccurred: false,
      };
    }
    case SET_AUTHORIZATION_ORDER_DATA: {
      return {
        ...state,
        liveAuthorizationRequestPageProcessing: false,
        liveAuthorizationRequestData: action.payload,
      };
    }
    case GET_AUTHORIZATION_ORDER_DATA: {
      return {
        ...state,
        liveAuthorizationRequestPageProcessing: true,
      };
    }
    case CLEAR_AUTHORIZATION_ORDER_DATA: {
      return {
        ...state,
        liveAuthorizationRequestData: null,
      };
    }
    case GET_LIVE_AUTHORIZATION_DATA: {
      return {
        ...state,
        loadingLiveAuthorizationGrid: true,
      };
    }
    case GET_EVENT_LIMIT_DATA: {
      return {
        ...state,
        loadingEventLimitModel: true,
      };
    }
    case SET_LIVE_AUTHORIZATION_DATA: {
      return {
        ...state,
        loadingLiveAuthorizationGrid: false,
        liveAuthorizationModel: action.payload,
      };
    }
    case CLEAR_LIVE_AUTHORIZATION_DATA: {
      return {
        ...state,
        loadingLiveAuthorizationGrid: false,
        liveAuthorizationModel: null,
      };
    }
    case SET_IF_NEW_AUTHORIZATION_REQUEST_EXIST: {
      return {
        ...state,
        existsNewEvents: action.payload.existsNewEvents,
      };
    }
    case CHECK_IF_NEW_AUTHORIZATION_REQUEST_EXIST: {
      return {
        ...state,
        existsNewEvents: false,
      };
    }
    case SET_EVENT_LIMIT_DATA: {
      return {
        ...state,
        loadingEventLimitModel: false,
        eventLimitModel: action.payload.eventLimitList,
        totalEventsInEventLimitReport: action.payload.totalEvents,
      };
    }
    case CLEAR_EVENT_LIMIT_DATA: {
      return {
        ...state,
        loadingEventLimitModel: false,
        eventLimitModel: null,
        totalEventsInEventLimitReport: null,
      };
    }
    case FAILURE: {
      return {
        ...state,
        processing: false,
        failureJustOccurred: true,
      };
    }
    default: {
      return state;
    }
  }
}
export const getEventLimitModel = (state: fromApp.AppState) => state.exposureReducer.eventLimitModel;
export const getEventLimitLoadingStatus = (state: fromApp.AppState) => state.exposureReducer.loadingEventLimitModel;
export const getTotalEventsForEventLimitReport = (state: fromApp.AppState) =>
  state.exposureReducer.totalEventsInEventLimitReport;

export const getLiveAuthorizationModel = (state: fromApp.AppState) => {
  return state.exposureReducer.liveAuthorizationModel;
};
export const getLiveAuthorizationLoadingStatus = (state: fromApp.AppState) =>
  state.exposureReducer.loadingLiveAuthorizationGrid;
export const selectIfNewEventsExist = (state: fromApp.AppState) => state.exposureReducer.existsNewEvents;
export const selectLiveAuthorizationRequestData = (state: fromApp.AppState) =>
  state.exposureReducer.liveAuthorizationRequestData;
export const selectLiveAuthorizationRequestDataPageProcessing = (state: fromApp.AppState) =>
  state.exposureReducer.liveAuthorizationRequestPageProcessing;
export const selectLiveAuthorizationRequestDataProcessed = createSelector(
  selectLiveAuthorizationRequestData,
  (data) => {
    if (data) {
      const copyObj = copy(data);
      if (copyObj.requestTime) {
        const UTCtime = moment(copyObj.requestTime).unix();
        const currTemp = moment().utc().unix();
        const diff = currTemp - UTCtime;
        copyObj.timePassedSinceCreated = diff;
      }
      return copyObj;
    }
    return null;
  }
);
export const selectLiveAuthorizationRequestDataForGuard = createSelector(selectLiveAuthorizationRequestData, (data) => {
  if (!data) {
    return 'EMPTY';
  }
  return data;
});
export const selectLiveAuthorizationData = createSelector(getLiveAuthorizationModel, (liveAuthorization) => {
  if (liveAuthorization) {
    return liveAuthorization.liveAuthorizationList;
  }
  return null;
});
export const selectLiveAuthorizationDataProcessed = createSelector(getLiveAuthorizationModel, (liveAuthorization) => {
  if (liveAuthorization && liveAuthorization.liveAuthorizationList) {
    const copyObj = copy(liveAuthorization);
    copyObj.liveAuthorizationList.forEach((row) => {
      if (row.requestTime) {
        const UTCtime = moment(row.requestTime).unix();
        const currTemp = moment().utc().unix();
        const diff = currTemp - UTCtime;
        row.timePassedSinceCreated = diff;
      }
    });
    return copyObj.liveAuthorizationList;
  }
  return null;
});
export const selectLiveAuthorizationTotalBets = createSelector(getLiveAuthorizationModel, (liveAuthorization) => {
  if (liveAuthorization) {
    return liveAuthorization.totalBets;
  }
  return null;
});
export const getEventLimitModelProcessed = createSelector(getEventLimitModel, (limits) => {
  if (limits) {
    limits = copy(limits);
    limits.forEach((limit) => {
      limit.limitUsedPercentage = (limit.limitUsed / limit.currLimit) * 100;
    });
    return limits;
  }
  return null;
});
export const exposureGuardData = createSelector(getEnumsMetadata, (enums) => {
  return { enums };
});
export const selectAuthorizationHistoryGuardData = createSelector(
  getBranches,
  getEnumsMetadata,
  getBetTypes,
  getEventsMetadataSportsTree,
  (branches, enums, betTypes, sportTypes) => {
    return { branches, enums, betTypes, sportTypes };
  }
);
