import { Action } from '@ngrx/store';

export const GET_DISTRIBUTORS_DATA = 'voucher/getDistributorsData';
export const GET_DISTRIBUTORS_DATA_SUCCESS = 'voucher/getDistributorsDataSuccess';
export const CREATE_BATCH = 'voucher/createBatch';
export const CREATE_BATCH_SUCCESS = 'voucher/createBatchSuccess';
export const CREATE_BATCH_FAIL = 'voucher/createBatchFail';
export const END_REQUEST = 'voucher/endRequest';

export class GetDistributorsData implements Action {
    readonly type = GET_DISTRIBUTORS_DATA;

    constructor(public payload: any = null) {
    }
}

export class GetDistributorsDataSuccess implements Action {
    readonly type = GET_DISTRIBUTORS_DATA_SUCCESS;

    constructor(public payload: any = null) {
    }
}

export class CreateBatch implements Action {
    readonly type = CREATE_BATCH;

    constructor(public payload: any = null) { }
}

export class CreateBatchSuccess implements Action {
    readonly type = CREATE_BATCH_SUCCESS;

    constructor(public payload: any = null) { }
}

export class CreateBatchFail implements Action {
    readonly type = CREATE_BATCH_FAIL;

    constructor(public payload: any = null) { }
}

export class EndRequest implements Action {
    readonly type = END_REQUEST;

    constructor(public payload: any = null) { }
}

export type Actions =
    GetDistributorsData
    | GetDistributorsDataSuccess
    | CreateBatch
    | CreateBatchSuccess
    | CreateBatchFail
    | EndRequest