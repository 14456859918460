import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { getEnumByIdSelector } from '../../../../core/store/reducers/metadata.reducer';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-enum-val-call',
  templateUrl: './enum-val-call.component.html',
  styleUrls: ['./enum-val-call.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EnumValCallComponent {
  @Input() id: number;
  @Input() enum: string;
  @Input() noDataTemplate: boolean;

  constructor(private store: Store<any>) {
  }

  public getEnumValById(id, enumType): Observable<any> {
    return this.store.select(getEnumByIdSelector(id, enumType));
  }
}
