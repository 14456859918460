import { EntityState, EntityAdapter, createEntityAdapter } from "@ngrx/entity";
import { OperationResult } from "src/app/sport-betting/common/models/operation-result.model";
import { SportType } from "../../models/entities";

export interface SportsTypesState extends EntityState<SportType> {
  totalCount: number;
  operationResult?: OperationResult;
}

export const sportsTypesAdapter: EntityAdapter<SportType> = createEntityAdapter<SportType>({
  selectId: (item) => item.id,
});
export const sportTypesInitialState: SportsTypesState = sportsTypesAdapter.getInitialState({
  totalCount: 0,
});