import {
  CustomerNote,
  depositLimits,
  LinkedAccount,
  OnlineCustomer,
  onlineCustomerProfile,
} from '../../models/online-customers-list-models';
import {
  CLEAR_ONLINE_CUSTOMER_BALANCE_DATA,
  CLEAR_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1,
  CLEAR_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2,
  CLEAR_ONLINE_CUSTOMER_TRANSFERS,
  CLEAR_ONLINE_CUSTOMERS_DATA,
  EXPORT_ALL_DATA_TO_EXCEL,
  EXPORT_ALL_DATA_TO_EXCEL_SUCCESS,
  FAILURE,
  SET_ONLINE_CUSTOMER_DEPOSIT_LIMITS,
  GET_ONLINE_CUSTOMERS_DATA,
  LOADING_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1,
  LOADING_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2,
  RESET_ONLINE_CUSTOMER_PROFILE,
  SET_CUSTOMER_BONUSES,
  SET_CUSTOMER_STATEMENT,
  SET_ONLINE_CUSTOMER_BALANCE_DATA,
  SET_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1,
  SET_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2,
  SET_ONLINE_CUSTOMER_MONTHLY_DEPOSIT_WITHDRAW_BALANCE_DATA,
  SET_ONLINE_CUSTOMER_MONTHLY_REVENUE_BALANCE_DATA,
  SET_ONLINE_CUSTOMER_PROFILE,
  SET_ONLINE_CUSTOMER_PROFILE_LINK_ACCOUNTS,
  SET_ONLINE_CUSTOMER_PROFILE_NOTES,
  SET_ONLINE_CUSTOMER_PROFILE_SELF_EXCLUDED,
  SET_PROCESSING_SELF_EXCLUDED,
  SET_ONLINE_CUSTOMER_TRANSFERS,
  SET_ONLINE_CUSTOMERS,
  SET_ONLINE_CUSTOMERS_LOADING,
  SET_SUPPORTED_LANGUAGES,
} from '../actions/online-customers-list.actions';
import * as fromApp from 'abs@core/store/reducers';
import { createSelector } from '@ngrx/store';
import {
  getBranches,
  getEnumsMetadata,
  getMarketingCampaigns,
} from 'abs@core/store/reducers/metadata.reducer';
import * as moment from 'moment';
import { CustomerStatement } from '../../modules/statement/models/models';
import { forEach } from 'lodash';
import copy from 'fast-copy';
import {
  BalanceData,
  MonthlyDepositWithdrawBalanceData,
  MonthlyRevenueBalanceData,
} from '../../modules/balance/models/models';
import { CustomerBonus } from '../../modules/bonuses/models/models';
import { CustomerTransfer } from '../../../deposit-withdraw/models/models';

export interface State {
  loading: boolean;
  processingProfile: boolean;
  processing: boolean;
  processingSelfExcluded: boolean;
  onlineCustomers: OnlineCustomer[];
  totalOnlineCustomers: number;
  customerProfileData: onlineCustomerProfile;
  failure: boolean;
  customerTransfers: CustomerTransfer[];
  totalTransfers: number;
  customerStatements: CustomerStatement[];
  totalCustomerStatements: number;
  customerBonuses: CustomerBonus[];
  totalCustomerBonuses: number;
  customerNotes: CustomerNote[];
  totalNotes: number;
  linkedAccounts: LinkedAccount[];
  totalLinkedAccounts: number;
  totalIncomes: number;
  totalOutflows: number;
  customerBalance: BalanceData;
  customerBalanceSpecificRange1: BalanceData;
  customerBalanceSpecificRange2: BalanceData;
  loadingCustomerBalanceSpecificRange1: boolean;
  loadingCustomerBalanceSpecificRange2: boolean;
  customerMonthlyDepositWithdrawBalance: MonthlyDepositWithdrawBalanceData[];
  customerMonthlyRevenueBalance: MonthlyRevenueBalanceData[];
  customerProfilelanguages: any;
  depositLimits: depositLimits[];
}

export const initialState: State = {
  loading: false,
  processingProfile: false,
  processing: false,
  processingSelfExcluded: false,
  onlineCustomers: null,
  totalOnlineCustomers: null,
  customerProfileData: null,
  failure: false,
  customerTransfers: null,
  totalTransfers: null,
  customerStatements: null,
  totalCustomerStatements: null,
  customerBonuses: null,
  totalCustomerBonuses: null,
  customerNotes: null,
  totalNotes: null,
  totalIncomes: null,
  totalOutflows: null,
  linkedAccounts: null,
  totalLinkedAccounts: null,
  customerBalance: null,
  customerBalanceSpecificRange1: null,
  customerBalanceSpecificRange2: null,
  loadingCustomerBalanceSpecificRange1: null,
  loadingCustomerBalanceSpecificRange2: null,
  customerMonthlyDepositWithdrawBalance: null,
  customerMonthlyRevenueBalance: null,
  customerProfilelanguages: null,
  depositLimits: null,
};

export function OnlineCustomersReducer(state = initialState, action: any): State {
  switch (action.type) {
    case GET_ONLINE_CUSTOMERS_DATA: {
      return {
        ...state,
        loading: true,
        // customerProfileData: null,
        failure: false,
        processingProfile: action.payload.constructor.name == 'GetOnlineCustomerProfileRequest',
      };
    }
    case SET_ONLINE_CUSTOMERS_LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case SET_ONLINE_CUSTOMER_TRANSFERS: {
      return {
        ...state,
        loading: false,
        customerTransfers: action.payload.transfers,
        totalTransfers: +action.payload.totalCountTransfers,
        processing: false,
      };
    }
    case CLEAR_ONLINE_CUSTOMER_TRANSFERS: {
      return {
        ...state,
        customerTransfers: null,
        totalTransfers: 0,
        loading: false,
        processing: false,
      };
    }
    case SET_ONLINE_CUSTOMERS: {
      return {
        ...state,
        loading: false,
        onlineCustomers: action.payload.boCustomers,
        totalOnlineCustomers: +(action.payload.totalRecords || 0),
      };
    }
    case SET_ONLINE_CUSTOMER_PROFILE: {
      return {
        ...state,
        customerProfileData: action.payload,
        processingProfile: false,
      };
    }
    case RESET_ONLINE_CUSTOMER_PROFILE: {
      return {
        ...state,
        customerProfileData: null,
        processingProfile: false,
      };
    }
    case SET_ONLINE_CUSTOMER_PROFILE_SELF_EXCLUDED: {
      return {
        ...state,
        processingSelfExcluded: false,
        customerProfileData: {
          ...state?.customerProfileData,
          statusId: action.payload.statusId,
          selfExcludedExpiryDate: action.payload.selfExcludedExpiryDate,
        },
      };
    }
    case SET_PROCESSING_SELF_EXCLUDED: {
      return {
        ...state,
        processingSelfExcluded: action.payload
      };
    }
    case SET_ONLINE_CUSTOMER_DEPOSIT_LIMITS: {
      return {
        ...state,
        depositLimits: action.payload.depositLimits,
      };
    }
    case SET_SUPPORTED_LANGUAGES: {
      return {
        ...state,
        loading: false,
        customerProfilelanguages: action.payload,
      };
    }
    case SET_ONLINE_CUSTOMER_PROFILE_NOTES: {
      return {
        ...state,
        loading: false,
        customerNotes: action.payload.notes,
        totalNotes: +action.payload.totalNotes,
      };
    }
    case SET_ONLINE_CUSTOMER_PROFILE_LINK_ACCOUNTS: {
      return {
        ...state,
        loading: false,
        linkedAccounts: action.payload.linkedAccounts,
        totalLinkedAccounts: +action.payload.totalLinkedAccounts,
      };
    }
    case SET_ONLINE_CUSTOMER_BALANCE_DATA: {
      return {
        ...state,
        loading: false,
        customerBalance: action.payload,
      };
    }
    case SET_ONLINE_CUSTOMER_MONTHLY_DEPOSIT_WITHDRAW_BALANCE_DATA: {
      return {
        ...state,
        loading: false,
        customerMonthlyDepositWithdrawBalance: action.payload.months,
      };
    }
    case SET_ONLINE_CUSTOMER_MONTHLY_REVENUE_BALANCE_DATA: {
      return {
        ...state,
        loading: false,
        customerMonthlyRevenueBalance: action.payload.months,
      };
    }
    case LOADING_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1: {
      return {
        ...state,
        loadingCustomerBalanceSpecificRange1: true,
      };
    }
    case LOADING_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2: {
      return {
        ...state,
        loadingCustomerBalanceSpecificRange2: true,
      };
    }
    case SET_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1: {
      return {
        ...state,
        loading: false,
        customerBalanceSpecificRange1: action.payload,
        loadingCustomerBalanceSpecificRange1: false,
      };
    }
    case SET_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2: {
      return {
        ...state,
        loading: false,
        customerBalanceSpecificRange2: action.payload,
        loadingCustomerBalanceSpecificRange2: false,
      };
    }
    case CLEAR_ONLINE_CUSTOMER_BALANCE_DATA: {
      return {
        ...state,
        customerBalance: null,
        customerMonthlyDepositWithdrawBalance: null,
        customerMonthlyRevenueBalance: null,
      };
    }
    case CLEAR_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE1: {
      return {
        ...state,
        loading: false,
        customerBalanceSpecificRange1: null,
      };
    }
    case CLEAR_ONLINE_CUSTOMER_BALANCE_DATA_SPECIFIC_DATE_RANGE2: {
      return {
        ...state,
        loading: false,
        customerBalanceSpecificRange2: null,
      };
    }
    case SET_CUSTOMER_STATEMENT: {
      return {
        ...state,
        loading: false,
        customerStatements: action.payload.statements,
        totalCustomerStatements: +action.payload.totalStatements,
      };
    }
    case SET_CUSTOMER_BONUSES: {
      return {
        ...state,
        loading: false,
        customerBonuses: action.payload.bonuses,
        totalCustomerBonuses: +action.payload.totalCountBonuses,
      };
    }
    case CLEAR_ONLINE_CUSTOMERS_DATA: {
      return {
        ...state,
        loading: false,
        onlineCustomers: null,
        totalOnlineCustomers: 0,
      };
    }
    case EXPORT_ALL_DATA_TO_EXCEL: {
      return {
        ...state,
        processing: true,
        failure: false,
      };
    }
    case EXPORT_ALL_DATA_TO_EXCEL_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case FAILURE: {
      return {
        ...state,
        onlineCustomers: null,
        totalOnlineCustomers: null,
        loading: false,
        processing: false,
        processingProfile: false,
        processingSelfExcluded: false,
        failure: true,
        loadingCustomerBalanceSpecificRange1: false,
        loadingCustomerBalanceSpecificRange2: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const getLoadingStatus = (state: fromApp.AppState) => state.onlineCustomersListReducer.loading;
export const getOnlineCustomers = (state: fromApp.AppState) => state.onlineCustomersListReducer.onlineCustomers;
export const getTotalOnlineCustomers = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.totalOnlineCustomers;
export const getProcessingState = (state: fromApp.AppState) => state.onlineCustomersListReducer.processing;
export const getProcessingProfileState = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.processingProfile;
export const getProcessingSelfExcluded = (state: fromApp.AppState) => state.onlineCustomersListReducer.processingSelfExcluded;
export const getCustomerProfile = (state: fromApp.AppState) => state.onlineCustomersListReducer.customerProfileData;
export const getDepositLimits = (state: fromApp.AppState) => state.onlineCustomersListReducer.depositLimits;

export const getSupportedLanguages = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.customerProfilelanguages;
export const getCustomerNotes = (state: fromApp.AppState) => state.onlineCustomersListReducer.customerNotes;
export const getCustomerTotalNotes = (state: fromApp.AppState) => state.onlineCustomersListReducer.totalNotes;
export const getCustomerLinkedAccounts = (state: fromApp.AppState) => state.onlineCustomersListReducer.linkedAccounts;
export const getCustomerTotalLinkedAccounts = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.totalLinkedAccounts;
export const getCustomerBalanceData = (state: fromApp.AppState) =>
  copy(state.onlineCustomersListReducer.customerBalance);
export const getCustomerMonthlyDepositWithdrawBalanceData = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.customerMonthlyDepositWithdrawBalance;
export const getCustomerMonthlyRevenueBalanceData = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.customerMonthlyRevenueBalance;
export const getCustomerBalanceDataForSpecificRange1 = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.customerBalanceSpecificRange1;
export const getCustomerBalanceDataForSpecificRange2 = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.customerBalanceSpecificRange2;
export const getLoadingCustomerBalanceDataForSpecificRange1 = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.loadingCustomerBalanceSpecificRange1;
export const getLoadingCustomerBalanceDataForSpecificRange2 = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.loadingCustomerBalanceSpecificRange2;

export const getFailureState = (state: fromApp.AppState) => state.onlineCustomersListReducer.failure;

export const getOnlineCustomersTransfers = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.customerTransfers;
export const getTotalOnlineCustomersTransfers = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.totalTransfers;
export const getCustomerStatements = (state: fromApp.AppState) => state.onlineCustomersListReducer.customerStatements;
export const getTotalCustomerStatements = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.totalCustomerStatements;
export const getCustomerBonuses = (state: fromApp.AppState) => state.onlineCustomersListReducer.customerBonuses;
export const getTotalCustomerBonuses = (state: fromApp.AppState) =>
  state.onlineCustomersListReducer.totalCustomerBonuses;
//export const getTotalIncomes = (state: fromApp.AppState) => state.onlineCustomersListReducer.totalIncomes;
//export const getTotalOutflows= (state: fromApp.AppState) => state.onlineCustomersListReducer.totalOutflows;

export const getOnlineCustomersTransfersPageState = createSelector(
  getOnlineCustomersTransfers,
  getTotalOnlineCustomersTransfers,
  (transfers, totalTransfers) => {
    return { transfers, totalTransfers };
  }
);
export const getCustomOnlineCustomerBalanceData1 = createSelector(
  getCustomerBalanceDataForSpecificRange1,
  (balance) => {
    if (balance && balance.timeGroups && balance.timeGroups[0]) {
      balance.timeGroups[0].name = balance.timeGroups[0].fromDate + '-' + balance.timeGroups[0].toDate;
    }
    return balance;
  }
);
export const getCustomOnlineCustomerBalanceData2 = createSelector(
  getCustomerBalanceDataForSpecificRange2,
  (balance) => {
    if (balance && balance.timeGroups && balance.timeGroups[0]) {
      balance.timeGroups[0].name = balance.timeGroups[0].fromDate + '-' + balance.timeGroups[0].toDate;
    }
    return balance;
  }
);
export const getCustomerNotesCustom = createSelector(getCustomerNotes, (notes) => {
  if (notes) {
    notes = copy(notes);
    notes.forEach((note) => {
      if (note.authorUser) {
        note.userClientSide = note.authorUser.name + '#' + note.authorUser.userName + '#' + note.authorUser.id;
      }
    });
  }
  return notes;
});
export const getCustomerLinkedAccountsCustom = createSelector(getCustomerLinkedAccounts, (linkedAccounts) => {
  if (linkedAccounts) {
    const copyLnkAccounts = copy(linkedAccounts);
    copyLnkAccounts.forEach((lAccount) => {
      if (lAccount.linkUser) {
        lAccount.userClientSide =
          lAccount.linkUser.name + '#' + lAccount.linkUser.userName + '#' + lAccount.linkUser.id;
      }
    });
    return copyLnkAccounts;
  }
  return undefined;
});
export const getCustomerProfileSelector = createSelector(getCustomerProfile, (profile) => {
  if (profile) {
    profile = copy(profile);
    profile.countryId = profile.country ? profile.country.id : null;
    profile.currencyId = profile.currency ? profile.currency.id : null;
    profile.birthDayClientOnEdit = profile.birthDay ? moment(profile.birthDay).toDate() : null;
    profile.birthDayClient = profile.birthDay ? moment(profile.birthDay).toDate() : null;
    profile.isManualValidated = !!profile.confirmedByUser;
    return profile;
  }
  return undefined;
});

export const getCustomerProfileStatusId = createSelector(getCustomerProfile, (profile: onlineCustomerProfile) => profile.statusId);

export const getCustomerProfileSelfExcludedExpiryDate = createSelector(getCustomerProfile, (profile: onlineCustomerProfile) => profile.selfExcludedExpiryDate);

export const getCustomerProfileId = createSelector(getCustomerProfile, (profile: onlineCustomerProfile) => profile.id);

export const getCustomerDepositLimitSelector = createSelector(getDepositLimits, (depositLimits) => {
  if (depositLimits) {
    depositLimits = JSON.parse(JSON.stringify(depositLimits));
    return depositLimits;
  }
  return undefined;
});
export const getTotalIncomes = createSelector(getCustomerStatements, (customerStatements) => {
  let totalIncomes = 0;
  forEach(customerStatements, (customerStatement: CustomerStatement) => {
    if (customerStatement.balanceMovement > 0) totalIncomes += customerStatement.balanceMovement;
  });
  return totalIncomes;
});
export const getTotalOutflows = createSelector(getCustomerStatements, (customerStatements) => {
  let totalOutflows = 0;
  forEach(customerStatements, (customerStatement: CustomerStatement) => {
    if (customerStatement.balanceMovement < 0) totalOutflows += customerStatement.balanceMovement;
  });
  return totalOutflows;
});
export const onlineCustomersGuardData = createSelector(
  getBranches,
  getMarketingCampaigns,
  getEnumsMetadata,
  (branches, marketingCampaigns, enums) => {
    return { branches, marketingCampaigns, enums };
  }
);
// export const onlineCustomerProfileGuardData = createSelector(getCustomerProfile, getFailureState,
//     (profileData, isFailed) => {
//     return {profileData, isFailed}
//     })
export const onlineCustomerProfileGuardData = createSelector(
  getCustomerProfile,
  getFailureState,
  (profile, failure) => {
    if (profile && !failure) {
      return profile;
    }
    if (failure) {
      return 'EMPTY';
    }
    return undefined;
  }
);

export const DropDownSupportedLanguagesBuilder = () =>
  createSelector(getSupportedLanguages, (supportedLanguages) => {
    if (supportedLanguages && supportedLanguages.length > 0) {
      const dropDown = [];
      for (const supportedLanguage of supportedLanguages) {
        dropDown.push({
          value: supportedLanguage,
          label: supportedLanguage,
        });
      }
      return dropDown;
    }
    return undefined;
  });
