import * as cmsHtmls from '../actions/cms.html.actions';
import * as fromApp from 'abs@core/store/reducers';
import { createSelector } from '@ngrx/store';
import { Html } from '../../models/Html';

export interface State {
  loading: boolean;
  htmls: Html[];
  selectedChannel: string;
  selectedBranch: string;
  savingHtml: boolean;
  selectedHtmlContent: string;
  selectedHtml: string;
}
const initialState: State = {
  loading: false,
  htmls: null,
  selectedChannel: null,
  selectedBranch: null,
  savingHtml: false,
  selectedHtml: null,
  selectedHtmlContent: null,
};

export function CmsHtmlsReducer(state = initialState, action: cmsHtmls.Actions): State {
  switch (action.type) {
    case cmsHtmls.GET_HTMLS: {
      return {
        ...state,
        loading: true,
        selectedChannel: action.payload.requestedChannelId,
        selectedBranch: action.payload.requestedBranchId,
        // selectedHtml: null
      };
    }
    case cmsHtmls.SET_FILTER_HTMLS: {
      return {
        ...state,
        selectedChannel: action.payload.requestedChannelId,
        selectedBranch: action.payload.requestedBranchId,
        selectedHtml:
          action.payload.requestedChannelId !== state.selectedChannel ? null : action.payload.requestedHtmllKey,
      };
    }
    case cmsHtmls.GET_HTMLS_FAILURE: {
      return {
        ...state,
        loading: false,
        htmls: null,
        selectedHtml: null,
      };
    }
    case cmsHtmls.RESET_HTML_PAGE_FILTERS: {
      return {
        ...state,
        loading: false,
        htmls: null,
        selectedHtml: null,
        selectedChannel: null,
        selectedBranch: null,
      };
    }
    case cmsHtmls.SET_HTMLS: {
      return {
        ...state,
        htmls: action.payload.map((banner) => {
          return {
            key: banner.key,
            lastUpdateGMT: banner.properties.lastUpdateGMT,
            url: banner.properties.url,
          };
        }),
        loading: false,
      };
    }
    case cmsHtmls.LOAD_HTML_CONTENT: {
      return {
        ...state,
        loading: true,
        // selectedHtml: action.payload
      };
    }
    case cmsHtmls.LOAD_HTML_CONTENT_SUCCESS: {
      return {
        ...state,
        loading: false,
        selectedHtmlContent: action.payload,
      };
    }
    case cmsHtmls.LOAD_HTML_CONTENT_FAILURE: {
      return {
        ...state,
        loading: false,
        selectedHtmlContent: '',
      };
    }
    case cmsHtmls.SAVE_HTML: {
      return {
        ...state,
        loading: true,
        savingHtml: true,
      };
    }
    case cmsHtmls.SAVE_HTML_SUCCESS: {
      return {
        ...state,
        loading: false,
        savingHtml: false,
      };
    }
    case cmsHtmls.SAVE_HTML_FAILURE: {
      return {
        ...state,
        loading: false,
        savingHtml: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const getHtmls = (state: fromApp.AppState) => {
  return state.cmsHtmlsReducer.htmls;
};
export const getSelectedChannel = (state: fromApp.AppState) => {
  return state.cmsHtmlsReducer.selectedChannel;
};
export const getSelectedOnlineBranch = (state: fromApp.AppState) => {
  return state.cmsHtmlsReducer.selectedBranch;
};
export const getSelectedHtml = (state: fromApp.AppState) => {
  return state.cmsHtmlsReducer.selectedHtml;
};
export const getSelectedHtmlContent = (state: fromApp.AppState) => {
  return state.cmsHtmlsReducer.selectedHtmlContent;
};
export const getSavingHtmlStatus = (state: fromApp.AppState) => {
  return state.cmsHtmlsReducer.savingHtml;
};

export const getSelectedHtmlToLoad = createSelector(getHtmls, getSelectedHtml, (htmlList, selectedHtmlKey) => {
  if (htmlList && selectedHtmlKey) {
    return htmlList.find((html) => {
      return html.key === selectedHtmlKey;
    });
  }
  return null;
});

export const getHtmlsToShow = createSelector(getHtmls, (htmls) => {
  if (htmls) {
    return htmls.map((html) => {
      return {
        label: html.key,
        value: html.key,
      };
    });
  }
  return [];
});
