import { APP_INITIALIZER, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutes } from './app.routes';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { PasswordModule } from 'primeng/password';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { AppComponent } from './app.component';
import { AppMainComponent } from './layout/app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AppMenuComponent } from './layout/app.menu.component';
import { AppBreadcrumbComponent } from './layout/breadcrumb.component/app.breadcrumb.component';
import { AppTopBarComponent } from './layout/app.topbar.component';
import { AppFooterComponent } from './layout/app.footer.component';
import { DashboardComponent } from './home/dashboard.component';

import { AuthModule } from './auth/auth.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { EffectsModule } from '@ngrx/effects';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { reducers } from './core/store/reducers';
import { StoreModule } from '@ngrx/store';
import { AuthEffects } from './auth/store/effects/auth.effects';
import { OutChannelService } from './core/services/outchannel/outchannel.service';
import { AuthGuardService } from './auth/guards/auth-guard.service';
import { ReducersConstants } from './core/app.config';
import { ErrorReducer } from './core/store/reducers/error.reducer';
import { LayoutReducer } from './core/store/reducers/layout';
import { MetaDataReducer } from './core/store/reducers/metadata.reducer';
import { ConfirmationService, MessageService } from 'primeng/api';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { MetadataEffects } from './core/store/effects/metadata.effects';
import { ErrorEffects } from './core/store/effects/error.effects';
import { LayoutEffects } from './core/store/effects/layout.effects';
import { environment } from '../environments/environment';
import { AppGlobalSearchesComponent } from './core/components/app-global-searches/app-global-searches.component';
import { AppConfigService } from './core/services/appConfig.service';
import { AuthorizationModule } from './shared/authorization/authorization.module';
import { CommentFormComponent } from './shared/forms/comment-form/comment-form.component';
import { EmptyComponent } from './empty/empty.component';
import { TableDialogComponent } from './shared/table-dialog/table-dialog.component';
import { InputTextModule } from 'primeng/inputtext';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { TooltipModule } from 'primeng/tooltip';
import { CellsModule } from './shared/table/cells/cells.module';
import { ProgressBarComponent } from './shared/progress-bar/progress-bar.component';
import { clearState } from './auth/store/reducers/clearState';

import { AppConfigComponent } from './app.config.component';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { AppMenuitemComponent } from './layout/app.menuitem.component';
import { RippleModule } from 'primeng/ripple';
import { MenuService } from './layout/app.menu.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { AppRightMenuComponent } from './layout/app.rightmenu.component';
import { AppInlineMenuComponent } from './layout/app.inlinemenu.component';
import { CalendarModule } from 'primeng/calendar';
import { NonAuthGuardService } from './auth/guards/non-auth-guard.service';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { TokenInterceptor } from 'abs@core/interceptors/token.interceptor';
import { InputNumberModule } from 'primeng/inputnumber';
import { FeedHealtReducer } from 'abs@core/store/reducers/feed-health.reducer';
import { FeedHealthEffects } from 'abs@core/store/effects/feed-health.effect';

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const initializerConfigFn = (appConfig: AppConfigService): () => void => {
  return () => {
    return appConfig.loadAppConfig();
  };
};

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutes,
    HttpClientModule,
    BrowserAnimationsModule,
    DialogModule,
    DropdownModule,
    PasswordModule,
    ProgressBarModule,
    InputTextModule,
    InputNumberModule,
    ToastModule,
    AuthModule,
    AuthorizationModule,
    AutoCompleteModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    StoreModule.forFeature(ReducersConstants.FEED_HEALTH, FeedHealtReducer),
    StoreModule.forFeature(ReducersConstants.ERROR, ErrorReducer),
    StoreModule.forFeature(ReducersConstants.LAYOUT, LayoutReducer),
    StoreModule.forFeature(ReducersConstants.METADATA, MetaDataReducer),
    StoreModule.forRoot(reducers, { metaReducers: [clearState] }),
    EffectsModule.forRoot([AuthEffects, MetadataEffects, ErrorEffects, LayoutEffects, FeedHealthEffects]),
    !environment.production
      ? StoreDevtoolsModule.instrument({
        maxAge: 25 /*  Retains last 25 states */,
      })
      : [],
    ReactiveFormsModule,
    ButtonModule,
    InputTextareaModule,
    TableModule,
    TooltipModule,
    CellsModule,
    BreadcrumbModule,
    RippleModule,
    RadioButtonModule,
    CalendarModule,
    InputSwitchModule,
    MultiSelectModule
  ],
  exports: [TranslateModule],
  declarations: [
    AppComponent,
    AppMainComponent,
    AppMenuComponent,
    AppBreadcrumbComponent,
    AppTopBarComponent,
    AppFooterComponent,
    DashboardComponent,
    AppNotfoundComponent,
    AppErrorComponent,
    AppAccessdeniedComponent,
    AppGlobalSearchesComponent,
    CommentFormComponent,
    EmptyComponent,
    TableDialogComponent,
    ProgressBarComponent,
    AppConfigComponent,
    AppMenuitemComponent,
    AppRightMenuComponent,
    AppInlineMenuComponent
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializerConfigFn,
      multi: true,
      deps: [AppConfigService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    AuthGuardService,
    NonAuthGuardService,
    MessageService,
    ConfirmationService,
    OutChannelService,
    MenuService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
