import {
  GET_USER_ACCESS_DATA,
  SET_USER_ACCESS_FAILURE,
  SET_USER_ACCESS_DATA,
  CLEAR_USER_ACCESS_STATE
} from '../actions/user-access.actions';
import * as fromApp from 'abs@core/store/reducers';
import { UserAccess } from '../../models/models';

export interface State {
  loading: boolean;
  error: Error;
  userAccessList: UserAccess[];
  totalRecords: number;
}

export const initialState: State = {
  loading: false,
  error: null,
  userAccessList: null,
  totalRecords: null,
};

export function UserAccessReducer(state = initialState, action: any): State {
  switch(action.type) {
    case GET_USER_ACCESS_DATA: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }
    case SET_USER_ACCESS_DATA: {
      return {
        ...state,
        loading: false,
        error: null,
        userAccessList: action.payload.logins,
        totalRecords: (+action.payload.TotalRecords || 0),
      };
    }
    case SET_USER_ACCESS_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload,
        userAccessList: null
      };
    }
    case CLEAR_USER_ACCESS_STATE: {
      return {
        loading: false,
        error: null,
        userAccessList: null,
        totalRecords: null,
      };
    }
    default: {
      return state;
    }
  }
}

export const getLoadingStatus = (state: fromApp.AppState) => state.userAccessReducer.loading;
export const getUserAccessList = (state: fromApp.AppState) => state.userAccessReducer.userAccessList;
export const getTotalRecords = (state: fromApp.AppState) => state.userAccessReducer.totalRecords;
