import { OrderData } from '../../models/betslip.models';
import { FAILURE } from '../actions/betslips.actions';
import * as fromApp from 'abs@core/store/reducers';
import {
  EDIT_ROW_FAILED,
  EDIT_ROW_SUCCESS,
  GET_ORDER_DATA,
  ORDER_ACTION,
  ORDER_ACTION_FAILED,
  ORDER_ACTION_SUCCESS,
  SET_ORDER_DATA,
} from '../actions/order.actions';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';

export interface State {
  loading: boolean;
  processing: boolean;
  orderData: OrderData;
}
export const initialState: State = {
  loading: false,
  processing: false,
  orderData: null,
};

export function OrderReducer(state = initialState, action: any): State {
  switch (action.type) {
    case GET_ORDER_DATA: {
      return {
        ...state,
        loading: true,
        orderData: null,
      };
    }
    case ORDER_ACTION: {
      return {
        ...state,
        processing: true,
      };
    }
    case ORDER_ACTION_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case ORDER_ACTION_FAILED: {
      return {
        ...state,
        processing: false,
      };
    }
    case EDIT_ROW_SUCCESS: {
      return {
        ...state,
        processing: false,
      };
    }
    case EDIT_ROW_FAILED: {
      return {
        ...state,
        processing: false,
      };
    }
    case SET_ORDER_DATA: {
      return {
        ...state,
        orderData: action.payload,
      };
    }
    case FAILURE: {
      return {
        ...state,
        loading: false,
        processing: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const getLoadingStatus = (state: fromApp.AppState) => state.orderReducer.loading;
export const getOrderData = (state: fromApp.AppState) => state.orderReducer.orderData;
export const getProcessingState = (state: fromApp.AppState) => state.orderReducer.processing;

export const getProductData = createSelector(getOrderData, (orderData) => {
  orderData = copy(orderData);
  if (orderData && orderData.productData) {
    if (orderData.product.id === 5) {
      //lotto
      let lost = 0;
      orderData.productData.forEach((line) => {
        line.lines.forEach((lottoLine) => {
          if (line.lines) {
            if (lottoLine.winStatusId === 2) {
              //Won
              line.lottoBetStatus = 2;
            } else if (lottoLine.winStatusId === 0) {
              //Pending
            } else if (lottoLine.winStatusId === 1) {
              //Lost
              lost++;
            }
            const includeBonus = lottoLine.chosenNumbers?.includes('&');
            if (includeBonus) {

              // bonus exist
              if (includeBonus) {
                const chosenNumbers = lottoLine.chosenNumbers.split('&');
                lottoLine.chosenNumbersClient = chosenNumbers[0].split(',');
                lottoLine.chosenBonusNumbersClient = chosenNumbers[1].split(',');
              } else {
                lottoLine.chosenNumbersClient = lottoLine.chosenNumbers?.split(',');
              }

              if (line.winningNumbers?.includes('&')) {
                const numbers = line.winningNumbers.split('&');
                line.winningNumbersClient = numbers[0].split(',');
                line.winningBonusClient = numbers[1].split(',');
              } else {
                line.winningNumbersClient = line.winningNumbers?.split(',');
              }
            } else {
              lottoLine.chosenNumbersClient = lottoLine.chosenNumbers.split('&')[0].split(',');
              line.winningNumbersClient = line.winningNumbers?.split('&')[0].split(',');
            }
          }
        });

        if (line.lottoBetStatus != 2) {
          //not Won
          if (line.lines.length === lost) {
            line.lottoBetStatus = 1; //Lost
          } else {
            line.lottoBetStatus = 0; //Pending
          }
        }
      });
    } else if ([1, 2, 3, 4].includes(orderData.product.id)) {
      orderData.productData.forEach((line) => {
        if (line.bets) {
          line.bets.forEach((bet) => {
            bet.maxOdd = bet.originalOdd;
            if (!bet.selection) {
              bet.selection = bet.odd.selection;
            }
          });
        }
      });
    }
    return orderData;
  }
  return null;
});
