import { Action } from '@ngrx/store';

export const FAILURE = '[POOL] Failure';
export const SET_FAILURE = '[POOL] Set Failure';
/******************POOL************************/
export const GET_POOL_DATA = '[POOL] Get Pool Data';

export const GET_POOL_TEMPLATES = '[POOL] Get Pool Templates';

export const SET_POOL_TEMPLATES = '[POOL] Set Pool Templates';

export const GET_POOL_TEMPLATE_PRIZE = '[POOL] Get Pool Template Prize';

export const SET_POOL_TEMPLATE_PRIZE = '[POOL] Set Pool Template Prize';

export const GET_POOLS = '[POOL] Get Pools';

export const SET_POOLS = '[POOL] Set Pools';

export const GET_POOL_BY_ID = '[POOL] Get Pool By Id';

export const SET_POOL_BY_ID = '[POOL] Set Pool By Id';

export const GET_POOL_MATCHES = '[POOL] Get Pool Matches';

export const SET_POOL_MATCHES = '[POOL] Set Pool Events';

export const SET_MATCHES = '[POOL] Set Import Matches';

export const SET_EVENTS = '[POOL] Set Import Events';

export const GET_POOL_EVENTS = '[POOL] Get Import Events';

export const SET_POOL_BET_TYPES = '[POOL] Set Pool Bet Types';

export const POOL_ACTIVATION_FAILED = '[POOL] Pool Activation Failed';

export class GetPoolData implements Action {
  readonly type = GET_POOL_DATA;

  constructor(public payload: any = null) {}
}
export class SetPoolBetTypes implements Action {
  readonly type = SET_POOL_BET_TYPES;

  constructor(public payload: any = null) {}
}

export class PoolActivationFailed implements Action {
  readonly type = POOL_ACTIVATION_FAILED;

  
}

export class GetPoolTemplatePrize implements Action {
  readonly type = GET_POOL_TEMPLATE_PRIZE;

  constructor(public payload: any = null) {}
}

export class SetPoolTemplatePrize implements Action {
  readonly type = SET_POOL_TEMPLATE_PRIZE;

  constructor(public payload: any = null) {}
}

export class GetPoolById implements Action {
  readonly type = GET_POOL_BY_ID;

  constructor(public payload: any = null) {}
}

export class SetMatchesPoolById implements Action {
  readonly type = SET_POOL_MATCHES;

  constructor(public payload: any = null) {}
}

export class SetPoolById implements Action {
  readonly type = SET_POOL_BY_ID;

  constructor(public payload: any = null) {}
}

export class GetPools implements Action {
  readonly type = GET_POOLS;

  constructor(public payload: any = null) {}
}

export class SetPools implements Action {
  readonly type = SET_POOLS;

  constructor(public payload: any = null) {}
}

// export class GetPoolMatches implements Action {
//   readonly type = GET_POOL_MATCHES;
//
//   constructor(public excludedEvents: any, public payload: any = null) {
//   }
// }
export class GetPoolMatches implements Action {
  readonly type = GET_POOL_MATCHES;

  constructor(public payload: any = null) {}
}

export class SetPoolEvents implements Action {
  readonly type = SET_EVENTS;

  constructor(public payload: any = null) {}
}

export class GetPoolEvents implements Action {
  readonly type = GET_POOL_EVENTS;

  constructor(public payload: any = null) {}
}

export class GetPoolTemplates implements Action {
  readonly type = GET_POOL_TEMPLATES;

  constructor(public payload: any = null) {}
}

export class SetPoolTemplates implements Action {
  readonly type = SET_POOL_TEMPLATES;

  constructor(public payload: any = null) {}
}

export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}

export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}

export type Actions =
  | GetPoolData
  | GetPoolTemplatePrize
  | GetPoolTemplates
  | SetPoolTemplates
  | SetPoolBetTypes
  | PoolActivationFailed
  | Failure
  | SetFailure;
