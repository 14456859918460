import {
  Activity,
  ConnectedUserRolesAndPermissions,
  GeneralRole,
  Permission,
  UserPermissionsLogResponse,
  UserPerRole,
  UsersPerPermissionResponse,
} from '../../models/models';
import { sortBy } from 'lodash';
import {
  CLEAR_ALL_DATA,
  FAILURE,
  GET_ROLES_AND_PERMISSIONS_DATA,
  GET_USERS_PER_ROLE,
  GET_USERS_PER_ROLE_FAILURE, RESET_CUSTOM_PERMISSIONS_SUCCESS,
  SET_ACTIVITIES,
  SET_CURRENT_USER_ROLES_AND_PERMISSIONS,
  SET_ROLES_NAME_LIST,
  SET_USER_PERMISSION_LOG,
  SET_USERS_PER_PERMISSION,
  SET_USERS_PER_ROLE
} from '../actions/roles-and-permissions.actions';
import * as fromApp from 'abs@core/store/reducers';
import { createSelector } from '@ngrx/store';
import copy from 'fast-copy';

export interface State {
  loadingMainPermissionsGrid: boolean;
  processing: boolean;
  failureJustOccurred: boolean;
  connectedUserRolesAndPermissions: ConnectedUserRolesAndPermissions;
  usersPerRole: UserPerRole[];
  loadingUsersPerRole: boolean;
  usersPerRoleTotalRecords: number;
  rolesList: GeneralRole[];
  usersPerPermissionModel: UsersPerPermissionResponse;
  usersPerPermissionModelTotalRecords: number;
  loadingUsersPerPermissionGrid: boolean;
  userPermissionLogModel: UserPermissionsLogResponse;
  userPermissionLogModelTotalRecords: number;
  loadingUserPermissionsLogGrid: boolean;
  activities: Activity[];
  forceUpdateCompleted: boolean;
}
export const initialState: State = {
  loadingMainPermissionsGrid: false,
  processing: false,
  failureJustOccurred: false,
  connectedUserRolesAndPermissions: null,
  usersPerRole: null,
  loadingUsersPerRole: null,
  usersPerRoleTotalRecords: null,
  rolesList: null,
  usersPerPermissionModel: null,
  usersPerPermissionModelTotalRecords: null,
  loadingUsersPerPermissionGrid: null,
  userPermissionLogModel: null,
  userPermissionLogModelTotalRecords: null,
  loadingUserPermissionsLogGrid: null,
  activities: null,
  forceUpdateCompleted: false
};

export function RolesAndPermissionsReducer(state = initialState, action: any): State {
  switch (action.type) {
  case GET_ROLES_AND_PERMISSIONS_DATA: {
    return {
      ...state,
      loadingMainPermissionsGrid: true,
      failureJustOccurred: false,
      // processing: action.payload instanceof GetCurrentUserRolesAndPermissionsRequest
    };
  }
  case SET_CURRENT_USER_ROLES_AND_PERMISSIONS: {
    return {
      ...state,
      loadingMainPermissionsGrid: false,
      connectedUserRolesAndPermissions: action.payload,
    };
  }
  case SET_USERS_PER_PERMISSION: {
    return {
      ...state,
      loadingUsersPerPermissionGrid: false,
      usersPerPermissionModel: action.payload,
      usersPerPermissionModelTotalRecords: action.payload.totalRecords,
    };
  }
  case SET_ACTIVITIES: {
    return {
      ...state,
      activities: action.payload.activities,
    };
  }
  case SET_USER_PERMISSION_LOG: {
    return {
      ...state,
      loadingUserPermissionsLogGrid: false,
      userPermissionLogModel: action.payload,
      userPermissionLogModelTotalRecords: action.payload.totalRecords,
    };
  }
  case GET_USERS_PER_ROLE: {
    return {
      ...state,
      loadingUsersPerRole: true,
    };
  }
  case GET_USERS_PER_ROLE_FAILURE: {
    return {
      ...state,
      loadingUsersPerRole: false,
    };
  }
  case SET_USERS_PER_ROLE: {
    return {
      ...state,
      loadingUsersPerRole: false,
      usersPerRole: action.payload.users,
      usersPerRoleTotalRecords: action.payload.totalRecords,
    };
  }
  case SET_ROLES_NAME_LIST: {
    return {
      ...state,
      rolesList: action.payload.roles,
    };
  }
  case CLEAR_ALL_DATA: {
    return {
      ...state,
      loadingMainPermissionsGrid: false,
      processing: false,
      failureJustOccurred: false,
      connectedUserRolesAndPermissions: null,
    };
  }
  case FAILURE: {
    return {
      ...state,
      loadingMainPermissionsGrid: false,
      processing: false,
      failureJustOccurred: true,
    };
  }
  case RESET_CUSTOM_PERMISSIONS_SUCCESS: {
    return {
      ...state,
      forceUpdateCompleted: true,
    };
  }
  default: {
    return state;
  }
  }
}

export const getForceUpdateStatus = (state: fromApp.AppState) => state.rolesAndPermissionsReducer.forceUpdateCompleted;
export const getLoadingMainPermissionsGridStatus = (state: fromApp.AppState) =>
  state.rolesAndPermissionsReducer.loadingMainPermissionsGrid;
export const getProcessStatus = (state: fromApp.AppState) => state.rolesAndPermissionsReducer.processing;
export const getFailureStatus = (state: fromApp.AppState) => state.rolesAndPermissionsReducer.failureJustOccurred;
export const getConnectedUserRolesAndPermissionsData = (state: fromApp.AppState) =>
  state.rolesAndPermissionsReducer.connectedUserRolesAndPermissions;
export const getLoadingUsersPerRole = (state: fromApp.AppState) => state.rolesAndPermissionsReducer.loadingUsersPerRole;
export const getUsersPerRole = (state: fromApp.AppState) => state.rolesAndPermissionsReducer.usersPerRole;
export const getUsersPerRoleTotalRecords = (state: fromApp.AppState) =>
  state.rolesAndPermissionsReducer.usersPerRoleTotalRecords;
export const getRolesList = (state: fromApp.AppState) => state.rolesAndPermissionsReducer.rolesList;
export const getUsersPerPermissionModel = (state: fromApp.AppState) =>
  state.rolesAndPermissionsReducer.usersPerPermissionModel;
export const getUsersPerPermissionModelTotalRecords = (state: fromApp.AppState) =>
  state.rolesAndPermissionsReducer.usersPerPermissionModelTotalRecords;
export const getUsersPerPermissionLoading = (state: fromApp.AppState) =>
  state.rolesAndPermissionsReducer.loadingUsersPerPermissionGrid;
export const getUserPermissionsLogModel = (state: fromApp.AppState) =>
  state.rolesAndPermissionsReducer.userPermissionLogModel;
export const getUserPermissionsLogModelTotalRecords = (state: fromApp.AppState) =>
  state.rolesAndPermissionsReducer.userPermissionLogModelTotalRecords;
export const getUserPermissionsLogLoading = (state: fromApp.AppState) =>
  state.rolesAndPermissionsReducer.loadingUserPermissionsLogGrid;
export const getActivities = (state: fromApp.AppState) => state.rolesAndPermissionsReducer.activities;

export const RolesAndPermissionsGuard = createSelector(
  getConnectedUserRolesAndPermissionsData,
  getFailureStatus,
  (connectedUserDetails, failure) => {
    if (connectedUserDetails && !failure) {
      return connectedUserDetails;
    }

    if (failure) {
      return 'EMPTY';
    }
    return undefined;
  }
);
export const getActivityListDropDownSorted = createSelector(getActivities, (activities) => {
  if (!activities) {
    return undefined;
  }

  const sortedActivities = sortBy(activities, 'name');
  return sortedActivities.map((activity) => {
    return {
      label: activity.name,
      value: activity.id,
    };
  });
});
export const getConnectedUserRolesAndPermissionsDataProcessed = createSelector(
  getConnectedUserRolesAndPermissionsData,
  (connectedUserData) => {
    if (connectedUserData) {
      connectedUserData = copy(connectedUserData);
      connectedUserData.roles.forEach((role) => {
        role.permissionsMap = new Map<number, Permission>();
        role.permissions.forEach((permission) => {
          if (permission.permissionLevel == 1) {
            permission.readonly = true;
            permission.readAndWrite = false;
          } else if (permission.permissionLevel == 15) {
            permission.readonly = true;
            permission.readAndWrite = true;
          } else {
            permission.readonly = false;
            permission.readAndWrite = false;
          }
          role.permissionsMap.set(permission.activityId, permission);
        });
      });
      connectedUserData.roles = sortBy(connectedUserData.roles, 'level');
      return connectedUserData;
    }
    return null;
  }
);
export const getRoleById = (id, tempName) =>
  createSelector(getRolesList, (roles) => {
    if (roles) {
      const role = roles.find((role) => role.id == id);
      if (role) {
        return role.name;
      } else {
        return tempName;
      }
    } else {
      return tempName;
    }
  });
export const getRoleListDropDown = createSelector(getRolesList, (roles) => {
  if (roles) {
    return roles.map((role) => {
      return {
        label: role.name,
        value: role.id,
      };
    });
  }
  return [];
});
