import { createAction, props } from '@ngrx/store';
import { BetTypes, UpdateBetType } from '../../models';
import { FailAction, Filtering } from 'src/app/sport-betting/common/models';

const SB_GET_BET_TYPES = '[SBBT] Get bet types';
const SB_SET_BET_TYPES = '[SBBT] Set bet types';
const SB_UPDATE_BET_TYPE = '[SBBT] Update bet type';
const SB_UPDATE_BET_TYPE_SUCCESS = '[SBBT] Update bet type SUCCESS';

const FAILURE = '[SBBT] Failure';

export const SbGetBetTypes = createAction(SB_GET_BET_TYPES, props<{ payload: Filtering }>());
export const SbSetBetTypes = createAction(SB_SET_BET_TYPES, props<{
  payload: {
    data: Array<BetTypes>,
    totalCount: number;
  }
}>());
export const SbUpdateBetType = createAction(SB_UPDATE_BET_TYPE, props<{ payload: UpdateBetType }>());
export const SbUpdateBetTypeSuccess = createAction(SB_UPDATE_BET_TYPE_SUCCESS, props<{ payload: UpdateBetType }>());

export const BetTypesFailure = createAction(FAILURE, props<FailAction>());