import * as moment from 'moment';
export interface LottoType {
  id: number;
  name: string;
  currencyId: number;
  currencyName: string;
  maxNumber: number;
  maxBonusNumber: number;
  selectNumbers: number;
  selectBonusNumbers: number;
  betTypeId: number;
  betOnLottoEnable: boolean;
  betOnLottoDrawEnable: boolean;
  originalLinePrice: number;
  betLotteryTypes: BetLotteryType[];
}
export interface BetLotteryType {
  betLotteryTypeId: number;
  betLotteryConstTypeId: number;
  betLotteryTypeText: string;
  betLotteryTypeOrder: number;
  betLotteryTypeEnable: boolean;
}
export interface BetLotteryLine {
  betLotteryLineTypeId: number;
  order: number;
  text: string;
  enable: boolean;
  betLotteryLineOdds: BetLotteryLineOdds[];
}
export interface BetLotteryLineOdds {
  betLotteryLineOddId: number;
  betLotteryLineOddText: string;
  odd: string;
  setting: LineOddSettings;
}
export interface LineOddSettings {
  number?: number;
  divCount?: number;
  divStart?: number;
  divEnd?: number;
}
export interface LottoDraw {
  id: number;
  userIdAuthorize: number;
  userIdAuthorize2: number;
  drawDate: string;
  winningNumbers: string;
  jackpot: number;
  displayText: number;
  isSettled: boolean;
  isCalculated: boolean;
  status: number;
}
export interface LottoLog {
  id: number;
  drawId: number;
  userId: number;
  lotteryTypeId: number;
  lotteryName: string;
  fullName: string;
  changeDate: string;
  drawCloseTime: string;
  change: string;
}
export interface LottoTotal {
  drawTypeName: string;
  lottoName: string;
  Calculated: string;
  Settled: string;
  drawId: number;
  specialJpCount: number;
  specialJpLineCount: number;
  jpCount: number;
  jpLineCount: number;
  linePrice: number;
  specialLinePrice: number;
  totalSales: number;
  totalWinning: number;
  totalPayout: number;
  totalProfit: number;
  salesCommissions: number;
  paidCommissions: number;
  closeBetTime: string;
  drawDate: string;
  lastBetTime: string;
}

export interface LottoTicketData {
  ticketId: number;
  date: string;
  ticketAmount: number;
  ticketSelection: string;
}

export class LottoTicketDataExcel implements LottoTicketData {
  ticketId: number;
  date: string;
  ticketAmount: number;
  ticketSelection: string;
  constructor(ticketId: number, date: string, ticketAmount: number, ticketSelection: string) {
    this.ticketId = ticketId;
    this.date = moment(date).format('DD-MM-YYYY HH:mm:ss');
    this.ticketAmount = ticketAmount;
    this.ticketSelection = ticketSelection;
  }
}

export class LottoLogExcel implements LottoLog {
  id: number;
  drawId: number;
  userId: number;
  lotteryTypeId: number;
  lotteryName: string;
  fullName: string;
  changeDate: string;
  drawCloseTime: string;
  change: string;
  constructor(
    id: number,
    drawId: number,
    userId: number,
    lotteryTypeId: number,
    lotteryName: string,
    fullName: string,
    changeDate: string,
    drawCloseTime: string,
    change: string
  ) {
    this.id = id;
    this.drawId = drawId;
    this.userId = userId;
    this.lotteryTypeId = lotteryTypeId;
    this.lotteryName = lotteryName;
    this.fullName = fullName;
    this.changeDate = moment(changeDate).format('DD-MM-YYYY HH:mm:ss');
    this.drawCloseTime = moment(drawCloseTime).format('DD-MM-YYYY HH:mm:ss');
    this.change = change;
  }
}

export enum eLottoBetTypes {
  OddEven = 'Odd/Even',
  UnderOver = 'Under/Over',
  Bullseys = 'Bullseys',
  Range = 'Range',
  Hits = 'Hits',
}
