import { Component, OnInit } from '@angular/core';
import { AppMainComponent } from './app.main.component';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { getUserData } from '../auth/store/reducers/auth';
import { Store } from '@ngrx/store';
import { getSelectedTimeZone } from '../core/store/reducers/metadata.reducer';
import { tap } from 'rxjs/operators';
import { PermissionsScreensCodes } from '../core/app.config';
import { AuthorizationService } from '../shared/authorization/authorization.service';
import { SelectTimeZone } from "../core/store/actions/metadata.actions";
import { AppComponent } from "../app.component";
import { Observable } from 'rxjs';
import { UserData } from '../auth/models/user';

@Component({
  selector: 'app-inlinemenu',
  templateUrl: './app.inlinemenu.component.html',
  animations: [
    trigger('inline', [
      state(
        'hidden',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      state(
        'visible',
        style({
          height: '*',
        })
      ),
      state(
        'hiddenAnimated',
        style({
          height: '0px',
          overflow: 'hidden',
        })
      ),
      state(
        'visibleAnimated',
        style({
          height: '*',
        })
      ),
      transition('visibleAnimated => hiddenAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hiddenAnimated => visibleAnimated', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
    ]),
  ],
})
export class AppInlineMenuComponent implements OnInit {
  userData: Observable<UserData>;
  selectedTimeZone: any;
  dashboardPermission: boolean;
  timeZoneDialogVisible = false;
  timeZonesList: any;
  screenPermissions = PermissionsScreensCodes;
  userDataStyle = {
    float: 'right',
    "padding-left": '1rem'
  };
  constructor(
    public appMain: AppMainComponent,
    private store: Store<any>,
    private authorizationService: AuthorizationService,
    public app: AppComponent
  ) {
    this.userData = this.store.select(getUserData);
    this.store
      .select(getSelectedTimeZone)
      .pipe(
        tap((selectedTimeZone) => {
          this.selectedTimeZone = selectedTimeZone;
        })
      )
      .subscribe();
  }
  ngOnInit() {
    this.dashboardPermission = this.authorizationService.hasPermission(PermissionsScreensCodes.DASHBOARD_MAIN_SCREEN);
  }
  onChangeTImeZone(event) {
    this.timeZoneDialogVisible = false;
    this.store.dispatch(new SelectTimeZone(event.value.tzCode));
  }
  openTimeZoneDialog() {
    //this.timeZoneDialogVisible = true;
  }

  checkPermission(permission): boolean {
    return this.authorizationService.hasPermission(permission);
  }
}
