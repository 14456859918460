import { Injectable } from "@angular/core";
import { AppSettings, RouteConstants } from "../../app.config";
import { Observable } from "rxjs";
import { BaseRequest } from "../../models/request";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { take, tap, timeout } from "rxjs/operators";
import { BaseResponse } from "../../models/response";
import { Router } from "@angular/router";
import copy from "fast-copy";

@Injectable()
export class OutChannelService {
  private readonly interceptorSkipContentType = 'X-Skip-Content-Type';

  constructor(
    private http: HttpClient,
    private router: Router
  ) {
  }

  postRequest(baseURL: string, method: string, request: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    if (request && request.extraData) {
      headers = headers.set("ExtraData", JSON.stringify(request.extraData));
      request = copy(request);
      delete request.extraData;
    }

    const apiAddress = baseURL.concat(method);
    return this.http.post(apiAddress, request, { headers }).pipe(
      timeout(60000),
      tap((response: BaseResponse) => {
        if (response?.result?.errorCode === 100) {
          this.router.navigate([RouteConstants.LOGIN_PAGE]);
        }
      })
    );
  }

  postFormDataRequest(baseURL: string, method: string, request: any): Observable<any> {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.set(this.interceptorSkipContentType, '');

    const apiAddress = baseURL.concat(method);

    return this.http.post(apiAddress, request, { headers }).pipe(
      timeout(15000),
      tap((response: BaseResponse) => {
        if (response.result.errorCode == 100) {
          this.router.navigate([RouteConstants.LOGIN_PAGE]);
        }
      })
    );
  }

  getRequest(url: string): Observable<any> {
    return this.http.get(url, { responseType: "text" }).pipe(timeout(15000));
  }

  brandedRequest(url: string, data: any = {}, method = "get"): Observable<any> {
    const { headers, params, ...rest } = data;

    let newHeaders = headers || new HttpHeaders();
    newHeaders = newHeaders.set(this.interceptorSkipContentType, '');

    let options: any = {
      headers: newHeaders
    };

    if (params && method === "get") {
      options.params = params;
    }
    if (rest) {
      options = Object.assign(options, rest);
    }
    return (
      method === "get" ?
        this.http[method](url, options) : this.http[method](url, params, options)
    ).pipe(timeout(15000), take(1));
  }

  getRequestJSON(url: string, params = null): Observable<any> {
    return this.http.get(url, { params: params }).pipe(timeout(15000));
  }

  getRequestTest(baseURL: string, method: string): Observable<any> {
    const apiAddress = baseURL.concat(method);
    return this.http.get(apiAddress).pipe(timeout(15000));
  }

  lottoPostRequest(method: string, request: any): Observable<any> {
    if (request == null) {
      request = new BaseRequest(null, null);
    }

    const apiAddress = AppSettings.LOTTO_API_ENDPOINT.concat(method);
    return this.http.post(apiAddress, request).pipe(timeout(15000));
  }

  lottoExcelPostRequestData(method: string, request: any): Observable<any> {
    if (request == null) {
      request = new BaseRequest(null, null);
    }
    const headers: HttpHeaders = new HttpHeaders({
      "Accept": "application/vnd.ms-excel"
    });

    const apiAddress = AppSettings.LOTTO_API_ENDPOINT.concat(method);
    return this.http
      .post(apiAddress, request, {
        headers,
        responseType: "blob"
      }).pipe(timeout(15000));
  }
}
