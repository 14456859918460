import { APIMethodsGames, AppSettings } from '../../../core/app.config';
import { DataRequest, PaginationAndSort, PostActionRequest } from '../../../core/models/request';
import { APIMethodsExposure } from '../exposure.config';
import {
  SET_AUTHORIZATION_ORDER_DATA,
  SET_EVENT_LIMIT_DATA,
  SET_LIVE_AUTHORIZATION_DATA,
  UPDATE_AUTHORIZATION_STATUS_FAILURE,
  UPDATE_AUTHORIZATION_STATUS_SUCCESS,
} from '../store/actions/exposure.actions';

export class GetEventsLimitRequest extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethodsExposure.GET_EVENTS_LIMIT,
      SET_EVENT_LIMIT_DATA,
      request
    );
  }
}
export class UpdateAuthorizationStatusAction extends PostActionRequest {
  constructor(
    public request,
    public methodName = APIMethodsExposure.SET_AUTHORIZATION,
    public successAction = UPDATE_AUTHORIZATION_STATUS_SUCCESS,
    public failureAction = UPDATE_AUTHORIZATION_STATUS_FAILURE,
    url = AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY
  ) {
    super(url, methodName, request, successAction, failureAction);
  }
}
export class GetAuthorizationRequest extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethodsExposure.GET_AUTHORIZATION_ORDER,
      SET_AUTHORIZATION_ORDER_DATA,
      request
    );
  }
}
export class GetBetsForAuthorizationRequest extends DataRequest {
  constructor(public request = null) {
    super(
      AppSettings.BASE_API_ENDPOINT + AppSettings.BACKOFFICE_API + APIMethodsGames.DYNAMIC_QUERY,
      APIMethodsExposure.GET_BETS_FOR_AUTHORIZATION,
      SET_LIVE_AUTHORIZATION_DATA,
      request
    );
  }
}

export class CheckIfNewAuthorizationEventsRequest {
  constructor(public timeMS: number) {}
}

export class GetEventsLimitRequestModel extends PaginationAndSort {
  constructor(
    public fromDate: string = null,
    public toDate: string = null,
    public marketType: number = null,
    public marketStatuses: any = null,
    public eventId: number = null,
    public limitAbove: number = null,
    public skip: number = null,
    public take: number = null,
    public sort: any = null
  ) {
    super(skip, take, sort);
  }
}
export class GetBetsForAuthorizationRequestModel extends PaginationAndSort {
  constructor(
    public timeFrameFilter: number = null,
    public fromDate: string = null,
    public toDate: string = null,
    public usernameOrId: string = null,
    public channels: any = null,
    public branches: any = null,
    public sportTypes: any = null,
    public marketTypes: any = null,
    public countryIds: any = null,
    public leagueIds: any = null,
    public stakeFrom: number = null,
    public stakeTo: number = null,
    public oddsFrom: number = null,
    public oddsTo: number = null,
    public winStatuses: any = null,
    public eventId: number = null,
    public selectionsFrom: number = null,
    public selectionsTo: number = null,
    public authorizationStatuses: any = null,
    public respondingUsernameOrId: any = null,
    public skip: number = null,
    public take: number = null,
    public sort: any = null
  ) {
    super(skip, take, sort);
  }
}

export class UpdateAuthorizationStatusRequestModel {
  constructor(
    public authorizationOrderId: number,
    public authorizationStatus: number,
    public authorizedOrderBets: any = null,
    public authorizedLimits: any = null,
    public authorizedStake: any = null,
    public authorizedComment: any = null
  ) {}
}
