import * as actions from "../actions/instant-win.actions";
import { AppState } from "abs@core/store/reducers";
import { BatchManagement, FastGames } from "../../models/models";
import { createSelector } from "@ngrx/store";

export interface State {
  batchManagements: BatchManagement[];
  fastGames: FastGames[];
  loading: boolean;
  error: any;
}

const initialState: State = {
  batchManagements: [],
  fastGames: [],
  loading: false,
  error: null
};

export function fastGamesManagementReducer(state = initialState, action: actions.Actions): State {
  switch (action.type) {
    case actions.LOAD_BATCH_MANAGEMENT_DATA:
      return { ...state, loading: false, batchManagements: action.payload, error: null };
    case actions.LOAD_BATCH_MANAGEMENT_DATA_SUCCESS:
      return { ...state, loading: false, batchManagements: action.payload, error: null };
    case actions.LOAD_BATCH_MANAGEMENT_DATA_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case actions.UPDATE_BATCH_TABLE:
      return { ...state, loading: true, error: null };
    case actions.UPDATE_BATCH_STATUS_SUCCESS:
      return { ...state, loading: false, batchManagements: action.payload, error: null };
    case actions.UPDATE_BATCH_STATUS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    case actions.SAVE_BATCH_TEMPLATE:
      return { ...state, loading: true, error: null };
    case actions.SAVE_BATCH_TEMPLATE_SUCCESS:
      return { ...state, loading: false, batchManagements: action.payload, error: null };
    case actions.SAVE_BATCH_TEMPLATE_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    //Game Management Fast Games

    case actions.LOAD_FAST_GAMES_MANAGEMENT_DATA:
      return { ...state, loading: true, error: null };
    case actions.LOAD_FAST_GAMES_MANAGEMENT_DATA_SUCCESS:
      return { ...state, loading: false, fastGames: action.payload, error: null };

    case actions.UPDATE_FAST_GAMES_TABLE:
      return { ...state, loading: true, error: null };
    case actions.UPDATE_FAST_GAMES_STATUS_SUCCESS:
      return { ...state, loading: false, fastGames: action.payload, error: null };
    case actions.UPDATE_FAST_GAMES_STATUS_FAILURE:
      return { ...state, loading: false, error: action.payload.error };

    default:
      return state;
  }
}

export const getBatchManagementData = (state: AppState) => state.fastGamesManagementReducer.batchManagements;

export const getFastGamesManagementData = (state: AppState) => state.fastGamesManagementReducer.fastGames;
export const getFastGamesManagementDataDropDown = createSelector(getFastGamesManagementData, (fastGamesList: any) => {
  if (fastGamesList) {
    return fastGamesList.map((fastGamesNames) => {
      return {
        label: fastGamesNames.Name,
        value: fastGamesNames.ID
      };
    });
  }
});

