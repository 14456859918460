import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { ePermissionsLevel } from '../../core/app.config';
import { AuthorizationService } from './authorization.service';

@Directive({
  selector: '[appCanAccessControl]',
})
export class CanAccessControlDirective implements OnInit {
  @Input() CanAccess: string | string[];
  @Input() permissionLevel: ePermissionsLevel;
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private authorizationServiceService: AuthorizationService
  ) { }
  ngOnInit(): void {
    this.applyPermission();
  }

  private applyPermission(): void {
    if (
      !this.authorizationServiceService.hasPermission(this.CanAccess, this.permissionLevel)
    ) {
      this.renderer.setProperty(this.elementRef.nativeElement, 'disabled', true);
    }
  }
}
