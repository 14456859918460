import { createReducer, on } from "@ngrx/store";
import { sportTypesInitialState, sportsTypesAdapter } from "../adapters";
import { SbSetSportsTypes, SbUpdateSportType, SbUpdateSportTypeSuccess, SportTypesFailure } from "../actions";
import { OperationType } from "src/app/sport-betting/common/models";

export const SportTypesReducer = createReducer(
  sportTypesInitialState,
  on(SbSetSportsTypes, (state, { payload }) => {
    return sportsTypesAdapter.setAll(payload.data, {
      ...state,
      totalCount: payload.totalCount
    });
  }),
  on(SbUpdateSportType, (state) => {
    return {
      ...state,
      operationResult: null
    };
  }),
  on(SbUpdateSportTypeSuccess, (state, { payload }) => {
    return sportsTypesAdapter.updateOne({
      id: payload.sportTypeId,
      changes: payload
    }, {
      ...state,
      operationResult: {
        id: payload.sportTypeId,
        isSuccessful: true,
        operationType: OperationType.Update
      }
    });
  }),
  on(SportTypesFailure, (state, payload) => {
    return {
      ...state,
      operationResult: {
        id: payload.identifier,
        isSuccessful: false,
        operationType: payload.operationType
      }
    };
  })
);
