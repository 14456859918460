import { Action } from '@ngrx/store';

export const GET_EVENTS_DATA = '[EVENTS] Get Events Data';
export const SAVE_EVENTS_DATA = '[EVENTS] Save Events Data';
export const SAVE_EVENTS_DATA_SUCCESS = '[EVENTS] Save Events Data Success';
export const SAVE_PANEL_BETSLIP_DATA = '[EVENTS] Save Panel Betslip Data';

export const FILTER_MANUAL_CHANGES = '[EVENTS] Filter Manual Changes';
export const FILTER_PENDING_ODDS = '[EVENTS] Filter Pending Odds';
export const CUSTOMIZE_EVENT = '[EVENTS] Customize Event';
export const CUSTOMIZE_EVENT_SUCCESS = '[EVENTS] Customize Event Success';
export const CUSTOMIZE_EVENT_FAILED = '[EVENTS] Customize Event Failed';
export const CUSTOMIZE_ODDS = '[EVENTS] Customize Odds';
export const CUSTOMIZE_ODDS_SUCCESS = '[EVENTS] Customize Odds Success';
export const CUSTOMIZE_ODDS_FAILED = '[EVENTS] Customize Odds Failed';

export const CUSTOMIZE_EVENT_DETAILS = '[EVENTS] Customize Event Details';
export const CUSTOMIZE_EVENT_DETAILS_SUCCESS = '[EVENTS] Customize Event Details Success';
export const CUSTOMIZE_EVENT_DETAILS_FAILED = '[EVENTS] Customize Event Details Failed';

export const GET_MATCHES = '[EVENTS] Get Matches';
export const SET_MATCHES = '[EVENTS] Set Matches';

export const GET_EVENT_DETAILS = '[EVENTS] Get Event Details';
export const SET_EVENT_DETAILS = '[EVENTS] Set Event Details';

export const GET_EVENT_AUTOCOMPLETE = '[EVENTS] Get Event AutoComplete';
export const SET_EVENT_AUTOCOMPLETE = '[EVENTS] Set Event AutoComplete';
export const GET_PRODUCT_FILTER = '[EVENTS] Set Product Filter';
export const SET_SPORTS_TREE_FILTER = '[EVENTS] Set Sports Tree Filter';
export const RESET_SPORTS_TREE_FILTER = '[EVENTS] ResSet Sports Tree Filter';

export const FAILURE = '[EVENTS] Failure';

export const SET_FAILURE = '[EVENTS] Set Failure';

export class FilterPendingOdds implements Action {
  readonly type = FILTER_PENDING_ODDS;

  constructor(public payload: any = null) {}
}
export class FilterManualChanges implements Action {
  readonly type = FILTER_MANUAL_CHANGES;

  constructor(public payload: any = null) {}
}
export class CustomizeOdds implements Action {
  readonly type = CUSTOMIZE_ODDS;

  constructor(public payload: any = null) {}
}
export class CustomizeOddsSuccess implements Action {
  readonly type = CUSTOMIZE_ODDS_SUCCESS;


}
export class CustomizeOddsFailed implements Action {
  readonly type = CUSTOMIZE_ODDS_FAILED;


}
export class CustomizeEventDetails implements Action {
  readonly type = CUSTOMIZE_EVENT_DETAILS;

  constructor(public payload: any = null) {}
}
export class CustomizeEventDetailsSuccess implements Action {
  readonly type = CUSTOMIZE_EVENT_DETAILS_SUCCESS;


}
export class CustomizeEventDetailsFailed implements Action {
  readonly type = CUSTOMIZE_EVENT_DETAILS_FAILED;


}
export class CustomizeEvent implements Action {
  readonly type = CUSTOMIZE_EVENT;

  constructor(public payload: any = null) {}
}
export class CustomizeEventSuccess implements Action {
  readonly type = CUSTOMIZE_EVENT_SUCCESS;


}
export class CustomizeEventFailed implements Action {
  readonly type = CUSTOMIZE_EVENT_FAILED;


}

export class GetEventsData implements Action {
  readonly type = GET_EVENTS_DATA;

  constructor(public payload: any = null) {}
}
export class SaveEventsData implements Action {
  readonly type = SAVE_EVENTS_DATA;

  constructor(public payload: any = null) {}
}
export class SaveEventsDataSuccess implements Action {
  readonly type = SAVE_EVENTS_DATA;


}
export class SavePanelBetslipData implements Action {
  readonly type = SAVE_PANEL_BETSLIP_DATA;

  constructor(public payload: any = null) {}
}

export class GetMatches implements Action {
  readonly type = GET_MATCHES;

  constructor(public payload: any = null) {}
}
export class SetMatches implements Action {
  readonly type = SET_MATCHES;

  constructor(public payload: any = null) {}
}
export class GetEventAutoComplete implements Action {
  readonly type = GET_EVENT_AUTOCOMPLETE;

  constructor(public payload: any = null) {}
}
export class SetEventAutoComplete implements Action {
  readonly type = SET_EVENT_AUTOCOMPLETE;

  constructor(public payload: any = null) {}
}
export class GetEventDetails implements Action {
  readonly type = GET_EVENT_DETAILS;

  constructor(public payload: any = null) {}
}
export class SetEventDetails implements Action {
  readonly type = SET_EVENT_DETAILS;

  constructor(public payload: any = null) {}
}
export class SetSportsTreeFilter implements Action {
  readonly type = SET_SPORTS_TREE_FILTER;

  constructor(public payload: any = null) {}
}
export class GetProductFilter implements Action {
  public readonly type = GET_PRODUCT_FILTER;

  constructor(public payload: any = null) {}
}
export class ResetSportsTreeFilter implements Action {
  readonly type = RESET_SPORTS_TREE_FILTER;

}
export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;


}

export type Actions =
  | FilterPendingOdds
  | FilterManualChanges
  | CustomizeEvent
  | CustomizeEventSuccess
  | CustomizeEventFailed
  | CustomizeOdds
  | CustomizeOddsSuccess
  | CustomizeOddsFailed
  | CustomizeEventDetails
  | CustomizeEventDetailsSuccess
  | CustomizeEventDetailsFailed
  | SaveEventsData
  | SaveEventsDataSuccess
  | SavePanelBetslipData
  | GetEventsData
  | GetMatches
  | SetMatches
  | GetEventDetails
  | SetEventDetails
  | SetSportsTreeFilter
  | ResetSportsTreeFilter
  | GetEventAutoComplete
  | SetEventAutoComplete
  | Failure
  | SetFailure;
