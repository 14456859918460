import { Injectable } from '@angular/core';
import { AppSettings, GeneralConstants } from '../app.config';
import brand from '../../../assets/brand.json';
import config from '../../../assets/config.json';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService {
  private appConfig = { BrandId: brand.brandId };

  

  loadAppConfig() {
    this.setBaseURL();
    this.setBaseStyles();
  }

  getBrandId(): string {
    return this.appConfig.BrandId;
  }

  setBaseURL() {
    let baseURL;
    if (window.location.host.includes('localhost')) {
      baseURL = AppSettings.PROTOCOL.concat(AppSettings.LOCAL_BASE_URL);
      AppSettings.BRAND_DOMAIN = 'idobet.com';
    } else {
      baseURL = AppSettings.PROTOCOL.concat(window.location.host);
    }
    AppSettings.BASE_API_ENDPOINT = baseURL + '/' + AppSettings.BASE_API_ENDPOINT;
    AppSettings.BASE_URL_ENDPOINT = baseURL;
    AppSettings.API_ENDPOINT = baseURL + '/' + AppSettings.API_ENDPOINT;
    AppSettings.API_ENDPOINT_SERVICES = baseURL + '/' + AppSettings.API_ENDPOINT_SERVICES;
    AppSettings.EV_API_ENDPOINT = baseURL + '/' + AppSettings.EV_API_ENDPOINT;
    AppSettings.LOTTO_API_ENDPOINT = baseURL + '/' + AppSettings.LOTTO_API_ENDPOINT;
    GeneralConstants.OLD_BACKOFFICE_BETSLIPS = baseURL + '/' + GeneralConstants.OLD_BACKOFFICE_BETSLIPS;
    AppSettings.BRAND_NAME = config[AppSettings.BRAND_DOMAIN].brandName;
  }

  setBaseStyles() {
    // change default theme in header index.html
    const themeLink: HTMLLinkElement = document.getElementById('theme-css') as HTMLLinkElement;
    themeLink.href =
      'assets/theme/' +
      (localStorage.getItem('theme') || config[AppSettings.BRAND_DOMAIN].themeColor) +
      '/theme-' +
      (localStorage.getItem('colorScheme') || config[AppSettings.BRAND_DOMAIN].colorScheme) +
      '.css';

    // change default layout in header index.html
    const layoutLink: HTMLLinkElement = document.getElementById('layout-css') as HTMLLinkElement;
    layoutLink.href =
      'assets/layout/css/' +
      (localStorage.getItem('layout') || config[AppSettings.BRAND_DOMAIN].layoutColor) +
      '/layout-' +
      (localStorage.getItem('colorScheme') || config[AppSettings.BRAND_DOMAIN].colorScheme) +
      '.css';
  }
}
