import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-date-cell',
  templateUrl: './date-cell.component.html',
  styleUrls: ['./date-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateCellComponent implements OnChanges, OnDestroy {
  @Input() value: string;
  modelInEditMode: any;
  @Input() format: string;
  @Input() compName: string;
  @Input() noDataTemplate: boolean;
  @Input() inEditState: boolean;
  @Input() maxDateToCompareWith: any;
  @Input() minDateToCompareWith: any;
  @Input() appendTo: any;
  @Input() placeHolder: string;
  @Output() finishedEditEvent = new EventEmitter<any>();
  wasTouched: boolean;
  typingTimer: any;
  doneTypingInterval = 1000;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.maxDateToCompareWith && changes.maxDateToCompareWith.currentValue) {
      this.maxDateToCompareWith = this.maxDateToCompareWith ? new Date(Date.parse(this.maxDateToCompareWith)) : null;
    }
    if (changes.minDateToCompareWith && changes.minDateToCompareWith.currentValue) {
      this.minDateToCompareWith = this.minDateToCompareWith ? new Date(Date.parse(this.minDateToCompareWith)) : null;
    }
    if (changes.value && changes.value.currentValue) {
      this.modelInEditMode = this.value ? new Date(Date.parse(this.value)) : null;
    }
  }
  @HostListener('window:keyup', ['$event.target']) //typing case
  keyEvent(target: any) {
    if (target.name == this.compName) {
      clearTimeout(this.typingTimer);
      this.wasTouched = true;
      this.typingTimer = setTimeout(() => {
        this.emitEditEvent();
      }, this.doneTypingInterval);
    }
  }
  emitEditEvent() {
    if (
      this.wasTouched &&
      (Object.prototype.toString.call(this.modelInEditMode) == '[object Date]' || this.modelInEditMode == null)
    ) {
      this.finishedEditEvent.emit(this.modelInEditMode);
    }
  }
  ngOnDestroy() {
    if (this.typingTimer) {
      clearTimeout(this.typingTimer);
    }
  }
}
