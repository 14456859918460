import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import config from '../assets/config.json';
import { AppSettings } from './core/app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  menuMode = localStorage.getItem('menuType') || config[AppSettings.BRAND_DOMAIN].menuType;

  layout = localStorage.getItem('layout') || config[AppSettings.BRAND_DOMAIN].layoutColor;

  theme = localStorage.getItem('theme') || config[AppSettings.BRAND_DOMAIN].themeColor;

  ripple: boolean = localStorage.getItem('rippleEffect') == 'true';

  colorScheme = localStorage.getItem('colorScheme') || config[AppSettings.BRAND_DOMAIN].colorScheme;

  constructor(private primengConfig: PrimeNGConfig, private translate: TranslateService) {
    this.translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.primengConfig.ripple = this.ripple;
  }
}
