import { Action } from '@ngrx/store';

export const GET_FEED_STATUS = 'GET_FEED_STATUS';
export const SET_FEED_STATUS = 'SET_FEED_STATUS';

export class GetFeedStatus implements Action {
  public readonly type = GET_FEED_STATUS;

  constructor(public payload: number) { }
}
export class SetFeedStatus implements Action {
  public readonly type = SET_FEED_STATUS;

  constructor(public payload: string) { }
}