import * as eventsActions from '../actions/events.actions';
import {
  BetType,
  BetTypeCustom,
  EventAutoCompleteSuggestion,
  OddTypeCustom,
  SportGame,
  SportsTreeFilter,
} from '../../models/events.models';
import * as fromApp from 'abs@core/store/reducers';
import {createSelector} from '@ngrx/store';
import * as moment from 'moment';
import {GeneralConstants} from 'abs@core/app.config';
import {eEventStatus} from 'abs@shared/eNums';
import {orderBy} from 'lodash';
import copy from 'fast-copy';

export interface State {
  matches: SportGame[];
  totalCountMatches: number;
  eventDetails: SportGame;
  loading: boolean;
  inProcess: boolean;
  sportsTreeFilter: SportsTreeFilter;
  eventAutoCompleteSuggestions: EventAutoCompleteSuggestion[];
  processingOdds: boolean;
  processEventGrid: boolean;
  processEventDetails: boolean;
  showOnlyManualChanges: boolean;
  showOnlyPendingOdds: boolean;
  gameName: { ProductTypeIds: number[] };
}

export const initialState: State = {
  matches: null,
  eventDetails: null,
  totalCountMatches: null,
  loading: false,
  inProcess: false,
  sportsTreeFilter: null,
  eventAutoCompleteSuggestions: null,
  processingOdds: false,
  processEventGrid: false,
  processEventDetails: false,
  showOnlyManualChanges: false,
  showOnlyPendingOdds: false,
  gameName: null,
};

export function EventsReducer(state = initialState, action: any): State {
  switch (action.type) {
    case eventsActions.FILTER_MANUAL_CHANGES: {
      return {
        ...state,
        showOnlyManualChanges: action.payload,
      };
    }
    case eventsActions.FILTER_PENDING_ODDS: {
      return {
        ...state,
        showOnlyPendingOdds: action.payload,
      };
    }
    case eventsActions.CUSTOMIZE_EVENT: {
      return {
        ...state,
        processEventGrid: true,
      };
    }
    case eventsActions.CUSTOMIZE_ODDS: {
      return {
        ...state,
        processingOdds: true,
      };
    }
    case eventsActions.CUSTOMIZE_EVENT_DETAILS: {
      return {
        ...state,
        processEventDetails: true,
      };
    }
    case eventsActions.CUSTOMIZE_EVENT_DETAILS_SUCCESS: {
      return {
        ...state,
        processEventDetails: false,
      };
    }
    case eventsActions.CUSTOMIZE_EVENT_DETAILS_FAILED: {
      return {
        ...state,
        processEventDetails: false,
      };
    }
    case eventsActions.CUSTOMIZE_ODDS_SUCCESS: {
      return {
        ...state,
        processingOdds: false,
      };
    }
    case eventsActions.CUSTOMIZE_ODDS_FAILED: {
      return {
        ...state,
        processingOdds: false,
      };
    }
    case eventsActions.CUSTOMIZE_EVENT_SUCCESS: {
      return {
        ...state,
        processEventGrid: false,
      };
    }
    case eventsActions.CUSTOMIZE_EVENT_FAILED: {
      return {
        ...state,
        processEventGrid: false,
      };
    }
    case eventsActions.GET_EVENTS_DATA: {
      return {
        ...state,
        loading: true,
      };
    }
    case eventsActions.SAVE_EVENTS_DATA: {
      return {
        ...state,
        inProcess: true,
      };
    }
    case eventsActions.SAVE_EVENTS_DATA_SUCCESS: {
      return {
        ...state,
        inProcess: false,
      };
    }
    case eventsActions.SAVE_PANEL_BETSLIP_DATA: {
      return {
        ...state,
        inProcess: true,
      };
    }
    case eventsActions.GET_MATCHES: {
      return {
        ...state,
        loading: true,
      };
    }
    case eventsActions.SET_MATCHES: {
      return {
        ...state,
        matches: action.payload.data,
        totalCountMatches: action.payload.totalCount,
        loading: false,
      };
    }
    case eventsActions.GET_EVENT_DETAILS: {
      return {
        ...state,
        eventDetails: null,
        loading: true,
      };
    }
    case eventsActions.SET_EVENT_AUTOCOMPLETE: {
      return {
        ...state,
        eventAutoCompleteSuggestions: action.payload,
      };
    }
    case eventsActions.GET_PRODUCT_FILTER: {
      return {
        ...state,
        gameName: action.payload,
      };
    }
    case eventsActions.SET_EVENT_DETAILS: {
      return {
        ...state,
        eventDetails: action.payload[0] ? action.payload[0] : undefined,
        loading: false,
      };
    }
    case eventsActions.SET_SPORTS_TREE_FILTER: {
      return {
        ...state,
        sportsTreeFilter: action.payload,
      };
    }
    case eventsActions.RESET_SPORTS_TREE_FILTER: {
      return {
        ...state,
        sportsTreeFilter: null,
      };
    }
    case eventsActions.SET_FAILURE: {
      return {
        ...state,
        loading: false,
        inProcess: false,
      };
    }
    default: {
      return state;
    }
  }
}

export const getProductType = (state: fromApp.AppState) => state.eventsReducer.gameName;
export const getProductTypeData = createSelector(getProductType, (v) => {
  if(v?.ProductTypeIds?.indexOf(11) > -1){
  return v.ProductTypeIds;
  }
  return [];
});
export const getMatches = (state: fromApp.AppState) => state.eventsReducer.matches;
export const getIfToShowOnlyPendingOdds = (state: fromApp.AppState) => state.eventsReducer.showOnlyPendingOdds;
export const getIfToShowOnlyManualChanges = (state: fromApp.AppState) => state.eventsReducer.showOnlyManualChanges;
export const getInProcessState = (state: fromApp.AppState) => state.eventsReducer.inProcess;
export const getTotalCountMatches = (state: fromApp.AppState) => state.eventsReducer.totalCountMatches;
export const getLoading = (state: fromApp.AppState) => state.eventsReducer.loading;
export const getSportsTreeFilter = (state: fromApp.AppState) => state.eventsReducer.sportsTreeFilter;
export const getEventDetails = (state: fromApp.AppState) => state.eventsReducer.eventDetails;
export const getEventAutoCompleteSuggestions = (state: fromApp.AppState) =>
  state.eventsReducer.eventAutoCompleteSuggestions;
export const getProcessingEventDetails = (state: fromApp.AppState) => state.eventsReducer.processEventDetails;
export const getProcessingOdds = (state: fromApp.AppState) => state.eventsReducer.processingOdds;
export const getEventAutoCompleteSuggestionsCustom = createSelector(getEventAutoCompleteSuggestions, (events) => {
  if (events) {
    return events.map((event) => {
      event.toShow =
        moment(event.date).format(GeneralConstants.PIPE_DATE_TIME_FORMAT_SHORT) +
        ' ' +
        event.homeTeam.name +
        ' VS ' +
        event.awayTeam.name;
      return event;
    });
  }
  return null;
});
export const getEventDetailsData = createSelector(getEventDetails, (sportGame) => {
  if (sportGame) {
    return sportGame;
  }
  return null;
});
export const getEventDetailsDataGuard = createSelector(getEventDetails, (sportGame) => {
  if (sportGame) {
    return sportGame;
  }
  return 'EMPTY';
});
export const filteredBetTypes = createSelector(
  getIfToShowOnlyPendingOdds,
  getIfToShowOnlyManualChanges,
  getEventDetails,
  (showOnlyPendingOdds, ShowOnlyManualChanges) => {
    return [showOnlyPendingOdds, ShowOnlyManualChanges];
  }
);
export const mapEventDetailsBetTypes = createSelector(getEventDetailsData, (sportGame) => {
  if (sportGame && sportGame.betTypes) {
    const game: SportGame = copy(sportGame);
    calculateCustomization(game);
    orderBetTypesAndOdds(game);
    game.betTypes.forEach((betType: BetType) => {
      calculateBetTypeStatus(betType);
    });
    return game;
  }
  return null;
});
export const getEventBetTypesDropDown = createSelector(getEventDetails, (sportGame) => {
  if (sportGame && sportGame.betTypes && sportGame.betTypes.length > 0) {
    return sportGame.betTypes.map((betType: BetType) => {
      const arrStatuses = [];
      if (betType.isLive) {
        arrStatuses.push('live');
      }
      arrStatuses.push(eEventStatus[betType.clientStatus]);
      return {
        label: betType.name + ' (' + betType.id + ')',
        value: betType.id,
        key: arrStatuses.join(),
      };
    });
  }
  return null;
});
export const getCurrentOddsByRow = (id) =>
  createSelector(getEventDetails, (sportGame) => {
    if (sportGame && sportGame[0] && sportGame[0].betTypes && sportGame[0].betTypes.length > 0) {
      return sportGame[0].betTypes[id];
    }
    return null;
  });

function orderBetTypesAndOdds(sportGame: SportGame) {
  sportGame.betTypes.forEach((betType, indexBetType) => {
    if (!betType.clientOrder) {
      // case its null
      if (betType.isLive) {
        betType.clientOrder = 1;
      } else {
        betType.clientOrder = 1000000 + indexBetType;
      }
    }
    betType.odds.forEach((odd) => {
      odd.clientOrderRow = odd.clientOrderRow === null ? 1 : odd.clientOrderRow;
      odd.clientOrderCol = odd.clientOrderCol === null ? 1 : odd.clientOrderCol;
      odd.clientOrderCustom = (odd.clientOrderRow - 1) * 3 + odd.clientOrderCol;
    });
    betType.odds = orderBy(betType.odds, ['clientOrderCustom', 'clientOddName'], ['asc']);
  });
  sportGame.betTypes = orderBy(sportGame.betTypes, ['clientOrder', 'clientName'], ['asc']);
}

function calculateBetTypeStatus(betType: BetType) {
  if (betType.odds.length > 0) {
    let closed = 0;
    for (let i = 0; i < betType.odds.length; i++) {
      if (betType.odds[i].oddStatusId === 0) {
        betType.clientBetTypeStatusName = 'Open';
        betType.isActive = true;
        return;
      } else if (betType.odds[i].oddStatusId === 2) {
        closed += 1;
      }
    }
    if (betType.odds.length === closed) {
      betType.clientBetTypeStatusName = 'Closed';
      betType.isActive = false;
    } else {
      betType.clientBetTypeStatusName = 'Suspended';
      betType.isActive = false;
    }
  } else {
    betType.clientBetTypeStatusName = 'Closed';
  }
}

function calculateCustomization(sportGame: SportGame) {
  if (sportGame.customization) {
    if (sportGame.customization.betTypes) {
      sportGame.customization.betTypesCustomized = new Map<number, BetTypeCustom>();
      sportGame.customization.betTypes.forEach((betType) => {
        sportGame.customization.betTypesCustomized.set(betType.id, betType);
        if (betType.odds) {
          betType.oddsCustomized = new Map<any, OddTypeCustom>();
          betType.odds.forEach((odd) => {
            betType.oddsCustomized.set(odd.id.toString(), odd);
          });
        }
      });
    }
  }
  return null;
}

