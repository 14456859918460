import { Action } from '@ngrx/store';

export const GET_SPORTSBOOK_SETTINGS_DATA = '[SportsBook-Settings] Get Setting Data';
export const SPORTSBOOK_SETTINGS_ACTION = '[SportsBook-Settings] Setting Action';
export const SPORTSBOOK_SETTINGS_ACTION_SUCCESS = '[SportsBook-Settings] Setting Action Success';
export const SPORTSBOOK_SETTINGS_DELETE_RULE_SUCCESS = '[SportsBook-Settings] Setting Delete Rule Success';
export const SPORTSBOOK_SETTINGS_ACTION_CHANGE_STATUS_SUCCESS =
  '[SportsBook-Settings] Setting Action Change Status Success';
export const SPORTSBOOK_SETTINGS_ACTION_CHANGE_ODDS_THRESHOLD_SUCCESS =
  '[SportsBook-Settings] Setting Action Change Odds Threshold Success';
export const SPORTSBOOK_SETTINGS_ACTION_CHANGE_MARGIN_RATIO_SUCCESS =
  '[SportsBook-Settings] Setting Action Change Margin Ratio Success';

export const SET_TURBO_CASHOUT_RULES = '[SportsBook-Settings] Set Turbo Cashout Rules';
export const SET_PROFITABILITY_REPORT = '[SportsBook-Settings] Set Profitability Report';
export const SET_PROFITABILITY_REPORT_SUMMARY = '[SportsBook-Settings] Set Profitability Report Summary';

export const SET_FAILURE = '[SportsBook-Settings] Set Failure';
export const FAILURE = '[SportsBook-Settings] Failure';

export class GetSportsBookSettingsData implements Action {
  readonly type = GET_SPORTSBOOK_SETTINGS_DATA;
  constructor(public payload: any) {}
}
export class SportsBookSettingsAction implements Action {
  readonly type = SPORTSBOOK_SETTINGS_ACTION;
  constructor(public payload: any) {}
}
export class SportsBookSettingsDeleteRuleSuccess implements Action {
  readonly type = SPORTSBOOK_SETTINGS_DELETE_RULE_SUCCESS;
  
}
export class SportsBookSettingsActionChangeStatusSuccess implements Action {
  readonly type = SPORTSBOOK_SETTINGS_ACTION_CHANGE_STATUS_SUCCESS;
  
}
export class SportsBookSettingsActionChangeOddsThresholdSuccess implements Action {
  readonly type = SPORTSBOOK_SETTINGS_ACTION_CHANGE_ODDS_THRESHOLD_SUCCESS;
  
}
export class SportsBookSettingsActionChangeMarginRatioSuccess implements Action {
  readonly type = SPORTSBOOK_SETTINGS_ACTION_CHANGE_MARGIN_RATIO_SUCCESS;
  
}

export class SetTurboCashoutRules implements Action {
  readonly type = SET_TURBO_CASHOUT_RULES;
  constructor(public payload: any = null) {}
}
export class SetProfitabilityReport implements Action {
  readonly type = SET_PROFITABILITY_REPORT;
  constructor(public payload: any = null) {}
}
export class SetProfitabilityReportSummary implements Action {
  readonly type = SET_PROFITABILITY_REPORT_SUMMARY;
  constructor(public payload: any = null) {}
}
export class SetFailure implements Action {
  readonly type = SET_FAILURE;

  
}
export class Failure implements Action {
  readonly type = FAILURE;

  constructor(public payload: any) {}
}
export type Actions =
  | GetSportsBookSettingsData
  | SetTurboCashoutRules
  | SetProfitabilityReport
  | SetProfitabilityReportSummary
  | SportsBookSettingsAction
  | SportsBookSettingsActionChangeStatusSuccess
  | SportsBookSettingsActionChangeOddsThresholdSuccess
  | SportsBookSettingsActionChangeMarginRatioSuccess
  | SportsBookSettingsDeleteRuleSuccess
  | Failure
  | SetFailure;
