<ng-template *ngIf="inEditState then editTemplate else normal"></ng-template>
<ng-template #editTemplate>
  <p-calendar
    dateFormat="dd/mm/yy"
    [name]="compName"
    [maxDate]="maxDateToCompareWith"
    [minDate]="minDateToCompareWith"
    [showTime]="true"
    (onSelect)="wasTouched = true"
    [appendTo]="appendTo"
    (onClose)="emitEditEvent()"
    [(ngModel)]="modelInEditMode"
    placeholder="{{placeHolder | translate}}"></p-calendar>
</ng-template>
<ng-template #normal>
  <span *ngIf="value else noData">{{value | dateTimeExtendedTimeZoneAsync: format | async}}</span>
</ng-template>

<ng-template #noData>
  <span *ngIf="noDataTemplate">
    <i class="fas fa-minus noData"></i>
  </span>
</ng-template>
